import { createStyles, makeStyles } from '@material-ui/core';
export const styles = makeStyles(() =>
  createStyles({
    contactUsBG: {
      backgroundColor: 'rgba(245, 245, 245, 0.35)'
    },
    contactUsWrapper: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      alignItems: 'center',
      padding: '57px 0 0',
      minHeight: '382px',
      maxWidth: '1440px',
      margin: '0 auto',
      '@media only screen and (max-width: 870px)': {
        gridTemplateColumns: '100%',
        paddingBottom: '57px'
      }
    },
    contactUsLeftSide: {
      height: '100%',
      padding: '0 50px 30px 0',
      '@media only screen and (max-width: 1110px)': {
        padding: '0 25px 30px 0 !important'
      },
      '@media only screen and (max-width: 870px)': {
        padding: '0 !important'
      }
    },
    slideRight: {
      overflow: 'hidden',
      paddingLeft: '240px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      '@media only screen and (max-width: 1110px)': {
        paddingLeft: '140px !important'
      },
      '@media only screen and (max-width: 870px)': {
        padding: '0 25px !important'
      }
    },
    contactUsTitle: {
      marginBottom: '16px',
      fontSize: '28px',
      fontWeight: 800,
      color: '#3a78f2'
    },
    contactUsSubTitle: {
      marginBottom: '8px',
      fontSize: '40px',
      fontWeight: 'bold',
      color: '#55647d'
    },
    contactUsDescription: {
      margin: '8px 23px 16px 0',
      opacity: '0.8',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '1.5',
      color: '#55647d'
    },
    contactUsRightSide: {
      height: '100%',
      paddingLeft: '50px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      '@media only screen and (max-width: 1110px)': {
        padding: '0 25px 30px 0 !important'
      },
      '@media only screen and (max-width: 870px)': {
        display: 'none !important'
      }
    },
    slideLeft: {
      paddingRight: '240px',
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      '@media only screen and (max-width: 1110px)': {
        paddingRight: '140px !important'
      }
    },
    contactUsImgWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end'
    },
    contactUsImg: {
      width: '100%',
      height: '100%'
    }
  })
);
