import { Action } from './Actions';
import { User } from '../../models/User.model';
import * as types from './ActionTypes';
import { STATES } from '../../utils/constants'

// States' definition
export interface Auth {
  // redirectUri: string | null
  isAuthenticated: boolean;
  user: User | null;
  addToSlackUrl: string;
  signInWithSlackUrl: string;
  fetched: boolean;
  status: string;
  error: string
}

const auth = (
  state: Auth = {
    user: null,
    // redirectUri: null
    isAuthenticated: false,
    addToSlackUrl: '',
    signInWithSlackUrl: '',
    fetched: false,
    status: '',
    error: '',
    // code: '',
  },
  action: Action
): Auth => {
  switch (action.type) {
    case types.USER_UPDATED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        user: action.user,
        fetched: true
      };
    case types.FETCH_O_AUTH_URLS_SUCCEEDED:
      return {
        ...state,
        addToSlackUrl: action.addToSlackUrl,
        signInWithSlackUrl: action.signInWithSlackUrl
      };
    case types.AUTHORIZE_SLACK_SUCCEEDED:
      return {
        ...state,
        status: action.status
      };
    case types.AUTHORIZE_SLACK_FAILED:
      return {
        ...state,
        status: STATES.ERROR
      };

    // case types.SET_AFTER_LOGIN_REDIRECTION_URL:
    //   return { ...state, redirectUri: action.redirectUri }

    default:
      return state;
  }
};
export default auth;
