import React from 'react';
import { Button } from '@material-ui/core';
import { Link as RLink } from 'react-router-dom';
import  PATH_NAME from '../../../../utils/pathNames';
import { styles } from './WhatsNewStyles';
import t from '../../../../translations/english';

const WhatsNew = () => {
  const classes = styles();

  return (
    <>
      <RLink to={PATH_NAME.RELEASE_NOTES} className={classes.link}>
        <Button
          variant="contained"
          classes={{
            root: classes.whatsNewButton,
            label: classes.buttonLabel
          }}
        >
          {t.whatsNew.button}
        </Button>
      </RLink>
    </>
  );
};

export default WhatsNew;
