import { makeStyles, createStyles } from '@material-ui/core/styles';

export const workspaceStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      margin: 0,
      flexWrap: 'nowrap'
    },
    workspaceCardsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 !important',
      margin: 0,
      width: '100%'
    },
    workspacesTitle: {
      margin: '0 0 16px 0',
      fontFamily: '"lato", Lato-Bold',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: 1.35,
      letterSpacing: 'normal',
      color: '#858d9b'
    },
    workspaceCard: {
      padding: '0 !important',
      margin: '16px 0 0 0',
      '&:first-of-type': {
        margin: '0 0 0 0'
      }
    },
    newWorkspaceBtnWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 !important'
    },
    newWorkspaceBtn: {
      width: 'auto',
      height: '32px',
      borderRadius: '4px',
      boxShadow: '0 2px 4px 0 rgba(89, 89, 89, 0.11)',
      backgroundColor: '#f5a623',
      whiteSpace: 'nowrap',
      marginBottom: '20px',
      '&:hover': {
        backgroundColor: '#f5a623'
      }
    }
  })
);
