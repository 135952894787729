import { makeStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

export const attachmentsStyles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: 5,
      border: 'solid 1px #ededed',
      padding: '16px 16px 0 16px',
      backgroundColor: 'rgba(58, 120, 242, 0.05)',
      boxShadow: '0 2px 8px 0 rgba(37, 37, 37, 0.03)',
      borderLeft: `solid 4px ${fade('#3a78f2', 0.7)}`
    },
    commentsTitleIcon: {
      opacity: 0.7,
      marginRight: 8,
      color: '#3a78f2',
      fontSize: 'large'
    },
    commentsTitleText: {
      color: '#000',
      marginTop: -8,
      fontSize: '16px',
      fontFamily: 'Lato-Bold',
      letterSpacing: 'normal'
    },
    commentBody: {
      color: '#636e79',
      fontSize: '16px',
      fontFamily: 'Lato',
      letterSpacing: 'normal',
      margin: '8px 8px 16px 32px'
    },
    commentMemberInfo: {
      marginTop: 8
    },
    commentEditor: {
      padding: 8,
      position: 'relative',
      backgroundColor: '#fff',
    },
    commentEditorIcon: {
      width: 20,
      height: 20,
      opacity: 0.5,
      color: ' #7a7e83',
      position: 'absolute',
      margin: '5px 5px 0 0',
      '@media (max-width: 450px)': {
        display: 'none'
      }
    },
    submitBtn: {
      opacity: 0.8,
      fontSize: 16,
      color: '#3a78f2',
      cursor: 'pointer',
      fontFamily: 'Lato',
      textAlign: 'right',
      letterSpacing: '0.13px'
    },
    cancelBtn: {
      color: 'rgba(0, 0, 0, 0.6)'
    },
    submitBtnAbsolute: {
      top: 24,
      right: 36,
      position: 'absolute'
    },
    moreIcon: {
      opacity: 0.35,
      color: '#000000',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    actionMenuItem: {
      opacity: '0.8',
      fontSize: '19px',
      color: '#474f58',
      fontFamily: 'Lato'
    },
    actionMenuDeleteItem: {
      opacity: '0.8',
      fontSize: '19px',
      color: '#d0021b',
      fontFamily: 'Lato'
    },
    subMenuPaper: {
      width: '110px',
      backgroundColor: '#fff',
      boxShadow: '2px 0 24px 0 rgba(103, 101, 101, 0.14)'
    },
    editor: {
      padding: 10,
      cursor: 'text',
      borderRadius: 2,
      marginBottom: 8,
      color: '#85909c',
      fontSize: '14px',
      fontFamily: 'Lato',
      lineHeight: 'normal',
      border: '1 solid #ddd',
      backgroundColor: '#fff',
      letterSpacing: 'normal',
      boxShadow: 'inset 0px 1px 8px -3px #ababab'
    },
    loadMoreButton: {
      display: 'flex',
      margin: '0 auto',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    loadMoreBtnLabel: {
      opacity: 0.7,
      color: '#3a78f2',
      fontSize: '14px',
      fontFamily: 'Lato',
      fontWeight: 'bold',
      margin: '4px 0 4px 5px'
    },
    time: {
      color: '#a9aeb7',
      fontSize: '12px',
      fontFamily: 'Lato',
      letterSpacing: 'normal'
    },
    timeSpan: {
      color: '#858a94',
      fontWeight: 'bold'
    },
    rootEditor: {
      '& .DraftEditor-root': {
        paddingRight: 0
      },
      '@media (max-width: 450px)': {
        marginLeft: 0
      }
    },
    likesWrapper: {
      marginTop: 10 ,
    }, 
    likeButton: {
      marginRight: 8 ,
      marginLeft: 5 ,
      cursor: 'pointer' ,
    },
    likesCounter: {
      fontFamily: 'AvenirNext',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#85909C' ,
      marginTop: 2 ,
    },
    reactionWrapper:{
      padding: 8,
      borderRadius: 5,
      backgroundColor: '#fff',
      border: 'solid 1px #ededed',
      boxShadow: '0 2px 8px 0 rgba(37, 37, 37, 0.03)'
    }
  })
);
