import React from 'react';
import { Typography } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import { styles } from './styles';

export default function ResponsiveCarousel({ browser }: { browser: any }) {
  const classes = styles();
  const carouselItems = [
    { image: browser, index: 0 },
    { image: browser, index: 1 },
    { image: browser, index: 2 }
  ];
  return (
    <Carousel
      swipe={true}
      autoPlay={true}
      animation="slide"
      navButtonsAlwaysInvisible={true}
      indicatorContainerProps={{
        style: { textAlign: 'unset', marginLeft: '30px' },
        className: 'indicators'
      }}
      activeIndicatorIconButtonProps={{
        style: { opacity: '1' },
        className: 'active'
      }}
      indicatorIconButtonProps={{
        style: { color: '#ffffff', opacity: '0.5' },
        className: 'indicator'
      }}
    >
      {carouselItems.map(item => (
        <div key={item.index}>
          <img
            src={item.image}
            alt="browser"
            className={classes.browserImage}
          />
          <div>
            <Typography className={classes.title}>
              Create your first report
            </Typography>
            <Typography className={classes.txt}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry
            </Typography>
          </div>
        </div>
      ))}
    </Carousel>
  );
}
