import React from 'react';
import UserComponent from '../../../../../shared-components/UserComponent';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { styles } from './styles';
import { Question } from '../../../../../../models/Question.model';
import { QuestionResponse } from './QuestionGrouping';
import { useMembers } from '../../../ReportForm/reportFormHooks';
import CircularLoader from '../../../../../shared-components/CircularLoader';
import {
  answerSplitterType,
  prepareContentToRender
} from '../../../../../../utils/parser';
import classNames from 'classnames';
import { useMemo } from 'react';

interface QuestionResponseCardProps {
  question: Question;
  quesResponses: QuestionResponse[];
  questionIndex: number;
}

const QuestionResponseCard = ({
  question,
  quesResponses,
  questionIndex
}: QuestionResponseCardProps) => {
  const classes = styles();
  const { data: membersList, isLoading } = useMembers();
  const answersTags = useMemo(() => {
    let answersTags = new Map<number, any[]>();
    if (quesResponses) {
      quesResponses?.map((response, index) => {
        const quesAnswer = response.answer;
        let parsedAnswer: string = '';
        if (quesAnswer) {
          parsedAnswer = prepareContentToRender(
            quesAnswer.content,
            membersList!
          );
          answersTags.set(index, answerSplitterType(parsedAnswer, classes));
        }
      });
    }
    return answersTags;
  }, [quesResponses]);

  return (
    <CircularLoader isLoading={isLoading || !membersList}>
      <Grid container direction="column" className={classes.root}>
        <Grid item className={classes.question}>
          {`Q${questionIndex}: ${question.content}`}
        </Grid>

        <Grid container direction="column" className={classes.QuestionResponse}>
          {quesResponses?.map((response, index) => {
            return (
              <Box key={index}>
                <Grid
                  container
                  alignItems="center"
                  className={classes.QuestionAnswerContainer}
                >
                  <UserComponent
                    name={response.member.name}
                    avatar={response.member.avatar}
                    id={response.member.id}
                  />
                  <Grid item>
                    <Grid container direction="column">
                      <div
                        className={classNames(
                          classes.answer,
                          classes.answerByQuestion
                        )}
                      >
                        {answersTags.get(index)}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </CircularLoader>
  );
};

export default React.memo(QuestionResponseCard);
