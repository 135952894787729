import {
  STATIC_DAYS_REPORT_FORM,
  REPORT_PERIODS,
  STATIC_DAYS_ABBREVIATION
} from './constants';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export interface Time {
  time12Hours: string;
  time24Hours: string;
}

export const get30MinIntervals = () => {
  let intervals = new Map();
  for (let hour = 0; hour < 24; hour++) {
    const hourStart =dayjs().hour(hour).startOf('hour');
    const hourHalf = hourStart.add(30 ,'m');
    intervals.set(hourStart.format('HH:mm'), hourStart.format('hh:mm a'));
    intervals.set(hourHalf.format('HH:mm'), hourHalf.format('hh:mm a'));
  }
  return intervals;
};

// get time from date on form like "2020-10-21T12:00:00.000+02:00" or "12:00"
export const getTimeFromDate = (date: string) => {
  // if (date.length > 5) date = date.slice(0, -6);

  return dayjs(date).isValid() ? dayjs(date).format('HH:mm') : date;
};

export function getTimeFromDateSeconds(date: string) {
  return dayjs(date).format('DD/MM/YY hh:mm A') || date;
}

const getDayIndex = (day: string): number => {
  return STATIC_DAYS_REPORT_FORM.findIndex(weekDay => weekDay === day);
};

const getAbbreviationByIndex = (index: number): string => {
  return STATIC_DAYS_ABBREVIATION[STATIC_DAYS_REPORT_FORM[index]];
};

const getDaysString = (weekRangeHash: { [key: number]: number }): string => {
  const keys = Object.keys(weekRangeHash);

  let resultantString: string[] = [];

  for (const key of keys) {
    const parsedKey = parseInt(key);
    if (parseInt(key) !== weekRangeHash[parsedKey])
      resultantString.push(
        `${getAbbreviationByIndex(parsedKey)}-${getAbbreviationByIndex(
          weekRangeHash[parsedKey]
        )}`
      );
    else resultantString.push(`${getAbbreviationByIndex(parsedKey)}`);
  }

  return resultantString.join(', ');
};

export const getWeekDaysRange = (weekDays: string[]): string => {
  if (weekDays.length > 0) {
    // hash for memoization of end
    const ends: { [key: number]: number } = {};

    let currentStart = getDayIndex(weekDays[0]);
    ends[currentStart] = currentStart;

    for (let i = 1; i < weekDays.length; i++) {
      const nextIndex = getDayIndex(weekDays[i]);
      if (ends[currentStart] + 1 !== nextIndex) currentStart = nextIndex;
      ends[currentStart] = nextIndex;
    }

    return getDaysString(ends);
  }

  // impossible case if validations work correctly
  return 'No Days were specified';
};

export const getDifferenceInSeconds = (first:string , second:string)=>{
    return dayjs(first).diff(dayjs(second), 'second');
}