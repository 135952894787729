import { createStyles, makeStyles } from '@material-ui/core';
import background from '../../../../assets/images/blob-copy.svg';
export const styles = makeStyles(() =>
  createStyles({
    homeWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      alignItems: 'center',
      minHeight: '520px',
      position: 'relative',
      overflow: 'hidden',
      padding: '57px 0',
      maxWidth: '1440px',
      margin: '0 auto',
      '&::before': {
        content: `url(${background})`,
        position: 'absolute',
        left: '0',
        top: '17px'
      },
      '@media only screen and (max-width: 1000px)': {
        gridTemplateColumns: '1fr'
      }
    },
    homeLeftSide: {
      zIndex: 10,
      height: '100%',
      paddingRight: '50px',
      paddingLeft: '100px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '@media only screen and (max-width: 1040px)': {
        paddingLeft: '50px !important'
      },
      '@media only screen and (max-width: 1000px)': {
        paddingRight: '25px !important',
        paddingLeft: '25px !important'
      },
      h3: {
        fontSize: '36px',
        fontWeight: '900',
        lineHeight: '1.17',
        letterSpacing: 'normal',
        whiteSpace: 'pre-line',
        wordWrap: 'none',
        color: '#55647d'
      },
      p: {
        opacity: '0.8',
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '1.5',
        color: '#55647d'
      }
    },

    signUpWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    playVideoWrapper: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer'
    },
    playVideoBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      opacity: '0.59',
      borderRadius: '8px',
      boxShadow: '0 2px 5px 0 #ffcf80',
      backgroundColor: '#f5a623',
      border: 'none',
      marginRight: '10px',
      marginLeft: '15px'
    },
    watchVideoBtn: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#55647d'
    },
    homeRightSide: {
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      width: '539px',
      height: '459px',
      paddingLeft: '50px',
      paddingRight: '100px',
      flexDirection: 'column',
      '@media only screen and (max-width: 1040px)': {
        paddingLeft: '25px !important',
        paddingRight: '50px !important'
      },
      '@media only screen and (max-width: 1000px)': {
        display: 'none !important'
      }
    }
  })
);
