import { makeStyles, createStyles } from '@material-ui/core/styles';
export const styles = makeStyles(theme =>
  createStyles({
    loaderWrapper: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      margin: '10% 48% 0 48%',
      justifyContent: 'center'
    },
    loader: {
      margin: '30% 48% 0 48%'
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 0',
      '& .MuiPagination-root': {
        '& .MuiPagination-ul': {
          '& li': {
            '& button': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '32px',
              height: '32px',
              margin: '0 6px 0 0',
              borderRadius: '4px',
              backgroundColor: '#f9f9f9',
              fontFamily: 'Lato',
              fontSize: '16px',
              fontWeight: 'bold',
              lineHeight: 1.25,
              letterSpacing: '0.13px',
              border: '1px solid #dfe3e8',
              color: '#474f58',
              padding: 0,
              '&.Mui-selected': {
                backgroundColor: '#fff',
                border: '1px solid #3a78f2',
                color: '#3a78f2'
              },
              '&.Mui-disabled': {
                background: '#e6e6e6',
                opacity: 0.7,
                '& svg': {
                  opacity: 0.7
                }
              }
            }
          }
        }
      }
    }
  })
);
