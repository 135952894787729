import { makeStyles } from '@material-ui/core';

export const emptySmallIcon = makeStyles({
  emptySmallIcon: {
    height: '100vh',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px'
  },
  emptySmallIconShrinked: {
    maxHeight: '55vh'
  },
  emptySmallIconMini: {
    maxHeight: '300px'
  },
  bordered: {
    boxShadow: '0 2px 2px 0 rgba(37, 37, 37, 0.03)',
    border: 'solid 1px #ededed',
    borderRadius: '5px'
  },
  noResponsesIcon: {
    width: '100%',
    objectFit: 'contain',
    '@media screen and (max-width:450px)': {
      width: '95%'
    }
  },
  noResponsesText: {
    height: '24px',
    fontFamily: 'Lato',
    fontSize: '20px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.16px',
    color: '#4f5560',
    marginTop: '14px'
  },
  contentNeededText: {
    height: '20px',
    opacity: 0.9,
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#354052',
    marginTop: '9px',
    whiteSpace: 'pre-line',
    '@media screen and (max-width:630px)': {
      whiteSpace: 'normal'
    }
  }
});

export const emptyStateReports = makeStyles({
  root: {
    height: 'calc(100% - 24px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '5px',
    padding: '20px'
  },
  header: {
    margin: '22px 0 8px 0',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#242b37'
  },
  description: {
    fontSize: '16px',
    margin: '0 0 8px 0',
    opacity: 0.7,
    lineHeight: 1.69,
    color: '#354052',
    padding: 0,
    whiteSpace: 'pre-line',
    '@media screen and (max-width:630px)': {
      whiteSpace: 'normal'
    }
  },
  reportButton: {
    '& button': {
      background: 'transparent',
      border: 'none',
      boxShadow: 'none',
      '&:hover': {
        background: 'transparent',
        border: 'none',
        boxShadow: 'none'
      },
      '& span': {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#f5a623'
      }
    }
  }
});

export const emptySearchResult = makeStyles({
  noSearchDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    marginTop: '107px'
  },
  noSearchImg: {
    width: 150,
    height: 103,
    objectFit: 'contain',
    marginTop: 50
  },
  noSearchTxt: {
    fontFamily: 'Lato',
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.16,
    color: '#4f5560',
    marginTop: 24
  },
  noSearchHint: {
    width: 268,
    height: 20,
    opacity: 0.9,
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#354052',
    marginTop: 9
  }
});

export const emptyStateWorkspace = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    border: 'solid 1px #ededed',
    boxShadow: '0 2px 2px 0 rgba(37, 37, 37, 0.03)',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '20px'
  },
  header: {
    margin: '22px 0 8px 0',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#242b37'
  },
  description: {
    fontSize: '16px',
    margin: '0 0 8px 0',
    opacity: 0.7,
    lineHeight: 1.69,
    color: '#354052',
    padding: 0,
    whiteSpace: 'pre-line'
  },
  workspaceBtn: {
    '& button': {
      background: 'transparent',
      border: 'none',
      boxShadow: 'none',
      '&:hover': {
        background: 'transparent',
        border: 'none',
        boxShadow: 'none'
      },
      '& span': {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#f5a623'
      }
    }
  },
  emptyStateImage: {
    maxWidth: '513px',
    width: '100% !important',
    height: 'auto !important'
  }
});
