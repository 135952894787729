import { createStyles, makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: 'transparent'
    },
    card: {
      minWidth: '300px',
      width: '100%',
      marginBottom: '16px',
      padding: '16px',
      borderRadius: '10px',
      boxShadow: '0 2px 2px 0 rgba(37, 37, 37, 0.03)',
      border: 'solid 1px #ededed',
      backgroundColor: '#ffffff'
    },
    expandButton: {
      padding: 0
    },
    expandIcon: {
      opacity: 0.5,
      color: '#858d9b'
    },
    title: {
      fontSize: '18px',
      fontWeight: 'bold'
    },
    cardContent: {
      padding: 0
    },
    reportWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 0',
      borderBottom: '1px solid #e7e9ea',
      '&:last-child': {
        borderBottom: '0',
        paddingBottom: 0
      }
    },
    reportName: {
      fontSize: '16px',
      fontWeight: 600,
      color: '#6c7681'
    },
    responsesLink: {
      fontSize: '12px',
      fontWeight: 600,
      color: '#3a78f2',
      textDecoration: 'none'
    },
    rateBar: {
      height: '8px',
      maxWidth: '156px',
      width: '100%',
      borderRadius: '5px',
      backgroundColor: '#f0f0f0',
      opacity: 0.93,
      marginBottom: '8px',
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#f5a623'
      }
    },
    responseRate: {
      color: '#a9aeb7',
      fontSize: '14px',
      fontWeight: 500
    },
    emptyWorkSpace: {
      fontSize: '16px',
      fontWeight: 600,
      color: '#354052',
      opacity: 0.4,
      textAlign: 'center',
      paddingTop: '40px'
    }
  })
);
