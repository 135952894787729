import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import shortid from 'shortid';
import { ApiError } from '../../../models/ApiError.model';
import { User } from '../../../models/User.model';
import axios from '../../../services/axios';
import { addError } from '../../../store/errors/Actions';
import { QUERIES } from '../../../utils/queris';

export const useFetchOAuthUrls = () => {
  const dispatch = useDispatch();
  return useQuery(
    QUERIES.LOAD_AUTH_URLS,
    () => {
      return new Promise<any>((resolve, reject) => {
        axios
          .get('/slack/auth/oauth_urls')
          .then((res: any) => {
            resolve(res.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    {
      onError: (e: any) => {
        dispatch(
          addError(
            new ApiError(
              shortid.generate(),
              e.message,
              'Error: Can not fetch oauth urls'
            )
          )
        );
      }
    }
  );
};

export const useAuthorizeSlack = (code: string, state: string) => {
  const dispatch = useDispatch();
  const history = useHistory();
  return useQuery(
    [QUERIES.AUTHORIZE_SLACK, code, state],
    () => User.authorizeSlack(code, state),
    {
      enabled: !!code,
      retry: false,
      onError: (e: any) => {
        history.push('/app');
        dispatch(
          addError(
            new ApiError(
              shortid.generate(),
              e.message,
              e.response.data.error || 'Error: Can not authorize slack'
            )
          )
        );
      }
    }
  );
};
