import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, LinearProgress } from '@material-ui/core';
import { getReportURL } from '../../../../utils/routes';
import { workspaceReportStyles } from './workspaceReportStyles';

interface props {
  report: any;
  userID: string;
}

const WorkspaceReport = ({ report, userID }: props) => {
  const classes = workspaceReportStyles();

  return (
    <Box className={classes.reportWrapper} key={report.id}>
      <Box>
        <Typography className={classes.reportName}>{report.name}</Typography>
        <Link
          to={getReportURL(report)}
          className={classes.responsesLink}
        >
          View Responses
        </Link>
      </Box>
      {/*TODO: calculate response rate  */}
      {/* <Box display="flex" flexDirection="column" alignItems="flex-end">
        <LinearProgress
          className={classes.rateBar}
          variant="determinate"
          value={75}
        />
        <Typography className={classes.responseRate}>
          75% response rate
        </Typography>
      </Box> */}
    </Box>
  );
};
export default WorkspaceReport;
