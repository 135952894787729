import { API } from './API';
import axios from '../services/axios';
import {Member} from './Member.model';
import { Report } from './Report.model';
import store from '../store';

export type WorkspaceURLParams = {
  page?: number;
  count?: number;
  minimal?: boolean;
  userId?: number;
};

export class Workspace extends API {
  public id: number = -1;
  public name: string = '';
  public active: boolean = true;
  public creator_name: string = '';
  public creator_id: number = -1;
  public created_at: string = '';
  public description: string = '';
  public members: Member[] = [];
  public reports_count: number = 0 ;
  public is_default: boolean = false;
  
  public static async getAll(URLparamters: WorkspaceURLParams): Promise<{workspaces: Workspace[] ; pageCount: number }> {
    const headers = Workspace.getConfig();

    if (headers) {
      const queryString = buildWorkspacesParameters(URLparamters).join('&');
      return new Promise<{workspaces: Workspace[] ; pageCount: number }>((resolve, reject) => {
        axios
          .get(`dashboard/workspaces?${queryString}`, headers)
          .then(res => {
            resolve(res.data);
          })
          .catch(e => {
            Workspace.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }

  public static async getWorkspace(workspaceId: number): Promise<Workspace> {
    const headers = Workspace.getConfig();

    if (headers) {
      return new Promise<Workspace>((resolve, reject) => {
        axios
          .get(`dashboard/workspaces/${workspaceId}`, headers)
          .then(res => {
            resolve(res.data.workspace as Workspace);
          })
          .catch(e => {
            Workspace.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }

    return Promise.reject('Error while fetching this workspace');
  }

  public static async getReports(workspaceId :number , page:number): Promise<{reports: Report[] , pageCount:number}> {
    const headers = Workspace.getConfig();

    if(headers){
      return new Promise<{reports: Report[] , pageCount:number}>((resolve,reject)=>{
        axios
          .get(`dashboard/workspaces/${workspaceId}/reports?page=${page}` , headers)
          .then(res=>{
              resolve(res.data);
          })
          .catch(err=>{
            Workspace.checkUnauthorizedRequest(err,store);
            reject(err);
          })
      });
    }
    return Promise.reject('Error while fetching included report in the workspace');
  }
  
  public static async create(workspace: Workspace): Promise<Workspace> {
    const headers = Workspace.getConfig();
    if (headers) {
      return new Promise<Workspace>((resolve, reject) => {
        // Map members to member_ids
        workspace.members = (workspace.members.map(
          member => member.id
        ) as unknown) as Member[];
        axios
          .post('dashboard/workspaces', { workspace }, headers)
          .then(res => {
            resolve(res.data.workspace as Workspace);
          })
          .catch((e) => {
            Workspace.checkUnauthorizedRequest(e,store);
            reject(e as any);
          });
      });
    }
    return Promise.reject('System Error');
  }

  public static async update(workspace: Workspace): Promise<unknown> {
    const headers = Workspace.getConfig();

    if (headers) {
      return new Promise<unknown>((resolve, reject) => {
        // Map members to member_ids
        workspace.members = (workspace.members.map(
          member => member.id
        ) as unknown) as Member[];
        axios
          .put(
            `dashboard/workspaces/${workspace.id}`,
            { workspace },
            headers
          )
          .then(res => {
            resolve(res.data.workspace as Workspace);
          })
          .catch(e => {
            Workspace.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject(`Can't update ${workspace.name} workspace`);
  }

  public static async delete(workspaceId: number): Promise<unknown> {
    const headers = Workspace.getConfig();

    if (headers) {
      return new Promise<unknown>((resolve, reject) => {
        axios
          .delete(`dashboard/workspaces/${workspaceId}`, headers)
          .then(res => {
            const response: Workspace = res.data;
            resolve(response);
          })
          .catch(e => {
            Workspace.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }

  public static async pause(workspaceId: number): Promise<Workspace> {
    const headers = Workspace.getConfig();

    if (headers) {
      return new Promise<Workspace>((resolve, reject) => {
        axios
          .put(`dashboard/workspaces/pause/${workspaceId}`, {}, headers)
          .then(res => {
            resolve(res.data.workspace as Workspace);
          })
          .catch(e => {
            Workspace.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('system error');
  }

  public static async resume(workspaceId: number): Promise<Workspace> {
    const headers = Workspace.getConfig();

    if (headers) {
      return new Promise<Workspace>((resolve, reject) => {
        axios
          .put(
            `dashboard/workspaces/resume/${workspaceId}`,
            {},
            headers
          )
          .then(res => {
            resolve(res.data.workspace as Workspace);
          })
          .catch(e => {
            Workspace.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }
}


const buildWorkspacesParameters = (URLparamters: WorkspaceURLParams): string[] => {
  let queryParams: string[] = [];
  if(URLparamters.page){
    queryParams.push(`page=${URLparamters.page}`)
  }
  if(URLparamters.count){
    queryParams.push(`count=${URLparamters.count}`)
  }
  if(URLparamters.userId){
    queryParams.push(`userId=${URLparamters.userId}`)
  }
  if(URLparamters.minimal){
    queryParams.push("minimal=true")
  }
  return queryParams;
}