import { API } from "./API";
import axios from '../services/axios';

export type analyticsEvent =  {
    eventName: string ,
    eventTimeTaken: string ,
}

export class Analytics extends API {


    public static async addAnalyticsEvent(event: analyticsEvent):Promise<unknown>{
      const headers = Analytics.getConfig();
      if (headers) {
        return new Promise<unknown>((resolve, reject) => {
          axios
            .post(
              `dashboard/analytics`,
              {analytics : event},
              headers
            )
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
      }
      return Promise.reject('Error while sending analytics event');
    }
}