import React, { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import MaximizeRoundedIcon from '@material-ui/icons/MaximizeRounded';
import BscrumImage from '../../../../assets/images/group-13.png';
import styles from './styles';
import CarouselItem from './CarouselItem';
import Aos from 'aos';
import 'aos/dist/aos.css';

function HowItWorks() {
  useEffect(() => {
    Aos.init();
  }, []);
  const classes = styles();
  return (
    <section id="howItWorks" className={classes.howItWorksWrapper}>
      <div className={classes.howItWorksLeftSide}>
        <div
          className={classes.carouseWrapper}
          data-aos="slide-right"
          data-aos-once="true"
        >
          <h4 className={classes.carouseWrapperTitle}>How it works?</h4>
          <Carousel
            swipe={true}
            autoPlay={false}
            animation="slide"
            navButtonsAlwaysInvisible={true}
            IndicatorIcon={<MaximizeRoundedIcon/>}
            indicatorContainerProps={{
              style: { textAlign: 'unset' },
              className: 'indicators'
            }}
            activeIndicatorIconButtonProps={{
              style: { opacity: '0.5' },
              className: 'active'
            }}
            indicatorIconButtonProps={{
              style: { opacity: '0.1' },
              className: 'indicator',
            }}
          >
            <CarouselItem
              itemNumber="1"
              itemTitle="Quick setup steps"
              itemDescription="Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis maiores
            neque hic obcaecati repudiandae similique exercitationem error quis
            debitis at dolor laborum rem cum iste magnam repellat, dolore ullam
            fugiat!"
            />
            <CarouselItem
              itemNumber="2"
              itemTitle="Quick setup steps"
              itemDescription="Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis maiores
            neque hic obcaecati repudiandae similique exercitationem error quis
            debitis at dolo"
            />
            <CarouselItem
              itemNumber="3"
              itemTitle="Quick setup steps"
              itemDescription="Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis maiores
            neque hic obcaecati repudiandae similiqu"
            />
          </Carousel>
        </div>
      </div>
      <div className={classes.howItWorksRightSide}>
        <div
          className={classes.slideLeft}
          data-aos="slide-left"
          data-aos-once="true"
        >
          <div className={classes.howItWorksImgWrapper}>
            <img
              className={classes.howItWorksImg}
              src={BscrumImage}
              alt="bscrum_image"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
