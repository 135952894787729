import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Report } from '../../../../models/Report.model';
import { addConfirmation } from '../../../../store/confirmations/Actions';
import { Confirmation } from '../../../../models/Confirmation.model';
import { useDispatch } from 'react-redux';
import { Member } from '../../../../models/Member.model';
import { Comment , commentRequest} from '../../../../models/Comment.model';
import { QUERIES } from '../../../../utils/queris';
import { ApiError } from '../../../../models/ApiError.model';
import { addError } from '../../../../store/errors/Actions';
export const useRemindParticipants = (name: string) => {
  const dispatch = useDispatch();
  return useMutation(
    (reportId: number) => Report.remindParticipants(reportId),
    {
      onSuccess: async (data, variables) => {
        dispatch(
          addConfirmation(
            Confirmation.getSuccessConfirmation(
              `Great, sent reminders to members successfully`
            )
          )
        );
      }
    }
  );
};