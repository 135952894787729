import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import auth, { Auth as AuthState } from './auth/Reducer';
import settings, { Settings as SettingsState } from './settings/Reducer';
import errors, { State as ErrorsState } from './errors/Reducer';
import confirmations, {
  State as ConfirmationsState
} from './confirmations/Reducer';
import thunk from 'redux-thunk';

let composeEnhancers;
if (
  process.env.NODE_ENV !== 'production' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
} else {
  composeEnhancers = compose;
}

export interface RootState {
  auth: AuthState;
  settings: SettingsState;
  errors: ErrorsState;
  confirmations: ConfirmationsState;
}
export default createStore(
  combineReducers<RootState>({
    auth,
    settings,
    errors,
    confirmations
  }),
  composeEnhancers(applyMiddleware(thunk))
);
