import { API } from './API';
import axios from '../services/axios';
import { Member } from './Member.model';
import { USER_ROLES } from '../utils/constants';
import store from '../store';

export type commentRequest = {
  report_record_id: number;
  body: any;
  member_id?: string;
  id?: string;
  report_id: number;
};

export class Comment extends API {
  public id: string = '';
  public time: string = '';
  public body: any = '';
  public member: Member = new Member(1,'provided_id', 'Name', USER_ROLES.admin);
  public report_record_id: number = -1;
  public report_id: number = -1 ;

  public static async getAll(responseId: string): Promise<Comment[]> {
    const headers = Comment.getConfig();
    if (headers) {
      return new Promise<Comment[]>((resolve, reject) => {
        axios
          .get(`dashboard/report_record/${responseId}/comments`, headers)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
    return Promise.reject('Error while get comments');
  }

  public static async add(request: commentRequest): Promise<Comment> {
    const headers = Comment.getConfig();
    if (headers) {
      return new Promise<Comment>((resolve, reject) => {
        axios
          .post(
            `dashboard/report_record/${request.report_record_id}/comments`,
            {
              body: request.body,
              member_id: request.member_id
            },
            headers
          )
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
    return Promise.reject('Error while adding comment');
  }

  public static async delete(id: string): Promise<unknown> {
    const headers = Comment.getConfig();
    if (headers) {
      return new Promise<unknown>((resolve, reject) => {
        axios
          .delete(`dashboard/comments/${id}`, headers)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            Comment.checkUnauthorizedRequest(err,store);
            reject(err);
          });
      });
    }
    return Promise.reject('Errot while delete comment');
  }

  public static async update(request: commentRequest): Promise<Comment> {
    const headers = Comment.getConfig();
    if (headers) {
      return new Promise<Comment>((resolve, reject) => {
        axios
          .put(
            `dashboard/comments/${request.id}`,
            {
              body: request.body
            },
            headers
          )
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            Comment.checkUnauthorizedRequest(err,store);
            reject(err);
          });
      });
    }
    return Promise.reject('Errot while update comment');
  }
}
