import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Option } from '../../../../../models/Option.model';
import ClearIcon from '@material-ui/icons/Clear';
import InputField from '../InputField';
import { styles } from '../styles';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';

export const ReportFormPollOptions = React.memo(() => {
  const classes = styles();

  const { control, formState } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
    keyName: 'identifier'
  });

  useEffect(() => {
    if (fields.length === 1) {
      append(new Option());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      <InputField
        label="Poll Question"
        inputId="question"
        placeholder="Add poll question"
        type="text"
        required
      />

      <div className={classes.questionsWrapper}>
        <Typography className={classes.iconRootLabel}>
          <span className={classes.iconLabel}>Options</span>
        </Typography>
        <div className={classes.questions}>
          {fields.map((option, index) => {
            return (
              <div key={option.identifier} className={classes.emailsWrapper}>
                <Controller
                  name={`options[${index}].id`}
                  control={control}
                  defaultValue={option.id}
                  render={() => <></>}
                />

                <InputField
                  label={`Option ${index + 1}`}
                  defaultValue={option.content}
                  inputId={`options[${index}].content`} // name for react-hook-form
                  placeholder="Add Option"
                  customClass={classes.questionField}
                  type="text"
                  required
                  error={
                    formState.errors['options'] &&
                    formState.errors['options'][index]?.content
                  }
                />

                <div className={classes.clearIconWrapper}>
                  <ClearIcon
                    className={classes.clearIcon}
                    onClick={(e: any) => remove(index)}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <Typography className={classes.addQuestionDiv}>
          <span
            className={classes.addQuestionTxt}
            onClick={() => append(new Option())}
          >
            + Add Option
          </span>
        </Typography>
      </div>
    </>
  );
});
