import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    headerTxt: {
      color: '#777f8c',
      fontSize: '16px',
      letterSpacing: 'normal'
    },
    dashboardSpan: {
      color: '#777f8c',
      fontFamily: 'Lato',
      margin: '0 4px 0 0',
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline'
      }
    },
    createSpan: {
      color: '#354052',
      margin: '0 0 0 4px',
      fontFamily: 'Lato-SemiBold'
    },
    reportFormArea: {
      display: 'flex',
      marginTop: '16px',
      borderRadius: '6px',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: 'rgba(194,215,255,0.1)',
      boxShadow: '0 1px 10px 9px rgba(130, 130, 130, 0.04)'
    },
    stepsLine: {
      width: '95%',
      display: 'flex',
      maxWidth: '668px',
      flexDirection: 'row'
    },
    progressBar: {
      width: '100%',
      margin: '0 16px'
    },
    stepsDescription: {
      width: '90%',
      display: 'flex',
      maxWidth: '560px',
      paddingBottom: 24,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      '@media (max-width:520px)': {
        flexDirection: 'column'
      }
    },
    stepTitle: {
      marginTop: 8,
      color: '#858d9b',
      fontSize: '24px',
      lineHeight: '1.13',
      fontFamily: 'Lato',
      textAlign: 'center',
      letterSpacing: 'normal',
      '@media (max-width:860px)': {
        fontSize: '20px'
      },
      '@media (max-width:740px)': {
        fontSize: '16px'
      },
      '@media (max-width:700px)': {
        fontSize: '20px'
      }
    },
    buttonStep: {
      marginTop: 2,
      paddingBottom: 0,
      textTransform: 'none',
      fontFamily: 'Lato-Bold',
      boxSizing: 'content-box'
    },
    doneStep: {
      marginTop: 8,
      fill: '#ffc668',
      fontSize: '40px',
      '@media (max-width:860px)': {
        fontSize: '36px'
      },
      '@media (max-width:740px)': {
        fontSize: '30px'
      },
      '@media (max-width:700px)': {
        fontSize: '36px'
      }
    },
    currentStep: {
      fontFamily: 'Lato-Bold'
    },
    nextStep: {
      opacity: 0.5,
      fontFamily: 'Lato-SemiBold'
    },
    paper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: '#fff'
    },
    cardContent: {
      width: '75%',
      display: 'flex',
      padding: '16px',
      flexDirection: 'column',
      '@media (max-width: 1154px)': {
        width: '80%'
      },
      '@media (max-width: 1024px)': {
        width: '100%'
      }
    },
    actionButton: {
      height: 40,
      borderRadius: 6,
      padding: '0 44px',
      marginLeft: 'auto',
      textTransform: 'capitalize'
    },
    buttonLabel: {
      fontSize: 14,
      letterSpacing: 'normal',
      fontFamily: 'Lato-Bold'
    },
    backButton: {
      color: '#f5a623',
      backgroundColor: '#fff',
      border: 'solid 1px #f5a623',
      '&:hover': {
        backgroundColor: '#ffffff'
      }
    },
    nextButton: {
      color: '#fff',
      backgroundColor: '#f5a623',
      '&:hover': {
        backgroundColor: '#f5a623'
      }
    },
    form1Fields: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `"reportName workspace"
                          "start_date timezone"
                          "report_timing period"
                          "days days"
                          "reminder_section reminder_section"
                        `,
      columnGap: '16px',
      '@media (max-width:900px)': {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `"reportName"
                            "workspace"
                            "start_date"
                            "timezone"
                            "report_timing"
                            "period"
                            "days"
                            "reminder_section"
                          `
      }
    },
    formControl: {
      width: '100%'
    },
    reportName: {
      gridArea: 'reportName'
    },
    fieldText: {
      height: 40,
      color: '#76797f',
      fontSize: '16px',
      fontFamily: 'Lato',
      borderRadius: '6px',
      letterSpacing: 'normal',
      border: 'solid 1px #c3c3c3'
    },
    inputField: {
      width: '100%'
    },
    workspace: {
      gridArea: 'workspace'
    },
    selectField: {
      height: 40,
      borderRadius: 6,
      border: 'solid 1px #c3c3c3'
    },
    startDate: {
      gridArea: 'start_date'
    },
    timezone: {
      gridArea: 'timezone'
    },
    reportTiming: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      gridArea: 'report_timing',
      justifyContent: 'space-between',
      '@media (max-width:1080px)': {
        flexDirection: 'column'
      },
      '@media (max-width:1024px)': {
        flexDirection: 'row'
      },
      '@media (max-width:500px)': {
        flexDirection: 'column'
      }
    },
    reportTimingDiv: {
      marginRight: 4
    },
    reportTimeLabel: {
      display: 'flex',
      alignItems: 'center'
    },
    formIcon: {
      color: '#3a78f2',
      cursor: 'pointer',
      margin: '12px 0 0 4px'
    },
    period: {
      gridArea: 'period'
    },
    days: {
      marginTop: 40,
      display: 'flex',
      gridArea: 'days',
      flexDirection: 'column'
    },
    iconRootLabel: {
      display: 'flex',
      alignContent: 'center'
    },
    icon: {
      color: '#3a78f2'
    },
    iconLabel: {
      fontSize: 16,
      marginLeft: 8,
      color: '#000',
      fontFamily: 'Lato-Bold'
    },
    buttonGroup: {
      margin: '0 8px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'left'
    },
    toggleButton: {
      width: 97,
      height: 40,
      marginTop: 8,
      padding: '0 40px',
      marginLeft: '16px !important',
      borderRadius: '6px !important',
      border: 'solid 1px #c3c3c3 !important',
      '&:hover': {
        backgroundColor: '#dee9ff',
        boxShadow: '0 2px 9px 0 rgba(190, 190, 190, 0.23)',
        '& $toggleButtonLabel': {
          color: '#3a78f2'
        }
      }
    },
    toggleButtonLabel: {
      color: '#d0d0d0',
      fontSize: '16px',
      fontFamily: 'Lato-Bold',
      letterSpacing: 'normal'
    },
    selectedToggleButton: {
      boxShadow: '0 2px 10px 0 #b1cbff',
      backgroundColor: '#3a78f2 !important',
      '& $toggleButtonLabel': {
        color: '#fff'
      }
    },
    reminderSection: {
      marginTop: 40,
      gridArea: 'reminder_section'
    },
    reminderWrapper: {
      marginBottom: 24
    },
    promptStyle: {
      fontSize: 16,
      color: '#354052',
      fontFamily: 'Lato-SemiBold',
      '@media (max-width: 566px)': {
        display: 'block'
      }
    },
    inlineSelect: {
      height: '32px',
      margin: '0 8px',
      borderRadius: '6px',
      backgroundColor: '#fff',
      border: 'solid 1px #c3c3c3'
    },
    messageField: {
      gridArea: 'message'
    },
    message: {
      height: 80,
      marginBottom: 16
    },
    questionsWrapper: {
      marginTop: 24
    },
    questions: {
      marginBottom: 16
    },
    question: {
      display: 'flex',
      alignItems: 'center',
      '@media (max-width:860px)': {
        flexDirection: 'column'
      }
    },
    questionField: {
      marginRight: 40,
      '@media (max-width:860px)': {
        marginRight: 0
      }
    },
    questionCheckBox: {
      width: 24,
      height: 24
    },
    controlsWrapper: {
      display: 'flex',
      marginTop: '3.5em',
      '@media (max-width:860px)': {
        marginTop: 8
      }
    },
    optionalCheckboxLabel: {
      marginLeft: 16
    },
    arrowsDiv: {
      display: 'flex',
      alignItems: 'center'
    },
    clearIcon: {
      color: 'rgba(204, 51, 0, 0.7)',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    addQuestionDiv: {
      marginBottom: 40
    },
    addQuestionTxt: {
      fontSize: 18,
      color: '#3a78f2',
      fontFamily: 'Lato-Bold',
      letterSpacing: 'normal',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    teammatesTab: {
      display: 'flex',
      flexDirection: 'column'
    },
    channels: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '@media (max-width:750px)': {
        flexDirection: 'column'
      }
    },
    channelSelect: {
      width: '50%',
      '@media (max-width:750px)': {
        width: '100%'
      }
    },
    helperPrompt: {
      fontSize: 16,
      color: '#3a78f2',
      cursor: 'pointer',
      margin: '44px 8px 0 8px',
      fontFamily: 'Lato-Bold',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none'
      },
      '@media (max-width:750px)': {
        margin: '8px 8px 16px 8px'
      }
    },
    helpersCard: {
      margin: '4px 0',
      borderRadius: 12,
      maxWidth: '320px',
      padding: '16px 24px',
      backgroundColor: '#fff',
      border: 'solid 1px #eee',
      boxShadow: '0 2px 4px 0 rgba(201, 201, 201, 0.5)'
    },
    tooltip: {
      fontSize: 14,
      color: '#76797f',
      fontFamily: 'Lato'
    },
    refreshLink: {
      color: '#3a78f2',
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    emailsSection: {
      marginTop: 8
    },
    clearIconWrapper: {
      marginLeft: 8,
      marginTop: '3.5em'
    },
    emailsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    addEmailDiv: {
      marginBottom: 16
    },
    controllersWrapper: {
      marginBottom: 16
    }
  })
);
