import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { ApiError } from '../../../../../../../models/ApiError.model';
import {
  submitReportRequest,
  ReportResponse
} from '../../../../../../../models/ReportResponse.model';
import { Confirmation } from '../../../../../../../models/Confirmation.model';
import { Member } from '../../../../../../../models/Member.model';
import { addConfirmation } from '../../../../../../../store/confirmations/Actions';
import { addError } from '../../../../../../../store/errors/Actions';
import { QUERIES } from '../../../../../../../utils/queris';

export const useDeleteResponse = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    (request: submitReportRequest) => ReportResponse.delete(request),
    {
      onSuccess: async (data, request) => {
        dispatch(
          addConfirmation(
            Confirmation.getSuccessConfirmation(`response deleted successfully`)
          )
        );
        queryClient.invalidateQueries([
          QUERIES.LOAD_SUBMISSION_CYCLES,
          request.reportId.toString()
        ]);
      },
      onError: error => {
        dispatch(addError(ApiError.getError('', 'Failed to delete response')));
      }
    }
  );
};

export const useUpdateResponse = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    (request: submitReportRequest) => ReportResponse.update(request),
    {
      onSuccess: async (data ,request) => {
        dispatch(
          addConfirmation(
            Confirmation.getSuccessConfirmation(`Respose updated successfully`)
          )
        );
        queryClient.invalidateQueries([
          QUERIES.LOAD_SUBMISSION_CYCLES,
          request.reportId.toString()
        ]);
      },
      onError: error => {
        dispatch(addError(ApiError.getError('', 'Failed to update response')));
      }
    }
  );
};
