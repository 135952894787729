import { combineReducers } from 'redux';
import { Action } from './Actions';
import * as types from './ActionTypes';
import { Confirmation } from '../../models/Confirmation.model';

export interface State {
  confirmationsList: Confirmation[];
}

const confirmationsList = (
  state: Confirmation[] = [],
  action: Action
): Confirmation[] => {
  switch (true) {
    case action.type === types.ADD_CONFIRMATION:
      return [action.confirmation].concat(...state);
    case action.type.endsWith('_SUCCEEDED'):
      const newState = action.confirmation ? [action.confirmation] : [];
      return newState.concat(...state);
    case action.type === types.DISMISS_CONFIRMATION:
      return state.filter(c => c.id !== action.confirmation.id);
    default:
      return state;
  }
};
export default combineReducers<State>({
  confirmationsList
});
