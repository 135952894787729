import React from 'react';
import { withStyles, Drawer, Hidden } from '@material-ui/core';
import { styles } from './styles';
import SidebarContent from './SidebarContent';
import clsx from 'clsx';
interface Props {
  open: boolean;
  classes?: any;
  handleDrawer: () => void;
}

const Sidebar = (props: Props) => {
  const { classes, handleDrawer } = props;
  return (
    <>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="persistent"
          classes={{ paper: clsx(classes.drawerPaper) }}
          open={props.open}
        >
          <SidebarContent />
        </Drawer>
      </Hidden>

      <Hidden smUp>
        <Drawer
          variant="temporary"
          classes={{ paper: clsx(classes.drawerPaper) }}
          open={props.open}
          anchor="left"
          onClick={handleDrawer}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <SidebarContent />
        </Drawer>
      </Hidden>
    </>
  );
};

export default withStyles(styles)(Sidebar);
