import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      height: '100%'
    },
    dateText: {
      fontSize: '20px',
      fontWeight: 600,
      color: '#a9b3d7',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    dailyUpdatesText: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#858d9b',
      marginBottom: '16px'
    },
    buttonsGroup: {
      width: '266px',
      height: '53px',
      borderRadius: '4px',
      backgroundColor: 'rgba(236, 240, 255, 0.5)',
      marginTop: '24px',
      paddingLeft: '7px',
      border: '0px'
    },
    button: {
      width: '81px',
      height: '41px',
      marginRight: '4.5px',
      marginTop: '6px',
      marginBottom: '6px',
      backgroundColor: 'rgba(236, 240, 255, 0.5)',
      border: '0px'
    },
    buttonLabel: {
      height: '18px',
      fontFamily: 'Lato',
      fontSize: '15.6px',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#bcc7f2'
    },
    activeButton: {
      width: '81px',
      height: '41px',
      marginRight: '4.5px',
      marginTop: '6px',
      marginBottom: '6px',
      border: '0px',
      borderRadius: '4px',
      boxShadow: '2px 0 24px 0 rgba(103, 101, 101, 0.14)',
      backgroundColor: '#ffffff'
    },
    activeButtonLabel: {
      height: '15px',
      fontFamily: 'Lato',
      fontSize: '12px',
      fontWeight: 900,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#6896f6',
      textAlign: 'center'
    },
    dashboardHead: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 0 16px 0'
    },
    activitiesHeader: {
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: 1.35,
      color: '#858d9b',
      margin: 0,
      letterSpacing: 'normal'
    },
    activityList: {
      borderRadius: '5px',
      boxShadow: '0 2px 2px 0 rgba(37, 37, 37, 0.03)',
      border: 'solid 1px #ededed',
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: 'calc(100% - 56px)',
      padding: 0
    },
    cardsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    container: {
      flex: 1,
      padding: '0 16px',
      overflow: 'overlay',
      '@supports ( -moz-appearance:none )': {
        overflow: 'auto'
      },
      '&::-webkit-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      },
      '&::-moz-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-moz-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-moz-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      },
      '&::-ms-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-ms-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-ms-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      }
    },
    listItem: {
      height: '100%',
      padding: 0,
      display: 'grid',
      gridTemplateColumns: '4fr 1fr 1fr',
      '@media only screen and (max-width: 600px)': {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      }
    },
    avatarWrapper: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden'
    },
    activityAvatar: {
      width: '38px',
      height: '38px',
      marginRight: '15px'
    },
    activityTextPrimary: {
      fontFamily: 'lato',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#354052'
    },
    activityTextSecondary: {
      fontFamily: 'Lato',
      fontSize: '14px',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#354052'
    },
    reportName: {
      fontFamily: 'lato',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#354052'
    },
    reportTypeText: {
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: 600,
      color: '#8da7cf',
      margin: '0 15px',
      '@media only screen and (max-width: 600px)': {
        margin: '0 0'
      }
    },
    dateWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      whiteSpace: 'nowrap'
    },
    reportTimeText: {
      opacity: 0.8,
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#4f607b',
      marginRight: '28px',
      '@media only screen and (max-width: 600px)': {
        marginRight: 0
      }
    },
    timeIcon: {
      width: '17px',
      height: '17px',
      marginRight: '4px'
    },
    iconButton: {
      padding: 0,
      transition: 'all .3s ease-in'
    },
    loadMoreBtnWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loadMoreButton: {
      width: '146px',
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '2px solid #f5a623',
      borderRadius: '6px',
      margin: '16px 0',
      padding: 0
    },
    loadMoreBtnLabel: {
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: 'normal',
      color: '#f5a623',
      textTransform: 'none'
    },
    circularProgressWrapper: {
      height: '64px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    expandMorePinned: {
      margin: 'auto'
    },
    activityCardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '72px',
      padding: '16px 0 0 0',
      borderBottom: '1px solid #e7e9ea'
    },
    activityCard: {
      height: '100%'
    },
    pendingReminder: {
      width: '100%',
      backgroundColor: theme.palette.error.light
    },
    pendingListItem: {
      paddingLeft: theme.spacing(4)
    },
    reportContent: {
      margin: 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    memberLink: {
      textDecoration: 'none',
      '&:hover': {
        textDecorationColor: '#354052',
        textDecoration: 'underline'
      }
    },
    selectFavoriteField: {
      color: 'black',
      fontWeight: 500,
      fontFamily: 'Roboto',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.35,
      letterSpacing: 'normal',
      paddingRight: 15,
      height: 48,
      borderRadius: 6,
      boxShadow: '0 2px 5px 0 rgba(219, 219, 219, 0.5)',
      border: 'solid 1px rgba(224, 224, 224, 0.49)'
    }
  })
);
