import React from 'react';
import BscrumLogo from '../../../../assets/images/logo.png';
import dayjs from 'dayjs';
import styles from './styles';

function PageFooter() {
  const classes = styles();
  return (
    <section className={classes.pageFooterWrapper}>
      <div className={classes.footerBscrumLogoWrapper}>
        <img
          className={classes.bscrumLogo}
          src={BscrumLogo}
          alt="bscrum_logo"
        />
        <span className={classes.bscrumLogoText}>bscrum</span>
      </div>
      <p className={classes.copyRightsText}>
        &#169; {dayjs().year()} Blink22. All rights reserved
      </p>
    </section>
  );
}

export default PageFooter;
