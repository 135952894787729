import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { ApiError } from "../../../../../../models/ApiError.model";
import { commentRequest, Comment } from "../../../../../../models/Comment.model";
import { Confirmation } from "../../../../../../models/Confirmation.model";
import { Member } from "../../../../../../models/Member.model";
import { addConfirmation } from "../../../../../../store/confirmations/Actions";
import { addError } from "../../../../../../store/errors/Actions";
import { QUERIES } from "../../../../../../utils/queris";
import { ReportResponse, reactionRequest } from '../../../../../../models/ReportResponse.model';

export const useAddComment = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((request: commentRequest) => Comment.add(request), {
    onSuccess: async (data, request) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(`Comment added successfully`)
        )
      )
      queryClient.invalidateQueries([QUERIES.LOAD_SUBMISSION_CYCLES, request.report_id.toString()]);
    },
    onError: error => {
      dispatch(addError(ApiError.getError('', 'Failed to add comment')));
    }
  })
}

export const useDeleteComment = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((comment: Comment) => Comment.delete(comment.id), {
    onSuccess: async (data, comment) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(`Comment deleted successfully`)
        )
      )
      queryClient.invalidateQueries([QUERIES.LOAD_SUBMISSION_CYCLES, comment.report_id.toString()]);
    },
    onError: error => {
      dispatch(addError(ApiError.getError('', 'Failed to delete comment')));
    }
  })
}

export const useUpdateComment = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((request: commentRequest) => Comment.update(request), {
    onSuccess: async (data, request) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(`Comment updated successfully`)
        )
      )
      queryClient.invalidateQueries([QUERIES.LOAD_SUBMISSION_CYCLES, request.report_id.toString()]);
    },
    onError: error => {
      dispatch(addError(ApiError.getError('', 'Failed to update comment')));
    }
  })
}

export const useLikeResponse = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((reaction: reactionRequest) =>
    ReportResponse.like(reaction), {
    onMutate: async reaction => {
      await queryClient.cancelQueries([QUERIES.LOAD_LIKED_MEMBERS, reaction.responseId]);
      const prevLikedMembers = queryClient.getQueryData<number[]>(
        [QUERIES.LOAD_LIKED_MEMBERS, reaction.responseId]
      )
      const newLikedMembers = prevLikedMembers?.includes(reaction.responseId) ?
        prevLikedMembers : prevLikedMembers?.concat(reaction.memberId);

      queryClient.setQueryData(
        [QUERIES.LOAD_LIKED_MEMBERS, reaction.responseId],
        newLikedMembers
      )
      return { prevLikedMembers }
    },
    onSuccess: async (data, reaction) => {
      queryClient.invalidateQueries([QUERIES.LOAD_LIKED_MEMBERS, reaction.responseId])
    },
    onError: async (error, reaction, context: any) => {
      queryClient.setQueryData(
        [QUERIES.LOAD_LIKED_MEMBERS, reaction.responseId],
        context.prevLikedMembers
      )
      dispatch(
        addError(ApiError.getError('', 'Failed to like the response'))
      );
    }
  })
}

export const useUnLikeResponse = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((reaction: reactionRequest) =>
    ReportResponse.unlike(reaction), {
    onMutate: async reaction => {
      await queryClient.cancelQueries([QUERIES.LOAD_LIKED_MEMBERS, reaction.responseId]);
      const prevLikedMembers = queryClient.getQueryData<number[]>(
        [QUERIES.LOAD_LIKED_MEMBERS, reaction.responseId]
      )
      const newLikedMembers = prevLikedMembers?.filter(memberId => memberId !== reaction.memberId);
      queryClient.setQueryData(
        [QUERIES.LOAD_LIKED_MEMBERS, reaction.responseId],
        newLikedMembers
      )
      return { prevLikedMembers }
    },
    onSuccess: async (data, reaction) => {
      queryClient.invalidateQueries([QUERIES.LOAD_LIKED_MEMBERS, reaction.responseId])
    },
    onError: async (error, reaction, context: any) => {
      queryClient.setQueryData(
        [QUERIES.LOAD_LIKED_MEMBERS, reaction.responseId],
        context.prevLikedMembers
      )
      dispatch(
        addError(ApiError.getError('', 'Failed to unlike the response'))
      );
    }
  })
}

export const useLikedMembers = (responseId: number) => {
  return useQuery<number[]>(
    [QUERIES.LOAD_LIKED_MEMBERS, responseId],
    () => ReportResponse.getLikedMembers(responseId)
  )
}

export const useMembers = () => {
  return useQuery<Member[]>(QUERIES.LOAD_MEMBERS, () => Member.getAll());
};
