import { API } from './API';
import axios from '../services/axios';
import { Member } from './Member.model';
import { Ticket } from './Tickets';
import store from '../store';
export class Board extends API {
  public id: number = -1;
  public name: string = '';
  public color: string = '';
  public tickets: Ticket[] = [];

  public static async getAll(): Promise<Board[]> {
    const headers = Board.getConfig();

    if (headers) {
      return new Promise<Board[]>((resolve, reject) => {
        axios
          .get('dashboard/boards', headers)
          .then(res => {
            const boards: Board[] = res.data.boards as Board[];
            resolve(boards);
          })
          .catch(e => {
            Board.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }

}