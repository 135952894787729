import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  CssBaseline,
  Paper,
  Hidden
} from '@material-ui/core';
import PATH_NAME from '../../../utils/pathNames';
import BscrumLogo from '../../../assets/images/logo.png';
import { styles } from './styles';
import slackIcon from '../../../assets/images/slack-icon.png';
import { useHistory } from 'react-router';
import { useFetchOAuthUrls } from './AuthHooks';
import ResponsiveCarousel from '../../shared-components/Carousel/Carousel';
import browser from '../../../assets/images/mockup@3x.png';

const OAuth = () => {
  const { data } = useFetchOAuthUrls();
  const classes = styles();
  const history = useHistory();

  const handleTermsAndConditions = () => {
    history.push(PATH_NAME.PRIVACY_POLICY);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography className={classes.headerText}>
            <img src={BscrumLogo} alt="BscrumLogo" height={48} />{' '}
            <span className={classes.bold}>Bscrum</span>
          </Typography>
          <Card className={classes.card}>
            <CardContent className={classes.signInCard}>
              <Typography className={classes.signTxt}>
                Sign in or sign up to your Account !
              </Typography>
              {data?.sign_in_with_slack && (
                <a className={classes.slackUrl} href={data?.sign_in_with_slack}>
                  <img
                    alt="Sign in with Slack"
                    className={classes.slackIcon}
                    src={slackIcon}
                  />
                  Sign in with Slack
                </a>
              )}
              <Typography className={classes.signTxt}>Or</Typography>
              {data?.add_to_slack && (
                <a className={classes.slackUrl} href={data?.add_to_slack}>
                  <img
                    alt="Add to Slack"
                    className={classes.slackIcon}
                    src={slackIcon}
                  />
                  Add to Slack
                </a>
              )}
            </CardContent>
          </Card>
          <div className={classes.termsAndConditions}>
            By sign up you accept{' '}
            <span className={classes.link} onClick={handleTermsAndConditions}>
              Privacy Policy
            </span>
          </div>
        </div>
      </Grid>
      <Hidden smDown>
        <Grid
          sm={false}
          md={7}
          xs={false}
          className={classes.image}
          container
          item
          alignContent="space-around"
        >
          <Grid item sm={12}>
            <ResponsiveCarousel browser={browser} />
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default OAuth;
