import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { ApiError } from "../../../models/ApiError.model";
import { Confirmation } from "../../../models/Confirmation.model";
import { Member } from "../../../models/Member.model";
import { addConfirmation } from "../../../store/confirmations/Actions";
import { addError } from "../../../store/errors/Actions";
import { QUERIES } from "../../../utils/queris";

export const useUpdateRoles = (memberName: string) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation((updatedMember: Member) => Member.updateRoles([updatedMember]), {
      onSuccess: async (updatedMembers) => {
        dispatch(
          addConfirmation(
            Confirmation.getSuccessConfirmation(
              `The account type has been changed for ${memberName}.`
            )
          )
        );
      },
      onMutate: async updatedMember => {
        await queryClient.cancelQueries(QUERIES.LOAD_MEMBERS);
        const prevMemers = queryClient.getQueryData<Member[]>(QUERIES.LOAD_MEMBERS);
        const newMembers = prevMemers?.map((member: Member)=> member.id === updatedMember.id ? updatedMember : member)
        queryClient.setQueryData<any>(QUERIES.LOAD_MEMBERS, newMembers);
        return { prevMemers };
      },
      onError: (err: any, variables, context: any) => {
        if (context?.prevMemers) {
            queryClient.setQueryData<any>(QUERIES.LOAD_MEMBERS, context.prevMemers)
        }
        dispatch(
          addError(
            ApiError.getError(
              err,
              `${err.message}, Error: Cannot update role for ${memberName}.`
            )
          )
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(QUERIES.LOAD_MEMBERS)
      },
    });
  };
  