import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useState } from 'react';

export default function PaymentStatus() {
  const params = new URLSearchParams(window.location.search);
  const [open, setOpen] = useState(
    params.has('data.message') && params.has('success')
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={params.get('success') === 'true' ? 'success' : 'error'}
      >
        {params.get('success') === 'true'
          ? 'The payment is completed successfully!'
          : 'The payment is Faild: ' + params.get('data.message')}
      </Alert>
    </Snackbar>
  );
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
