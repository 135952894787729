import * as React from 'react';

interface ErrorBoundaryProps {
	hasError: boolean;
	error?: Error;
	info?: React.ErrorInfo;
}
export default class ErrorBoundary extends React.Component<
	{},
	ErrorBoundaryProps
	> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false, error: undefined, info: undefined };
	}

	componentDidCatch(error: any, info: any): void {
		this.setState({ hasError: true, error: error, info: info });
	}

	render(): {} {
		if (this.state.hasError) {
			return (
				<div>
					<h2>Something went wrong.</h2>
					<details style={{ whiteSpace: 'pre-wrap' }}>
						{this.state.error && this.state.error.toString()}
						<br />
						{this.state.info!.componentStack}
					</details>
				</div>
			);
		}

		// Render children if no error
		return this.props.children!;
	}
}