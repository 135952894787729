import React, { useEffect } from 'react';
import ContactUsImage from '../../../../assets/images/Bscrum1.png';
import SignUpButton from '../SingUpButton';
import 'aos/dist/aos.css';
import Aos from 'aos';
import { styles } from './styles';

function ContactUs() {
  useEffect(() => {
    Aos.init({
      offset: -450
    });
  }, []);
  const classes = styles();
  return (
    <div className={classes.contactUsBG}>
      <section id="contactUs" className={classes.contactUsWrapper}>
        <div className={classes.contactUsLeftSide}>
          <div
            className={classes.slideRight}
            data-aos="slide-right"
            data-aos-once="true"
          >
            <h4 className={classes.contactUsTitle}>Ready to get started?</h4>
            <h5 className={classes.contactUsSubTitle}>
              It’s fast, free and real time!
            </h5>
            <p className={classes.contactUsDescription}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the.
            </p>
            <SignUpButton buttonLabel="Get Started" />
          </div>
        </div>
        <div className={classes.contactUsRightSide}>
          <div
            className={classes.slideLeft}
            data-aos="slide-left"
            data-aos-once="true"
          >
            <div className={classes.contactUsImgWrapper}>
              <img
                className={classes.contactUsImg}
                src={ContactUsImage}
                alt="bscrum_image"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;
