import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const styles = makeStyles(theme =>
  createStyles({
  messagesList: {
    // margin: '1em 0',
    margin: '0px',
    padding: '0',
    listStyleType: 'none',
    height: 0
  },
  messageItem: {
    margin: '0.5em 0',
    width: 296,
  },
  messageRoot: {
    borderRadius: 6,
    boxShadow: '0 2px 15px 9px rgba(31, 31, 31, 0.07)',
  },
  confirmationRoot: {
    backgroundColor: '#2ecc71',
    marginTop: 80
  },
  messageTxt: {
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: '#ffffff'
  },
  closeMessage: {
    height: 40,
    opacity: '0.5 !important',
    color: '#fff',
  }
}));