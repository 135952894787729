import { object, date, string, bool, number, array, boolean } from 'yup';
import { REPORT_TYPES } from '../../../../utils/constants';
import { Report } from '../../../../models/Report.model';
import { REPORT_PERIODS } from '../../../../utils/constants';

export const REPORT_SETUP_SCHEMA = object<Report>().shape({
  name: string().required('Report name is required'),
  workspace: object()
    .test('workspace-required', 'Workspace is required', function (value: any) {
      return value && value.id && value.name;
    })
    .required('Report must belong to one workspace')
    .nullable(),

  scheduledSwitch: boolean(),
  notification_time: string()
    .nullable()
    .when('scheduledSwitch', {
      is: val => val === false,
      then: string().notRequired(),
      otherwise: string().required(
        'Report timing is required for scheduled reports.'
      )
    }),
  wrap_time: string()
    .nullable()
    .when('scheduledSwitch', {
      is: val => val === false,
      then: string().notRequired(),
      otherwise: string().required(
        'Wrap time is required for scheduled reports.'
      )
    }),
  recurrence: string()
    .nullable()
    .when('scheduledSwitch', {
      is: val => val === false,
      then: string().notRequired(),
      otherwise: string().required(
        'Report recurrence is required for scheduled reports.'
      )
    }),
  timezone: object()
    .test('timezone-required', 'Timezone is required', function (value: any) {
      return value && value.name && value.offset;
    })
    .required('Report timezone is required')
    .nullable(),
  start_date: date().required('Report start date is required'),

  wrap_reminder: number()
    .required('Report wrap reminder is required')
    .nullable(),
  wrap_reminder_message: string()
    .nullable()
    .when('scheduledSwitch', {
      is: val => val === false,
      then: string().notRequired(),
      otherwise: string().required(
        'Report wrap reminder message is required for scheduled reports.'
      )
    }),
  week_days: array()
    .of(string())
    .test(
      'check-weekly-weekdays',
      'One day must be chosen',
      function (value: any) {
        // if non-scheduled report, don't need to select weekdays
        if (this.parent.scheduledSwitch === false) return true;

        // if recurrent report, select at least 1 day
        if (this.parent.recurrence !== REPORT_PERIODS[3])
          return value.length > 0;
        return true;
      }
    )
});

export const QUESTIONS_TAB_SCHEMA = (reportTypeId: number) =>
  object<Report>().shape({
    intro_message: string().required('Report intro message is required'),
    confirmation_message: string().required(
      'Report confirmation message is required'
    ),
    questions: array()
      .of(
        object().shape({
          id: number().required('Question ID must be a number'),
          content: string().required('Question content is required'),
          // .min(3, 'Question content must be more than 3 characters'),
          optional: bool().required('Optional field is required')
        })
      )
      .test(
        'questions-not-poll',
        'Questions are required',
        function (value: any) {
          if (reportTypeId !== REPORT_TYPES[3].id) {
            // NOt poll
            return value?.length > 0;
          }
          return true;
        }
      ),
    question: string().test(
      'question-validation',
      'Question is required',
      function (value: any) {
        if (reportTypeId === REPORT_TYPES[3].id)
          // POLL request question
          return value && value?.length > 0;
        return true;
      }
    ),
    options: array()
      .of(
        object().shape({
          id: number().required('Option ID must be a number'),
          content: string().required('Option content is required')
        })
      )
      .test('options-test', 'Options are required', function (value?: any) {
        if (reportTypeId === REPORT_TYPES[3].id) {
          //POLL request
          return value.length > 1;
        }
        return true;
      })
  });

export const TEAMMATES_TAB_SCHEMA = object<Report>().shape({
  participants: array().min(1)
});

export const REPORTING_TAB_SCHEMA = object<Report>().shape({
  emails: array().of(
    object().shape({
      id: number().required('Email ID must be a number'),
      email: string()
        .email('Email address is invalid')
        .required('Email address is required')
        .nullable()
    })
  )
});
