import { ReportType } from './ReportType.model';
import { Member } from './Member.model';
import { ReportResponse } from './ReportResponse.model';
import { Question } from './Question.model';
import { Option } from './Option.model';
import axios from '../services/axios';
import { API } from './API';
import { Workspace } from './Workspace.model';
import { Channel } from './Channel.model';
import {
  STANDUP_QUESTIONS,
  RETRO_QUESTIONS,
  REPORT_TYPES,
  REPORT_PERIODS
} from '../utils/constants';
import dayjs from 'dayjs'
import { ACTIVE_FILTER } from '../utils/constants';
import { Email } from './Email.model';
import { SubmissionCycle } from './SubmissionCycle.model';
import { TimeZone } from './TimeZone.model';
import railsTimezone from 'rails-timezone';
import store from '../store';
export type ReportStatus = 'Active' | 'Inactive';

export type ReportFilters = {
  status: number[];
  types: number[];
  workspaces: number[];
  title?: string;
};

export const PINNED_REEPORTS_PER_PAGE = 2;
export interface PinnedReportsResponse {
  count: number;
  reports: Report[];
}

export class Report extends API {
  public id: number = -1;
  public name: string = '';
  public workspace: Workspace = new Workspace();
  public channels: Channel[] = [];
  public notification_time: string = '';
  public wrap_time: number = 2;
  public start_date: string = dayjs().format('YYYY-MM-DD');
  public intro_message: string = '';
  public confirmation_message: string = '';
  public week_days: string[] = [];
  public wrap_reminder: number = 15;
  public wrap_reminder_message: string = '';
  public timezone: TimeZone | undefined;
  public respondents: number = 0;
  public recurrence: string = '';
  public active: boolean = true;
  public participants: Member[] = [];
  public admins: Member[] = [];
  public questions: Question[] = [];
  public responses: ReportResponse[] = [];
  public type: ReportType = new ReportType();
  public creator_id: number = -1;
  public last_updated: string = '';
  public updated_by: string = '';
  public pinned: boolean = true;
  public description: string = '';
  public emails: Email[] = [];
  public submission_cycles: SubmissionCycle[] = [];
  public notification_subscribers: Member[] = [];
  public threads_enabled: boolean = false;
  public group_responses: boolean = false;
  public not_submitted_member_ids: number[] = [];
  public next_submission_cycle_date: string = '';
  public submission_status: string = '';
  public submission_status_description = '';
  public kind: string = '';
  public num_responses: number = 0;

  //Poll Report attributes
  public question: string = '';
  public options: Option[] = [];

  constructor(type: ReportType) {
    super();
    this.type = type;
    this.initializeReport();
  }

  private initializeReport(): void {
    switch (this.type.id) {
      case REPORT_TYPES[0].id:
        this.questions = STANDUP_QUESTIONS;
        this.recurrence = REPORT_PERIODS[0];
        this.intro_message =
          'it’s time to submit your daily standup report. Please answer the questions below';
        break;
      case REPORT_TYPES[1].id:
        this.questions = RETRO_QUESTIONS;
        this.recurrence = REPORT_PERIODS[1];
        this.intro_message =
          'it’s time to submit your weekly retrospective report. Please answer the questions below';
        break;
      case REPORT_TYPES[2].id:
        this.questions = [new Question()];
        break;
      case REPORT_TYPES[3].id:
        this.options = [new Option(), new Option()];
        this.recurrence = REPORT_PERIODS[3];
        this.intro_message =
          'we are looking for your opinions! Please let us know what you think on the question below';
        break;
      default:
        break;
    }
    this.start_date = dayjs().format('YYYY-MM-DD');
    this.wrap_reminder = 15;
    this.confirmation_message =
      'Thanks a lot for your answers and your hard work!';
    const userTimeZone = railsTimezone.to(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const offset = -(new Date().getTimezoneOffset() / 60);
    const offsetStr = `${Math.abs(offset).toString().padStart(2, '0')}:00`;
    const timeZoneOffset = offset >= 0 ? `+${offsetStr}` : `-${offsetStr}`;
    if(userTimeZone){
      this.timezone = {name: userTimeZone, offset: timeZoneOffset.toString()} as TimeZone
    }
  }

  public static async getReport(reportId: number): Promise<Report> {
    const headers = Report.getConfig();

    if (headers) {
      return new Promise<Report>((resolve, reject) => {
        axios
          .get('dashboard/reports/' + reportId, headers)
          .then(res => {
            resolve(res.data.report as Report);
          })
          .catch(e => {
            Report.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }

    return Promise.reject('Error while fetching this report');
  }

  public static async getAll(
    page: number,
    filters?: ReportFilters
  ): Promise<{ reports: Report[]; pageCount: number }> {
    const headers = Report.getConfig();
    if (headers) {
      return new Promise<{ reports: Report[]; pageCount: number }>(
        (resolve, reject) => {
          let url = `dashboard/reports?page=${page}`;
          if (filters) url += `${buildFiltersParams(filters).join('')}`;
          axios
            .get(url, headers)
            .then(res => {
              const reports: Report[] = res.data.reports as Report[];
              const pageCount: number = res.data.pageCount;
              resolve({ reports, pageCount });
            })
            .catch(e => {
              Report.checkUnauthorizedRequest(e,store);
              reject(e);
            });
        }
      );
    }

    return Promise.reject('system error');
  }

  public static getPendingReports(memberId : number): Promise<Report []>{
    const  headers = Report.getConfig();
    if(headers){
      return new Promise<Report []>(
        (resolve , reject) => {
          axios 
            .get(`dashboard/pending_reports/${memberId}` , headers)
            .then( res => {
              resolve(res.data);
            })
            .catch( err => {
              Report.checkUnauthorizedRequest(err,store);
              reject(err);
            });
        }
      )
    }

    return Promise.reject('system error');
  }
  public static async getPinnedReports(
    page: number = 1
  ): Promise<PinnedReportsResponse> {
    const headers = Report.getConfig();
    if (headers) {
      return new Promise<PinnedReportsResponse>((resolve, reject) => {
        let url = `dashboard/pinned_reports?page=${page}&page_limit=${PINNED_REEPORTS_PER_PAGE}`;
        axios
          .get(url, headers)
          .then(res => {
            resolve(res.data as PinnedReportsResponse);
          })
          .catch(e => {
            Report.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }

    return Promise.reject('system error');
  }

  public static getAssignedReports(memberId : number): Promise<Report []>{
    const  headers = Report.getConfig();
    if(headers){
      return new Promise<Report []>(
        (resolve , reject) => {
          axios 
            .get(`dashboard/assigned_reports/${memberId}` , headers)
            .then( res => {
              resolve(res.data.reports);
            })
            .catch( err => {
              Report.checkUnauthorizedRequest(err,store);
              reject(err);
            });
        }
      )
    }

    return Promise.reject('system error');
  }

  public static async pauseReport(reportId: number): Promise<void> {
    const headers = Report.getConfig();

    if (headers) {
      return new Promise<void>((resolve, reject) => {
        axios
          .put('dashboard/reports/pause/' + reportId, {}, headers)
          .then(res => resolve())
          .catch(e => {
            Report.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('system error');
  }

  public static async resumeReport(reportId: number): Promise<void> {
    const headers = Report.getConfig();

    if (headers) {
      return new Promise<void>((resolve, reject) => {
        axios
          .put('dashboard/reports/resume/' + reportId, {}, headers)
          .then(res => resolve())
          .catch(e => {
            Report.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }

  public static async unpinReport(reportId: number): Promise<void> {
    const headers = Report.getConfig();

    if (headers) {
      return new Promise<void>((resolve, reject) => {
        axios
          .put('dashboard/reports/unpin/' + reportId, {}, headers)
          .then(res => resolve())
          .catch(e => {
            Report.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('system error');
  }

  public static async pinReport(reportId: number): Promise<void> {
    const headers = Report.getConfig();

    if (headers) {
      return new Promise<void>((resolve, reject) => {
        axios
          .put('dashboard/reports/pin/' + reportId, {}, headers)
          .then(res => resolve())
          .catch(e => {
            Report.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }

  public static async joinReport(reportId : number): Promise<void> {
    const headers = Report.getConfig();

    if(headers) {
        return new Promise<void>((resolve,reject) => { 
          axios
            .put(`dashboard/reports/join/${reportId}`,{},headers)
            .then(res=> resolve())
            .catch(err=>{
              Report.checkUnauthorizedRequest(err,store);
              reject(err)
            });
        });
    }
    return Promise.reject("Error during join report")
  }

  public static async leaveReport(reportId: number): Promise<void> {
    const headers = Report.getConfig();

    if(headers) {
        return new Promise<void>((resolve,reject) => { 
          axios
            .put(`dashboard/reports/leave/${reportId}` ,{},headers)
            .then(res=> resolve())
            .catch(err=>{
              Report.checkUnauthorizedRequest(err,store);
              reject(err)
            });
        });
    }
    return Promise.reject("Error during join report")
  }

  public static async deleteReport(reportId: number): Promise<void> {
    const headers = Report.getConfig();

    if (headers) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete('dashboard/reports/' + reportId, headers)
          .then(res => resolve())
          .catch(e => {
            Report.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }

  public static async create(report: Report): Promise<Report> {
    const headers = Report.getConfig();

    if (headers) {
      return new Promise<Report>((resolve, reject) => {
        // Map channels to channel_ids
        report.channels = (report.channels.map(
          channel => channel.id
        ) as unknown) as Channel[];

        // Map members to member_ids
        report.participants = (report.participants.map(
          member => member.id
        ) as unknown) as Member[];
        report.admins = (report.admins.map(
          member => member.id
        ) as unknown) as Member[];
        report.notification_subscribers = (report.notification_subscribers.map(
          member => member.id
        ) as unknown) as Member[];
        report.kind = report.type.title;
        axios
          .post('dashboard/reports/', { report: report }, headers)
          .then(res => {
            resolve(res.data.report as Report);
          })
          .catch(e => {
            Report.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }

  public static async edit(report: Report): Promise<Report> {
    const headers = Report.getConfig();

    if (headers) {
      return new Promise<Report>((resolve, reject) => {
        if (report.notification_time.length > 5) {
          report.notification_time = report.notification_time.slice(0, -6);
          report.notification_time = dayjs(report.notification_time).format(
            'HH:mm'
          );
        }
        // Map channels to channel_ids
        report.channels = (report.channels.map(
          channel => channel.id
        ) as unknown) as Channel[];

        // Map members to member_ids
        report.participants = (report.participants.map(
          member => member.id
        ) as unknown) as Member[];
        report.admins = (report.admins.map(
          member => member.id
        ) as unknown) as Member[];
        report.notification_subscribers = (report.notification_subscribers.map(
          member => member.id
        ) as unknown) as Member[];
        report.kind = report.type.title;
        axios
          .put(
            'dashboard/reports/' + report.id,
            { report: report },
            headers
          )
          .then(res => {
            resolve(res.data.report as Report);
          })
          .catch(e => {
            this.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }

  public static async remindParticipants(reportId: number): Promise<void>{
    const headers = Report.getConfig();
    if (headers) {
      return new Promise<void>((resolve, reject) => {
        let url = `dashboard/reports/${reportId}/reminder`;
        axios
          .put(url, {} , headers)
          .then(res => {
            resolve();
          })
          .catch(e => {
            this.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }

    return Promise.reject('system error');
  }
}



function buildFiltersParams(filters: ReportFilters): string[] {
  const params: string[] = [];
  if (filters.status && filters.status.length) {
    params.push(
      `&status=${JSON.stringify(filters.status.map(val => ACTIVE_FILTER[val]))}`
    );
  }

  if (filters.types && filters.types.length) {
    params.push(
      `&types=${JSON.stringify(
        filters.types.map(
          type => REPORT_TYPES.find(el => el.id === type)?.title
        )
      )}`
    );
  }

  if (filters.workspaces && filters.workspaces.length) {
    params.push(`&workspaces_ids=${JSON.stringify(filters.workspaces)}`);
  }

  // sending title filter
  if (filters.title) params.push(`&title=${JSON.stringify(filters.title)}`);
  return params;
}
