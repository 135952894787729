import React from 'react';
import { styles } from '../styles';
import { Typography, Grid } from '@material-ui/core';
import { ReportResponse } from '../../../../../../../models/ReportResponse.model';
import { Question } from '../../../../../../../models/Question.model';
import { useMembers } from '../../../../ReportForm/reportFormHooks';
import CircularLoader from '../../../../../../shared-components/CircularLoader';
import {
  prepareContentToRender,
  answerSplitterType
} from '../../../../../../../utils/parser';
import { useMemo } from 'react';

interface Props {
  questions: Question[];
  response: ReportResponse;
}

export default function ResponseContent({ response, questions }: Props) {
  const classes = styles();
  const { data: membersList, isLoading } = useMembers();
  const answersTags = useMemo(() => {
    let answersTags = new Map<number, any[]>();
    if (questions) {
      questions.map(ques => {
        const quesAnswer = response.answers.filter(
          ans => ans.question_id === ques.id
        )[0];
        if (quesAnswer) {
          const parsedAnswer = prepareContentToRender(
            quesAnswer.content,
            membersList!
          );
          answersTags.set(ques.id, answerSplitterType(parsedAnswer, classes));
        }
      });
    }
    return answersTags;
  }, [response, questions]);

  if (!membersList) return null;

  return (
    <CircularLoader isLoading={isLoading}>
      <Grid container direction="column" className={classes.root}>
        {response.quick_response ? (
          <Grid item>
            <p className={classes.answer}>{response.quick_response}</p>{' '}
          </Grid>
        ) : (
          <Grid item>
            {questions
              ? questions.map((ques, index) => {
                  const quesAnswer = response.answers.filter(
                    ans => ans.question_id === ques.id
                  )[0];
                  return (
                    <div key={ques.id}>
                      <Typography className={classes.question}>
                        Q{index + 1}: {ques.content}
                      </Typography>
                      <>
                        {quesAnswer ? (
                          <div className={classes.answer}>
                            {answersTags.get(ques.id)}
                          </div>
                        ) : (
                          <p className={classes.answer}>No Answer</p>
                        )}
                      </>
                    </div>
                  );
                })
              : ''}
          </Grid>
        )}
      </Grid>
    </CircularLoader>
  );
}
