import React, { useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  Comment,
  commentRequest
} from '../../../../../../../models/Comment.model';
import UserComponent from '../../../../../../shared-components/UserComponent';
import { attachmentsStyles } from '../styles';
import { CommentSubMenu } from './CommentSubMenu';
import { Member } from '../../../../../../../models/Member.model';
import {
  extractContent,
  prepareContentToRender,
  ParsingOptions,
  parseEditorState
} from '../../../../../../../utils/parser';
import parse from 'html-react-parser';
import { useState } from 'react';
import { ContentState, EditorState } from 'draft-js';
import { CustomizedEditor } from '../../../../../../shared-components/CustomizedEditor';
import { useUpdateComment } from '../ResponseAttachmentsHooks';
import dayjs from 'dayjs';
import classNames from 'classnames';

interface commentProps {
  comment: Comment;
  members: Member[];
}
export const CommentContent = ({ comment, members }: commentProps) => {
  const classes = attachmentsStyles();
  const parsedComment = prepareContentToRender(comment.body, members);
  const [editMode, setEditMode] = useState(false);
  const updateCommentMutation = useUpdateComment();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromText(extractContent(parsedComment) || '{}')
    )
  );

  const handleSubmit = () => {
    const parsedComment = parseEditorState(editorState);
    const request: commentRequest = {
      body: parsedComment,
      report_record_id: comment.report_record_id,
      id: comment.id,
      report_id: comment.report_id
    };
    updateCommentMutation.mutate(request);
    setEditMode(false);
  };

  const handleCancel = useCallback(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromText(extractContent(parsedComment) || '{}')
      )
    );
    setEditMode(false);
  }, [setEditMode, setEditorState]);

  return (
    <Grid item container direction="column">
      <Grid
        item
        container
        justify="space-between"
        className={classes.commentMemberInfo}
      >
        <Grid item xs>
          <Grid container alignItems="center">
            <UserComponent
              id={Number(comment.member.id)}
              name={comment.member.name}
              avatar={comment.member.avatar}
            />
            {comment.time && (
              <Typography className={classes.time}>
                (
                <span className={classes.timeSpan}>
                  {dayjs(comment.time).fromNow(true)}
                </span>
                &nbsp;ago)
              </Typography>
            )}
          </Grid>
        </Grid>
        {!editMode && (
          <Grid>
            <CommentSubMenu comment={comment} setEditMode={setEditMode} />
          </Grid>
        )}
      </Grid>
      {editMode ? (
        <Grid item container spacing={1} alignItems="baseline" >
          <Grid item xs={12}>
            <CustomizedEditor
              rootStyleClass={classes.rootEditor}
              setEditorState={setEditorState}
              editorState={editorState}
              members={members!}
              placeholder="Edit your comment"
              editorStyleClass={classes.editor}
              editMode= {editorState.getCurrentContent().getPlainText().length > 0}
              submitEdit = {handleSubmit}
              cancelEdit = {handleCancel}
              
            />
                     
          </Grid>

        </Grid>
      ) : (
        <Grid item>
          <Typography className={classes.commentBody}>
            {parsedComment.split('\n')?.map((line, ind) => (
              <span key={ind}>{parse(line, ParsingOptions)}</span>
            ))}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
