/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useMemo, useRef, useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import { reportHeaderStyles } from './styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { Workspace } from '../../../../models/Workspace.model';
import { ReportFilters } from '../../../../models/Report.model';
import { mapToSelectOption } from '../../../../utils/forms';
import {
  SelectField as SelectFilter,
  SelectOption
} from '../../../shared-components/SelectField';
import { REPORT_TYPES } from '../../../../utils/constants';
import { ACTIVE_FILTER } from '../../../../utils/constants';
import SearchIcon from '../../../../assets/images/magnifiying-glass.svg';

interface ReportsHeaderProps {
  workspaces: Workspace[];
  reportFilters: ReportFilters;
  setReportTypesFilter: (types: number[]) => void;
  setWorkspaceFilter: (workspaces: number[]) => void;
  setStatusFilter: (status: number[]) => void;
  setTitleFilter: (title: string | undefined) => void;
  setPage: any;
}
const reportTypesOptions: SelectOption[] = mapToSelectOption(
  REPORT_TYPES,
  'id',
  'title'
);

const reportStatusOptions: SelectOption[] = ACTIVE_FILTER.map((value, idx) => ({
  id: idx,
  value
})) as SelectOption[];

const ReportsHeader = ({
  workspaces,
  reportFilters,
  setReportTypesFilter,
  setWorkspaceFilter,
  setStatusFilter,
  setTitleFilter,
  setPage
}: ReportsHeaderProps) => {
  const classes = reportHeaderStyles();
  const [titleSearch, setTitleSearch] = useState<string>('');
  const searchTimeout = useRef<any>(0);

  const workspacesOptions: SelectOption[] = useMemo(
    () => mapToSelectOption(workspaces, 'id', 'name'),
    [workspaces]
  );

  const handleSearch = (value: string) => {
    setPage(1);
    setTitleFilter(value);
  };

  const handleClear = () => {
    if (searchTimeout.current) clearTimeout(searchTimeout.current);
    setTitleFilter('');
    setTitleSearch('');
  };

  const startAdornment = (
    <InputAdornment position="start">
      <IconButton onClick={() => handleSearch(titleSearch)}>
        <img src={SearchIcon} alt="search" />
      </IconButton>
    </InputAdornment>
  );

  const endAdornment = titleSearch && (
    <InputAdornment position="end">
      <IconButton onClick={handleClear}>
        <ClearIcon fontSize="small" className={classes.clearSearch} />
      </IconButton>
    </InputAdornment>
  );
  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} sm={6} lg={2}>
          <Typography className={classes.reportsHeaderText}>
            My reports
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} lg={4} classes={{ root: classes.searchGrid }}>
          <InputBase
            placeholder="Search…"
            classes={{ root: classes.search }}
            name="title"
            id="reports-search-term"
            value={titleSearch || ''}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            autoComplete="off"
            onBlur={event => handleSearch(titleSearch)}
            onChange={event => {
              setTitleSearch(event.target.value);
              if (searchTimeout.current) clearTimeout(searchTimeout.current);
              searchTimeout.current = setTimeout(() => {
                handleSearch(event.target.value);
              }, 1000);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <SelectFilter
            selectId="reportType"
            label="Report type"
            selectItems={reportTypesOptions}
            multiple={true}
            value={reportFilters.types}
            onSelect={(selectId: any, value: any[]) => {
              setPage(1);
              setReportTypesFilter(value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <SelectFilter
            selectId="workspace"
            label="Workspace"
            selectItems={workspacesOptions}
            multiple={true}
            value={reportFilters.workspaces || []}
            onSelect={(selectId: any, value: any[]) => {
              setPage(1);
              setWorkspaceFilter(value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <SelectFilter
            selectId="status"
            label="Status"
            selectItems={reportStatusOptions}
            multiple={true}
            value={reportFilters.status}
            onSelect={(selectId: any, value: any) => {
              setPage(1);
              setStatusFilter(value);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default memo(ReportsHeader);
