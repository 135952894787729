import React from 'react';
import { styles as newReportStyles } from './styles';
import classNames from 'classnames';
import { REPORT_FORM_STEPS } from '../../../../utils/constants';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Button, Typography } from '@material-ui/core';
import { Link as RLink } from 'react-router-dom';

interface Props {
  activeStep: number;
  reportTitle: string;
  editForm: boolean;
  handleStepNavigation: Function;
}

export const ReportFormHeader = React.memo(
  ({ activeStep, reportTitle, editForm, handleStepNavigation }: Props) => {
    const classes = newReportStyles();

    return (
      <>
        <Typography className={classes.headerTxt}>
          <RLink to={`/app/dashboard`} className={classes.dashboardSpan}>
            <span>Dashboard</span>
          </RLink>
          /
          <span className={classes.createSpan}>
            {editForm ? `Edit ${reportTitle}` : `Create new ${reportTitle}`}
          </span>
        </Typography>
        <div className={classes.reportFormArea}>
          <div className={classes.stepsLine}>
            <progress
              className={classes.progressBar}
              id="file"
              max="4"
              value={activeStep + 1}
            />
          </div>
          <div className={classes.stepsDescription}>
            {REPORT_FORM_STEPS.map((step, index) => {
              return editForm ? (
                <Button
                  key={step}
                  className={classNames(classes.stepTitle, classes.buttonStep)}
                  onClick={() => handleStepNavigation(index)}
                >
                  {step}
                </Button>
              ) : activeStep > index ? (
                <CheckCircleIcon
                  classes={{ root: classes.doneStep }}
                  key={step}
                />
              ) : (
                <Typography
                  className={classNames(
                    classes.stepTitle,
                    activeStep === index
                      ? classes.currentStep
                      : classes.nextStep
                  )}
                  key={step}
                >
                  {step}
                </Typography>
              );
            })}
          </div>
        </div>
      </>
    );
  }
);
