import React, { useState, useEffect, useContext } from 'react';
import {
  ListItemText,
  ListItemIcon,
  Typography,
  ListItem,
  Divider,
  Button,
  Avatar,
  List,
  Box
} from '@material-ui/core';
import { Link as RLink } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { AdminAuthority } from '../../../shared-components/AdminAuthority';
import { USER_ROLES } from '../../../../utils/constants';
import PATH_NAME from '../../../../utils/pathNames';
import { getUserProfileURL } from '../../../../utils/routes';
import upsellingIllustration from '../../../../assets/images/Upgrade now.png';
import WorkspacesIconWhite from '../../../../assets/images/workspace_icon_white.svg';
import WorkspacesIconGray from '../../../../assets/images/workspace_icon_gray.svg';
import LogoutConfirmation from './LogoutConfirmation';
import DashboardIconWhite from '../../../../assets/images/dashboard_icon_white.svg';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardIconGray from '../../../../assets/images/dashboard_icon_gray.svg';
import SettingArrowWhite from '../../../../assets/images/setting_arrow_white.svg';
import SettingArrowGray from '../../../../assets/images/setting_arrow_gray.svg';
import SettingIconWhite from '../../../../assets/images/settings_icon_white.svg';
import SettingIconGray from '../../../../assets/images/settings_icon_gray.svg';
import ReportIconWhite from '../../../../assets/images/report_icon_white.svg';
import LogoutIconGray from '../../../../assets/images/logout_icon_gray.svg';
import ReportIconGray from '../../../../assets/images/report_icon_gray.svg';
import BscrumLogo from '../../../../assets/images/logo.png';
import { styles } from './sidebarContentStyles';
import { AppContext } from '../../../../App';
import { User } from '../../../../models/User.model';
import { useTeam, useTeamSubscription } from '../homeHooks';

const SidebarContent = () => {
  const [selected, setSelected] = useState(0);
  const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);
  const [openViewProfileDialog, setOpenViewProfileDialog] = useState(false);
  const { data: subscription } = useTeamSubscription();
  const currentUser: User = useContext(AppContext);
  const { data: team } = useTeam(currentUser?.team_id);
  const classes = styles();
  const location = useLocation();
  const history = useHistory();

  const updateSelected = (selectedIndex: number) => {
    setSelected(selectedIndex);
  };

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === PATH_NAME.DASHBOARD) setSelected(0);
    else if (pathname.startsWith(PATH_NAME.WORKSPACES)) setSelected(1);
    else if (pathname.startsWith(PATH_NAME.REPORTS)) setSelected(2);
    else if (pathname === PATH_NAME.SETTINGS) setSelected(3);
  }, [location.pathname]);

  const redirectToPlanPage = () => {
    history.push({
      pathname: PATH_NAME.SETTINGS,
      state: { tab: 1 } // current plan tab in settings page
    });
  };

  const handleOpenLogoutConfirmation = (event: any) => {
    event.preventDefault();
    setOpenLogoutConfirmation(true);
  };

  const handleCloseLogoutConfirmation = () => {
    setOpenLogoutConfirmation(false);
  };
  const handleViewProfileDialog = () => {
    setOpenViewProfileDialog(!openViewProfileDialog);
  };

  const handleLogoClick = () => {
    history.push(PATH_NAME.DASHBOARD);
  };
  return (
    <>
      <div className={classes.drawerParentTitle} onClick={handleLogoClick}>
        <img
          src={BscrumLogo}
          alt="Bscrum_Logo"
          height={38}
          className={classes.drawerParentLogo}
        />
        <span className={classes.drawerParentLogoText}>bscrum</span>
      </div>
      {team && (
        <div className={classes.drawerParentTeam}>
          <Typography className={classes.drawerTeamName}>
            {team.name}
          </Typography>
        </div>
      )}

      {currentUser ? (
        <Box className={classes.drawerProfile}>
          <RLink
            to={getUserProfileURL(currentUser.id)}
            className={classes.drawerProfileLink}
            title="View Profile"
          >
            <Avatar
              className={classes.drawerProfileImage}
              src={currentUser.avatar}
              alt="Profile_Image"
            ></Avatar>
          </RLink>
          <Box className={classes.drawerProfileTextWrap}>
            <RLink
              to={getUserProfileURL(currentUser.id)}
              className={classes.drawerProfileLink}
              title="View Profile"
            >
              <Typography className={classes.drawerProfileName}>
                {currentUser.name}
              </Typography>
            </RLink>
            <Typography className={classes.drawerProfilePosition}>
              {currentUser.position}
            </Typography>
          </Box>
        </Box>
      ) : (
        <List>
          <RLink to="#" onClick={handleViewProfileDialog}>
            <ListItem button>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
            </ListItem>
          </RLink>
        </List>
      )}
      <Box className={classes.bottomWrapper}>
        <List className={classes.list}>
          <RLink to={PATH_NAME.DASHBOARD} className={classes.listItem}>
            <ListItem
              onClick={() => updateSelected(0)}
              selected={selected === 0}
              className={
                selected === 0 ? classes.selectedListButton : classes.listButton
              }
            >
              <img
                src={selected === 0 ? DashboardIconWhite : DashboardIconGray}
                alt="Dashboard"
              />

              <ListItemText
                primary="Dashboard"
                className={
                  selected === 0
                    ? classes.selectedListItemText
                    : classes.listItemText
                }
              />
            </ListItem>
          </RLink>

          <Divider className={classes.divider} />

          <RLink to={PATH_NAME.REPORTS} className={classes.listItem}>
            <ListItem
              onClick={() => updateSelected(2)}
              selected={selected === 2}
              className={
                selected === 2 ? classes.selectedListButton : classes.listButton
              }
            >
              <img
                src={selected === 2 ? ReportIconWhite : ReportIconGray}
                alt="Reports"
              />

              <ListItemText
                primary="Reports"
                className={
                  selected === 2
                    ? classes.selectedListItemText
                    : classes.listItemText
                }
              />
            </ListItem>
          </RLink>

          <Divider className={classes.divider} />

          <RLink to={PATH_NAME.WORKSPACES} className={classes.listItem}>
            <ListItem
              onClick={() => updateSelected(1)}
              selected={selected === 1}
              className={
                selected === 1 ? classes.selectedListButton : classes.listButton
              }
            >
              {selected === 1 ? (
                <img src={WorkspacesIconWhite} alt="Workspaces" />
              ) : (
                <img src={WorkspacesIconGray} alt="Workspaces" />
              )}

              <ListItemText
                primary="Workspaces"
                className={
                  selected === 1
                    ? classes.selectedListItemText
                    : classes.listItemText
                }
              />
            </ListItem>
          </RLink>

          <Divider className={classes.divider} />

          <AdminAuthority>
            <RLink to={PATH_NAME.SETTINGS} className={classes.listItem}>
              <ListItem
                onClick={() => updateSelected(3)}
                selected={selected === 3}
                className={
                  selected === 3
                    ? classes.selectedListButton
                    : classes.listButton
                }
              >
                <img
                  src={selected === 3 ? SettingIconWhite : SettingIconGray}
                  alt="Settings"
                />

                <ListItemText
                  primary="Settings"
                  className={
                    selected === 3
                      ? classes.selectedListItemText
                      : classes.listItemText
                  }
                />
                <img
                  className={classes.settingsArrow}
                  src={selected === 3 ? SettingArrowWhite : SettingArrowGray}
                  alt="Arrow"
                />
              </ListItem>
            </RLink>
            <Divider className={classes.divider} />
          </AdminAuthority>

          <RLink to={PATH_NAME.LOG_OUT} className={classes.listItem}>
            <ListItem
              onClick={handleOpenLogoutConfirmation}
              className={classes.listButton}
            >
              <img src={LogoutIconGray} alt="Logout" />
              <ListItemText primary="Logout" className={classes.listItemText} />
            </ListItem>
          </RLink>
          <Divider className={classes.divider} />
        </List>
        {subscription?.team_id &&
          subscription?.team_id !== -1 &&
          currentUser &&
          currentUser.role === USER_ROLES.admin.toLowerCase() &&
          subscription?.plan.price === 0 && (
            <div className={classes.sidebarImage}>
              <img
                src={upsellingIllustration}
                className={classes.sidebarIllustration}
                alt="Subscribe"
              />
              <Typography className={classes.moreFeaturesText}>
                {`Unlock more features and create
                  more workspaces`}
              </Typography>
              <Button
                className={classes.upgradeButton}
                onClick={redirectToPlanPage}
              >
                Upgrade now
              </Button>
            </div>
          )}
        <LogoutConfirmation
          open={openLogoutConfirmation}
          handleClose={handleCloseLogoutConfirmation}
        />
      </Box>
    </>
  );
};

export default React.memo(SidebarContent);
