import { Button, Grid } from '@material-ui/core';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { workspaceStyles } from './workspaceStyles';

interface IProps {
  openCreateForm: any;
}

export const NewWorkspaceButton = ({ openCreateForm }: IProps) => {
  const classes = workspaceStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={openCreateForm}
      className={classes.newWorkspaceBtn}
      id="new_workspace_button"
    >
      New Workspace
    </Button>
  );
};
