import React, { useEffect } from 'react';
import FeatureCard from './FeatureCard';
import ReportIcon from '../../../../assets/images/ic-responses.svg';
import ClockIcon from '../../../../assets/images/ic-standup.svg';
import DiffReportsIcon from '../../../../assets/images/reportsIcon.svg';
import Aos from 'aos';
import 'aos/dist/aos.css';
import styles from './styles';

function Features() {
  useEffect(() => {
    Aos.init();
  }, []);
  const classes = styles();
  return (
    <div className={classes.featuresBG}>
      <section id="features" className={classes.featuresWrapper}>
        <div className={classes.featuresLeftSide}>
          <div
            data-aos="slide-right"
            data-aos-once="true"
            className={classes.slideRight}
          >
            <h2 className={classes.featuresTitle}>
              {`What’s inside
            Bscrum?`}
            </h2>
            <p className={classes.featuresDescription}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries
            </p>
            <p className={classes.featuresDescription}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries
            </p>
          </div>
        </div>
        <div className={classes.featuresRightSide}>
          <div
            className={classes.slideLeft}
            data-aos="slide-left"
            data-aos-once="true"
          >
            <div className={classes.cardsLeftSide}>
              <FeatureCard
                cardIcon={DiffReportsIcon}
                cardTitle="Different reports types"
                cardDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                            when an unknown printer took."
              />
              <FeatureCard
                cardIcon={ReportIcon}
                cardTitle="All responses in one place"
                cardDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                            when an unknown printer took."
              />
            </div>
            <div className={classes.cardsRightSide}>
              <FeatureCard
                cardIcon={ClockIcon}
                cardTitle="Real time reports "
                cardDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                            when an unknown printer took."
              />
              <FeatureCard
                cardIcon={ClockIcon}
                cardTitle="Real time reports "
                cardDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                            when an unknown printer took."
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Features;
