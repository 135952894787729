import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { styles } from './styles';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldLabel } from './shared/FieldLabel';

interface inputProps {
  label: string;
  inputId: string;
  customClass?: any;
  customFieldText?: any;
  type: string;
  placeholder?: string;
  rootClass?: any;
  multiline?: boolean;
  required?: boolean;
  disabled?: boolean;
  endAdornment?: any;
  key?: any;
  defaultValue?: string;
  error?: any;
}

const InputField = ({
  label,
  inputId,
  customClass,
  customFieldText,
  type,
  placeholder,
  rootClass,
  multiline,
  required,
  disabled,
  endAdornment,
  key,
  defaultValue,
  error
}: inputProps) => {
  const { formState, control } = useFormContext();
  const classes = styles();

  const [errorMessage, setErrorMessage] = useState<any>();
  useEffect(() => {
    setErrorMessage(error || formState.errors[inputId]);
  }, [error, formState.errors[inputId]]);

  return (
    <div className={classNames(classes.formControl, customClass)} key={key}>
      <FieldLabel label={label} required={required} />
      <Controller
        name={inputId}
        control={control}
        defaultValue={defaultValue || ''}
        render={({ onChange, value }) => (
          <TextField
            id={inputId}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            disabled={disabled}
            variant="outlined"
            type={type}
            InputProps={{
              endAdornment: endAdornment,
              classes: {
                root: classNames(classes.fieldText, customFieldText)
              }
            }}
            classes={{
              root: classNames(classes.inputField, rootClass)
            }}
            multiline={multiline}
            rows="2"
            helperText={
              errorMessage?.message ? errorMessage?.message : undefined
            }
            error={!!errorMessage}
            autoComplete="off"
          />
        )}
      />
    </div>
  );
};

export default React.memo(InputField);
