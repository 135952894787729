import React from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import { EmptyStateHandler } from '../../shared-components/EmptyStates/EmptyStateHandler';
import { useBoards, useReleaseNotes } from './releaseNotesHooks';
import { Boards } from './Boards'
import { Title } from './Title'
import { ReleaseNotes as ReleaseNotesList } from './ReleaseNotes'
import { styles } from './styles';
import t from '../../../translations/english'

const ReleaseNotes = () => {
  const classes = styles();
  const { data: boards, isLoading: boardsAreLoading } = useBoards();
  const { data: releaseNotes, isLoading: releaseNotesAreLoading } = useReleaseNotes();

  return (
    <>
      <EmptyStateHandler
        loading={boardsAreLoading || releaseNotesAreLoading}
        data={boards! && releaseNotes!}
        emptyComponent={<> </>}
      >
        <Grid
          container
          direction="column"
          spacing={1}
        >

          <br/>

          <Title
            header={ t.releaseNotes.boards.title.header }
            subHeader={ t.releaseNotes.boards.title.subHeader }
          />

          <br/>

          <Boards boards={boards!}/>

          <br/>

          <Title
            header={ t.releaseNotes.releaseNotes.title.header }
            subHeader={ t.releaseNotes.releaseNotes.title.subHeader }
          />

          <div className={classes.releaseNotes}>
            <ReleaseNotesList 
              releaseNotes={releaseNotes!}
            />
          </div>

        </Grid>

      </EmptyStateHandler>
    </>
  );
};

export default ReleaseNotes;
