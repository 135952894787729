import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography
} from '@material-ui/core';
import { Report } from '../../../../models/Report.model';
import { ReportResponse } from '../../../../models/ReportResponse.model';
import { submitReportRequest } from '../../../../models/ReportResponse.model';
import { Answer } from '../../../../models/Answer.model';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Member } from '../../../../models/Member.model';
import { EditorState, ContentState } from 'draft-js';
import { styles } from './styles';
import { useSubmitReport } from './SubmissionHooks';
import { SubmissionEditor } from './SubmissionEditor';
import { parseEditorState } from '../../../../utils/parser';
import CloseIcon from '@material-ui/icons/Close';
import { FieldLabel } from '../../../shared-components/FieldLabel';
import { LastMemberResponse } from './LastMemberResponse';
import { useUpdateResponse } from '../SubmissionCycles/SubmissionCycleCard/Responses/ResponseCard/ResponseCardHooks';

interface reportSubmissionProps {
  response?: ReportResponse;
  report: Report;
  open: boolean;
  setOpen: any;
  setTargetReportId: any;
  members: Member[];
}

interface Map {
  [key: string]: any;
}

export const ReportSubmission = (props: reportSubmissionProps) => {
  const classes = styles();
  const {
    report,
    members,
    open,
    setOpen,
    setTargetReportId,
    response
  } = props;
  const { handleSubmit } = useForm();
  const history = useHistory();
  const submitReportMutation = useSubmitReport(history);
  const updateResponse = useUpdateResponse();
  const [errorMessage, setErrorMessage] = useState('');

  const getEditorStates = () => {
    const editorStates: Map = {};
    report.questions.forEach(question => {
      editorStates[question.id] = EditorState.createEmpty();
    });
    if (response) {
      response.answers.forEach(answer => {
        editorStates[answer.question_id] = EditorState.createWithContent(
          ContentState.createFromText(answer.content)
        );
      });
    }
    return editorStates;
  };

  const [editorStates, setEditorStates] = useState(getEditorStates());

  const setEditorState = (question_id: number, editorState: EditorState) => {
    setEditorStates(prevState => ({
      ...prevState,
      [question_id]: editorState
    }));
  };

  const onSubmit = (data: any) => {
    let oldAnswers: Answer[] = response ? response.answers : [];
    let newAnswers: Answer[] = [];
    let missingAnswers: boolean = false;
    let reportResponse = response ? response : new ReportResponse();
    let counter = 0;
    report?.questions.forEach(question => {
      // parse the answer
      const parsedAnswer = parseEditorState(editorStates[question.id]);
      if (!question.optional && !parsedAnswer) {
        missingAnswers = true;
        setErrorMessage('You must answer all the required questions!');
      }
      // get the answer
      if (!oldAnswers[counter] && parsedAnswer) {
        let userAnswer: Answer = new Answer();
        userAnswer.question_id = Number(question.id);
        userAnswer.content = parsedAnswer;
        newAnswers.push(userAnswer);
      } else if (oldAnswers[counter]) {
        let userAnswer: Answer = oldAnswers[counter];
        userAnswer.content = parsedAnswer;
        newAnswers.push(userAnswer);
      }
      if (missingAnswers) {
        newAnswers = oldAnswers;
      }
      counter++;
    });
    if (!missingAnswers) {
      reportResponse.answers = newAnswers;
      const request: submitReportRequest = {
        reportId: report!.id,
        reportType: report!.type,
        response: reportResponse,
        id: reportResponse ? (reportResponse.id as any as string) : ''
      };
      response
        ? updateResponse.mutate(request)
        : submitReportMutation.mutate(request);
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (response) {
      history.replace({
        pathname: `/app/reports/${report?.id}`
      });
    } else {
      setTargetReportId(-1);
      const currentLocation = window.location.href;
      if (/#*\/submit/.test(currentLocation)) {
        history.replace({
          pathname: `/app/reports/${report?.id}`
        });
      }
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <div className={classes.dialogTitleWrapper}>
        <Typography className={classes.dialogTitle}>
          {response ? 'Edit Your Response' : 'Submit your report status'}
        </Typography>
        <CloseIcon className={classes.closeButton} onClick={handleClose} />
      </div>
      <DialogContent className={classes.reportDialogContent}>
        {report?.questions.map((question, index) => (
          <Grid item key={question.id}>
            <div className={classes.optionFieldLabelWrapper}>
              <FieldLabel
                required={!question.optional}
                label={`Q ${index + 1}: ${question.content}`}
                className={classes.fieldLabel}
              />
              {question.optional && (
                <span className={classes.optionWordLabel}>(optional)</span>
              )}
            </div>

            <SubmissionEditor
              question_id={question.id}
              members={members}
              setEditorState={setEditorState}
              editorState={editorStates[question.id]}
              editMode={false}
            />
          </Grid>
        ))}
        <div>
          {errorMessage && (
            <Typography className={classes.errorMessage}>
              {' '}
              {errorMessage}{' '}
            </Typography>
          )}
        </div>
        <LastMemberResponse report={report} />
        <Box className={classes.formActions}>
          <Button className={classes.cancelBtn} onClick={handleClose}>
            <Typography className={classes.cancelBtnText}>Cancel</Typography>
          </Button>

          <Button
            className={classes.createBtn}
            onClick={handleSubmit(onSubmit)}
          >
            <Typography className={classes.createBtnText}>
              {response ? 'Update' : 'Submit'}
            </Typography>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
