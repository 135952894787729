import { makeStyles, createStyles } from '@material-ui/core/styles';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

export const styles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: 12
    },
    filter: {
      padding: 0
    },
    headerTxt: {
      fontSize: '16px',
      color: '#777f8c',
      letterSpacing: 'normal'
    },
    reportsWord: {
      color: '#777f8c',
      fontFamily: 'Lato',
      margin: '0 4px 0 0',
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline'
      }
    },
    reportName: {
      color: '#354052',
      margin: '0 0 0 4px',
      fontFamily: 'Lato-SemiBold'
    },
    dateFilterBtn: {
      height: 32,
      borderRadius: 6,
      padding: '4px 16px',
      backgroundColor: '#fff',
      border: 'solid 1px rgba(224, 224, 224, 0.49)',
      boxShadow: '0 2px 5px 0 rgba(219, 219, 219, 0.5)'
    },
    dateFilterBtnLabel: {
      height: 27,
      fontSize: 12,
      color: '#76797f',
      fontFamily: 'Lato',
      fontWeight: 'bold',
      textTransform: 'none',
      letterSpacing: 'normal',
      '@media (max-width: 320px)': {
        fontSize: 10
      }
    },
    datePickerBox: {
      overflow: 'overlay',
      '@supports ( -moz-appearance:none )': {
        overflow: 'auto'
      },
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      },
      '&::-ms-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-ms-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-ms-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      },
      '&::-moz-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-moz-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-moz-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      }
    }
  })
);
