import {Action} from './Actions'
import * as types from './ActionTypes';
import {Member} from '../../models/Member.model';
import {Channel} from "../../models/Channel.model";
import {Workspace} from "../../models/Workspace.model";
import {TimeZone} from "../../models/TimeZone.model";
import { Subscription } from '../../models/Subscription.model';

export interface Settings {
	membersList: Member[];
	channelsList: Channel[];
	workspacesList: Workspace[];
	timezonesList: TimeZone[];
	subscription: Subscription;
}

const settings = (state: Settings = {
	membersList: [],
	channelsList: [],
	workspacesList: [],
	timezonesList: [],
	subscription: new Subscription(),
}, action: Action): Settings => {
	switch (action.type) {
		case types.FETCH_MEMBERS_SUCCEEDED:
			return {...state, membersList: action.members};
		case types.UPDATE_MEMBERS_SUCCEEDED:
			const updatedMembers = state.membersList.map(member => {
				const role = action.members.find(({id}) => member.id === id)?.role || member.role;
				return new Member(member.id, member.provided_id,member.name, role, member.avatar, member.email);
			})
			return {...state, membersList: updatedMembers};
		case types.FETCH_workspaces_SUCCEEDED:
			return {...state, workspacesList: action.workspaces};
		case types.FETCH_CHANNELS_SUCCEEDED:
			return {...state, channelsList: action.channels};
		case types.FETCH_TIMEZONES_SUCCEEDED:
			return {...state, timezonesList: action.timezones};
		case types.FETCH_SUBSCRIPTION_SUCCEEDED:
			return {...state, subscription: action.subscription};
		case types.CHANGE_SUBSCRIPTION__STATUS_SUCCEEDED:
			return {...state, subscription: action.subscription}
		default:
			return state;
	}

}
export default settings;
