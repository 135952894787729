import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Workspace, WorkspaceURLParams } from '../../../models/Workspace.model';
import { useDispatch } from 'react-redux';
import { addConfirmation } from '../../../store/confirmations/Actions';
import { Confirmation } from '../../../models/Confirmation.model';
import { QUERIES } from '../../../utils/queris';
import { ApiError } from '../../../models/ApiError.model';
import { addError } from '../../../store/errors/Actions';
import { Member } from '../../../models/Member.model';
import { Report } from '../../../models/Report.model';

export const useDeleteWorkspace = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((workspaceId: number) => Workspace.delete(workspaceId), {
    onSuccess: async (data, variables) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(`Workspace deleted successfully`)
        )
      );
      queryClient.invalidateQueries(QUERIES.LOAD_WORKSPACES);
    },
    onError: error => {
      dispatch(
        addError(ApiError.getError('', 'Failed to delete the workspace'))
      );
    }
  });
};

export const useEditWorkspace = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((workspace: Workspace) => Workspace.update(workspace), {
    onSuccess: async (data, variables) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(`Workspace updated successfully`)
        )
      );
      queryClient.invalidateQueries(QUERIES.LOAD_WORKSPACES);
    },
    onError: error => {
      dispatch(
        addError(ApiError.getError('', 'Failed to update the workspace'))
      );
    }
  });
};

export const useCreateWorkspace = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((workspace: Workspace) => Workspace.create(workspace), {
    onSuccess: async (data, variables) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(`Workspace created successfully`)
        )
      );
      queryClient.invalidateQueries(QUERIES.LOAD_WORKSPACES);
    },
    onError: (error: any) => {
      if (error.response.data.name){
        dispatch(
          addError(ApiError.getError('', error.response.data.name[0]))
        );
      }else{
        dispatch(
          addError(ApiError.getError('', 'Failed to create the workspace'))
        );
      }
    }
  });
};



export const useWorkspaceDetails = (workspaceId: number) => {
  return useQuery<Workspace>([QUERIES.LOAD_WORKSPACE, workspaceId], () =>
    Workspace.getWorkspace(workspaceId)
  );
};

export const useIncludedReports = (workspaceId: number , page=1) => {
  return useQuery<{ reports: Report[] }>(
    [QUERIES.LOAD_WORKSPACE_REPORTS, workspaceId , page],
    () => Workspace.getReports(workspaceId,page),
    { keepPreviousData : true }
  );
};

export const useWorkspaces = (page:number) => {
  const queryClient = useQueryClient();
  const params: WorkspaceURLParams = {page: page, count: 10};
  return useQuery<{workspaces: Workspace[] ; pageCount: number }>([QUERIES.LOAD_WORKSPACES , page],
    () => Workspace.getAll(params),
    { keepPreviousData : true }
  );
};

export const useMembers = () => {
  return useQuery<Member[]>(QUERIES.LOAD_MEMBERS, () => Member.getAll());
};
