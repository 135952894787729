import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERIES } from '../../../utils/queris';
import { Ticket } from '../../../models/Tickets';
import { Board } from '../../../models/Boards';
import { ReleaseNote } from '../../../models/ReleaseNotes';


export const useReleaseNotes = () => {
  return useQuery<ReleaseNote[]>(QUERIES.LOAD_RELEASE_NOTES, () => ReleaseNote.getAll());
};

export const useBoards = () => {
  return useQuery<Board[]>(QUERIES.LOAD_BOARDS, () => Board.getAll());
};
