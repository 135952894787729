import { createStyles, makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    selectField: {
      width: '162px',
      height: '32px',
      borderRadius: '6px',
      fontSize: '16px',
      color: '#76797f',
      backgroundColor: '#fff',
      border: '1px solid #c3c3c3',
      transition: 'all 0.3s ease-in-out',
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
      },
      '& svg': {
        transition: 'all 0.3s ease-in-out'
      },
      '&:hover,&.Mui-focused': {
        boxShadow: '2px 0 24px 0 rgba(103, 101, 101, 0.14)',
        border: '0.5px solid #3a78f2'
      },
      '& .MuiInputBase-input': {
        padding: '7px 8px 7px 16px',
        fontSize: '14px',
        color: '#354052',
        textAlign: 'left'
      },
      '& .MuiAutocomplete-endAdornment': {
        '& .MuiButtonBase-root': {
          transition: 'all .3s ease-in-out'
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    },
    dropdownStyle: {
      width: '162px',
      borderRadius: '6px',
      '& .MuiList-root': {
        '& option': {
          cursor: 'pointer',
          padding: '7px 0 10px 0',
          margin: '0 16px',
          fontFamily: 'Lato',
          fontSize: '16px',
          lineHeight: 'normal',
          letterSpacing: '0.13px',
          color: '#a3a9b4',
          borderBottom: '1px solid #e7e9ea',
          '&:focus': {
            outline: 'none'
          },
          '&:last-of-type': { borderBottom: 'none' },
          '&[aria-selected~="true"]': {
            color: '#3a78f2'
          }
        }
      },
      left: 'unset !important',
      right: '24px'
    }
  })
);
