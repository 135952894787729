import React, { useEffect, useState } from 'react';
import { EmptyStateHandler } from '../../../shared-components/EmptyStates/EmptyStateHandler';
import { EmptySearchResult } from '../../../shared-components/EmptyStates/EmptySearchResult';
import { Autocomplete } from '@material-ui/lab';
import { usersStyles } from './styles';
import { USER_ROLES } from '../../../../utils/constants';
import { useMembers } from '../../Reports/ReportForm/reportFormHooks';
import { Member } from '../../../../models/Member.model';
import {
  CardContent,
  withStyles,
  TextField,
  Divider,
  Card
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import UserCard from './UserCard';

interface Props {
  classes?: any;
}

const Users = ({ classes }: Props) => {
  const { isLoading: loadingMembers, data: membersList } = useMembers();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [roleFilter, setRoleFilter] = useState<string>('');
  const [filteredMembers, setFilteredMembers] = useState<Member[]>(
    membersList || []
  );

  useEffect(() => {
    setFilteredMembers(
      membersList?.filter(
        ({ name, role }) =>
          name.match(new RegExp(searchTerm, 'i')) &&
          (!roleFilter || role === roleFilter.toLowerCase())
      ) || []
    );
  }, [membersList, roleFilter, searchTerm]);

  return (
    <>
      <Card className={classes.card}>
        <CardContent classes={{ root: classes.cardContent }}>
          <div className={classes.userFilters}>
            <TextField
              id="searchTerm"
              placeholder="Search for team member"
              value={searchTerm}
              onChange={(e: any) => {
                setSearchTerm(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      classes={{
                        root: classes.searchIcon
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <ClearIcon
                      classes={{
                        root: classes.clearIcon
                      }}
                      fontSize="small"
                      onClick={() => {
                        setSearchTerm('');
                      }}
                    />
                  </InputAdornment>
                ),
                classes: {
                  input: classes.textInput,
                  underline: classes.underlineTextField
                }
              }}
              classes={{
                root: classes.searchTextField
              }}
            />
            <Autocomplete
              id="role-filter"
              popupIcon={<ExpandMoreIcon />}
              options={Object.values(USER_ROLES)}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Role"
                  label="Role Filter"
                  className={classes.userRoleFilterInput}
                />
              )}
              onChange={(event: object, value: any) => {
                setRoleFilter(value);
              }}
              classes={{
                root: classes.userRoleFilter,
                paper: classes.paper
              }}
            />
          </div>

          <div className={classes.membersList}>
            <EmptyStateHandler
              data={filteredMembers}
              loading={loadingMembers || !membersList}
              emptyComponent={<EmptySearchResult />}
            >
              {filteredMembers.map(mem => {
                return (
                  <div key={mem.id} 
                    id={`user-${mem.id}`} 
                    className={classes.memberCard}>
                    <UserCard key={mem.id} member={mem} />
                    <Divider />
                  </div>
                );
              })}
            </EmptyStateHandler>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default withStyles(usersStyles)(Users);
