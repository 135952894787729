import { Grid } from '@material-ui/core';
import React, { memo } from 'react';
import { Report } from '../../../../models/Report.model';
import { styles } from './styles';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import classNames from 'classnames';
import { usePinReport, useUnpinReport } from './ReportCardHooks';

interface Props {
  report: Report;
  updateCashedReport: (updatedReport: Report) => void;
}
const ReportCardHeader = ({ report, updateCashedReport }: Props) => {
  const classes = styles();

  const pinReport = usePinReport(report, updateCashedReport);
  const unpinReport = useUnpinReport(report, updateCashedReport);

  return (
    <Grid item>
      <span className={classes.reportNameTxt}>{report.name}</span>
      <span
        className={
          report.active
            ? classes.reportStatus
            : classNames(classes.reportStatus, classes.inactiveReport)
        }
      >
        {report.active ? 'Active' : 'Inactive'}
      </span>
      {report.pinned ? (
        <StarIcon
          className={classes.pinnedReportIcon}
          onClick={e => {
            e.stopPropagation();
            unpinReport.mutate(report.id);
          }}
        />
      ) : (
        <StarBorderIcon
          className={classes.unpinnedReportIcon}
          onClick={e => {
            e.stopPropagation();
            pinReport.mutate(report.id);
          }}
        />
      )}
    </Grid>
  );
};

export default memo(ReportCardHeader);
