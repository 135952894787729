import { Grid, IconButton, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { styles } from '../styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import classNames from 'classnames';

interface Props {
  createdDate: string;
  submittedResponsesCount: number;
  totalResponsesCount: number;
  closed: boolean;
  collapse: boolean;
  setOpenId: Function;
  submissionCycleId: number;
}

const SubmissionCycleHeader = ({
  createdDate,
  submittedResponsesCount,
  totalResponsesCount,
  closed,
  collapse,
  setOpenId,
  submissionCycleId
}: Props) => {
  const classes = styles();

  const day = useMemo(() => dayjs(createdDate).format('DD'), [createdDate]);

  const month = useMemo(() => dayjs(createdDate).format('MMM'), [createdDate]);

  const handleCollapse = () => {
    setOpenId(collapse ? submissionCycleId : null);
  };

  return (
    <Grid
      container
      item
      direction="row"
      alignItems="center"
      className={classes.submissionCycleHeader}
    >
      <Grid item>
        <div className={classes.rectDate}>
          <Typography className={classes.rectDateNumber}>{day}</Typography>
          <Typography className={classes.rectDateMon}>{month}</Typography>
        </div>
      </Grid>

      <Grid item>
        <Typography className={classes.responsesStatus}>
          <span className={classes.responsesNumbers}>
            {`${submittedResponsesCount} `}
          </span>
          out of
          <span
            className={classes.responsesNumbers}
          >{` ${totalResponsesCount} `}</span>
          responses
        </Typography>
      </Grid>

      <Grid item>
        <Typography>
          <span className={classes.separationPoint}></span>
          <span className={classes.submissionWord}>Submission</span>
          <span
            className={classNames(
              classes.submissionStatus,
              closed
                ? classes.submissionStatusClosed
                : classes.submissionStatusOpen
            )}
          >
            {closed ? 'Closed' : 'Open'}
          </span>
        </Typography>
      </Grid>

      <Grid container item xs justify="flex-end" alignItems="center">
        <IconButton onClick={handleCollapse} aria-label="expand">
          {collapse ? (
            <ExpandMoreIcon fontSize="large" className={classes.expandCard} />
          ) : (
            <ExpandLessIcon fontSize="large" className={classes.expandCard} />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default React.memo(SubmissionCycleHeader);
