import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles({
  card: {
    display: 'flex',
    width:'100%',
    '&:last-child': {
      paddingBottom: 0
    }
  },
  icon: {
    width: '50px',
    height: '50px',
    marginTop: '11px',
    marginRight:'16px'
  },
  countClass: {
    opacity: 0.9,
    lineHeight: 0.83,
    fontSize: '24px',
    height: '20px',
    margin: '0 67px 10px 0',
    color: '#383d45',
    whiteSpace: 'nowrap',
    fontWeight: 800
  },
  reportNameClass: {
    fontWeight: 500,
    color: '#474f58',
    fontSize: '16px',
    whiteSpace: 'nowrap',
  },
});
