import { Button } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../App';
import { Report } from '../../../../models/Report.model';
import { ReportResponse } from '../../../../models/ReportResponse.model';
import { User } from '../../../../models/User.model';
import ResponseContent from '../SubmissionCycles/SubmissionCycleCard/Responses/ResponseCard/ResponseContent';
import { lastReponseSectionStyles } from './styles';
import { useLastMemberResponse } from './SubmissionHooks';

interface Props {
  report: Report;
}
export const LastMemberResponse = ({ report }: Props) => {
  const classes = lastReponseSectionStyles();
  const user: User = useContext(AppContext);
  const [viewLastResponse, setViewLastResponse] = useState(false);
  const { data: LastMemberResponse } = useLastMemberResponse(
    report.id,
    user?.id as number
  );

  return LastMemberResponse?.responses[0] ? (
    <div className={classes.lastResponseSection}>
      <Button
        color="primary"
        onClick={() => setViewLastResponse(!viewLastResponse)}
        className={classes.viewLastResponseBtn}
      >
        {viewLastResponse ? 'Hide Last Response' : 'View Last Response'}
      </Button>
      {viewLastResponse && (
        <ResponseContent
          questions={report.questions}
          response={LastMemberResponse?.responses[0] as ReportResponse}
        />
      )}
    </div>
  ) : null;
};
