import * as React from 'react';
import { PinnedReports } from './PinnedReports';
import { styles } from './styles';
import ActivitySection from './ActivitySection';
import PendingReports from './PendingReports';

export default function ResponsesGrid() {
  const classes = styles();
  return (
    <div className={classes.root}>
      <PendingReports />
      <PinnedReports />
      <ActivitySection />
    </div>
  );
}
