import React from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider
} from '@material-ui/core';
import Image from 'material-ui-image'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReleaseNote } from '../../../../models/ReleaseNotes';
import { releaseNotesStyles } from './releaseNotesStyles';

interface Props {
  releaseNotes: ReleaseNote[];
}

export const ReleaseNotes = ({releaseNotes}: Props) => {
  const classes = releaseNotesStyles();

  const options = {
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "numeric"
  };

  const formatDate = (date: string) => {
    return new Date(Date.parse(date)).toLocaleDateString("en", options)
  }

  return (
    <>
      <Grid item xs = {12} >
        <Grid container justify="center" spacing={2}>
            {
              releaseNotes && releaseNotes.map((releaseNote: ReleaseNote) => {
                return (
                  <Grid
                    key={releaseNote.id}
                    item
                    container
                    xs={7}
                    justify="center"
                  >
                    <Grid item xs= {10}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h5">
                            { releaseNote.title }
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs= {12}>
                            {
                              releaseNote.release_date && <Typography variant="body1" gutterBottom>
                                { formatDate(releaseNote.release_date) }
                              </Typography>
                            }
                            {
                              releaseNote.image_url && <Image
                                src={releaseNote.image_url}
                                aspectRatio={(16/7)}
                                className={classes.headImage}
                              />
                            }
                            {
                              releaseNote.description && <Typography
                                variant="body2"
                                className={classes.bottomText}
                                gutterBottom
                              >
                                { releaseNote.description }
                              </Typography>
                            }

                            {
                              releaseNote.tickets.length > 0 &&
                              releaseNote.tickets.map(ticket => {
                                  return (
                                    <div key={ticket.id}>
                                      <Divider
                                        variant="middle" 
                                        className={classes.horizontalDivider}
                                      />
                                      {
                                        ticket.title && <Typography
                                          variant="body1"
                                          gutterBottom
                                        >
                                          { ticket.title }
                                        </Typography>
                                      }
                                      {
                                        ticket.short_description && <Typography
                                          variant="body2"
                                          className={classes.bottomText}
                                          gutterBottom
                                        >
                                          { ticket.short_description }
                                        </Typography>
                                      }
                                      {
                                        ticket.image_url && <Image
                                          src={ticket.image_url}
                                          className={classes.headImage}
                                          aspectRatio={(16/7)}
                                        />
                                      }
                                      {
                                        ticket.description && <Typography
                                          variant="body2"
                                          className={classes.bottomText}
                                          gutterBottom
                                        >
                                          { ticket.description }
                                        </Typography>
                                      }
                                    </div>
                                  )
                                })
                            }
                          </Grid>

                        </AccordionDetails>
                      </Accordion>
                      </Grid>
                    </Grid>
                )
              })
            }
        </Grid>
      </Grid>
    </>
  );
};

