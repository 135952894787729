import { createStyles, makeStyles } from '@material-ui/core';
const styles = makeStyles(() =>
  createStyles({
    featuresBG: {
      backgroundColor: '#f2faff'
    },
    featuresWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      minHeight: '820px',
      padding: '57px 0',
      maxWidth: '1440px',
      margin: '0 auto',
      '@media only screen and (max-width: 1000px)': {
        gridTemplateColumns: '1fr'
      }
    },
    featuresLeftSide: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingRight: '50px',
      '@media only screen and (max-width: 1040px)': {
        paddingRight: '25px !important'
      }
    },
    slideRight: {
      height: '100%',
      paddingLeft: '100px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '@media only screen and (max-width: 1040px)': {
        paddingLeft: '50px !important'
      },
      '@media only screen and (max-width: 1000px)': {
        paddingLeft: '25px !important'
      }
    },
    featuresTitle: {
      whiteSpace: 'pre-line',
      fontSize: '48px',
      color: '#55647d',
      fontWeight: 'bold',
      lineWeight: '56px',
      marginBottom: '40px'
    },
    featuresDescription: {
      color: '#55647d',
      fontSize: '18px',
      lineHeight: '27px',
      '&:firstOfType': {
        marginBottom: '40px'
      }
    },
    featuresRightSide: {
      paddingLeft: '50px',
      '@media only screen and (max-width: 1040px)': {
        paddingLeft: '25px !important'
      }
    },
    slideLeft: {
      height: '100%',
      paddingRight: '100px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '@media only screen and (max-width: 1040px)': {
        paddingRight: '50px !important'
      },
      '@media only screen and (max-width: 1000px)': {
        paddingRight: '25px !important'
      },
      '@media only screen and (max-width: 550px)': {
        flexDirection: 'column !important'
      }
    },
    cardsLeftSide: {
      margin: '0 32px 60px 0',
      minWidth: '270px',
      maxWidth: '40%',
      '@media only screen and (max-width: 1000px)': {
        margin: '0',
        minWidth: 'unset',
        maxWidth: 'unset',
        width: '100%',
        marginRight: '10px'
      },
      '@media only screen and (max-width: 550px)': {
        marginRight: '0 !important'
      }
    },
    cardsRightSide: {
      margin: '60px 0 0 0',
      minWidth: '270px',
      maxWidth: '40%',
      '@media only screen and (max-width: 1000px)': {
        margin: '0',
        minWidth: 'unset',
        maxWidth: 'unset',
        width: '100%',
        marginLeft: '10px'
      },
      '@media only screen and (max-width: 550px)': {
        marginLeft: '0 !important'
      }
    },
    featuresCard: {
      minHeight: '309px',
      borderRadius: '10px',
      backgroundColor: '#fff',
      padding: '0 24px',
      boxShadow: '0 10px 18px 0 rgba(83, 83, 83, 0.12)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '16px 0',
      animationName: `$scale3dIn`,
      animationDuration: '0.5s',
      animationFillMode: 'both',
      animationTimingFunction: 'ease-in-out',
      '&:hover': {
        animationName: `$scale3dOut`,
        animationDuration: '0.5s',
        animationFillMode: 'both',
        animationTimingFunction: 'ease-in-out'
      },
      '@media only screen and (max-width: 1000px)': {
        margin: '0 0 10px 0 !important'
      }
    },
    featuresCardIcon: {
      width: '50px',
      height: '50px'
    },
    featuresCardTitle: {
      margin: '24px 0 16px',
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#55647d'
    },
    featuresCardDescription: {
      opacity: '0.5',
      fontSize: '14px',
      lineHeight: '1.71',
      color: '#55647d',
      margin: '0'
    },
    '@keyframes scale3dOut': {
      '0%': {
        transform: 'scale3d(1, 1, 1)'
      },
      '100%': {
        transform: 'scale3d(1.05, 1.05, 1.05)'
      }
    },
    '@keyframes scale3dIn': {
      '0%': {
        transform: 'scale3d(1.05, 1.05, 1.05)'
      },
      '100%': {
        transform: 'scale3d(1, 1, 1)'
      }
    }
  })
);
export default styles;
