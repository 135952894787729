import { makeStyles, createStyles } from '@material-ui/core/styles';

export const workspaceCardStyles = makeStyles(theme =>
  createStyles({
    cardWrapper: {
      cursor: 'pointer',
      width: '100%',
      height: '123px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px',
      borderRadius: '10px',
      boxShadow: '0 2px 2px 0 rgba(37, 37, 37, 0.03)',
      border: 'solid 1px #ededed',
      backgroundColor: '#fff'
    },
    cardLeftSide: {
      display: 'flex',
      flexDirection: 'column'
    },
    workspaceName: {
      fontFamily: '"lato", Lato-SemiBold',
      fontSize: '28px',
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#354052',
      margin: '0 0 16px 0'
    },
    cardLeftLower: {
      display: 'flex',
      alignItems: 'flex-end'
    },
    numberInfo: {
      fontFamily: 'Nunito-Regular',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#7d838d',
      margin: 0
    },
    totalNumber: { fontFamily: 'Nunito-Bold' },
    dot: {
      width: '5px',
      height: '5px',
      borderRadius: '50%',
      opacity: 0.5,
      backgroundColor: '#7d838d',
      margin: '0 15px 6px 15px'
    },
    cardRightSide: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    viewDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center'
    },
    infoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: 5
    },
    workspaceInfo: {
      fontFamily: 'Lato',
      fontSize: '18px',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#7d838d',
      marginTop: 2
    },
    viewDetailsBtn: {
      fontFamily: 'Lato',
      fontSize: '20px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#3a78f2',
      margin: '16px 0 0 0',
      whiteSpace: 'nowrap',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    leftInfo: {
      display: 'flex',
      flexDirection: 'column'
    },
    rightInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    emptyDiv: {
      width: '26px',
      margin: '0 0 0 30px'
    }
  })
);
