import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { AppContext } from '../../../../../../App';
import ClapIcon from '../../../../../../assets/images/clap_icon.svg';
import { User } from '../../../../../../models/User.model';
import CircularLoader from '../../../../../shared-components/CircularLoader';
import {
  useLikedMembers,
  useLikeResponse,
  useUnLikeResponse
} from './ResponseAttachmentsHooks';
import { attachmentsStyles } from './styles';

interface Iprops {
  responseId: number;
}

export const LikeButton = ({ responseId }: Iprops) => {
  const classes = attachmentsStyles();
  const currentUser: User = useContext(AppContext);
  const likeMutation = useLikeResponse();
  const unlikeMutation = useUnLikeResponse();
  const { data: LikedMembers, isLoading } = useLikedMembers(responseId);

  const like = () => {
    likeMutation.mutate({
      memberId: currentUser?.id!,
      responseId: responseId
    });
  };

  const unlike = () => {
    unlikeMutation.mutate({
      memberId: currentUser?.id!,
      responseId: responseId
    });
  };

  return (
    <CircularLoader isLoading={isLoading}>
      <Grid className={classes.likesWrapper} container>
        <Grid item>
          <img
            src={ClapIcon}
            onClick={LikedMembers?.includes(currentUser?.id!) ? unlike : like}
            className={classes.likeButton}
          />
        </Grid>

        <Grid item className={classes.likesCounter}>
          <span>{LikedMembers?.length} Claps</span>
        </Grid>
      </Grid>
    </CircularLoader>
  );
};
