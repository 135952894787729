import React from 'react' ;
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { styles } from '../styles';
import classNames from 'classnames';

interface IProps {
    handleClose: any ;
    confirmation: any ;
};

export const PopupConfirmationMessage = ({handleClose , confirmation}:IProps)=>{
    const classes = styles();
    return (
        <Snackbar
        key={confirmation.id}
        open={true}
        autoHideDuration={5000}
        onClose={() => handleClose(confirmation)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className={classNames(
          classes.messageItem,
          classes.confirmationRoot
        )}
      >
        <MuiAlert
          onClose={() => handleClose(confirmation)}
          severity="success"
          classes={{
            filledError: classes.messageRoot,
            message: classes.messageTxt,
            action: classes.closeMessage
          }}
          elevation={6} 
          variant="filled" 
        >
          {confirmation.presenterMsg}
        </MuiAlert>
      </Snackbar>
    )
}



