import React, { useMemo } from 'react';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { notificationStyles } from './styles';
import {
  useNotifications,
  useReadAllNotifications
} from './notificationsHooks';
import {
  Notification,
  NotificationsResponse
} from '../../../../models/Notification.model';
import {
  IconButton,
  withStyles,
  Popover,
  Button,
  Badge
} from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import emptyNotificationImg from '../../../../assets/images/emptyNotification.svg';
import NotificationElement from './NotificationElement';
import { InfiniteData } from 'react-query/types/core';

interface Props {
  classes?: any;
}

const Notifications = ({ classes }: Props) => {
  const readAllNotifications = useReadAllNotifications();
  const {
    hasNextPage,
    data: notificationResponse,
    isFetchingNextPage,
    fetchNextPage
  } = useNotifications();

  function getNotifications(
    notificationResponse: InfiniteData<NotificationsResponse> | undefined
  ) {
    const allNotifications2D = notificationResponse?.pages?.map(
      page => page?.notifications
    );

    const allNotifications =
      // @ts-ignore
      allNotifications2D && [].concat(...allNotifications2D);

    if (allNotifications) return [...(allNotifications as Notification[])];

    return allNotifications2D;
  }

  const notifications = useMemo(() => {
    return getNotifications(notificationResponse);
  }, [notificationResponse]) as Array<Notification>;

  const handleMarkAllNotificationsAsRead = (e: any) => {
    readAllNotifications.mutate();
  };

  return (
    <PopupState variant="popover">
      {popupState => (
        <>
          <IconButton
            className={classes.notificationIcon}
            {...bindTrigger(popupState)}
          >
            <Badge
              badgeContent={
                notificationResponse?.pages[0]?.unread_count as number
              }
              max={9}
              overlap="circle"
              classes={{
                badge: classes.notificationBadge
              }}
            >
              <NotificationsActiveIcon />
            </Badge>
          </IconButton>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            classes={{ paper: classes.arrowUp }}
          >
            <div className={classes.notificationTitleWrapper}>
              <h3 className={classes.notificationTitle}>Notifications</h3>
              {notificationResponse && notificationResponse.pages[0].unread_count != 0 && (
                <div className={classes.markAllAsRead}>
                  <Button
                    style={{ textTransform: 'none' }}
                    color="primary"
                    onClick={handleMarkAllNotificationsAsRead}
                  >
                    Mark all as read
                  </Button>
                </div>
              )}
            </div>
            <div className={classes.scrollableNotifications}>
              {notifications?.map((notification: Notification) => (
                <div key={notification.id}>
                  <div>
                    <NotificationElement
                      key={notification.id}
                      notification={notification}
                    />
                  </div>
                </div>
              ))}
              {hasNextPage && (
                <div className={classes.seeMoreWrapper}>
                  <Button color="primary" onClick={() => fetchNextPage()}>
                    See More +
                  </Button>
                </div>
              )}
            </div>

            {!notificationResponse?.pages[0]?.notifications.length && (
              <div className={classes.noNotificationsWrapper}>
                <img src={emptyNotificationImg} alt="" />
                <p className={classes.noNotificationsHead}>
                  No Notifications yet
                </p>
                <p className={classes.noNotificationsContent}>
                  {`When your team start working on things,
                    you will get notification here.`}
                </p>
              </div>
            )}
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default withStyles(notificationStyles)(React.memo(Notifications));
