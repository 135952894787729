import shortid from 'shortid';

export class ApiError {
  public id: string = '';
  public systemMsg: string = '';
  public presenterMsg: string = '';

  constructor(id: string, systemMsg: string, presenterMsg: string) {
    this.id = id;
    this.systemMsg = systemMsg;
    this.presenterMsg = presenterMsg;
  }

  public static getError(error: string, errorMessage: string): ApiError {
    return new ApiError(shortid.generate(), error, errorMessage);
  }
}
