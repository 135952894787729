import React from 'react';
import styles from './styles';

interface Props {
  itemNumber: string;
  itemTitle: string;
  itemDescription: string;
}

function CarouselItem(props: Props) {
  const { itemNumber, itemTitle, itemDescription } = props;
  const classes = styles();
  return (
    <div>
      <div className={classes.carouselItemTitle}>
        <div className={classes.carouselItemTitleCount}>
          <span>{itemNumber}</span>
        </div>
        <h5 className={classes.carouselItemName}>{itemTitle}</h5>
      </div>
      <p className={classes.carouselItemContent}>{itemDescription}</p>
    </div>
  );
}

export default CarouselItem;
