import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { styles } from './styles';
import { DateRangePicker } from 'react-date-range';
import { subMonths } from 'date-fns';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import DateRangeIcon from '@material-ui/icons/DateRange';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Link as RLink } from 'react-router-dom';

dayjs.extend(advancedFormat);

export interface ReportResponseHeaderProps {
  reportName: String;
  handleDateRangeFilter: (startDate: string, endDate: string) => void;
}

const Header = ({
  reportName,
  handleDateRangeFilter
}: ReportResponseHeaderProps) => {
  const classes = styles();

  const [dateFilterRange, setDateFilterRange] = useState({
    startDate: subMonths(new Date(), 1),
    endDate: new Date(),
    key: 'selection'
  });

  const handleDateFilter = () => {
    handleDateRangeFilter(
      dayjs(dateFilterRange.startDate).format('DD-MM-YYYY'),
      dayjs(dateFilterRange.endDate).format('DD-MM-YYYY')
    );
  };

  return (
    <Grid
      container
      spacing={1}
      className={classes.root}
      justify="space-between"
      alignItems="center"
    >
      <Grid item>
        <Typography className={classes.headerTxt}>
          <RLink to={'/app/reports/'} className={classes.reportsWord}>
            <span>Reports</span>
          </RLink>
          /<span className={classes.reportName}>{reportName}</span>
        </Typography>
      </Grid>

      <Grid item className={classes.filter}>
        <PopupState variant="popover">
          {popupState => (
            <>
              <Button
                classes={{
                  root: classes.dateFilterBtn,
                  label: classes.dateFilterBtnLabel
                }}
                {...bindTrigger(popupState)}
                endIcon={<DateRangeIcon />}
              >
                {`${dayjs(dateFilterRange.startDate).format(
                  'ddd, MMM Do YYYY'
                )} - 
                ${dayjs(dateFilterRange.endDate).format('ddd, MMM Do YYYY')}`}
              </Button>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                onExit={handleDateFilter}
              >
                <Box p={1} className={classes.datePickerBox}>
                  <DateRangePicker
                    onChange={(item: any) => setDateFilterRange(item.selection)}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    ranges={[dateFilterRange]}
                  />
                </Box>
              </Popover>
            </>
          )}
        </PopupState>
      </Grid>
    </Grid>
  );
};

export const ReportDetailsHeader = React.memo(Header);
