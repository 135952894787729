import axios from '../services/axios';
import { API } from './API';
import { Member } from './Member.model';

export interface NotificationsResponse {
  page_count: number;
  notifications: Notification[];
  unread_count: number;
}

export class Notification extends API {
  public id: number = -1;
  public notify_type: string = '';
  public title: string = '';
  public created_at: string = '';
  public read_at: string = '';
  public target_type?: string = '';
  public second_target_type?: string = '';
  public user: Member = new Member(-1, '', '', '');
  public actors?: Member[] = [];
  public target?: any = undefined;
  public second_target?: any = undefined;
  public meta_data: any = {};

  public static async getAll(page: number): Promise<NotificationsResponse> {
    const headers = Notification.getConfig();
    if (headers) {
      return new Promise<NotificationsResponse>((resolve, reject) => {
        axios
          .get(`dashboard/notifications?page=${page}`, headers)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
    return Promise.reject('Error while get notifications');
  }

  public static async readNotification(
    notificationId: number
  ): Promise<any> {
    const headers = Notification.getConfig();
    if (headers) {
      return new Promise<any>((resolve, reject) => {
        axios
          .put(
            `dashboard/notifications/${notificationId}`,
            {notification: { read: true }},
            headers
          )
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
    return Promise.reject('Error while reading a notification');
  }

  public static async readAllNotifications(
  ): Promise<any> {
    const headers = Notification.getConfig();
    if (headers) {
      return new Promise<any>((resolve, reject) => {
        axios
          .put(
            `dashboard/notifications`,
            {notification: { read: true }},
            headers
          )
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
    return Promise.reject('Error while reading all notifications');
  }
}
