import * as React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { styles } from './styles';
import PaymentStatus from './PaymentStatus';
import Users from './Users';
import Plan from './Plan';

export default function Settings(props: any) {
  const classes = styles();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    const state = props.location.state;
    state && setValue(state.tab);
  }, [props.location.state]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className={classes.settings}>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
          className={classes.tabsRoot}
          classes={{
            indicator: classes.tabsIndicator
          }}
        >
          <Tab
            label="Team Members"
            {...a11yProps(0)}
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab
            }}
          />
          <Tab
            label="Current Plan"
            {...a11yProps(1)}
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab
            }}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Users />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Plan />
        </TabPanel>
      </Paper>
      <PaymentStatus />
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}
