import shortid from 'shortid';

export class Confirmation {
  public id: string = '';
  public presenterMsg: string = '';

  constructor(id: string, presenterMsg: string) {
    this.id = id;
    this.presenterMsg = presenterMsg;
  }

  public static getSuccessConfirmation(message: string): Confirmation {
    return new Confirmation(shortid.generate(), message);
  }
}
