import React from 'react';
import { Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { styles } from '../styles';
import { Controller, useFormContext } from 'react-hook-form';
import { STATIC_DAYS_REPORT_FORM } from '../../../../../utils/constants';
import TodayIcon from '@material-ui/icons/Today';
import { FormErrorMessage } from '../shared/FormErrorMessage';

interface Props {
  disabled: boolean;
}

export const WeekDays = ({ disabled }: Props) => {
  const { control } = useFormContext();
  const classes = styles();

  return (
    <div className={classes.days}>
      <Typography className={classes.iconRootLabel}>
        <TodayIcon className={classes.icon} />
        <span className={classes.iconLabel}>Days *</span>
      </Typography>
      <Controller
        render={({ onChange, value }) => (
          <ToggleButtonGroup
            value={value}
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newWeekDays: string[]
            ) => {
              onChange(newWeekDays);
            }}
            className={classes.buttonGroup}
          >
            {STATIC_DAYS_REPORT_FORM.map(day => {
              return (
                <ToggleButton
                  disabled={disabled}
                  key={day}
                  value={day}
                  classes={{
                    root: classes.toggleButton,
                    label: classes.toggleButtonLabel,
                    selected: classes.selectedToggleButton
                  }}
                >
                  {day.substring(0, 3)}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        )}
        rules={{ required: !disabled }}
        name="week_days"
        control={control}
      />

      <FormErrorMessage fieldName="week_days" />
    </div>
  );
};
