import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Report } from '../../../../models/Report.model';
import { SubmissionCycle } from '../../../../models/SubmissionCycle.model';
import { SubmissionCycleCard } from './SubmissionCycleCard';
import { styles } from './styles';

interface SubmissionCyclesProps {
  report: Report;
  submissionCycles: SubmissionCycle[];
}

const SubmissionCycles = ({
  report,
  submissionCycles
}: SubmissionCyclesProps) => {
  const classes = styles();
  const [openId, setOpenId] = useState(submissionCycles[0].id);

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      className={classes.submissionCyclesContainer}
    >
      {submissionCycles.map(cycle => (
        <Grid item key={cycle.id}>
          <SubmissionCycleCard
            submissionCycle={cycle}
            report={report}
            openId={openId}
            setOpenId={setOpenId}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default React.memo(SubmissionCycles);
