import React, { useMemo } from 'react';
import { Paper } from '@material-ui/core';
import { Report } from '../../../../../../models/Report.model';
import { ReportResponse } from '../../../../../../models/ReportResponse.model';
import { SubmissionCycle } from '../../../../../../models/SubmissionCycle.model';
import { PollOptionList, PollOptionListModel } from './PollOptionList';
import { pollResponsesStyles } from './pollresponses.styles';
import { Option } from '../../../../../../models/Option.model';
import { EmptySmallIcon } from '../../../../../shared-components/EmptyStates/EmptySmallIcon';

interface Props {
  report: Report;
  reportSubmissionCycle: SubmissionCycle;
}

export const PollResponses = ({ report, reportSubmissionCycle }: Props) => {
  const pollStyles = pollResponsesStyles();

  const groupedPollResponses = useMemo(
    () =>
      groupPollResponses(
        reportSubmissionCycle.responses as ReportResponse[],
        report.options
      ),
    [reportSubmissionCycle]
  );
  return (
    <>
      <Paper elevation={1}>
        <h3 className={pollStyles.pollQuestion}>{report.question}</h3>

        {groupedPollResponses.length > 0 &&
          groupedPollResponses.map(pollResponsesList => (
            <PollOptionList
              pollOptionResponses={pollResponsesList}
              key={pollResponsesList.optionId}
            />
          ))}

        {groupedPollResponses.length === 0 && (
          <EmptySmallIcon page="pollResponses" />
        )}
      </Paper>
    </>
  );
};

const groupPollResponses = (
  responses: ReportResponse[],
  options: Option[]
): PollOptionListModel[] => {
  const groupedResponses: PollOptionListModel[] = [];

  let bestVoteCount = 0,
    bestVoteIndex = -1;

  // loop on options and filter their responses
  options.forEach((option, index) => {
    const responsesOfCurrentOption = responses.filter(response => {
      return response.poll_vote.id === option.id;
    });

    if (responsesOfCurrentOption.length > bestVoteCount) {
      bestVoteCount = responsesOfCurrentOption.length;
      bestVoteIndex = index;
    }

    groupedResponses.push({
      optionId: option.id,
      optionName: option.content,
      members: responsesOfCurrentOption.map(el => el.member),
      totalCount: responses.length,
      isMaxNumber: false
    } as PollOptionListModel);
  });

  if (bestVoteIndex > -1) groupedResponses[bestVoteIndex].isMaxNumber = true;

  return groupedResponses;
};
