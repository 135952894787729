import React, { useContext } from 'react';
import { useInfiniteQuery } from 'react-query';
import { EmptySmallIcon } from '../../../shared-components/EmptyStates/EmptySmallIcon';
import { List, Button } from '@material-ui/core';
import { Activity } from './Activity';
import { QUERIES } from '../../../../utils/queris';
import { styles } from './styles';
import {
  Activity as ActivityModel,
  ActivityFilters,
  ActivityScroll
} from '../../../../models/Activity.model';
import CircularLoader from '../../../shared-components/CircularLoader';
import { CircularProgress } from '@material-ui/core';
import { AppContext } from '../../../../App';
import { User } from '../../../../models/User.model';

interface activityListProps {
  selectFavorite: boolean;
}
const ActivityList = ({ selectFavorite }: activityListProps) => {
  const classes = styles();
  const currentUser: User = useContext(AppContext);

  const {
    isLoading,
    hasNextPage,
    data: activityList,
    isFetchingNextPage,
    fetchNextPage
  } = useInfiniteQuery<ActivityScroll>(
    [QUERIES.LOAD_ACTIVITIES, selectFavorite, currentUser?.id],
    ({ pageParam }) =>
      ActivityModel.getRecent(pageParam, {
        selectFavorite: selectFavorite
      } as ActivityFilters),
    {
      getNextPageParam: (_lastPage, _allPages) => {
        const allCount = _allPages.reduce(
          (acc, _currentPage) => acc + _currentPage.activities.length,
          0
        );
        if (_lastPage.count <= allCount) return false;
        return _allPages.length + 1;
      }
    }
  );

  return (
    <CircularLoader isLoading={isLoading}>
      <List className={classes.activityList}>
        {activityList &&
        activityList.pages &&
        activityList.pages[0]?.count === 0 ? (
          <EmptySmallIcon />
        ) : (
          <div className={classes.cardsWrapper}>
            <div className={classes.container}>
              {activityList &&
                activityList.pages &&
                activityList.pages.map((page, index) => (
                  <React.Fragment key={index}>
                    {page.activities.map((activity: ActivityModel) => (
                      <Activity
                        activity={activity}
                        key={activity.response.id}
                        defaultExpand={false}
                      />
                    ))}
                  </React.Fragment>
                ))}
              {hasNextPage ? (
                !isFetchingNextPage ? (
                  <div className={classes.loadMoreBtnWrapper}>
                    <Button
                      id={"loadMoreButton"}
                      onClick={() => fetchNextPage()}
                      classes={{
                        root: classes.loadMoreButton,
                        label: classes.loadMoreBtnLabel
                      }}
                    >
                      Load More
                    </Button>
                  </div>
                ) : (
                  <div className={classes.circularProgressWrapper}>
                    <CircularProgress />
                  </div>
                )
              ) : null}
            </div>
          </div>
        )}
      </List>
    </CircularLoader>
  );
};

export default React.memo(ActivityList);
