import React from 'react';
import HowItWorks from './HowItWorks';
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import ContactUs from './ContactUs';
import Features from './Features';
import Pricing from './Pricing';
import Home from './Home';

function LandingPage() {
  return (
    <>
      <PageHeader />
      <Home />
      <Features />
      <HowItWorks />
      <Pricing />
      <ContactUs />
      <PageFooter />
    </>
  );
}

export default LandingPage;
