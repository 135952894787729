import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    reportsWord: {
      color: '#777f8c',
      fontFamily: 'Lato',
      margin: '0 4px 0 0',
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline'
      }
    },
    reportName: {
      color: '#354052',
      margin: '0 0 0 4px',
      fontFamily: 'Lato-SemiBold'
    },
    headerTxt: {
      color: '#777f8c',
      fontSize: '16px',
      letterSpacing: 'normal'
    },
    form: {
      marginBottom: 24,
      padding: '16px 16px 0 16px'
    },
    question: {
      color: '#474f58',
      fontSize: '14px',
      fontFamily: 'Lato',
      fontWeight: 'bold',
      margin: '8px 8px 8px 0',
      letterSpacing: 'normal'
    },
    pollForm: {
      width: '80%',
      maxWidth: 800,
      marginTop: 16,
      borderRadius: 4,
      backgroundColor: '#fff',
      padding: '8px 16px 16px 24px',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      '@media (max-width: 500px)': {
        width: '95%'
      }
    },
    optionContent: {
      color: '#85909c',
      margin: '8px 0 0 24px',
      fontFamily: 'Lato-Bold',
      '@media (max-width: 500px)': {
        marginLeft: 0
      }
    },
    radioOptions: {
      padding: '4px 8px'
    },
    optionContentLabel: {
      fontSize: 16,
      '@media (max-width: 500px)': {
        fontSize: 14
      }
    },
    submitBtn: {
      height: 40,
      fontSize: 14,
      padding: '0 24px',
      margin: '16px 16px 0 16px'
    },
    editor: {
      padding: 10,
      minHeight: 120,
      cursor: 'text',
      borderRadius: 2,
      marginBottom: 8,
      color: '#85909c',
      fontSize: '14px',
      fontFamily: 'Lato',
      lineHeight: 'normal',
      border: '1 solid #ddd',
      letterSpacing: 'normal',
      backgroundColor: '#fefefe',
      boxShadow: 'inset 0px 1px 8px -3px #ababab'
    },
    rootEditor: {
      margin: '0 0 0 0 !important',
      '& .DraftEditor-root': {
        paddingRight: '0px !important'
      },
      '@media (max-width: 450px)': {
        marginLeft: 0
      }
    },
    pollSubmissionWrapper: {
      marginBottom: 8
    },
    dialogTitleWrapper: {
      display: 'flex',
      padding: '8px 0',
      flexDirection: 'row',
      alignItems: 'center'
    },
    dialogTitle: {
      fontSize: 24,
      margin: '0 auto',
      marginTop: '24px',
      color: ' #858d9b',
      fontFamily: 'Lato-Bold',
      '@media (max-width:330px)': {
        fontSize: '15px'
      }
    },
    closeButton: {
      width: 22,
      height: 22,
      opacity: 0.3,
      marginRight: 16,
      '&:hover': {
        opacity: 0.6,
        cursor: 'pointer'
      },
      '@media (max-width:330px)': {
        width: 17,
        height: 17,
        marginRight: 8,
        marginTop: 2
      }
    },
    reportDialogContent: {
      width: 600,
      overflow: 'overlay',
      '@supports ( -moz-appearance:none )': {
        overflow: 'auto'
      },
      '@media (max-width:600px)': {
        width: '100%'
      },
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      },
      '&::-ms-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-ms-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-ms-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      },
      '&::-moz-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-moz-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-moz-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      }
    },
    fieldLabel: {
      fontSize: 16,
      color: '#474f58',
      margin: '8px 8px 8px 0',
      fontFamily: 'Lato-Bold',
      letterSpacing: 'normal',
      '@media (max-width:400px)': {
        fontSize: 14
      }
    },
    formActions: {
      margin: '16px 0 8px 0',
      display: 'flex',
      justifyContent: 'center'
    },
    cancelBtn: {
      height: 40,
      borderRadius: 6,
      marginRight: 16,
      padding: '8px 32px',
      border: 'solid 1px #f5a623'
    },
    cancelBtnText: {
      fontSize: 14,
      color: '#f5a623',
      fontFamily: 'Lato-Bold',
      letterSpacing: 'normal'
    },
    createBtn: {
      height: 40,
      borderRadius: 6,
      opacity: 0.9,
      padding: '8px 32px',
      backgroundColor: '#f5a623',
      '&:hover': {
        opacity: 1,
        backgroundColor: '#f5a623'
      }
    },
    createBtnText: {
      color: '#fff',
      fontSize: 14,
      fontFamily: 'Lato-Bold',
      letterSpacing: 'normal'
    },
    errorMessage: {
      fontSize: 20,
      color: '#FF0000',
      fontFamily: 'Lato-Bold',
      letterSpacing: 'normal',
      textAlign: 'center'
    },
    optionWordLabel: {
      fontSize: 16,
      color: '#808080',
      margin: '8px 8px 8px 0',
      fontFamily: 'Lato-Bold',
      letterSpacing: 'normal',
      '@media (max-width:400px)': {
        fontSize: 14
      }
    },
    optionFieldLabelWrapper: {
      display: 'flex'
    },
    lastResponseSection: {
      display: 'flex',
      flexDirection: 'column'
    }
  })
);

export const lastReponseSectionStyles = makeStyles(() =>
  createStyles({
    lastResponseSection: {
      display: 'flex',
      flexDirection: 'column'
    },
    viewLastResponseBtn: {
      textTransform: 'none'
    }
  })
);
