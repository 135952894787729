import React from 'react';
import { DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { DialogStyles } from './DialogStyles';
import { Workspace } from '../../../../models/Workspace.model';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

interface IProps {
  handleClose: any;
  open: boolean;
  workspace: Workspace;
  handleDeleteWorkspace: any;
}
export const DeleteWorkspaceDialog = (props: IProps) => {
  const classes = DialogStyles();
  const { handleClose, open, handleDeleteWorkspace, workspace } = props;
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" className={classes.dialogTitle}>
        <Typography>Delete Workspace</Typography>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          {/*TODO : add move deleted workspace reports to another workspace */}
          <Grid item>
            <Typography className={classes.deleteModalContent}>
              {`Are you sure you want to Delete ${workspace?.name} workspace?
                Deleting the workspace won’t affected the related reports
                you need to move the reports to another workspace`}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justify="center"
            className={classes.deleteModalActions}
          >
            <Button className={classes.cancelBtn} onClick={handleClose}>
              <Typography className={classes.cancelBtnText}>Cancel</Typography>
            </Button>
            <Button
              className={classes.deleteBtn}
              onClick={handleDeleteWorkspace}
            >
              <Typography className={classes.deleteBtnText}>Delete</Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
