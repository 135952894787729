import { Grid, IconButton, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { styles } from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

interface Props {
  createdDate: string;
  collapse: boolean;
  setOpenId: Function;
  responseGroupId: number;
  responsesCount: number;
}

const GroupedResponsesHeader = ({
  createdDate,
  collapse,
  setOpenId,
  responseGroupId,
  responsesCount
}: Props) => {
  const classes = styles(); 

  const day = useMemo(() => dayjs(createdDate).format('DD'), [createdDate]);

  const month = useMemo(() => dayjs(createdDate).format('MMM'), [createdDate]);

  const handleCollapse = () => {
    setOpenId(collapse ? responseGroupId : null);
  };

  return (
    <Grid
      container
      item
      direction="row"
      alignItems="center"
      className={classes.groupedResponsesHeader}
    >
      <Grid item>
        <div className={classes.rectDate}>
          <Typography className={classes.rectDateNumber}>{day}</Typography>
          <Typography className={classes.rectDateMon}>{month}</Typography>
        </div>
      </Grid>

      <Grid item>
        <Typography className={classes.responsesStatus}>
          <span className={classes.responsesNumbers}>
            {`${responsesCount} `}
          </span>
          response
          {responsesCount === 0 || responsesCount > 1 ? 's' : ''}
        </Typography>
      </Grid>

      <Grid container item xs justify="flex-end" alignItems="center">
        <IconButton onClick={handleCollapse} aria-label="expand">
          {collapse ? (
            <ExpandMoreIcon fontSize="large" className={classes.expandCard} />
          ) : (
            <ExpandLessIcon fontSize="large" className={classes.expandCard} />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default React.memo(GroupedResponsesHeader);