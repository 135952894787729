import { makeStyles, createStyles } from '@material-ui/core/styles';

export const reportHeaderStyles = makeStyles(theme =>
  createStyles({
    search: {
      height: 32,
      fontSize: 14,
      width: '100%',
      borderRadius: 6,
      color: '#76797f',
      minWidth: '200px',
      maxWidth: '283px',
      backgroundColor: '#fff',
      border: 'solid 1px rgb(224, 224, 224)',
      boxShadow: '0 2px 5px 0 rgba(219, 219, 219, 0.5)'
    },
    searchGrid: {
      display: 'flex',
      justifyContent: 'flex-end',
      '@media only screen and (max-width: 600px)': {
        justifyContent: 'flex-start'
      }
    },
    reportsHeaderText: {
      color: '#858d9b',
      fontSize: '20px',
      fontFamily: 'Lato',
      fontWeight: 'bold',
      letterSpacing: 'normal'
    },
    clearSearch: {
      width: '12px',
      height: '12px',
      color: '#76797f'
    }
  })
);
