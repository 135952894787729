import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';

import BscrumLogo from '../../../../assets/images/logo.png';
import SignUpButton from '../SingUpButton';
import styles from './styles';

interface Props {
  isSideDrawer: boolean;
}

function Header(props: Props) {
  const [selected, setSelected] = useState(0);
  const { isSideDrawer } = props;
  const classes = styles();

  const updateSelected = (selectedIndex: number) => {
    setSelected(selectedIndex);
  };

  return (
    <List className={clsx(classes.nav, { [classes.sideNav]: isSideDrawer })}>
      <Link href="#home" underline="none" className={classes.bscrumLogoWrapper}>
        <img
          className={classes.bscrumLogoImg}
          src={BscrumLogo}
          alt="bscrum_logo"
        />
        <span className={classes.bscrumLogoText}>bscrum</span>
      </Link>
      <List className={classes.navRightSide}>
        <Link className={classes.navLink} underline="none" href="#home">
          <ListItem
            onClick={() => updateSelected(0)}
            selected={selected === 0}
            className={
              selected === 0 ? classes.selectedNavItem : classes.navItem
            }
          >
            Home
          </ListItem>
        </Link>
        <Link className={classes.navLink} underline="none" href="#features">
          <ListItem
            onClick={() => updateSelected(1)}
            selected={selected === 1}
            className={
              selected === 1 ? classes.selectedNavItem : classes.navItem
            }
          >
            Features
          </ListItem>
        </Link>
        <Link className={classes.navLink} underline="none" href="#howItWorks">
          <ListItem
            onClick={() => updateSelected(2)}
            selected={selected === 2}
            className={
              selected === 2 ? classes.selectedNavItem : classes.navItem
            }
          >
            How it works
          </ListItem>
        </Link>
        <Link className={classes.navLink} underline="none" href="#pricing">
          <ListItem
            onClick={() => updateSelected(3)}
            selected={selected === 3}
            className={
              selected === 3 ? classes.selectedNavItem : classes.navItem
            }
          >
            Pricing
          </ListItem>
        </Link>
        <Link className={classes.navLink} underline="none" href="#contactUs">
          <ListItem
            onClick={() => updateSelected(4)}
            selected={selected === 4}
            className={
              selected === 4 ? classes.selectedNavItem : classes.navItem
            }
          >
            Contact Us
          </ListItem>
        </Link>
        <SignUpButton buttonLabel="Sign Up" />
      </List>
    </List>
  );
}

export default Header;
