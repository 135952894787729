import React, { useContext } from 'react';
import { Avatar } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { styles } from './styles';
import { useFormContext } from 'react-hook-form';
import { Member } from '../../../../../models/Member.model';
import { AppContext } from '../../../../../App';
import { User } from '../../../../../models/User.model';

interface Props {
  participantList: Member[];
  inputName: string;
  adminsList?: boolean;
}
export const ParticipantsList = ({ participantList, inputName, adminsList = false }: Props) => {
  const classes = styles();
  const { setValue, getValues } = useFormContext();
  const currentUser: User = useContext(AppContext);

  return (
    <div className={classes.membersList}>
      {participantList.map((member, index) => {
        return (
          <div key={member.id} className={classes.container}>
            <Avatar
              className={classes.oval}
              alt={member.name}
              src={member.avatar}
            />

            <span className={classes.memberName}>{member.name}</span>
            {
              member.id === currentUser?.id && adminsList ?
              <></>
              :
              <ClearIcon
                className={classes.clearIcon}
                onClick={(e: any) => {
                  setValue(
                    inputName,
                    getValues(inputName).filter((m: any) => m.id !== member.id)
                  );
                }}
              />
            }
          </div>
        );
      })}
    </div>
  );
};
