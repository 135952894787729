import React, { useContext } from 'react';
import { Grid, TextField, Tooltip } from '@material-ui/core';
import { capitalizeString } from '../../../../../utils/helpers';
import { useUpdateRoles } from '../../settingsHooks';
import { userCardStyles } from './styles';
import { Autocomplete } from '@material-ui/lab';
import { Member } from '../../../../../models/Member.model';
import {
  USER_ROLES_DESCRIPTION,
  USER_ROLES
} from '../../../../../utils/constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UserComponent from '../../../../shared-components/UserComponent';
import { AppContext } from '../../../../../App';
import { User } from '../../../../../models/User.model';

interface UserCardProps {
  member: Member;
}

function UserCard(props: UserCardProps) {
  const classes = userCardStyles();
  const { member } = props;
  const currentUser: User = useContext(AppContext);
  const updateRoles = useUpdateRoles(member.name);

  return (
    <Grid container classes={{ container: classes.containerGrid }}>
      <UserComponent name={member.name} avatar={member.avatar} id={member.id} />
      <Autocomplete
        options={Object.values(USER_ROLES)}
        getOptionLabel={option => option}
        value={capitalizeString(member.role)}
        disableClearable
        disabled={currentUser != null && currentUser.name === member.name}
        renderInput={params => <TextField {...params} variant="outlined" />}
        onChange={(event: object, value: string) => {
          updateRoles.mutate(Object.assign(member, { role: value }));
        }}
        classes={{
          root: classes.memberRole,
          paper: classes.paper
        }}
        popupIcon={<ExpandMoreIcon className={classes.arrowIcon} />}
        renderOption={(option: string) => (
          <Tooltip
            classes={{
              tooltip: classes.userRoleTooltip
            }}
            title={USER_ROLES_DESCRIPTION[option.toLowerCase()]}
            placement="left"
          >
            <span style={{ width: '100%' }}>{option}</span>
          </Tooltip>
        )}
      />
    </Grid>
  );
}

export default React.memo(UserCard);
