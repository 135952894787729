import React, { useMemo } from 'react';
import {
  Card,
  Box,
  Typography,
  IconButton,
  CardContent,
  Collapse
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import WorkspaceReport from './WorkspaceReport';

import { workspaceCardStyles } from './workspaceCardStyles';

interface props {
  workspace: any;
  reports: any[];
  userID: string;
}

const WorkspaceCard = ({ workspace, reports, userID }: props) => {
  const [expanded, setExpanded] = React.useState(true);

  const assignedReports = useMemo(
    () =>
      reports.filter(
        report => report.workspace && report.workspace.id === workspace.id
      ),
    [workspace, reports]
  );
  const classes = workspaceCardStyles();

  const handleExpandClick = () => {
    setExpanded(expanded => !expanded);
  };

  return (
    <Card key={workspace.id} className={classes.card}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.title}>{workspace.name}</Typography>
        <IconButton
          className={classes.expandButton}
          onClick={handleExpandClick}
        >
          {expanded ? (
            <ExpandLessIcon className={classes.expandIcon} />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} />
          )}
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.cardContent}>
          {assignedReports.length > 0 ? (
            assignedReports.map(report => (
              <WorkspaceReport
                key={report.id}
                userID={userID}
                report={report}
              />
            ))
          ) : (
            <Typography className={classes.emptyWorkSpace}>
              Not assigned to any reports
            </Typography>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};
export default WorkspaceCard;
