import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import styles from './styles';
import { Subscription } from '../../../../models/Subscription.model';
import { Button } from '@material-ui/core';

interface Props {
  planId: string;
  planName: string;
  planInformation: string[];
  planPrice: string;
  planDuration: string;
  subscription?: Subscription;
  isSettings?: boolean;
}



function PricingPlanCard(props: Props) {
  const classes = styles();

  const { planId,planName, planInformation, planPrice, planDuration, isSettings,subscription } =
    props;
   
  

   const activePaidCard = subscription?.plan.name === 'Paid Plan' && subscription.plan.name === planId? classes.activePaidSubscription : ''
   const activeFreeCard = subscription?.plan.name === 'Free Plan' && subscription.plan.name === planId? classes.activeFreeSubscription : ''
   const buttonLabel = subscription ?subscription.plan.name === planId? "Current Plan" : "Get Started" : "Get Started"
   const activeButtonClass = subscription? buttonLabel === "Current Plan"? classes.currentPlanButton : classes.getStartedButton:''

  return (
    <div
      className={`${classes.pricingPlansCard} ${
        isSettings && classes.altPricingPlansCard
      } ${activePaidCard} ${activeFreeCard}`}
    >
      <h3 className={classes.plansCardTitle}>{planName}</h3>
      <div className={classes.plansCardPrice}>
        <p>$</p>
        <p>{planPrice}</p>
        <p>/{planDuration}</p>
      </div>
      <div className={classes.plansCardInfo}>
        {planInformation.map((info, index) => (
          <p key={index}>
            <CheckIcon className={classes.checkIcon} fontSize="large" />
            {info}
          </p>
        ))}
      </div>
      <Button disabled={buttonLabel === "Current Plan"} className={`${classes.plansCardBtn} ${activeButtonClass} `}>{buttonLabel}</Button>
    </div>
  );
}

export default PricingPlanCard;
