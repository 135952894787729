import * as React from 'react';
import { styles } from './styles';
import { Grid } from '@material-ui/core';
import { Report } from '../../../../models/Report.model';
import { ReportResponse } from '../../../../models/ReportResponse.model';
import GroupedResponsesHeader from './GroupedResponsesHeader';
import { Responses } from '../SubmissionCycles/SubmissionCycleCard/Responses';

interface GroupedResponsesCard {
  report: Report;
  date: string;
  responses: ReportResponse[];
  openId: number;
  setOpenId: Function;
  responseGroupId: number;
}

export default function GroupedResponsesCard({
  report,
  date,
  responses,
  openId,
  setOpenId,
  responseGroupId
}: GroupedResponsesCard) {
  const classes = styles();

  return (
    <Grid container direction="column" className={classes.groupedResponsesCard}>
      <GroupedResponsesHeader
        createdDate={date}
        collapse={!(responseGroupId === openId)}
        setOpenId={setOpenId}
        responseGroupId={responseGroupId}
        responsesCount={responses.length}
      />

      {responseGroupId === openId && (
        <>
          <Grid item className={classes.gridSeparator}></Grid>
          <Responses responses={responses} questions={report.questions} />
        </>
      )}
    </Grid>
  );
}
