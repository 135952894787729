import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import styles from './styles';
import { useHistory } from 'react-router-dom';
import PricingPlanCard from './PricingPlanCard';
import { Subscription } from '../../../../models/Subscription.model';

interface Props {
  subscription?: Subscription;
}

function Pricing(props: Props) {
  const { subscription } = props;

  useEffect(() => {
    Aos.init();
  }, []);

  const classes = styles();
  const history = useHistory();
  const isSettings = history.location.pathname.includes('/settings');

  const freePlanInfo = [
    'Limit Access',
    'Real time responses',
    'One project',
    'All types of reports',
    'Unlimited team members'
  ];

  const paidPlanInfo = [
    'Full Access',
    'Real time responses',
    'One project',
    'All types of reports',
    'Unlimited team members'
  ];

  return (
    <section
      id="pricing"
      className={`${classes.pricingWrapper} ${
        isSettings && classes.altPricingWrapper
      }`}
    >
      {!isSettings && (
        <>
          <h2 className={classes.pricingTitle}>Our pricing plans</h2>
          <p className={classes.pricingDescription}>
            {`Simple, Fair and affordable prices for all.
          you can select the suitable plan for your business.`}
          </p>
        </>
      )}
      <div className={classes.pricingPlansCardsWrapper}>
        <div
          className={classes.pricingCardLeftWrapper}
          data-aos="slide-right"
          data-aos-once="true"
        >
          <PricingPlanCard
            isSettings={isSettings}
            planId="Free Plan"
            planName="Free"
            planInformation={freePlanInfo}
            planPrice="10"
            planDuration="mo"
            subscription={subscription}
          />
        </div>
        <div
          className={classes.pricingCardRightWrapper}
          data-aos="slide-left"
          data-aos-once="true"
        >
          <PricingPlanCard
            planId="Paid Plan"
            isSettings={isSettings}
            planName="Paid"
            planInformation={paidPlanInfo}
            planPrice="15"
            planDuration="yr"
            subscription={subscription}
          />
        </div>
      </div>
    </section>
  );
}

export default Pricing;
