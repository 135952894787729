import axios from '../services/axios';
import { USER_ROLES } from '../utils/constants';

import { API } from './API';
import store from '../store';
import { PaginatedReportResponses } from './ReportResponse.model';

export type MemberResponsesRequest = {
  member_id: number;
  page: number;
  items: number;
  report_ids?: number[];
};

export class Member extends API {
  public id: number = -1;
  public provided_id: string = '';
  public name: string = '';
  public role: string = USER_ROLES.member;
  public avatar: string = '';
  public email: string = '';

  constructor(
    id: number,
    provided_id: string,
    name: string,
    role: string,
    avatar: string = '',
    email: string = ''
  ) {
    super();
    this.id = id;
    this.provided_id = provided_id;
    this.name = name;
    this.role = role;
    this.avatar = avatar;
    this.email = email;
  }

  public static async getAll(): Promise<Member[]> {
    const headers = Member.getConfig();

    if (headers) {
      return new Promise<Member[]>((resolve, reject) => {
        axios
          .get('dashboard/members', headers)
          .then(res => {
            resolve(res.data.members as Member[]);
          })
          .catch(e => {
            Member.checkUnauthorizedRequest(e, store);
            reject(e);
          });
      });
    }
    return Promise.reject('system error');
  }

  public static async getMember(memberId: string): Promise<Member> {
    const headers = Member.getConfig();
    if (headers) {
      return new Promise<Member>((resolve, reject) => {
        axios
          .get(`dashboard/members/${memberId}`, headers)
          .then(res => {
            resolve(res.data.member as Member);
          })
          .catch(e => {
            Member.checkUnauthorizedRequest(e, store);
            reject(e);
          });
      });
    }
    return Promise.reject("can't fetch member data");
  }

  public static async updateRoles(members: Member[]): Promise<Member[]> {
    const headers = Member.getConfig();

    if (headers) {
      return new Promise<Member[]>((resolve, reject) => {
        axios
          .put('dashboard/members/update_roles', { members: members }, headers)
          .then(res => {
            resolve(res.data.members as Member[]);
          })
          .catch(e => {
            Member.checkUnauthorizedRequest(e, store);
            reject(e);
          });
      });
    }
    return Promise.reject('system error');
  }

  public static async getMemberResponses(
    requestParams: MemberResponsesRequest
  ): Promise<PaginatedReportResponses> {
    const headers = Member.getConfig();
    if (headers) {
      return new Promise<PaginatedReportResponses>((resolve, reject) => {
        const queryParams = `page=${requestParams.page}&items=${requestParams.items}&report_ids=${requestParams.report_ids}`;
        axios
          .get(
            `dashboard/members/${requestParams.member_id}/responses?${queryParams}`,
            headers
          )
          .then(res => {
            resolve(res.data);
          })
          .catch(e => {
            Member.checkUnauthorizedRequest(e, store);
            reject(e);
          });
      });
    }
    return Promise.reject("can't fetch member responses");
  }
}
