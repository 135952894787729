import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    root: {
      padding: '16px 16px 0 16px',
      margin: 0,
      width: '100%'
    },
    time: {
      color: '#a9aeb7',
      fontSize: '12px',
      fontFamily: 'Lato',
      letterSpacing: 'normal'
    },
    timeSpan: {
      color: '#858a94',
      fontWeight: 'bold'
    },
    responseTime: {
      display: 'flex',
      alignItems: 'center',
      '@media (max-width: 750px)': {
        display: 'none'
      }
    },
    question: {
      marginRight: 8,
      color: '#474f58',
      fontSize: '14px',
      fontFamily: 'Lato',
      fontWeight: 'bold',
      letterSpacing: 'normal'
    },
    answer: {
      display: 'inline-block',
      color: '#68727d',
      fontSize: '16px',
      fontFamily: 'Lato',
      wordBreak: 'break-word',
      margin: '8px 4px 8px 4px'
    },
    answerByQuestion: {
      margin: '15px'
    },
    QuestionResponse: {
      margin: '8px 0 8px 16px'
    },
    QuestionGrouping: {
      marginBottom: 8
    },
    QuestionAnswerContainer: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  })
);
