import React, { useState } from 'react';
import NoPlanImg from '../../../../assets/images/no_plan_illustration.svg';
import Pricing from '../../LandingPage/Pricing';
import PaidPlanDialog from './PaidPlanDialog';
import { Subscription } from '../../../../models/Subscription.model';
import { QUERIES } from '../../../../utils/queris';
import { useQuery } from 'react-query';
import { styles } from './styles';

const Plan = () => {
  const [open, setOpen] = useState(false);
  const classes = styles();

  const { data: subscription } = useQuery(
    QUERIES.LOAD_TEAM_SUBSCRIPTION,
    Subscription.getSubscription
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.planIllustration}>
      {subscription?.plan.price === 0 && (
        <img src={NoPlanImg} className={classes.noPlanImg} alt="no plan icon" />
      )}
      <PaidPlanDialog open={open} handleClose={handleClose} />
      <Pricing subscription={subscription} />
    </div>
  );
};

export default Plan;
