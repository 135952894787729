import React from 'react';
import {fieldLabelStyles} from './styles'

interface Props {
    label: string;
    required?: boolean
}
export const FieldLabel = React.memo(({label, required = false}: Props) => {
    const classes = fieldLabelStyles();
    return (
        <p className={classes.fieldLabel}>
            {`${label} ${required ? '*' : ''}`}
        </p>
    )
}
)