import React, { useState, useCallback } from 'react';
import { Typography, List, Button, Grid, Card, Box } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import {
  Activity as ActivityModel,
  ActivityFilters
} from '../../../../models/Activity.model';

import { EmptySmallIcon } from '../../../shared-components/EmptyStates/EmptySmallIcon';
import CircularLoader from '../../../shared-components/CircularLoader';
import ResponseContent from '../../Reports/SubmissionCycles/SubmissionCycleCard/Responses/ResponseCard/ResponseContent';
import { useMemberActivity } from '../UserHooks';
import ReportsFilter from '../../../shared-components/ReportsFilter';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { styles } from './styles';

dayjs.extend(relativeTime);

interface props {
  id?: string;
}
const ActivityOption = {
  allOption: 1,
  favoriteOption: 2
};

const Responses = ({ id }: props) => {
  const [selectFavorite, setSelectFavorite] = useState(
    Number(localStorage.getItem('activityOption')) ==
      ActivityOption.favoriteOption || false
  );

  const classes = styles();

  const activityFilters: ActivityFilters = {
    memberId: Number(id),
    selectFavorite
  } as ActivityFilters;

  const handleReportFilterChange =useCallback(( isFavoriteSelected:boolean) => {
    setSelectFavorite(isFavoriteSelected)},[selectFavorite]);

  const {
    isLoading,
    hasNextPage,
    data: activityList,
    isFetchingNextPage,
    fetchNextPage
  } = useMemberActivity(activityFilters);

  return (
    <Card className={classes.root}>
      <Box className={classes.headerWrapper} display="flex">
        <Typography className={classes.activitiesHeader}>Responses</Typography>
        <ReportsFilter onChange={handleReportFilterChange} />
      </Box>
      <Grid item>
        <CircularLoader isLoading={isLoading || !activityList}>
          <List className={classes.activityList}>
            {activityList &&
            activityList.pages &&
            activityList.pages[0]?.count === 0 ? (
              <EmptySmallIcon bordered />
            ) : (
              <div>
                {activityList &&
                  activityList.pages &&
                  activityList.pages.map((page, index) => (
                    <React.Fragment key={index}>
                      {page.activities.map((activity: ActivityModel, index) => (
                        <Box className={classes.activityWrapper} key={index}>
                          <Box className={classes.reportNameWrapper}>
                            <Typography className={classes.reportName}>
                              {activity.report.name}
                              <span className={classes.reportNameSpan}>
                                (
                                {dayjs(activity.response.time).format(
                                  'D MMM YYYY'
                                )}
                                )
                              </span>
                            </Typography>
                            <Typography className={classes.time}>
                              <AccessTimeIcon className={classes.timeIcon} />
                              <span className={classes.timeSpan}>
                                {dayjs(activity.response.time).fromNow(true)}
                              </span>
                              &nbsp;
                            </Typography>
                          </Box>
                          <ResponseContent
                            questions={activity.report.questions}
                            response={activity.response}
                          />
                        </Box>
                      ))}
                    </React.Fragment>
                  ))}
                {hasNextPage && !isFetchingNextPage ? (
                  <Button
                    onClick={() => fetchNextPage()}
                    classes={{
                      root: classes.loadMoreButton,
                      label: classes.loadMoreBtnLabel
                    }}
                  >
                    Load More
                  </Button>
                ) : null}
              </div>
            )}
          </List>
        </CircularLoader>
      </Grid>
    </Card>
  );
};

export default React.memo(Responses);
