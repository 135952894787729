import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { Freshchat } from 'reactjs-freshchat';
import ConfirmationsList from './pages/components/popMessages/ConfirmationsList';
import ErrorBoundary from './pages/shared-components/ErrorBoundary';
import ErrorsList from './pages/components/popMessages/ErrorsList';
import history from './services/history';
import Home from './pages/components/Home';
import 'reactjs-freshchat/dist/index.css';
import { useQuery } from 'react-query';
import { QUERIES } from './utils/queris';
import { User } from './models/User.model';
import CircularLoader from './pages/shared-components/CircularLoader';
import Auth from './pages/components/Auth';

export const AppContext = React.createContext<User>(new User());

const App = () => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const { data: fetchedUser, isLoading } = useQuery(
    QUERIES.LOAD_CURRENT_USER,
    User.currentSession,
    {
      enabled: !currentUser,
      retry: false
    }
  );

  useEffect(() => {
    if (fetchedUser) setCurrentUser(fetchedUser as User);
  }, [fetchedUser]);

  return (
    <AppContext.Provider value={(fetchedUser || currentUser) as User}>
      <CircularLoader isLoading={isLoading}>
        <ErrorBoundary>
          <Freshchat
            token={process.env.REACT_APP_FRESH_CHAT_TOKEN || ''}
            host={process.env.REACT_APP_FRESH_CHAT_HOST || ''}
          />
          <ConfirmationsList />
          <ErrorsList />
          {(fetchedUser || currentUser) ? (
            <Router history={history}>
              <Home />
            </Router>
          ) : (
            <Router history={history}>
              <Auth setCurrentUser={setCurrentUser} />
            </Router>
          )}
        </ErrorBoundary>
      </CircularLoader>
    </AppContext.Provider>
  );
};

export default App;
