import { API } from './API';
import axios from '../services/axios';
import { Member } from './Member.model';
import { Ticket } from './Tickets';
import store from '../store';
export class ReleaseNote extends API {
  public id: number = -1;
  public title: string = '';
  public description: string = '';
  public release_date: string = '';
  public image_url: string = '';
  public tickets: Ticket[] = [];

  public static async getAll(): Promise<ReleaseNote[]> {
    const headers = ReleaseNote.getConfig();

    if (headers) {
      return new Promise<ReleaseNote[]>((resolve, reject) => {
        axios
          .get('dashboard/release_notes', headers)
          .then(res => {
            const releaseNotes: ReleaseNote[] = res.data.releaseNotes as ReleaseNote[];
            resolve(releaseNotes);
          })
          .catch(e => {
            ReleaseNote.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }

}