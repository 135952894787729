import React from 'react';
import Typography from '@material-ui/core/Typography';
import { getImageUrl } from '../../../../utils/helpers';

import { styles } from './ReportCountCardStyles';

interface ReportCountProp {
  count: number;
  reportName: string;
}

function ReportCountCard({ count, reportName }: ReportCountProp) {
  const classes = styles();

  const categoryData = getImageUrl(reportName, classes.icon);

  return (
    <div className={classes.card}>
      <div>{categoryData.imageUrl}</div>
      <div>
        <Typography className={classes.countClass}>{count}</Typography>
        <Typography className={classes.reportNameClass}>
          {reportName}
        </Typography>
      </div>
    </div>
  );
}

export default React.memo(ReportCountCard);
