import { API } from './API';
import axios from '../services/axios';
import store from '../store';
export class TimeZone extends API {
  public name: string = '';
  public offset: string = '';

  public static async getAll(): Promise<TimeZone[]> {
    const headers = TimeZone.getConfig();

    if (headers) {
      return new Promise<TimeZone[]>((resolve, reject) => {
        axios
          .get('dashboard/reports/get_timezones', headers)
          .then(res => {
            let timezones: TimeZone[] = res.data;
            resolve(timezones);
          })
          .catch(e => {
            TimeZone.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }
}
