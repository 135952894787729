import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import OAuth from './OAuth';
import SlackAuthCallback from './SlackAuthCallback';
import PATH_NAME from '../../../utils/pathNames';
import LandingPage from '../LandingPage';
import Privacy from '../privacy/privacy';

interface Props {
  setCurrentUser: Function;
}
export default function Auth({ setCurrentUser }: Props) {
  return (
    <Switch>
      <Route exact path={PATH_NAME.ROOT} component={LandingPage} />
      <Route exact path={PATH_NAME.HOME} component={OAuth} />
      <Route exact path={PATH_NAME.PRIVACY_POLICY} component={Privacy} />

      <Route
        exact
        path={PATH_NAME.AUTH_CALLBACK}
        render={() => <SlackAuthCallback setCurrentUser={setCurrentUser} />}
      />
      <Redirect to={PATH_NAME.ROOT} />
    </Switch>
  );
}
