import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { date } from 'yup';
import { ReportResponse } from '../models/ReportResponse.model';

dayjs.extend(utc);
export function getTimeFrameInterval(timeFrame?: number): {
  startTime?: string;
  endTime?: string;
} {
  switch (timeFrame) {
    case 0:
      return {
        startTime: dayjs.utc().startOf('day').toISOString(),
        endTime: dayjs.utc().endOf('day').toISOString()
      };

    case 1:
      return {
        startTime: dayjs.utc().startOf('day').subtract(1, 'day').toISOString(),
        endTime: dayjs.utc().endOf('day').subtract(1, 'day').toISOString()
      };

    case 2:
      return {
        startTime: dayjs.utc().startOf('day').subtract(1, 'week').toISOString(),
        endTime: dayjs.utc().endOf('day').toISOString()
      };

    default:
      return { startTime: undefined, endTime: undefined };
  }
}

export function groupResponsesByDay(responses: ReportResponse[]): any {
  const dateFormat = 'MM/DD/YYYY';

  // Function that groups responses by day and returns an object with key=date, value=[responses]
  const groupBy = (array: any) => {
    return array.reduce((groupedResponses: any, response: any) => {
      (groupedResponses[dayjs(response.time).format(dateFormat)] =
        groupedResponses[dayjs(response.time).format(dateFormat)] || []).push(
        response
      );
      return groupedResponses;
    }, {});
  };
  let groupedResponses = groupBy(responses);

  // Returns an array of objects: key=index, value={date: date, responses=[responses]}
  groupedResponses = Object.keys(groupedResponses).map(function (date) {
    return {
      date: date,
      responses: groupedResponses[date]
    };
  });

  return groupedResponses;
}
