import React, { useState } from 'react';
import { useForm, NestedValue, Controller } from 'react-hook-form';
import { SelectAutoComplete } from '../../../shared-components/SelectAutocomplete';
import { DialogStyles } from './DialogStyles';
import { FieldLabel } from '../../../shared-components/FieldLabel';
import { Workspace } from '../../../../models/Workspace.model';
import { Member } from '../../../../models/Member.model';
import {
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Typography,
  Dialog,
  Box
} from '@material-ui/core';
import {
  useCreateWorkspace,
  useEditWorkspace,
  useMembers
} from '../workspaceHooks';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

interface workspaceDialogProps {
  open: boolean;
  handleClose: () => void;
  isCreateWorkspace: boolean;
  workspace: Workspace;
  openConfirmForm?: (workspace: Workspace) => void;
  setEditedWorkspaceData?: (data: any) => void;
}

interface IFormInput {
  name: string;
  description: string;
  assigned_members: NestedValue<Member[]>;
}

export const CreateWorkspaceDialog = (props: workspaceDialogProps) => {
  const classes = DialogStyles();
  const {
    open,
    handleClose,
    isCreateWorkspace,
    workspace,
    openConfirmForm,
    setEditedWorkspaceData
  } = props;
  const [currentOption, setCurrentOption] = useState(1);

  const handleChange = (event: any) => {
    setCurrentOption(event.target.value);
  };

  const openWorkspaceOption = () => {
    return (
      <Typography className={classes.optionContentText}>
        Open Workspace{' '}
        <span className={classes.optionContentSpan}>
          {' '}
          (members can request to join)
        </span>
      </Typography>
    );
  };

  const privateWorkspaceOption = () => {
    return (
      <Typography className={classes.optionContentText}>
        Private Workspace{' '}
        <span className={classes.optionContentSpan}>
          {' '}
          (invite members only)
        </span>
      </Typography>
    );
  };

  const { register, handleSubmit, setValue, control, errors } =
    useForm<IFormInput>();
  const createWorkspace = useCreateWorkspace();
  const editWorkspace = useEditWorkspace();
  const { data: membersList } = useMembers();

  const onSubmit = (data: IFormInput) => {
    let newWorkspace: Workspace = Object.assign({}, workspace);
    newWorkspace.name = data.name;
    newWorkspace.description = data.description;
    newWorkspace.members = data.assigned_members;
    if (isCreateWorkspace) {
      createWorkspace.mutate(newWorkspace);
    } else {
      const removedMembersList = getRemovedMembersList(
        data.assigned_members,
        workspace.members
      );
      if (removedMembersList.length > 0) {
        if (setEditedWorkspaceData) setEditedWorkspaceData(data);
        if (openConfirmForm) {
          openConfirmForm(workspace);
        }
      } else {
        editWorkspace.mutate(newWorkspace);
      }
    }
    handleClose();
  };

  const getRemovedMembersList = (
    newMembers: NestedValue<Member[]>,
    oldMembers: Member[]
  ) => {
    return oldMembers.filter(item => newMembers.indexOf(item) < 0);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      classes={{
        paper: classes.paper
      }}
    >
      <DialogTitle id="customized-dialog-title" className={classes.dialogTitle}>
        <Typography>
          {workspace.id === -1 ? 'Create New Workspace' : `Edit Workspace`}
        </Typography>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.workspaceDialogContent}>
        <FormControl className={classes.formItem}>
          <FieldLabel
            required
            label="Workspace Name"
            className={classes.fieldLabel}
          />
          <TextField
            inputRef={register({ required: true })}
            name={'name'}
            variant="outlined"
            defaultValue={workspace.name}
            className={classes.formField}
            placeholder="Add name"
            id="outlined-error-helper-text"
            error={errors.name ? true : false}
          />
        </FormControl>

        <FormControl className={classes.formItem}>
          <FieldLabel label="Description" className={classes.fieldLabel} />
          <TextField
            inputRef={register()}
            name={'description'}
            variant="outlined"
            multiline={true}
            placeholder="Add description"
            defaultValue={workspace.description}
            className={classes.formFieldTextArea}
          />
        </FormControl>
        {!workspace?.is_default && (
          <FormControl className={classes.formItemDropdown}>
            <FieldLabel label="Add teammates" className={classes.fieldLabel} />
            <Controller
              render={({ onChange, value }) => (
                <SelectAutoComplete
                  value={value}
                  onChange={(options: any) => {
                    setValue('assigned_members', options);
                  }}
                  options={(membersList as Member[]) || []}
                  getOptionLabel={(member: Member) => member.name}
                  selectId="assigned_members"
                  multiple={true}
                  getOptionSelected={(option: any, value: any) =>
                    option.id === value.id
                  }
                  placeholder="Select members"
                />
              )}
              name="assigned_members"
              control={control}
              defaultValue={workspace.members}
            />
          </FormControl>
        )}

        {/*TODO:: uncomment this part after implement functionality */}
        {/* <FormControl>
          <FieldLabel
            label="Joining Permission"
            className={classes.fieldLabel}
          />
          <RadioGroup
            aria-label="gender"
            name="permission"
            value={currentOption}
            onChange={handleChange}
          >
            <FormControlLabel
              value="public"
              control={<Radio style={{ color: 'orange' }} />}
              label={openWorkspaceOption()}
            />
            <FormControlLabel
              value="private"
              control={<Radio style={{ color: 'orange' }} />}
              label={privateWorkspaceOption()}
            />
          </RadioGroup>
        </FormControl> */}

        <FormControl>
          <Box className={classes.formActions}>
            <Button className={classes.cancelBtn} onClick={handleClose}>
              <Typography className={classes.cancelBtnText}>Cancel</Typography>
            </Button>

            <Button
              id="submit_workspace_form"
              className={classes.createBtn}
              onClick={handleSubmit(onSubmit)}
            >
              <Typography className={classes.createBtnText}>
                {isCreateWorkspace ? 'Create' : 'Update'}
              </Typography>
            </Button>
          </Box>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};
