import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(theme =>
  createStyles({
    // Holds the dashboard title
    drawerParentTitle: {
      height: '48px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f5f6fa',
      margin: '12px 0'
    },
    drawerParentLogo: {
      margin: '5px',
      cursor: 'pointer'
    },
    drawerParentLogoText: {
      cursor: 'pointer',
      width: 77,
      lineHeight: 1.2,
      color: '#2a5cbf',
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '4px',
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: 'normal'
      // fontFamily: 'AvenirNext'
    },
    // Contains the profile image and name
    drawerProfile: {
      display: 'flex',
      alignItems: 'center',
      height: 104,
      width: '100%',
      backgroundColor: '#FFFFFF',
      mixBlendMode: 'normal',
      textDecoration: 'none'
    },
    // link to profile
    drawerProfileLink: {
      textDecoration: 'none'
    },
    // Profile image
    drawerProfileImage: {
      width: '56px',
      height: '56px',
      boxShadow: '0 2px 24px 0 rgba(97, 97, 97, 0.39)',
      marginLeft: 24
    },
    // Profile text wrapper
    drawerProfileTextWrap: {
      marginLeft: 12
    },
    // Profile name
    drawerProfileName: {
      fontWeight: 'bold',
      color: '#354052',
      fontSize: '16px',
      lineHeight: '24px',
      fontStyle: 'normal',
      marginBottom: 2,
      letterSpacing: '0.03em'
    },

    // profile position
    drawerProfilePosition: {
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.03em',
      color: '#AFB1B6',
      marginTop: 2,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 160
    },
    // List
    bottomWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 'calc(100% - 252px)',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      },
      '&::-ms-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-ms-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-ms-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      },
      '&::-moz-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-moz-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-moz-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      }
    },
    list: {
      padding: '0px'
    },
    // List item (dashboard, reports, workspace, settings, logout)
    listItem: {
      textDecoration: 'none'
    },
    listButton: {
      height: '80px',
      display: 'flex',
      borderRadius: '4px',
      alignItems: 'center',
      textDecoration: 'none',
      padding: '5px 5px 5px 20px',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        opacity: 0.7
      }
    },
    // Styles applied to the selected list item
    selectedListButton: {
      height: '80px',
      display: 'flex',
      borderRadius: '4px',
      alignItems: 'center',
      textDecoration: 'none',
      padding: '5px 5px 5px 20px',
      transition: 'all .2s ease-in-out',
      backgroundColor: '#3a78f2 !important',
      boxShadow: '0 1px 10px 0 rgba(159, 159, 159, 0.62)'
    },
    // List item text
    listItemText: {
      color: '#858d9b',
      fontSize: '16px',
      marginLeft: '17px'
    },
    // Selected list item text
    selectedListItemText: {
      color: '#fff',
      fontSize: '16px',
      fontWeight: 'bold',
      marginLeft: '17px'
    },
    // Settings arrow button
    settingsArrow: {
      paddingRight: '13px'
    },
    // Divider between each list item and the next
    divider: {
      width: '232px',
      height: '1px',
      marginLeft: '24px',
      backgroundColor: '#eaeaea'
    },
    /// Sidebar Subscription box
    sidebarImage: {
      display: 'flex',
      alignItems: 'center',
      margin: '48px 0 24px 0',
      flexDirection: 'column'
    },
    // Sidebar illustration
    sidebarIllustration: {
      width: '165px',
      height: '177px',
      marginBottom: '15px'
    },
    // "Upgrade for more features" text
    moreFeaturesText: {
      fontWeight: 600,
      fontSize: '14px',
      color: '#354052',
      textAlign: 'center',
      margin: '15px 0 9px',
      lineHeight: 'normal',
      whiteSpace: 'pre-line',
      letterSpacing: 'normal'
    },
    // "Upgrade now" button
    upgradeButton: {
      display: 'flex',
      color: '#f5a623',
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '2px 29px',
      borderRadius: '6px',
      fontStyle: 'normal',
      alignItems: 'center',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      flexDirection: 'column',
      justifyContent: 'center',
      border: '2px solid #f5a623',
      textTransform: 'capitalize'
    },
    drawerParentTeam: {
      width: '100%',
      height: '48px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F6F9F9'
    },
    drawerTeamName: {
      fontWeight: 600,
      color: '#354052',
      fontSize: '16px',
      lineHeight: '1.35',
      fontStyle: 'normal',
      fontStretch: 'normal',
      margin: '12px 0 4px 0',
      letterSpacing: 'normal'
    }
  })
);
