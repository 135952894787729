import { makeStyles, createStyles } from '@material-ui/core/styles';

export const userCardStyles = makeStyles(theme =>
  createStyles({
    card: {
      width: '642px',
      flexDirection: 'row'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '600px'
    },
    oval: {
      width: '38px',
      height: '38px',
      backgroundColor: '#d8d8d8',
      borderRadius: '50%'
    },
    ovalData: {
      margin: '20%',
      fontFamily: 'Axiforma',
      fontSize: '16px',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.13px',
      textAlign: 'left',
      color: '#474f58'
    },
    memberName: {
      marginTop: '10px',
      marginLeft: '17px',
      height: '17px',
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#354052',
      textAlign: 'left'
    },
    memberInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center'
    },
    containerGrid: {
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '17px 0',
      flexWrap: 'nowrap',
      '@media (max-width:730px)': {
        flexDirection: 'column'
      }
    },
    memberRole: {
      minWidth: '144px',
      maxWidth: '144px',
      height: '32px',
      borderRadius: '4px',
      textAlign: 'left',
      backgroundColor: '#fff',
      '@media (max-width:730px)': {
        marginTop: '10px',
        minWidth: '100%'
      },
      '& .MuiFormControl-root': {
        height: '100%',
        '& .MuiInputBase-root': {
          border: '1px solid #c3c3c3',
          transition: 'all .2s ease-in-out',
          padding: '0 0 0 16px',
          height: '100%',
          '&:hover,&.Mui-focused': {
            boxShadow: '2px 0 24px 0 rgba(103, 101, 101, 0.14)',
            border: '0.5px solid #3a78f2'
          },
          '& .MuiInputBase-input': {
            padding: '0 !important',
            fontSize: '14px',
            lineHeight: 1.14,
            color: '#354052',
            textAlign: 'left'
          },
          '& .MuiAutocomplete-endAdornment': {
            '& .MuiButtonBase-root': {
              transition: 'all .3s ease-in-out'
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }
      }
    },
    select: {
      textAlign: 'center',
      height: '40px'
    },
    inputLabel: {
      marginLeft: '5%',
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: 600,
      color: '#000000'
    },
    arrowIcon: {
      fill: '#858d9b'
    },
    updateButton: {
      width: '176px',
      height: '40px',
      borderRadius: '6px',
      backgroundColor: '#4f4f4f',
      color: '#ffffff',
      margin: '5px'
    },
    dropdownStyle: {
      width: 145,
      height: 73,
      borderRadius: 4,
      boxShadow: '2px 0 24px 0 rgba(103, 101, 101, 0.14)',
      backgroundColor: '#ffffff'
    },
    userRoleTooltip: {
      borderRadius: '12px',
      boxShadow: '0 2px 4px 0 rgba(201, 201, 201, 0.5)',
      border: 'solid 1px #eeeeee',
      backgroundColor: '#ffffff',
      fontFamily: 'Lato',
      fontSize: '14px',
      lineHeight: 1.43,
      color: '#76797f',
      fontWeight: 'normal'
    },
    paper: {
      borderRadius: '4px',
      boxShadow: ' 2px 0 24px 0 rgba(103, 101, 101, 0.14)',
      backgroundColor: '#fff',
      '& ul': {
        padding: '0 16px',
        '& li': {
          padding: '8px 0',
          borderBottom: '1px solid #e7e9ea',
          fontSize: '16px',
          letterSpacing: '0.13px',
          color: '#a3a9b4',
          '&:last-of-type': {
            borderBottom: 'none'
          },
          '&[aria-selected~="true"]': {
            background: '#fff',
            fontSize: '16px',
            lineHeight: 'normal',
            letterSpacing: '0.13px',
            color: '#3a78f2'
          },
          '&[data-focus~="true"]': { background: '#fff' }
        }
      }
    }
  })
);
