import React, { useCallback, useState, useEffect, useContext } from 'react';
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  useHistory
} from 'react-router-dom';
import { styles } from './styles';
import NewReportForm from '../Reports/ReportForm';
import CssBaseline from '@material-ui/core/CssBaseline';
import Workspaces from '../Workspaces';
import { WorkspaceDetails } from '../Workspaces/WorkspaceDetails';
import Dashboard from '../Dashboard';
import Settings from '../Settings/Index';
import Reports from '../Reports/Reports';
import Privacy from '../privacy/privacy';
import Sidebar from './Sidebar';
import Header from './Header';
import ReleaseNotes from '../ReleaseNotes';
import ReportDetails from '../Reports/ReportDetails';
import UserProfile from '../User/UserProfile';
import { Grid } from '@material-ui/core';
import PATH_NAME from '../../../utils/pathNames';
import clsx from 'clsx';
import useGaTracker from '../../../services/googleAnalyticsTracker';
import { AppContext } from '../../../App';
import { User } from '../../../models/User.model';
import { isAdmin } from '../../../utils/policies';
import BlockingDialog from './BlockingDialog';
import { useTeam } from './homeHooks';

const Home = React.memo(() => {
  const classes = styles();
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 700);
  const location = useLocation();
  const history = useHistory();
  const currentUser: User = useContext(AppContext);
  const { data: team } = useTeam(currentUser?.team_id);

  useEffect(() => {
    if (location.pathname === PATH_NAME.ROOT) history.push(PATH_NAME.HOME);
  });

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 700 ? setSidebarOpen(false) : setSidebarOpen(true);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sidebarOpen]);

  const handleDrawerOpen = useCallback(() => {
    setSidebarOpen(sidebarOpen => !sidebarOpen);
  }, [sidebarOpen]);

  useGaTracker();

  return (
    <>
      <Grid container className={classes.root}>
        <CssBaseline />
        <Header sidebarOpen={sidebarOpen} handleDrawer={handleDrawerOpen} />
        <Sidebar open={sidebarOpen} handleDrawer={handleDrawerOpen} />
        {team && !team?.integrated_with_slack && <BlockingDialog />}
        <main
          className={clsx(classes.container, {
            [classes.contentShift]: sidebarOpen
          })}
        >
          <Switch>
            <Route
              exact
              path={PATH_NAME.PRIVACY_POLICY}
              render={() => <Privacy />}
            />

            <Route exact path={PATH_NAME.HOME} render={() => <Dashboard />} />
            <Route
              exact
              path={PATH_NAME.DASHBOARD}
              render={() => <Dashboard />}
            />
            <Route exact path={PATH_NAME.REPORTS} render={() => <Reports />} />
            <Route
              exact
              path={PATH_NAME.WORKSPACES}
              render={() => <Workspaces />}
            />
            <Route
              exact
              path={PATH_NAME.WORKSPACE_DETAILS}
              render={() => <WorkspaceDetails />}
            />

            <Route
              exact
              path={PATH_NAME.REPORT_DETAILS}
              render={() => <ReportDetails key={Math.random()} />} // this key is to make the component re-render even if we on the same url
            />
            <Route
              exact //create and edit reports
              path={PATH_NAME.NEW_REPORT_FORM}
              render={() => <NewReportForm />} // should be create
            />
            <Route
              exact
              path={PATH_NAME.USER_PROFILE}
              render={() => <UserProfile />}
            />

            <Route
              exact
              path={PATH_NAME.RELEASE_NOTES}
              render={() => <ReleaseNotes />}
            />

            {isAdmin(currentUser) && (
              <Route exact path={PATH_NAME.SETTINGS} component={Settings} />
            )}
            <Redirect to={PATH_NAME.HOME} />
          </Switch>
        </main>
      </Grid>
    </>
  );
});

export default Home;
