import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    userClass: {
      display: 'flex',
      marginRight: '10px',
      alignItems: 'center',
      flexDirection: 'row'
    },
    avatarWrapper: {
      width: '34px',
      height: '34px',
      marginRight: 8,
      display: 'flex',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#d8d8d8',
      '& .MuiAvatar-circle': {
        width: '100%',
        height: '100%'
      }
    },
    memberName: {
      fontWeight: 600,
      color: '#354052',
      fontSize: '16px',
      letterSpacing: 'normal'
    },
    memberLink: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  })
);
