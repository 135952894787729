import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    logo: { margin: '40px auto 16px', display: 'flex' },

    wrapper: {
      padding: '32px 32px 19px ',
      background: '#FFFFFF',
      border: '1px solid #EDEDED',
      boxShadow: '0px 2px 2px rgba(37, 37, 37, 0.0329983)',
      borderRadius: '10px'
    },
    h1: {
      fontSize: 36,
      margin: ' 16px 0 24px',
      lineHeight: '48px',
      letterSpacing: '0.0075em',
      color: '#354052'
    },
    subTitle: {
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '24px',
      textAlign: 'center',
      letterSpacing: '0.03em',
      color: '#354052',
      marginBottom: 24
    },
    updateDate: {
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '0.03em',
      color: '#858D9B',
      marginBottom: 40
    },
    h2: {
      fontSize: 24,
      lineHeight: '32px',
      display: 'block !important',
      margin: '20px 0',
      fontFamily: 'lato',
      color: '#354052'
    },
    h3: {
      fontSize: 17,
      marginBottom: 10
    },
    bold: {
      fontWeight: 'bold',
      display: 'inline-block',
      color: '#354052'
    },
    lightGray: {
      color: '#858D9B !important'
    },
    linksWrapper: { display: 'flex', flexWrap: 'wrap' },
    linksBlock: {
      height: 97,
      display: 'flex',
      marginRight: 48,
      marginBottom: 12,
      flexDirection: 'column'
    },
    tableContentItem: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.03em',
      textDecorationLine: 'underline',
      color: '#3A78F2'
    },
    answer: {
      marginTop: '10px',
      marginBottom: '10px',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.03em',
      color: '#858D9B',
      fontFamily: 'Lato'
    },

    myListItem: {
      marginLeft: 20,
      display: 'list-item'
    },
    centeredText: {
      display: 'flex !important',
      justifyContent: 'center',
      textAlign: 'center'
    }
  })
);
