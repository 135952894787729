import React, { useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography
} from '@material-ui/core';
import { Question } from '../../../../../models/Question.model';
import ClearIcon from '@material-ui/icons/Clear';
import InputField from '../InputField';
import { styles } from '../styles';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

export const ReportFormQuestions = () => {
  const classes = styles();
  const { control, setValue, formState, watch } = useFormContext();
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: 'questions',
    keyName: 'identifier'
  });

  const watchFieldArray = watch('questions');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });

  useEffect(() => {
    if (fields.length === 0) {
      append(new Question());
    }
    fields.forEach((field, index) => {
      setValue(`questions[${index}].order_appearance`, index + 1);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <div>
      <Typography className={classes.iconRootLabel}>
        <HelpOutlineIcon className={classes.icon} />
        <span className={classes.iconLabel}>Questions</span>
      </Typography>
      <div className={classes.questions}>
        {controlledFields.map((question, index) => {
          return (
            <div key={question.identifier} className={classes.question}>
              <Controller
                name={`questions[${index}].id`}
                control={control}
                defaultValue={question.id}
                render={() => <></>}
              />
              <InputField
                defaultValue={question.content}
                label={`Question ${question.order_appearance}`}
                inputId={`questions[${index}].content`} // name for react-hook-form
                placeholder="Add Question"
                customClass={classes.questionField}
                type="text"
                required
                error={
                  formState.errors['questions'] &&
                  formState.errors['questions'][index]?.content
                }
              />
              <div className={classes.controlsWrapper}>
                {/* optional part */}
                <Controller
                  name={`questions[${index}].optional`}
                  control={control}
                  defaultValue={question.optional}
                  render={({ onChange, value }) => (
                    <FormControlLabel
                      checked={value}
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.questionCheckBox}
                        />
                      }
                      label="Optional"
                      labelPlacement="end"
                      onChange={(e: any) => onChange(e.target.checked)}
                      classes={{
                        label: classes.optionalCheckboxLabel
                      }}
                    />
                  )}
                />

                <Controller
                  name={`questions[${index}].order_appearance`}
                  control={control}
                  defaultValue={question.order_appearance}
                  render={({ onChange, value }) => (
                    <div className={classes.arrowsDiv}>
                      <IconButton
                        aria-label="arrow-up"
                        onClick={() => {
                          swap(index, index - 1);
                        }}
                        disabled={question.order_appearance === 1}
                      >
                        <ArrowUpwardIcon fontSize="small" />
                      </IconButton>

                      <IconButton
                        aria-label="arrow-down"
                        onClick={() => {
                          swap(index, index + 1);
                        }}
                        disabled={question.order_appearance === fields.length}
                      >
                        <ArrowDownwardIcon fontSize="small" />
                      </IconButton>
                    </div>
                  )}
                />

                <IconButton
                  onClick={(e: any) => {
                      remove(index);
                  }}
                  aria-label="delete"
                  className={classes.clearIcon}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          );
        })}
      </div>

      <Typography className={classes.addQuestionDiv}>
        <span
          className={classes.addQuestionTxt}
          onClick={() =>
            append(new Question(-1, '', false, fields.length + 1), true)
          }
        >
          + Add a question
        </span>
      </Typography>
    </div>
  );
};
