import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    groupedResponsesContainer: {
      marginBottom: 8,
      '& > :first-child': {
        marginTop: 8
      }
    },
    groupedResponsesCard: {
      borderRadius: '5px',
      backgroundColor: '#fff',
      border: 'solid 1px #ededed',
      boxShadow: '0 2px 8px 0 rgba(37, 37, 37, 0.03)'
    },
    gridSeparator: {
      paddingTop: 24
    },
    groupedResponsesHeader: {
      borderRadius: '5px',
      boxShadow: 'inset 0 -1px 0 0 #eaeaea'
    },
    rectDate: {
      padding: '0 16px',
      margin: '8px 16px',
      borderRadius: '5px',
      backgroundColor: '#f9fafc'
    },
    rectDateNumber: {
      fontSize: 36,
      opacity: 0.65,
      color: '#383d45',
      lineHeight: 1.33,
      textAlign: 'center',
      letterSpacing: 'normal',
      fontFamily: 'Lato-SemiBold'
    },
    rectDateMon: {
      fontSize: 12,
      marginTop: -8,
      color: '#2c2c2c',
      textAlign: 'center',
      letterSpacing: '0.1px',
      fontFamily: 'Lato-SemiBold'
    },
    responsesStatus: {
      fontSize: 16,
      marginLeft: 8,
      color: '#73777b',
      letterSpacing: 0.13,
      fontFamily: 'Lato-SemiBold'
    },
    responsesNumbers: {
      color: '#474f58',
      fontWeight: 'bold',
      fontFamily: 'Lato'
    },
    expandCard: {
      opacity: '0.5',
      color: '#858d9b'
    }
  })
);
