import React, { memo } from 'react';
import { Checkbox, TextField, Divider } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { styles } from './styles';
import classNames from 'classnames';

export interface SelectAutoCompleteProps {
  options: any[];
  selectId: string;
  onChange: Function;
  placeholder?: string;
  label?: string;
  value?: any;
  multiple?: boolean;
  disabled?: boolean;
  popupIcon?: any;
  customClasses?: any; // used to override or extend the styles applied to the component
  customClass?: any;
  error?: boolean;
  errorMessage?: string;
  getOptionLabel: Function;
  getOptionSelected?: (option: any, value: any) => boolean;
  defaultValue?: any[]; // used with react from hook
  groupBy?: Function
}

export const SelectAutoComplete = memo(
  ({
    options,
    value,
    label,
    selectId,
    placeholder = '',
    multiple = false,
    disabled = false,
    popupIcon,
    customClasses,
    customClass,
    error,
    errorMessage,
    getOptionLabel,
    onChange,
    defaultValue,
    groupBy,
    getOptionSelected = (option: any, value: any) => option === value
  }: SelectAutoCompleteProps) => {
    const classes = styles();
    return (
      <Autocomplete
        classes={customClasses}
        className={customClass}
        multiple={multiple}
        disabled={disabled}
        id={selectId}
        options={options}
        value={value}
        defaultValue={defaultValue}
        disableCloseOnSelect={multiple}
        limitTags={0}
        getLimitTagsText={more => `${more} selected`}
        getOptionLabel={option => getOptionLabel(option)}
        getOptionSelected={getOptionSelected}
        renderTags={value => (
          <span className={classes.selectedSpan}>{value?.length} selected</span>
        )}
        renderOption={(option, { selected }) => (
          <>
            {multiple && (
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
                disabled={disabled}
                color="primary"
              />
            )}
            <span
              className={classNames(
                classes.CheckboxTxt,
                selected ? classes.selectedCheckbox : classes.unSelectedCheckbox
              )}
            >
              {getOptionLabel(option)}
            </span>
            <Divider />
          </>
        )}
        renderInput={params => (
          <TextField
            {...params}
            disabled={disabled}
            className={classes.labelText}
            variant="outlined"
            label={label}
            placeholder={placeholder}
            helperText={error ? errorMessage : undefined}
            error={error}
          />
        )}
        onChange={(event: object, selectedValue: any) => {
          onChange(selectedValue);
        }}
        popupIcon={
          popupIcon || <KeyboardArrowDownIcon className={classes.dropIcon} />
        }
        groupBy={groupBy}
      />
    );
  }
);
