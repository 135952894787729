import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { styles } from './styles';

interface Props {
  children: any;
  isLoading: boolean;
}

export default function CircularLoader(props: Props): any {
  const classes = styles();
  if (props.isLoading)
    return (
      <div className={classes.loaderWrapper}>
        <CircularProgress />
      </div>
    );
  return props.children;
}
