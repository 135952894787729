import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    logoutDialog: {
      minWidth: 474,
      display: 'flex',
      borderRadius: '5px',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: '#fff',
      justifyContent: 'center',
      padding: '24px 33px 32px 33px'
    },
    logoutDialogHeader: {
      width: '100%',
      display: 'flex',
      marginBottom: '44px',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    closeIcon: {
      width: 16,
      height: 16,
      opacity: 0.7,
      objectFit: 'contain',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    logoutTitle: {
      fontSize: 24,
      color: '#858d9b',
      margin: '0 auto',
      fontFamily: 'Lato',
      fontWeight: 'bold',
      lineHeight: '1.13',
      textAlign: 'center',
      letterSpacing: 'normal'
    },
    dialogText: {
      padding: 0
    },
    logoutText: {
      height: 30,
      color: '#354052',
      fontSize: '16px',
      fontFamily: 'Lato',
      textAlign: 'center',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontStretch: 'normal',
      letterSpacing: 'normal'
    },
    dialogBtns: {
      padding: '16px 0 0 0'
    },
    closeLogoutBtn: {
      minWidth: 118,
      height: 40,
      borderRadius: 6,
      padding: '12px 24px',
      backgroundColor: '#f5a623 !important'
    },
    logoutBtn: {
      minWidth: 104,
      height: 40,
      borderRadius: 6,
      marginRight: '8px',
      backgroundColor: '#fff',
      border: '2px solid #f5a623'
    },
    logoutBtnsTxt: {
      minWidth: 70,
      height: 16,
      lineHeight: 1.14,
      fontFamily: 'Lato',
      textAlign: 'center',
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      textTransform: 'capitalize'
    },
    closeLogoutTxt: {
      fontSize: 14,
      color: '#fff'
    },
    logoutTxt: {
      fontSize: 18,
      color: '#f5a623',
      fontWeight: 'bold'
    },
    backDrop: {
      backgroundColor: '#000',
      opacity: '0.7 !important'
    }
  })
);
