import React, { useContext, useState } from 'react';
import { useWorkspaceDetails, useIncludedReports } from '../workspaceHooks';
import { workspaceDetailsStyles } from './workspaceDetailsStyles';
import { updateCashedReportList } from '../../Reports/ReportCard/ReportCardHooks';
import { CustomizedPagination } from '../../../shared-components/CustomizedPagination';
import { WorkspaceUsersList } from './WorkspaceUsersList';
import { ReportDetailsCard } from './ReportDetailsCard';
import { EmptyCreateReport } from '../../../shared-components/EmptyStates/EmptyCreateReport';
import { EmptyStateHandler } from '../../../shared-components/EmptyStates/EmptyStateHandler';
import { EmptySmallIcon } from '../../../shared-components/EmptyStates/EmptySmallIcon';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { QUERIES } from '../../../../utils/queris';
import { Report } from '../../../../models/Report.model';
import CircularLoader from '../../../shared-components/CircularLoader';
import { AppContext } from '../../../../App';
import { User } from '../../../../models/User.model';

interface Params {
  id?: string;
}

export const WorkspaceDetails = () => {
  const classes = workspaceDetailsStyles();
  const queryClient = useQueryClient();
  const { id: workspaceId }: Params = useParams();
  const [page, setPage] = useState(1);
  const { data: workspace, isLoading: isWorkspaceLoading } =
    useWorkspaceDetails(Number(workspaceId));
  const currentUser: User = useContext(AppContext);
  const { data, isLoading: isReportsLoading } = useIncludedReports(
    Number(workspaceId),
    page
  );

  const updateCashedReport = async (updatedReport: Report) => {
    updateCashedReportList(
      queryClient,
      [QUERIES.LOAD_WORKSPACE_REPORTS, Number(workspaceId), page],
      updatedReport
    );
  };

  return (
    <CircularLoader isLoading={isWorkspaceLoading || isReportsLoading}>
      <div className={classes.relatedReportsWrapper}>
        <div className={classes.relatedReportsLeftSide}>
          <div className={classes.head}>
            <p className={classes.workspaceTitle}>
              Workspace /{' '}
              <span className={classes.workspaceTitleText}>
                {workspace?.name}
              </span>
            </p>
            <h2 className={classes.listTitle}>Related Reports</h2>
          </div>
          <div className={classes.scrollableLeftSide}>
            <EmptyStateHandler
              loading={isWorkspaceLoading || isReportsLoading}
              data={data?.reports!}
              emptyComponent={
                workspace?.creator_id === currentUser?.id ? (
                  <div className={classes.relatedReportsEmptyState}>
                    <EmptyCreateReport />
                  </div>
                ) : (
                  <EmptySmallIcon
                    mainPrompt="No Reports"
                    subPrompt="No Reports Available in this workspace"
                  />
                )
              }
            >
              {data?.reports.map(report => (
                <ReportDetailsCard
                  report={report}
                  key={report.id}
                  workspace={workspace!}
                  updateCashedReport={updateCashedReport}
                />
              ))}
            </EmptyStateHandler>
          </div>
          <CustomizedPagination data={data} page={page} setPage={setPage} />
        </div>
        <div className={classes.relatedReportsRightSide}>
          <WorkspaceUsersList members={workspace?.members!} />
        </div>
      </div>
    </CircularLoader>
  );
};
