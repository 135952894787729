import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import NavBar from './NavBar';

import styles from './styles';

interface Props {
  showSideDrawer: boolean;
  handleSideDrawer: () => void;
}

function SideDrawerNav(props: Props) {
  const classes = styles();
  const { showSideDrawer, handleSideDrawer } = props;
  return (
    <div className={classes.sideDrawer}>
      <Drawer
        open={showSideDrawer}
        variant="temporary"
        anchor="left"
        onClick={handleSideDrawer}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <div>
          <NavBar isSideDrawer={true} />
        </div>
      </Drawer>
    </div>
  );
}

export default SideDrawerNav;
