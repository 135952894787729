import React from 'react';
import { Member } from '../models/Member.model';
import { Link as RLink } from 'react-router-dom';
import { convertToRaw, EditorState } from 'draft-js';
import { EDITOR_STYLES, INLINE_STYLES } from './constants';
import { getUserProfileURL } from './routes';
import parse from 'html-react-parser';

export const prepareContentToRender = (content: string, members: Member[]) => {
  if (content) {
    content = content.replace(/<@([^@.]*)>/g, (str: any, pattern: any) => {
      const mentionedUser = members.filter(
        member => member.provided_id == pattern
      );
      return `<span id='replace' name='${mentionedUser[0].name}' member=${mentionedUser[0].id} > @${mentionedUser[0].name} </span>`;
    });
    INLINE_STYLES.forEach(element => {
      content = content.replace(element.pattern, element.replacedEntity);
    });
  }
  return content;
};

export const parseEditorState = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const raw = convertToRaw(contentState);
  let parsedContent = '';
  let orderListIndex = 1;
  raw.blocks.forEach((block, index) => {
    const entityRanges: any[] = block.entityRanges;
    const inlineStylesRanges = block.inlineStyleRanges;
    // combine the ranges for mention and style entities
    let ranges = entityRanges.concat(inlineStylesRanges);
    ranges.sort((a, b) =>
      a.offset < b.offset ? 1 : a.offset > b.offset ? -1 : 0
    );
    let parsedLine = block.text;
    let listItemOffset = 0;
    if (block.type === EDITOR_STYLES.OrderList) {
      parsedLine = `${orderListIndex}. ${parsedLine}`;
      orderListIndex++;
      listItemOffset = 3;
    } else {
      orderListIndex = 1;
    }
    if (block.type === EDITOR_STYLES.UnorderList) {
      parsedLine = '• ' + parsedLine;
      listItemOffset = 2;
    }
    // loop on entities and replace each one with appropriate substitute
    ranges.forEach(entity => {
      const start = entity.offset + listItemOffset;
      const end = entity.offset + entity.length + listItemOffset;
      const offset = entity.offset + listItemOffset;
      let substitute = '';
      // mention entity
      if ('key' in entity) {
        const mentionedUserId =
          raw.entityMap[entity.key].data.mention.provided_id;
        substitute = `<@${mentionedUserId}>`;
        // style entity
      } else {
        switch (entity.style) {
          case EDITOR_STYLES.Bold:
            substitute = `*${parsedLine.substring(offset, end)}*`;
            break;
          case EDITOR_STYLES.Italic:
            substitute = `_${parsedLine.substring(offset, end)}_`;
            break;
          case EDITOR_STYLES.Code:
            substitute = `\`${parsedLine.substring(offset, end)}\``;
            break;
          case EDITOR_STYLES.Strikethrough:
            substitute = `~${parsedLine.substring(offset, end)}~`;
        }
      }
      parsedLine =
        parsedLine.substring(0, start) + substitute + parsedLine.substring(end);
    });

    // if this the last line don't add new line character
    if (index === raw.blocks.length - 1) {
      parsedContent += `${parsedLine}`;
    } else {
      parsedContent += `${parsedLine} \n`;
    }
  });
  return parsedContent;
};

export const extractContent = (text: string) => {
  return new DOMParser().parseFromString(text, 'text/html').documentElement
    .textContent;
};

export const ParsingOptions: any = {
  replace: ({ attribs, children }: any) => {
    if (!attribs) {
      return;
    }
    if (attribs.id === 'replace') {
      return (
        <RLink
          to={getUserProfileURL(attribs.member)}
          style={{
            fontFamily: 'Lato',
            fontSize: '16px',
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#636e79',
            textDecoration: 'none',
            opacity: 0.8
          }}
        >
          @{attribs.name}
        </RLink>
      );
    }
    if (attribs.id === 'code') {
      return (
        <span
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
            fontSize: 16,
            padding: 2,
            color: '#ec4242'
          }}
        >
          {attribs.content}
        </span>
      );
    }
  }
};

export const answerType = (parsedAnswer: string, classes: any) => {
  if (isEmail(parsedAnswer)) {
    return parse(
      `<a className=${classes.answer} href= mailto:${parsedAnswer} target="_blank">${parsedAnswer}</a>&nbsp;`,
      ParsingOptions
    );
  } else if (isUrl(parsedAnswer)) {
    const httpsUrl = geturlWithHttpsProtocol(parsedAnswer);
    return parse(
      `<a className=${classes.answer} href=${httpsUrl} target="_blank">${parsedAnswer}</a>&nbsp;`,
      ParsingOptions
    );
  }
  return parsedAnswer.split('\n')?.map((line, ind) => (
    <p key={ind} className={classes.answer}>
      {parse(line, ParsingOptions)}
    </p>
  ));
};

export const answerSplitterType = (
  parsedAnswer: string,
  classes: any
): any[] => {
  let answerElementsScript: any = [];
  const lines: string[] = parsedAnswer.split('\n');
  lines.forEach(line => {
    answerElementsScript = answerElementsScript.concat(
      answerLineSplitterType(line, classes)
    );
  });
  return answerElementsScript;
};

export const answerLineSplitterType = (parsedAnswer: string, classes: any) => {
  const splittedAnswers: string[] = parsedAnswer.split(' ');
  let text: string = '';
  let answerElements: any = [];

  splittedAnswers.forEach(splittedAnswer => {
    if (isUrl(splittedAnswer) || isEmail(splittedAnswer)) {
      if (isEmail(splittedAnswer)) splittedAnswer = getEmail(splittedAnswer);
      else if (splittedAnswer.charAt(0) === '<'){
        splittedAnswer = splittedAnswer.substring(1, splittedAnswer.length - 1);
        if (splittedAnswer.split('|')[1])
          splittedAnswer = splittedAnswer.split('|')[1];
      }
      if(text !== '') answerElements.push(answerType(text, classes));
      answerElements.push(answerType(splittedAnswer, classes));
      text = '';
    } else if (text === '') {
      text = text.concat(splittedAnswer);
    } else {
      text = text.concat(' ', splittedAnswer);
    }
  });

  if (text != '') {
    answerElements.push(answerType(text, classes));
  }
  answerElements.push(parse("<br>", ParsingOptions))
  return answerElements;
};

export const getUrl = (word: string): string => {
  const urlRegex: RegExp = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  const matchedUrls: string[] = word.match(urlRegex) as string[];
  return matchedUrls ? matchedUrls[0] : '';
};

export const isUrl = (word: string): boolean => {
  return getUrl(word) === '' && !isDomainUrl(word) ? false : true;
};

export const getDomainUrl = (word: string): string => {
  const domainRegex: RegExp =
    /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
  const matchedDomains: string[] = word.match(domainRegex) as string[];
  return matchedDomains && matchedDomains.length == 1 ? matchedDomains[0] : '';
};

export const isDomainUrl = (word: string): boolean => {
  return getDomainUrl(word) === '' ? false : true;
};

export const getEmail = (word: string): string => {
  const emailRegex: RegExp =
    /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
  const matchedEmails: string[] = word.match(emailRegex) as string[];
  return matchedEmails ? matchedEmails[0] : '';
};

export const isEmail = (word: string): boolean => {
  return getEmail(word) === '' ? false : true;
};

export const isHttpOrHttpsProtocol = (word: string): boolean => {
  return word.indexOf('http') === -1 ? false : true;
};

export const geturlWithHttpsProtocol = (word: string): string => {
  return isHttpOrHttpsProtocol(word) ? word : 'https://' + word;
};
