export const QUERIES = Object.freeze({
  // AUTH
  LOAD_AUTH_URLS: 'LOAD_AUTH_URLS',
  AUTHORIZE_SLACK: 'AUTHORIZE_SLACK',
  // Activities
  LOAD_ACTIVITIES: 'LOAD_ACTIVITIES',
  // Statistics
  LOAD_STATISTICS: 'LOAD_STATISTICS',
  // Reports
  LOAD_REPORTS: 'LOAD_REPORTS',
  LOAD_REPORT: 'LOAD_REPORT',
  LOAD_REPORT_RESPONSES: 'LOAD_REPORT_RESPONSES',
  // Pinned reports
  LOAD_PINNED_REPORTS: 'LOAD_PINNED_REPORTS',
  LOAD_SUBMISSION_CYCLES: 'LOAD_SUBMISSION_CYCLES',
  // Channels
  LOAD_CHANNELS: 'LOAD_CHANNELS',
  // channels members
  LOAD_CHANNEL_MEMBERS: 'LOAD_CHANNEL_MEMBERS',
  // TIMEZONES
  LOAD_TIMEZONES: 'LOAD_TIMEZONES',
  // Workspaces
  LOAD_WORKSPACES: 'LOAD_WORKSPACES',
  LOAD_MINIMAL_WORKSPACES: 'LOAD_MINIMAL_WORKSPACES',
  // Members
  LOAD_MEMBERS: 'LOAD_MEMBERS',
  LOAD_MEMBER_RESPONSES: 'LOAD_MEMBER_RESPONSES',
  // Member assigned reports
  LOAD_ASSIGNED_REPORTS: 'LOAD_ASSIGNED_REPORTS',
  // Member pending reports
  LOAD_PENDING_REPORTS: 'LOAD_PENDING_REPORTS',
  // Current user 
  LOAD_CURRENT_USER: 'LOAD_CURRENT_USER',
  // Assigned Reports
  LOAD_Assigned_REPORTS: 'LOAD_Assigned_REPORTS',
  // Member 
  LOAD_MEMBER: 'LOAD_MEMBER',
  LOAD_POLL_OPTIONS: 'LOAD_POLL_OPTIONS',
  LOAD_WORKSPACE_REPORTS: 'LOAD_WORKSPACE_REPORTS',
  LOAD_COMMENTS: 'LOAD_COMMENTS',
  LOAD_WORKSPACE: 'LOAD_WORKSPACE',
  LOAD_NOTIFICATIONS: 'LOAD_NOTIFICATIONS',
  // Tickets
  LOAD_TICKETS: 'LOAD_TICKETS',
  // Boards
  LOAD_BOARDS: 'LOAD_BOARDS',
  // Release notes
  LOAD_RELEASE_NOTES: 'LOAD_RELEASE_NOTES',
  // What's new
  LOAD_COMPONENTS: 'LOAD_COMPONENTS',
  // Liked members 
  LOAD_LIKED_MEMBERS: 'LOAD_LIKED_MEMBERS',
  // load team
  LOAD_TEAM: 'LOAD_TEAM',
  LOAD_TEAM_SUBSCRIPTION: "LOAD_TEAM_SUBSCRIPTION"
});
