import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import { CustomizedEditor } from '../../../../../shared-components/CustomizedEditor';
import { attachmentsStyles } from './styles';
import { EditorState } from 'draft-js';
import { parseEditorState } from '../../../../../../utils/parser';
import { commentRequest } from '../../../../../../models/Comment.model';
import { useAddComment } from './ResponseAttachmentsHooks';
import { Member } from '../../../../../../models/Member.model';
import classNames from 'classnames';
import { LikeButton } from './LikeButton';

interface IProps {
  responseId: number;
  reportId: number;
  members: Member[];
}
export const CommentEditor = ({ responseId, reportId, members }: IProps) => {
  const classes = attachmentsStyles();
  const addCommentMutation = useAddComment();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleSubmit = () => {
    const parsedComment = parseEditorState(editorState);
    const request: commentRequest = {
      body: parsedComment,
      report_record_id: responseId,
      report_id: reportId
    };
    setEditorState(() => EditorState.createEmpty());
    addCommentMutation.mutate(request);
  };
  return (
    <Grid item  className={classes.commentEditor}>
      <Grid >
      <ModeCommentIcon className={classes.commentEditorIcon} />
      </Grid>
      <Grid item xs>
        <CustomizedEditor
          setEditorState={setEditorState}
          editorState={editorState}
          members={members!}
          placeholder="Add a comment"
          editorStyleClass={classes.editor}
          editMode={false}
          submitEdit={undefined}
          cancelEdit={undefined}
        />
        {editorState.getCurrentContent().getPlainText().length > 0 && (
          <span
            className={classNames(classes.submitBtn, classes.submitBtnAbsolute)}
            onClick={handleSubmit}
          >
            Add Comment
          </span>
        )}
      </Grid>
    </Grid>
  );
};
