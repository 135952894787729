import { createStyles, makeStyles } from '@material-ui/core';
const styles = makeStyles(() =>
  createStyles({
    pageFooterWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '123px',
      padding: '25px 119px 25px 109px',
      backgroundColor: '#f3f3f3',
      '@media only screen and (max-width: 800px)': {
        padding: '25px !important'
      }
    },
    copyRightsText: {
      margin: '0 0 0 10px',
      fontFamily: 'Lato',
      fontSize: '12px',
      color: '#55647d'
    },
    footerBscrumLogoWrapper: {
      width: '108px',
      height: '45px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '15px'
    },
    bscrumLogo: {
      height: '100%',
      margin: '5px'
    },
    bscrumLogoText: {
      marginLeft: '2px',
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#2a5cbf'
    }
  })
);
export default styles;
