import { API } from './API';
import axios from '../services/axios';
import store from '../store';
export class Components extends API {
  public id: number = -1;
  public name: string = '';
  public is_visible: boolean = false;

  public static async getAll(): Promise<Components[]> {
    const headers = Components.getConfig();

    if (headers) {
      return new Promise<Components[]>((resolve, reject) => {
        axios
          .get('dashboard/components', headers)
          .then(res => {
            const components: Components[] = res.data.components as Components[];
            resolve(components);
          })
          .catch(e => {
            Components.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }

}