import React from 'react' ;
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { styles } from '../styles';

interface IProps {
    handleClose: any ;
    err: any ;
    open: any ;
};

export const PopupErrorMessage = ({handleClose , err , open}:IProps)=>{
    const classes = styles();
    return (
        <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={() => handleClose(err)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        className={classes.messageItem}
      >
        <MuiAlert
          onClose={() => handleClose(err)}
          severity="error"
          classes={{
            filledError: classes.messageRoot,
            message: classes.messageTxt,
            action: classes.closeMessage
          }}
          elevation={6} 
          variant="filled"
        >
          {err.presenterMsg}
        </MuiAlert>
      </Snackbar>
    )
}



