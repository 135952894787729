import * as React from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import { styles } from './styles';
import { ReportType } from '../../../../models/ReportType.model';
import { useHistory } from 'react-router';
import { getCreateReportURL } from '../../../../utils/routes';
import { REPORT_TYPES } from '../../../../utils/constants';
import { ReactComponent as CloseIcon } from '../../../../assets/images/ic-close.svg';
import { ReactComponent as StandupIcon } from '../../../../assets/images/standup_type.svg';
import { ReactComponent as RetrospectiveIcon } from '../../../../assets/images/retro_type.svg';
import { ReactComponent as CustomIcon } from '../../../../assets/images/custom_type.svg';
import { ReactComponent as PollIcon } from '../../../../assets/images/poll_type.svg';
import { useEventAnalytics } from '../ReportForm/reportFormHooks';

interface ReportTypeDialogProps {
  isOpen: boolean;
  closeReportTypeDialog: Function;
}

export const getReportTypeIcon = (id: number, className: string) => {
  switch (id) {
    case 1:
      return <StandupIcon className={className} />;
    case 2:
      return <RetrospectiveIcon className={className} />;
    case 3:
      return <CustomIcon className={className} />;
    case 4:
      return <PollIcon className={className} />;
    default:
      return <CloseIcon className={className} />;
  }
};

export default function ReportTypeDialog({
  isOpen,
  closeReportTypeDialog
}: ReportTypeDialogProps) {
  const history = useHistory();
  const classes = styles();
  const sendAnalyticsEvent = useEventAnalytics();

  const handleClose = () => {
    closeReportTypeDialog();
  };

  const clickedReport = (reportType: ReportType) => {
    closeReportTypeDialog();
    sendAnalyticsEvent.mutate({
      eventName: 'CLICK_NEW_REPORT_BUTTON',
      eventTimeTaken: '0'
    });
    history.push(getCreateReportURL(reportType));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={false}
    >
      <div className={classes.reportTypeDialogTitle}>
        <Typography className={classes.reportTypeDialogTitleText}>
          Select Report Type
        </Typography>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </div>
      <DialogContent className={classes.reportDialogContent}>
        <Grid
          container
          justify="center"
          className={classes.reportTypeDialogContent}
        >
          {REPORT_TYPES.map((report: ReportType) => {
            if (report.title === REPORT_TYPES[4].title) return;
            return (
              <Grid
                item
                xs
                key={report.id}
                onClick={() => clickedReport(report)}
              >
                <Card className={classes.reportTypeCard}>
                  <CardContent>
                    {getReportTypeIcon(report.id, classes.reportTypeImg)}
                    <Typography className={classes.reportTypeTitle}>
                      {report.title}
                    </Typography>
                    <Typography className={classes.reportTypeDescription}>
                      {report.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
