import React, { useState, useCallback } from 'react';
import { Paper, withStyles } from '@material-ui/core';
import ReportsHeader from './ReportsHeader';
import ReportCard from './ReportCard';
import { Report, ReportFilters } from '../../../models/Report.model';
import { styles } from './styles';
import CircularLoader from '../../shared-components/CircularLoader';
import { EmptyCreateReport } from '../../shared-components/EmptyStates/EmptyCreateReport';
import { useQuery, useQueryClient } from 'react-query';
import { EmptySmallIcon } from '../../shared-components/EmptyStates/EmptySmallIcon';
import { QUERIES } from '../../../utils/queris';
import { updateCashedReportList } from './ReportCard/ReportCardHooks';
import { CustomizedPagination } from '../../shared-components/CustomizedPagination';
import { useRepots, useWorkspaces } from './ReportsHooks';

interface Props {
  classes?: any;
}

const isFiltering = (filters: ReportFilters): boolean => {
  return (
    filters.workspaces?.length !== 0 ||
    filters.status?.length !== 0 ||
    filters.types?.length !== 0 ||
    !!filters.title
  );
};

const Reports = ({ classes }: Props) => {
  const [reportFilters, setReportFilters] = useState({
    status: [] as number[],
    types: [] as number[],
    workspaces: [] as number[],
    title: ''
  } as ReportFilters);
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();

  const { data, isLoading } = useRepots(page,reportFilters);;

  const { data: workspacesResponse } = useWorkspaces();

  const setReportTypesFilter = useCallback(
    (types: number[]) => {
      setReportFilters({
        ...reportFilters,
        types
      });
    },
    [reportFilters]
  );

  const setWorkspaceFilter = useCallback(
    (workspaces: number[]) => {
      setReportFilters({
        ...reportFilters,
        workspaces
      });
    },
    [reportFilters]
  );

  const setStatusFilter = useCallback(
    (status: number[]) => {
      setReportFilters({
        ...reportFilters,
        status
      });
    },
    [reportFilters]
  );

  const setTitleFilter = useCallback(
    (title: string | undefined) => {
      setReportFilters({
        ...reportFilters,
        title
      });
    },
    [reportFilters]
  );

  if (!isLoading && !isFiltering(reportFilters) && !data?.reports.length) {
    return <EmptyCreateReport />;
  }

  const updateCashedReport = async (updatedReport: Report) => {
    updateCashedReportList(
      queryClient,
      [QUERIES.LOAD_REPORTS, page, reportFilters],
      updatedReport
    );
  };

  return (
    <Paper className={classes.root} square elevation={0}>
      <ReportsHeader
        workspaces={workspacesResponse?.workspaces || []}
        reportFilters={reportFilters}
        setReportTypesFilter={setReportTypesFilter}
        setWorkspaceFilter={setWorkspaceFilter}
        setStatusFilter={setStatusFilter}
        setTitleFilter={setTitleFilter}
        setPage={setPage}
      />
      {!isLoading && <div className={classes.divSeparator} />}
      <CircularLoader isLoading={isLoading}>
        {data?.reports.length !== 0 ? (
          data?.reports.map(report => {
            return (
              <ReportCard
                key={report.id + '-' + report.type?.title}
                report={report}
                viewDetails={true}
                updateCashedReport={updateCashedReport}
              />
            );
          })
        ) : (
          <EmptySmallIcon
            mainPrompt="No related reports"
            subPrompt={`There are no reports to display related to search Keyword. Try to
                          search with another keywords.`}
          />
        )}

        <CustomizedPagination data={data} page={page} setPage={setPage} />
      </CircularLoader>
    </Paper>
  );
};

export default withStyles(styles)(Reports);
