import React from 'react';
import { EditorState } from 'draft-js';
import { INLINE_STYLES, BLOCK_TYPES } from '../../../../utils/constants';
import { customEditorStyles } from '../customEditorStyles';
import { StyleButton } from './StyleButton';

interface CustomToolBarProps {
  editorState: EditorState;
  onBlockToggle: any;
  onInlineToggle: any;
}

export const CustomToolBar = ({
  editorState,
  onBlockToggle,
  onInlineToggle
}: CustomToolBarProps) => {
  const classes = customEditorStyles();
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className={classes.editorControls}>
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onInlineToggle}
          style={type.style}
          shortcut = {type.shortcut}
        />
      ))}
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onBlockToggle}
          style={type.style}
          shortcut = {type.shortcut}
        />
      ))}
    </div>
  );
};

// Custom overrides for "code" style.
export const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
    color: '#ec4242'
  },
  STRIKETHROUGH: {
    textDecoration: 'line-through'
  }
};
