import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    oval: {
      width: 36,
      height: 36,
      marginRight: 8,
      borderRadius: '50%',
      backgroundColor: '#d8d8d8'
    },
    memberName: {
      fontSize: 16,
      color: '#354052',
      letterSpacing: 'normal',
      fontFamily: 'Lato-SemiBold'
    },
    container: {
      maxWidth: 270,
      display: 'flex',
      padding: '4px 8px',
      borderRadius: 23.5,
      alignItems: 'center',
      backgroundColor: 'rgba(194, 215, 255, 0.2)'
    },
    clearIcon: {
      width: 12,
      height: 12,
      margin: '0 8px 0 auto',
      color: 'rgba(58, 120, 242, 0.7)',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    membersList: {
      gridGap: 8,
      display: 'grid',
      margin: '16px 0 24px 0',
      gridTemplateColumns: 'repeat(3, 1fr)',
      '@media (max-width:820px)': {
        gridTemplateColumns: 'repeat(2, 1fr)'
      },
      '@media (max-width:520px)': {
        gridTemplateColumns: '1fr'
      }
    }
  })
);
