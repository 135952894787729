import React from 'react';
import { useParams } from 'react-router';
import Grid from '@material-ui/core/Grid';
import ProfileCard from '../ProfileCard';
import UserResponses from '../UserResponses';
import { useMember, useStatistics } from '../UserHooks';
import CircularLoader from '../../../shared-components/CircularLoader';
import UserWorkspaces from '../UserWorkspaces';
import { userProfileStyles } from './styles';

interface Params {
  id: string;
}

export default function UserProfile() {
  const classes = userProfileStyles();
  const { id }: Params = useParams() as Params;

  const { isLoading: isMemberLoading, data: member } = useMember(id);

  const { isLoading: isLoadingStatistics, data: statisticList } = useStatistics(
    id
  );

  const isLoading = isMemberLoading || isLoadingStatistics;

  return (
    <CircularLoader isLoading={isLoading}>
      <Grid container direction="column" justify="space-between">
        <Grid item>
          <ProfileCard member={member!} statistics={statisticList || []} />
        </Grid>

        <Grid
          item
          container
          justify="space-evenly"
          direction="row"
          spacing={1}
          className={classes.mainContent}
        >
          <Grid item xs={12} sm={12} lg={5}>
            <UserWorkspaces userID={id} />
          </Grid>
          <Grid item xs={12} sm={12} lg={7}>
            <UserResponses id={id} />
          </Grid>
        </Grid>
      </Grid>
    </CircularLoader>
  );
}
