import { Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Workspace } from '../../../models/Workspace.model';
import { subMenuStyles } from './subMenuStyles';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import { Report } from '../../../models/Report.model';
import DeleteDialog from '../../components/Dialogs/DeleteDialog';
import {
  useDeleteReport,
  useJoinReport,
  useLeaveReport,
  usePauseReport,
  useResumeReport
} from '../../components/Reports/ReportCard/ReportCardHooks';
import { useHistory } from 'react-router';
import { getEditReportURL } from '../../../utils/routes';
import classNames from 'classnames';
import { canEditReport } from '../../../utils/policies';
import { AppContext } from '../../../App';
import { User } from '../../../models/User.model';

interface ReportSubMenuProps {
  report: Report;
  workspace: Workspace;
  updateCashedReport: Function;
  redirectAfterDelete: boolean;
}

export const ReportSubMenu = ({
  report,
  workspace,
  updateCashedReport,
  redirectAfterDelete
}: ReportSubMenuProps) => {
  const classes = subMenuStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const currentUser: User = useContext(AppContext);
  const joinReport = useJoinReport(report);
  const leaveReport = useLeaveReport(report);
  const pauseReport = usePauseReport(report, updateCashedReport);
  const resumeReport = useResumeReport(report, updateCashedReport);
  const deleteReport = useDeleteReport(report, redirectAfterDelete);
  const history = useHistory();
  const url = history.location.pathname;
  const isWorkspaceDetails =
    url.includes('/workspaces') && url.substring(url.lastIndexOf('/') + 1);
  const subMenuPaperClass = `${classes.subMenuPaper} ${
    isWorkspaceDetails && classes.altSubMenuPaper
  }`;
  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteReport = useCallback(
    e => {
      e.stopPropagation();
      toggleDeleteDialog(e);
      deleteReport.mutate(report.id);
      handleClose(e);
    },
    [report]
  );

  const toggleDeleteDialog = (e: any) => {
    e.stopPropagation();
    setOpenDeleteDialog(openDeleteDialog => !openDeleteDialog);
    handleClose(e);
  };

  const handleEditReport = useCallback(
    e => {
      e.stopPropagation();
      history.push(getEditReportURL(report.id));
    },
    [history, report]
  );

  const isParticipated = useMemo(() => {
    return report.participants.some(member => {
      if (currentUser?.id == member.id) {
        return true;
      }
      return false;
    });
  }, [report, currentUser]);

  const handleJoin = (event: any) => {
    event.stopPropagation();
    joinReport.mutate(report.id);
    handleClose(event);
  };

  const handleLeave = (event: any) => {
    event.stopPropagation();

    leaveReport.mutate(report.id);
    handleClose(event);
  };

  const handleActivate = (event: any) => {
    event.stopPropagation();

    resumeReport.mutate(report.id);
    handleClose(event);
  };

  const handleDeactivate = (event: any) => {
    event.stopPropagation();

    pauseReport.mutate(report.id);
    handleClose(event);
  };

  return (
    <>
      <Typography id={`report-${report.id}-operations`} className={classes.moreIcon} onClick={handleClick}>
        <Tooltip arrow title="Open Settings Menu">
          <MoreVertOutlinedIcon fontSize="large" />
        </Tooltip>
      </Typography>
      <Menu
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        classes={{ paper: subMenuPaperClass }}
      >
        {isParticipated ? (
          <MenuItem
            className={classes.actionMenuItemImportant}
            onClick={handleLeave}
          >
            Leave Report
          </MenuItem>
        ) : (
          <MenuItem
            className={classNames(
              classes.actionMenuItemImportant,
              classes.actionMenuItemImportantGreen
            )}
            onClick={handleJoin}
          >
            Join Report
          </MenuItem>
        )}
        {currentUser && canEditReport(currentUser, report) && (
          <>
            <MenuItem
              className={classes.actionMenuItem}
              onClick={handleEditReport}
            >
              Edit
            </MenuItem>
            {report.active ? (
              <MenuItem
                className={classes.actionMenuItem}
                onClick={handleDeactivate}
              >
                Deactivate
              </MenuItem>
            ) : (
              <MenuItem
                className={classes.actionMenuItem}
                onClick={handleActivate}
              >
                Activate
              </MenuItem>
            )}
            <MenuItem
              className={classes.actionMenuItemImportant}
              onClick={toggleDeleteDialog}
            >
              Delete
            </MenuItem>
            <DeleteDialog
              contentText={
                <span>
                  Are you sure that you want to delete{' '}
                  <strong>{report.name}</strong>?{' '}
                </span>
              }
              openDialog={openDeleteDialog}
              handleDialogClose={toggleDeleteDialog}
              handleDialogDelete={handleDeleteReport}
            />
          </>
        )}
      </Menu>
    </>
  );
};
