import React from 'react';
import { withStyles, AppBar, Toolbar } from '@material-ui/core';
import { headerStyles } from './styles';
import NewReportButton from '../../Reports/NewReportButton';
import clsx from 'clsx';
import Notifications from './Notifications';
import WhatsNew from "./WhatsNew"
import { useComponents } from './whatsNewHooks';
import { componentIsVisible } from '../../../../utils/helpers';
import { COMPONENTS } from "../../../../utils/constants";
import { QuickFindSearch } from './QuickFindSearch';

interface HeaderProps {
  classes?: any;
  handleDrawer?: any;
  sidebarOpen?: boolean;
}

const Header = ({ classes, handleDrawer, sidebarOpen }: HeaderProps) => {

  const { 
    data: components,
  } = useComponents();

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, { [classes.appBarShift]: sidebarOpen })}
    >
      <Toolbar className={classes.toolbar}>
        <QuickFindSearch/>
        <div className={classes.notificationSection}>
          {
            componentIsVisible(COMPONENTS.WHATS_NEW_BUTTON, components || []) ?
              <WhatsNew/>
            :
              <></>
          }
          <NewReportButton />
          <Notifications />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(headerStyles)(Header);
