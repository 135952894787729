import { API } from './API';
import axios from '../services/axios';
import { ReportType } from './ReportType.model';
import { REPORTTYPES } from '../utils/constants';
import store from '../store';

export type submitPollRequest = {
  reportId: number , 
  reportType: ReportType ,
  optionId: number,
};

export class Option extends API {
  public id: number = -1;
  public content: string = '';
  public votes: number = -1;
  public _destroy: boolean = false;

  public static getListByReportId(reportId: number): Promise<Option[]> {
    const headers = Option.getConfig();

    if (headers) {
      return new Promise<Option[]>((resolve, reject) => {
        axios
          .get('dashboard/reports/get_options?id=' + reportId, headers)
          .then(res => {
            resolve(res.data.options as Option[]);
          })
          .catch(e => {
            Option.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }

    return Promise.reject('System Error');
  }

  public static submit(
    request: submitPollRequest
   ): Promise<void> {
      const headers = Option.getConfig(); 

      if(headers){
        let reportTypeUrl = REPORTTYPES[request.reportType.title].recordsUrl;
        return new Promise<void>((resolve,reject)=>{
          let url = `dashboard/${reportTypeUrl}/create?id=${request.reportId}` ;
          axios 
            .post(url , {optionId:request.optionId} ,headers )
            .then(()=> resolve())
            .catch( err => {
              Option.checkUnauthorizedRequest(err,store);
              reject(err);
            });
        })
      }
      return Promise.reject("Can't submit your response");
  }
}
