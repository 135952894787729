import { API } from './API';
import axios from '../services/axios';
import store from '../store';
export class Statistic extends API {
  public id: number = -1;
  public name: string = '';
  public count: number = -1;

  public static getAll(): Promise<Statistic[]> {
    const headers = Statistic.getConfig();

    if (headers) {
      return new Promise<Statistic[]>((resolve, reject) => {
        axios
          .get('dashboard/utilities/statistics', headers)
          .then(res => {
            resolve(res.data as Statistic[]);
          })
          .catch(e => {
            Statistic.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }

    return Promise.reject('System Error');
  }

  public static getMemberStatistics(memberId:string): Promise<Statistic[]> {
    const headers = Statistic.getConfig();
    if (headers) {
      return new Promise<Statistic[]>((resolve, reject) => {
        axios
          .get(`dashboard/members/statistics/${memberId}`, headers)
          .then(res => {
            resolve(res.data as Statistic[]);
          })
          .catch(e => {
            Statistic.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }

    return Promise.reject('System Error');
  }

}
