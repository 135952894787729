import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Confirmation } from '../../../../models/Confirmation.model';
import { RootState } from '../../../../store';
import { dismissConfirmation } from '../../../../store/confirmations/Actions';
import { styles } from '../styles';
import { PopupConfirmationMessage } from './PopupConfirmationMessage';


const ConfirmationsList = ()=>{
  const classes = styles();
  const dispatch = useDispatch();
  const confirmationsList = useSelector((states: RootState)=> states.confirmations.confirmationsList);
  const handleClose = (confirmation: Confirmation) => {
    dispatch(dismissConfirmation(confirmation));
  };
  
  return (
     confirmationsList.length ? (
      <div>
        <ul className={classes.messagesList}>
          {confirmationsList.map(confirmation => {
            return (
              <li key={confirmation.id}>
                <PopupConfirmationMessage handleClose={handleClose} confirmation={confirmation}/>
              </li>
            );
          })}
        </ul>
      </div>
    ) : null
  )
}

export default ConfirmationsList ;
