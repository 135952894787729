import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as types from './ActionTypes';
import { ApiError } from '../../models/ApiError.model';
import {
  SignInFailed,
  SignUpFailed,
  SignOutFailed,
  GetCurrentUserFailed,
  FetchOAuthUrlsFailed,
  AuthorizeSlackFailed
} from '../auth/Actions';

// Action Definition
export interface DismissError {
  type: types.DISMISS_ERROR;
  error: ApiError;
}

export interface AddError {
  type: types.ADD_ERROR;
  error: ApiError;
}
// Union Action Types
export type Action =
  | AddError
  | DismissError
  | SignInFailed
  | SignUpFailed
  | SignOutFailed
  | GetCurrentUserFailed
  | FetchOAuthUrlsFailed
  | AuthorizeSlackFailed;

// Action Creators
export const dismissError = (
  error: ApiError
): ThunkAction<DismissError, {}, {}, AnyAction> => {
  // Invoke API
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): DismissError => {
    return dispatch({ type: types.DISMISS_ERROR, error });
  };
};

export const addError = (
  error: ApiError
): ThunkAction<AddError, {}, {}, AnyAction> => {
  // Invoke API
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): AddError => {
    return dispatch({ type: types.ADD_ERROR, error });
  };
};
