import {
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Dialog,
  Button
} from '@material-ui/core';
import React from 'react';
import { Subscription } from '../../../../models/Subscription.model';

interface Props {
  open: boolean;
  handleClose: any;
}

export default function PaidPlanDialog({ open, handleClose }: Props) {
  const getPaymentPage = () => {
    Subscription.getPaymentPage(2, 0, '1.1.1.0').then(res =>
      window.open(res.payment_url, '_self')
    );
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Subscription to paid plan Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You will get unlimited access to all team reports and create unlimited
          number of reports
        </DialogContentText>

        <DialogContentText>Price: 50$</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={getPaymentPage} color="primary">
          Subscribe
        </Button>
      </DialogActions>
    </Dialog>
  );
}
