import React from 'react';
import HomeCover from '../../../../assets/images/Focus.png';
import PlayIcon from '../../../../assets/images/play-button-arrowhead.svg';
import SignUpButton from '../SingUpButton';
import { styles } from './styles';

function Home() {
  const classes = styles();
  return (
    <section id="home" className={classes.homeWrapper}>
      <div className={classes.homeLeftSide}>
        <h3>{`Build powerful responsive 
              fastest reports.`}</h3>
        <p>{`It's crafted with the latest trend of design & coded with all 
              modern approaches.
              It's a robust & multi-dimensional usable reports.`}</p>
        <div className={classes.signUpWrapper}>
          <SignUpButton buttonLabel="Sign up for free" />
          <div className={classes.playVideoWrapper}>
            <button className={classes.playVideoBtn}>
              <img src={PlayIcon} alt="play_icon" />
            </button>
            <span className={classes.watchVideoBtn}>Watch Now!</span>
          </div>
        </div>
      </div>
      <div className={classes.homeRightSide}>
        <img src={HomeCover} alt="home_cover" />
      </div>
    </section>
  );
}

export default Home;
