import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    settings: {
      height: 'calc(100% - 8px)',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#f9f9f9',
      margin: '8px auto 0 auto',
      width: '690px',
      boxShadow: '0 2px 20px 9px rgba(31, 31, 31, 0.04)',
      '@media screen and (max-width:1000px)': {
        width: '100%'
      }
    },
    root: {
      backgroundColor: '#fff',
      width: '100%',
      borderRadius: '6px 6px 0 0',
      '& #nav-tabpanel-0, &>div:nth-child(2)': {
        height: 'calc(100% - 72px)'
      }
    },
    tabsRoot: {
      width: '100%',
      height: '72px',
      background: 'fff',
      borderRadius: '6px 6px 0 0'
    },
    tabRoot: {
      Width: '50%',
      height: '72px',
      fontSize: '16px',
      color: '#242b37',
      textTransform: 'none'
    },
    selectedTab: {
      background: 'rgba(245, 246, 250, 0.5)',
      color: '#3a78f2',
      fontWeight: 'bold'
    },
    tabsIndicator: {
      height: '4px',
      backgroundColor: '#3a78f2'
    },
    tabPanel: {
      display: 'flex',
      justifyContent: 'center'
    }
  })
);
