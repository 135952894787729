import React from 'react';
import { Avatar, Grid, Menu, MenuItem } from '@material-ui/core';
import { Member } from '../../../../../../models/Member.model';
import { pollOptionListStyles } from './pollresponses.styles';
import classNames from 'classnames';
import CustomizedTooltip from '../../../../../shared-components/CustomizedTooltip';
import { Link as RLink } from 'react-router-dom';
import UserComponent from '../../../../../shared-components/UserComponent';
import {
  usePopupState,
  bindTrigger,
  bindMenu
} from 'material-ui-popup-state/hooks';
import { getUserProfileURL } from '../../../../../../utils/routes';

const FIXED_SIZE_MEMBERS_SHOW = 3;

const getMemberList = (members: Member[]): Member[] => {
  //TODO: sort by lastest votes
  if (members.length > FIXED_SIZE_MEMBERS_SHOW)
    return members.slice(0, FIXED_SIZE_MEMBERS_SHOW);
  return members;
};

interface Props {
  pollOptionResponses: PollOptionListModel;
}

export interface PollOptionListModel {
  optionId: number;
  optionName: string;
  members: Member[];
  totalCount: number;
  isMaxNumber?: boolean;
}

export const PollOptionList = ({ pollOptionResponses }: Props) => {
  const classes = pollOptionListStyles();
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });

  const slicedMembers = getMemberList(pollOptionResponses.members);

  return (
    <div
      className={classNames(classes.root, {
        [classes.rectMax]: !!pollOptionResponses.isMaxNumber
      })}
    >
      <Grid container justify="space-between" alignItems="center">
        <Grid item container direction="column" xs>
          <Grid
            item
            className={classNames(classes.optionContent, {
              [classes.rectMaxText]: !!pollOptionResponses.isMaxNumber
            })}
          >
            {pollOptionResponses.optionName}
          </Grid>

          <Grid item className={classes.avatars}>
            {slicedMembers.map(member => {
              return (
                <CustomizedTooltip title={member.name} placement="bottom">
                  <RLink to={getUserProfileURL(member.id)}>
                    <Avatar
                      className={classes.oval}
                      alt={member.name}
                      src={member.avatar}
                      key={member.id}
                    />
                  </RLink>
                </CustomizedTooltip>
              );
            })}
            {pollOptionResponses.members.length > FIXED_SIZE_MEMBERS_SHOW && (
              <div className={classNames(classes.oval, classes.fixedOval)}>
                <span className={classes.ovalText} {...bindTrigger(popupState)}>
                  +
                  {pollOptionResponses.members.length - FIXED_SIZE_MEMBERS_SHOW}
                </span>
                <Menu {...bindMenu(popupState)}>
                  {pollOptionResponses.members.map(member => (
                    <MenuItem onClick={popupState.close} key={member.id}>
                      <UserComponent
                        name={member.name}
                        avatar={member.avatar}
                        id={member.id}
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            )}
          </Grid>
        </Grid>

        <Grid item className={classes.votePercentageContainer}>
          <h2
            className={classNames(classes.votePercentage, {
              [classes.rectMaxText]: !!pollOptionResponses.isMaxNumber
            })}
          >
            {Number(
              (pollOptionResponses.members.length /
                pollOptionResponses.totalCount) *
                100
            ).toFixed(2)}
            %
          </h2>
          <p className={classes.voteCount}>
            ({pollOptionResponses.members.length}
            &nbsp;
            {pollOptionResponses.members.length <= 1 ? 'vote' : 'votes'})
          </p>
        </Grid>
      </Grid>
    </div>
  );
};
