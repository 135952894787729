import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Member } from '../../../../../models/Member.model';
import { FormSelect } from '../FormSelect';
import { styles } from '../styles';
import { ParticipantsList } from './ParticipantsList';
import { Channel } from '../../../../../models/Channel.model';
import { FieldLabel } from '../shared/FieldLabel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { AppContext } from '../../../../../App';
import { User } from '../../../../../models/User.model';

interface Props {
  membersList: Member[];
  channelsList: Channel[];
}

export default function TeammatesTab({ membersList, channelsList }: Props) {
  const { watch } = useFormContext();
  const currentUser: User = useContext(AppContext);
  const watchParticipants = watch('participants');
  const watchAdmins = watch('admins');
  const { setValue, getValues } = useFormContext();

  const admins = getValues('admins');
  const adminIndex = admins.findIndex((x: any) => x.id === currentUser?.id || -1);
  if (adminIndex === -1) {
    const newAdmin = membersList.find(x => x.id === currentUser?.id);
    if (newAdmin) {
      setValue('admins', [...admins, newAdmin]);
    }
  }

  const classes = styles();

  return (
    <>
      <div className={classes.teammatesTab}>
        <FieldLabel label="Add Teammates" required={true} />
        <FormSelect
          selectId="participants"
          placeholder="Select participants"
          multiple={true}
          options={(membersList as Member[]) || []}
          getOptionLabel={(member: Member) => member.name}
          customClasses={{
            inputRoot: classes.selectField
          }}
          getOptionSelected={(option: any, value: any) =>
            option.id === value.id
          }
        />

        <ParticipantsList
          participantList={watchParticipants}
          inputName="participants"
        />
      </div>

      <div className={classes.teammatesTab}>
        <FieldLabel label="Add Admins" required={true} />
        <FormSelect
          selectId="admins"
          placeholder="Select admins"
          multiple={true}
          options={(membersList as Member[]) || []}
          getOptionLabel={(member: Member) => member.name}
          customClasses={{
            inputRoot: classes.selectField
          }}
          getOptionSelected={(option: any, value: any) =>
            option.id === value.id
          }
        />

        <ParticipantsList
          participantList={watchAdmins}
          inputName="admins"
          adminsList={true}
        />
      </div>
    </>
  );
}
