import React from 'react';
import { Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import InputField from '../InputField';
import { styles } from '../styles';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Email } from '../../../../../models/Email.model';

export const Emails = () => {
  const classes = styles();
  const { control, formState } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails',
    keyName: 'identifier'
  });

  return (
    <div className={classes.emailsSection}>
      <Typography className={classes.iconRootLabel}>
        <span className={classes.iconLabel}>Deliver via emails</span>
      </Typography>
      <div className={classes.questions}>
        {fields.map((email, index) => {
          return (
            <div key={`${email.identifier}`} className={classes.emailsWrapper}>
              <Controller
                name={`emails[${index}].id`}
                control={control}
                defaultValue={email.id}
                render={() => <></>}
              />
              <InputField
                label={`Email ${index + 1}`}
                inputId={`emails[${index}].email`} // name for react-hook-form
                defaultValue={email.email}
                placeholder="Add user's email"
                customClass={classes.questionField}
                type="text"
                error={
                  formState.errors['emails'] &&
                  formState.errors['emails'][index]?.email
                }
              />
              <div className={classes.clearIconWrapper}>
                <ClearIcon
                  className={classes.clearIcon}
                  onClick={(e: any) => remove(index)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <Typography className={classes.addEmailDiv}>
        <span
          className={classes.addQuestionTxt}
          onClick={() => append(new Email())}
        >
          + Add Email
        </span>
      </Typography>
    </div>
  );
};
