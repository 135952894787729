import { API } from './API';
import axios from '../services/axios';
import { ReportResponse } from './ReportResponse.model';
import store from '../store';
import { Report } from './Report.model';
import { ReportType } from './ReportType.model';

export const ACTIVITIES_PER_PAGE: number = 12;

export interface ActivityScroll {
  count: number;
  activities: Activity[];
}

export type ActivityFilters = {
  memberId?: number;
  selectFavorite?: boolean;
};

export class Activity extends API {
  public response: ReportResponse = new ReportResponse();
  public report: Report = new Report(new ReportType());

  public static getRecent(
    page: number = 1,
    filters?: ActivityFilters
  ): Promise<ActivityScroll> {
    const headers = Activity.getConfig();

    if (headers) {
      return new Promise<ActivityScroll>((resolve, _reject) => {
        let url = `dashboard/utilities/activities?page=${page}&page_limit=${ACTIVITIES_PER_PAGE}`;
        if (filters) url += `${buildFiltersParams(filters).join('')}`;
        axios
          .get(url, headers)
          .then(res => {
            resolve(res.data as ActivityScroll);
          })
          .catch(error => {
            Activity.checkUnauthorizedRequest(error, store);
            _reject(error);
          });
      });
    }

    return Promise.reject('System Error');
  }
}

function buildFiltersParams(filters: ActivityFilters): string[] {
  const params: string[] = [];

  if (filters.memberId)
    params.push(`&memberId=${JSON.stringify(filters.memberId)}`);
  if (filters.selectFavorite)
    params.push(`&selectFavorite=${JSON.stringify(filters.selectFavorite)}`);

  return params;
}
