import { REPORT_TYPES } from '../utils/constants';

export class ReportType {
  public id: number = -1;
  public title: string = '';
  public description?: string = '';
  public imgSrc?: string = '';
}

const REPORT_TYPES_COLORS: { [key: string]: string } = {
  'Daily Standup': 'dailyStandupType',
  Retrospective: 'retrospectiveType',
  Poll: 'pollType',
  Custom: 'customType'
};

export const getReportTypeColor = (
  reportTypeTitle: string,
  reportTypeClasses: any
) => {
  const className: string = REPORT_TYPES_COLORS[reportTypeTitle];
  switch (className) {
    case 'customType':
      return reportTypeClasses.customType;
    case 'pollType':
      return reportTypeClasses.pollType;
    case 'retrospectiveType':
      return reportTypeClasses.retrospectiveType;
    case 'dailyStandupType':
      return reportTypeClasses.dailyStandupType;
    default:
      return reportTypeClasses.customType;
  }
};

export const getReportTypeById = (id: number | undefined): ReportType => {
  return REPORT_TYPES.find(o => o.id === Number(id)) || new ReportType();
};
