import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    labelText: {
      // Label
      '& .MuiInputLabel-root': {},
      // Label shrinked
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {},
      // Input text
      '& .MuiInputBase-input': {
        transform: 'translate(0, -9px)'
      },
      // All input text (including x selected)
      '& .MuiInputBase-root': {
        color: '#76797f',
        fontSize: '16px'
      }
    },
    selectedSpan:{
      transform: 'translate(0, -8px)'
    },
    CheckboxTxt: {
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.11px'
    },
    unSelectedCheckbox: {
      opacity: 0.5,
      color: '#474f58'
    },
    selectedCheckbox: {
      color: '#3a78f2'
    },
    dropIcon: {
      color: '#858d9b',
      opacity: 0.5
    }
  })
);
