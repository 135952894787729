import { MentionData } from '@draft-js-plugins/mention';
import { Member } from '../../../models/Member.model';

const getMentionedMembers = (members: Member[]) => {
  let mentions: MentionData[] = [];
  members.forEach(member => {
    mentions.push({
      name: member.name,
      avatar: member.avatar,
      id: member.id.toString(),
      provided_id: member.provided_id
    });
  });
  return mentions;
};

export default getMentionedMembers;
