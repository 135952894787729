import { combineReducers } from 'redux';
import { Action } from './Actions';
import * as types from './ActionTypes';
import { ApiError } from '../../models/ApiError.model';

export interface State {
  errorsList: ApiError[];
}

const errorsList = (state: ApiError[] = [], action: Action): ApiError[] => {
  switch (true) {
    case action.type === types.ADD_ERROR:
      return [action.error, ...state];
    case action.type.endsWith('_FAILED'):
      return [action.error, ...state];
    case action.type === types.DISMISS_ERROR:
      return state.filter(e => e.id !== action.error.id);
    default:
      return state;
  }
};
export default combineReducers<State>({
  errorsList
});
