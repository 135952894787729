import React from 'react';
import { styles } from '../styles';
import InputField from '../InputField';
import { ReportFormQuestions } from './ReportFormQuestions';
import { REPORT_TYPES } from '../../../../../utils/constants';
import { ReportFormPollOptions } from './ReportFormPollOptions';
import { useFormContext } from 'react-hook-form';

export default function QuestionsTab() {
  const classes = styles();
  const { getValues } = useFormContext();
  return (
    <div>
      <InputField
        label="Intro Message"
        inputId="intro_message"
        placeholder="Add intro message"
        customClass={classes.messageField}
        customFieldText={classes.message}
        type="text"
        multiline={true}
        required
      />
      <div className={classes.questionsWrapper}>
        {getValues('type').title === REPORT_TYPES[3].title ? (
          <ReportFormPollOptions />
        ) : (
          <ReportFormQuestions />
        )}
      </div>
      <InputField
        label="Confirmation Message"
        inputId="confirmation_message"
        placeholder="Add confirmation message"
        customClass={classes.messageField}
        customFieldText={classes.message}
        type="text"
        multiline={true}
        required
      />
    </div>
  );
}
