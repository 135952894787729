import { Store } from 'redux';
import history from '../services/history';
import { addError } from '../store/errors/Actions';
import { ApiError } from './ApiError.model';
export class API {
  private static token: string | null = null;

  protected static getToken(): string | null {
    this.token = localStorage.getItem('token');
    return this.token;
  }

  protected static getConfig() {
    this.getToken();

    if (this.token) {
      return {
        headers: {
          Authorization: `Bearer ${JSON.parse(this.token).access}`
        }
      };
    } else {
      this.logout();
    }
  }

  protected static checkUnauthorizedRequest(error: any, store: Store) {
    const response = error?.response;
    if (response?.status === 401) {
      // Unauthorized: invalid authentication credentials
      this.logout();
      store.dispatch<any>(
        addError(ApiError.getError('', 'Session is expired, try to sign in again'))
      );
    } else if (response?.status === 403) {
      // Forbidden: Not allowed to access a resource
      store.dispatch<any>(
        addError(
          ApiError.getError(
            '',
            "You don't have a permission to take this action"
          )
        )
      );
      history.push('/app/dashboard');
    }
  }

  protected static logout() {
    localStorage.clear();
    window.location.assign('/');
  }
}
