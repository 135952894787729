import { SelectOption } from '../pages/shared-components/SelectField';
import { ReportType } from '../models/ReportType.model';
import { Question } from '../models/Question.model';
import { ReportStatus } from '../models/Report.model';

export const TIME_FRAME_SELECT_FILTER_VALUE: SelectOption[] = [
  { id: 0, value: 'Today' },
  { id: 1, value: 'Yesterday' },
  { id: 2, value: 'Last 7 Days' }
];

export const RESPONSE_FILTER_IDS = {
  timeFrame: 'TimeFrame',
  participants: 'Participants',
  questions: 'Questions'
};

export const REPORT_PERIODS = [
  'weekly',
  'bi_weekly',
  'monthly',
  'non_recurrent'
];

export const ACTIVE_FILTER: ReportStatus[] = ['Active', 'Inactive'];

export const REPORT_FORM_STEPS = [
  'Setup',
  'Questions',
  'Teammates',
  'Reporting'
];

export const STATIC_DAYS_REPORT_FORM = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const STATIC_DAYS_ABBREVIATION: {
  [key: string]: string;
} = Object.freeze({
  Sunday: 'Sun',
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat'
});

export const STANDUP_QUESTIONS: Question[] = [
  {
    id: -1,
    content: 'What did you do last?',
    optional: false,
    order_appearance: 1
  },
  {
    id: -1,
    content: 'What are you planning to do next?',
    optional: false,
    order_appearance: 2
  },
  {
    id: -1,
    content: 'Lastly, do you have any blockers/obstacles?',
    optional: true,
    order_appearance: 3
  }
];

export const RETRO_QUESTIONS: Question[] = [
  {
    id: -1,
    content: 'What did we do right?',
    optional: false,
    order_appearance: 1
  },
  {
    id: -1,
    content: 'What could we do better next time?',
    optional: false,
    order_appearance: 2
  },
  {
    id: -1,
    content: 'What are the new activities we should introduce?',
    optional: true,
    order_appearance: 3
  }
];

export const CUSTOM_QUESTIONS: string[] = [''];

export const REPORT_TYPES: ReportType[] = [
  {
    id: 1,
    title: 'Daily Standup',
    description:
      'Keep your whole team in the loop, with updates on daily progress and possible blockers.',
    imgSrc: 'assets/images/standup_type.svg'
  },
  {
    id: 2,
    title: 'Retrospective',
    description:
      'Reflect on what went well and what can be improved. Decide what to focus on next.',
    imgSrc: 'assets/images/retro_type.svg'
  },
  {
    id: 3,
    title: 'Custom',
    description:
      'Set your own questions, days and frequency of your meetings, build a standup to fit your needs.',
    imgSrc: 'assets/images/custom_type.svg'
  },
  {
    id: 4,
    title: 'Poll',
    description:
      'Create a any number of poll questions to take the decision with your team.',
    imgSrc: 'assets/images/poll_type.svg'
  },
  {
    id: 5,
    title: 'Non-Scheduled',
    description: "This is a non-scheduled report.",
    imgSrc: 'assets/images/custom_type.svg'
  }
];

export const REPORTTYPES: any = {
  Poll: {
    recordsUrl: 'poll_records/'
  },
  'Daily Standup': {
    recordsUrl: 'report_records/'
  },
  Retrospective: {
    recordsUrl: 'report_records'
  },
  Custom: {
    recordsUrl: 'report_records'
  },
  'Non-Scheduled': {
    recordsUrl: 'report_records'
  }
};

// Positive value indicates that report wrap will be after start by this value
// Negative value means report wrap will be before next start by this value
export const WRAP_TIME_VALUES: any = [
  ...Array.from({ length: 14 }, (_, i) => i / 2 + 0.5),
  -0.5,
  -1
];

export const WRAP_REMIDER_OPTIONS: number[] = [10, 15, 20];

export const RESPONSE_GROUPING_OPTIONS: { id: string; value: string }[] = [
  {
    id: '1',
    value: 'Respondent'
  },
  {
    id: '2',
    value: 'Question'
  }
];

export const USER_ROLES: {
  [key: string]: string;
} = Object.freeze({
  viewer: 'Viewer',
  member: 'Member',
  admin: 'Admin'
});

export const USER_ROLES_DESCRIPTION: {
  [key: string]: string;
} = Object.freeze({
  viewer: 'Can only contribute and view to the report he was invited to',
  member: 'Can Create, edit and view thier own reports',
  admin: 'Can Create, edit and view all reports and change user roles'
});

export const EDITOR_STYLES: {
  [key: string]: string;
} = {
  OrderList: 'ordered-list-item',
  UnorderList: 'unordered-list-item',
  Bold: 'BOLD',
  Italic: 'ITALIC',
  Code: 'CODE',
  Strikethrough: 'STRIKETHROUGH'
};

export const BLOCK_TYPES = [
  {
    label: 'OrderList',
    style: EDITOR_STYLES.OrderList,
    shortcut: 'Ctrl+Shift+7'
  },
  {
    label: 'UnorderList',
    style: EDITOR_STYLES.UnorderList,
    shortcut: 'Ctrl+Shift+8'
  }
];

export const INLINE_STYLES = [
  {
    label: 'Bold',
    style: EDITOR_STYLES.Bold,
    shortcut: 'Ctrl+B',
    pattern: /\*(.*?)\*/gm,
    replacedEntity: '<strong>$1</strong>'
  },
  {
    label: 'Italic',
    style: EDITOR_STYLES.Italic,
    shortcut: 'Ctrl+I',
    pattern: /_(.*?)_/gm,
    replacedEntity: '<em>$1</em>'
  },
  {
    label: 'Code',
    style: EDITOR_STYLES.Code,
    shortcut: 'Ctrl+Shift+C',
    pattern: /`(.*?)`/gm,
    replacedEntity: '<span id="code" content="$1" ></span>'
  },
  {
    label: 'Strikethrough',
    style: EDITOR_STYLES.Strikethrough,
    shortcut: 'Ctrl+Shift+X',
    pattern: /~(.*?)~/gm,
    replacedEntity: '<del>$1</del>'
  }
];

export const DRAWER_WIDTH = '256';

export const STATES = {
  ERROR: 'error',
  SUCCESS: 'success'
};

export const COMPONENTS = {
  WHATS_NEW_BUTTON: "what's new button"
};

export const SUBMISSION_CYCLE_STATUS = [
  'Paused',
  'Completed',
  'Not Started',
  'In Progress',
  'Not Completed'
];

export const CREATION_REPORT_EVENTS = [
  'CREATE_REPORT_SETUP_TAB' ,
  'CREATE_REPORT_QUESTIONS_TAB',
  'CREATE_REPORT_TEAMMATES_TAB',
  'CREATE_REPORT_REPORTING_TAB',
]