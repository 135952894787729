import { API } from './API';
import axios from '../services/axios';
import { Member } from './Member.model';
import store from '../store';
export class Channel extends API {
  public id: number = -1;
  public name: string = '';

  public static async getAll(): Promise<Channel[]> {
    const headers = Channel.getConfig();

    if (headers) {
      return new Promise<Channel[]>((resolve, reject) => {
        axios
          .get('dashboard/channels', headers)
          .then(res => {
            const channels: Channel[] = res.data.channels as Channel[];
            resolve(channels);
          })
          .catch(e => {
            Channel.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }

  public static async getChannelMembers(channelID: number): Promise<Member[]> {
    const headers = Channel.getConfig();
    if (headers) {
      return new Promise<Member[]>((resolve, reject) => {
        axios
          .get(`dashboard/channels/${channelID}/members`, headers)
          .then(res => {
            resolve(res.data.members as Member[]);
          })
          .catch(e =>{ 
            Channel.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }


public static async refreshChannels(): Promise<Channel[]> {
  const headers = Channel.getConfig();

  if (headers) {
    return new Promise<Channel[]>((resolve, reject) => {
      axios
        .put('dashboard/channels/refresh', {}, headers)
        .then(res => {
          const channels: Channel[] = res.data.channels as Channel[];
          resolve(channels);
        })
        .catch(e => {
          Channel.checkUnauthorizedRequest(e,store);
          reject(e);
        });
    });
  }
  return Promise.reject('System Error');
}
}