import React, { useCallback, useContext } from 'react';
import { SlicedParticipantsList } from '../../Reports/SlicedParticipantsList';
import { workspaceCardStyles } from './workspaceCardStyles';
import { WorkspaceSubMenu } from '../../../shared-components/SettingsSubMenus/WorkspaceSubMenu';
import { useHistory } from 'react-router';
import { Workspace } from '../../../../models/Workspace.model';
import { getWorkspaceURL } from '../../../../utils/routes';
import { canEditWorkspace } from '../../../../utils/policies';
import { AppContext } from '../../../../App';
import { User } from '../../../../models/User.model';

interface WorkspaceCardProps {
  workspace: Workspace;
  openEditForm: (workspace: Workspace) => void;
}

export default function WorkspaceCard({
  openEditForm,
  workspace
}: WorkspaceCardProps) {
  const classes = workspaceCardStyles();
  const history = useHistory();
  const currentUser: User = useContext(AppContext);

  const viewWorkspaceDetails = useCallback(() => {
    history.push(getWorkspaceURL(workspace.id));
  }, [workspace]);

  return (
    <section className={`${classes.cardWrapper} workspace_card`} onClick={viewWorkspaceDetails}>
      <div className={classes.cardLeftSide}>
        <div>
          <h2 className={classes.workspaceName}>{workspace.name}</h2>
        </div>
        <div className={classes.cardLeftLower}>
          <p className={classes.numberInfo}>
            <span className={classes.totalNumber}>
              {workspace.reports_count}
            </span>{' '}
            Reports
          </p>
          <div className={classes.dot}></div>
          <p className={classes.numberInfo}>
            <span className={classes.totalNumber}>
              {workspace?.members?.length || 0}
            </span>{' '}
            Members
          </p>
        </div>
      </div>
      <div className={classes.cardRightSide}>
        <div className={classes.viewDetails}>
          <SlicedParticipantsList
            members={workspace.members || []}
            slicedCount={3}
          />
          <p onClick={viewWorkspaceDetails} className={classes.viewDetailsBtn}>
            View Details
          </p>
        </div>
        {currentUser && canEditWorkspace(currentUser, workspace) ? (
          <WorkspaceSubMenu workspace={workspace} openEditForm={openEditForm} />
        ) : (
          <div className={classes.emptyDiv}></div>
        )}
      </div>
    </section>
  );
}
