import { Grid } from '@material-ui/core';
import React from 'react';
import { GroupingProps } from '..';
import ResponseCard from './ResponseCard';
import { ResponseAttachments } from '../ResponseAttachments';

export const RespondentGrouping = ({ responses, questions }: GroupingProps) => {
  return responses.length ? (
    <Grid container item direction="column">
      {responses.map(response => (
        <Grid key={response.id} container direction="column">
          <ResponseCard response={response} questions={questions} />
          <ResponseAttachments response={response} />
        </Grid>
      ))}
    </Grid>
  ) : null;
};
