import React, { useState } from 'react';
import {
  Grid,
  Chip,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Board } from '../../../../models/Boards';
import t from '../../../../translations/english'

interface Props {
  boards: Board[];
}

export const Boards = ({boards}: Props) => {
  return (
    <>
      <Grid item xs = {12} >
        <Grid container justify="center" spacing={2}>
        {
          boards && boards.map((board: Board) => {
            return (
              <Grid 
                item
                key={board.id}
                xs={3}
              >
                <Chip 
                  color="primary"
                  style={{
                    backgroundColor: board.color
                  }}
                  label={board.name}
                />
                <Grid
                  container
                  direction="column"
                  spacing={2}
                >

                <br/>

                  {
                    board.tickets.length > 0 ?
                      board.tickets.map(ticket => {
                        return (
                          <Grid item key={ticket.id}>
                            <Card>
                              <CardContent>
                                <Typography variant="body1" gutterBottom>
                                  { ticket.title }
                                </Typography>
                                {
                                  ticket.short_description &&
                                  <Typography 
                                    variant="body2"
                                    color='textSecondary'
                                    gutterBottom
                                  >
                                    { ticket.short_description }
                                  </Typography>
                                }
                              </CardContent>
                            </Card>
                          </Grid>
                        )
                      })
                    :
                      <Grid item>
                        <Typography gutterBottom>
                          { t.boards.emptyList }
                        </Typography>
                      </Grid>
                  }
                </Grid>
              </Grid>
            
            )
          })
        }
        </Grid>
      </Grid>
    </>
  );
};
