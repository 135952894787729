import { createStyles, makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() =>
  createStyles({
    planIllustration: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      marginTop: 80
    },
    noPlanImg: {
      width: 235,
      height: 171,
      objectFit: 'contain'
    },
    currentPlanTxt: {
      maxWidth: 511,
      height: 24,
      fontFamily: 'Lato',
      fontSize: 20,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 0.16,
      textAlign: 'center',
      color: '#4f5560',
      marginTop: 24
    },
    planDescription: {
      height: 20,
      fontFamily: 'Lato',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#354052',
      opacity: 0.9,
      marginTop: 8
    },
    upgradeButton: {
      height: 27,
      fontFamily: 'Lato',
      fontSize: 18,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#f5a623',
      marginTop: 8,
      // width: 109,
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  })
);
