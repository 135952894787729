import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#f9f9f9'
    },
    divSeparator: {
      marginTop: '24px'
    },
    responsesCard: {
      borderRadius: '5px',
      backgroundColor: '#fff',
      border: 'solid 1px #ededed',
      boxShadow: '0 2px 8px 0 rgba(37, 37, 37, 0.03)'
    },
    responsesContainer: {
      marginBottom: 8,
      '& > :first-child': {
        marginTop: 8
      }
    },
    gridSeparator: {
      paddingTop: 24
    }
  });

export const SubmissionReminderStyles = makeStyles(theme =>
  createStyles({
    alertRoot: {
      minHeight: 40,
      borderRadius: 8,
      padding: '0 16px',
      margin: '16px 0 8px 0',
      backgroundColor: 'rgba(245, 166, 35, 0.29)',
      '@media only screen and (max-width: 320px)': {
        padding: '0 8px'
      }
    },
    alertMessage: {
      fontSize: 16,
      color: '#dd9b2f',
      letterSpacing: 0.13,
      display: 'inline-block',
      fontFamily: 'Lato-Bold'
    },
    alertIcon: {
      marginTop: 5,
      marginRight: 8,
      color: '#f5a623'
    },
    alertAction: {
      opacity: 0.8,
      fontSize: 16,
      color: '#3a78f2',
      letterSpacing: 0.13,
      textTransform: 'none',
      fontFamily: 'Lato-Bold'
    }
  })
);
