import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { ApiError } from '../../../models/ApiError.model';
import { Subscription } from '../../../models/Subscription.model';
import { Team } from '../../../models/Team.model';
import { User } from '../../../models/User.model';
import { addError } from '../../../store/errors/Actions';
import { QUERIES } from '../../../utils/queris';

export const useTeam = (teamId: number) => {
  return useQuery<Team>(
    [QUERIES.LOAD_TEAM, teamId],
    () => Team.getTeam(teamId),
    {
      enabled: !!teamId
    }
  );
};

export const useSignout = () => {
  const dispatch = useDispatch();
  return useMutation(() => User.signOut(), {
    onSuccess: () => {
      window.location.assign('/');
    },
    onError: (err: any) => {
      dispatch(addError(ApiError.getError(err, 'Error: Can not sign out')));
    }
  });
};

export const useTeamSubscription = () => {
  return useQuery<Subscription>(
    QUERIES.LOAD_TEAM_SUBSCRIPTION,
    Subscription.getSubscription
  );
};
