import { createStyles, makeStyles } from '@material-ui/core/styles';

export const workspaceReportStyles = makeStyles(() =>
  createStyles({
    reportWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 0',
      borderBottom: '1px solid #e7e9ea',
      '&:last-child': {
        borderBottom: '0',
        paddingBottom: 0
      }
    },
    reportName: {
      fontSize: '16px',
      fontWeight: 600,
      color: '#6c7681'
    },
    responsesLink: {
      fontSize: '12px',
      fontWeight: 600,
      color: '#3a78f2',
      textDecoration: 'none'
    },
    rateBar: {
      height: '8px',
      maxWidth: '156px',
      width: '100%',
      borderRadius: '5px',
      backgroundColor: '#f0f0f0',
      opacity: 0.93,
      marginBottom: '8px',
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#f5a623'
      }
    },
    responseRate: {
      color: '#a9aeb7',
      fontSize: '14px',
      fontWeight: 500
    },
  
  })
);