import React, { useCallback } from 'react';
import { Pagination } from '@material-ui/lab';
import { styles } from './styles';

interface IProps {
  data: any;
  page: number;
  setPage: any;
}
export const CustomizedPagination = (props: IProps) => {
  const { data, page, setPage } = props;
  const classes = styles();

  const handlePagination = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      event.preventDefault();
      setPage(value);
    },
    [setPage]
  );

  return (
    <>
      {data && data.pageCount && data.pageCount > 1 ? (
        <div className={classes.pagination}>
          <Pagination
            page={page}
            count={data?.pageCount}
            variant="outlined"
            onChange={handlePagination}
          />
        </div>
      ) : null}
    </>
  );
};
