import React from 'react';
import { ReactComponent as EmptyStateIllustration } from '../../../assets/images/empty-state-illustration.svg';
import { emptyStateReports } from './styles';
import NewReportButton from '../../components/Reports/NewReportButton';

export const EmptyCreateReport = () => {
  const classes = emptyStateReports();

  return (
    <div className={classes.root}>
      <EmptyStateIllustration />
      <h2 className={classes.header}>Create Your First Report</h2>
      <p className={classes.description}>
        {`There are no reports to display yet. Create your first report to improve your
          team’s efficiency from now on`}
      </p>
      <div className={classes.reportButton}>
        <NewReportButton isEmptyState={true} />
      </div>
    </div>
  );
};
