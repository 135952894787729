import React, { useCallback, useState } from 'react';
import { ReactComponent as EmptyStateIllustration } from '../../../assets/images/empty-search-state-illustration.svg';
import { emptyStateWorkspace } from './styles';
import NewReportButton from '../../components/Reports/NewReportButton';
import { Workspace } from '../../../models/Workspace.model';
import { CreateWorkspaceDialog } from '../../components/Workspaces/Dialogs/CreateWorkspaceDialog';
import { NewWorkspaceButton } from '../../components/Workspaces/NewWorkspaceButton';

export const EmptyCreateWorkspace = () => {
  const classes = emptyStateWorkspace();
  const [openWorkspaceForm, setOpenWorkspaceForm] = useState(false);
  const [workspaceForm, setWorkspaceForm] = useState(new Workspace());

  const openCreateForm = useCallback(() => {
    setOpenWorkspaceForm(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenWorkspaceForm(false);
    setWorkspaceForm(new Workspace());
  }, []);

  return (
    <div className={classes.root}>
      <EmptyStateIllustration className={classes.emptyStateImage} />
      <h2 className={classes.header}>Create Your First Workspace</h2>
      <p className={classes.description}>
        {`There are no workspaces to display yet. Create your first workspace to improve your
          team’s collaboration from now on`}
      </p>
      <div className={classes.workspaceBtn}>
        <CreateWorkspaceDialog
          open={openWorkspaceForm}
          handleClose={handleClose}
          isCreateWorkspace={true}
          workspace={workspaceForm}
        />
        <NewWorkspaceButton openCreateForm={openCreateForm} />
      </div>
    </div>
  );
};
