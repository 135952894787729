import React, { useCallback, useContext, useState } from 'react';
import { Grid, Paper, withStyles } from '@material-ui/core';
import { Report } from '../../../models/Report.model';
import { styles } from './styles';
import { useParams } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';
import { QUERIES } from '../../../utils/queris';
import { PlainLoader } from '../../shared-components/PlainLoader';
import SubmissionCycles from './SubmissionCycles';
import ReportCard from './ReportCard';
import { ReportDetailsHeader } from './ReportDetailsHeader';
import {
  PaginatedSubmissionCycles,
  SubmissionCycle
} from '../../../models/SubmissionCycle.model';
import { EmptyStateHandler } from '../../shared-components/EmptyStates/EmptyStateHandler';
import { EmptySmallIcon } from '../../shared-components/EmptyStates/EmptySmallIcon';
import { CustomizedPagination } from '../../shared-components/CustomizedPagination';
import { SubmissionReminder } from './SubmissionReminder';
import { AppContext } from '../../../App';
import { User } from '../../../models/User.model';
import { REPORT_TYPES } from '../../../utils/constants';
import { groupResponsesByDay } from '../../../utils/responses';
import GroupedResponses from './GroupedResponses/GroupedResponses';

interface Props {
  classes?: any;
}

const ReportDetails = ({ classes }: Props) => {
  const { id: reportId, status }: any = useParams();
  const [page, setPage] = useState(1);
  const [DateRangeFilter, setDateRangeFilter] = useState<any>({});
  const queryClient = useQueryClient();
  const currentUser: User = useContext(AppContext);
  const { data: report, isLoading: loadingReport } = useQuery<Report>(
    [QUERIES.LOAD_REPORT, reportId],
    () => Report.getReport(reportId),
    {
      enabled: !!reportId
    }
  );

  const { data: submissionCycleResponse, isLoading: submissionLoading } =
    useQuery<PaginatedSubmissionCycles>(
      [
        QUERIES.LOAD_SUBMISSION_CYCLES,
        reportId,
        page,
        DateRangeFilter.startDate,
        DateRangeFilter.endDate
      ],
      () =>
        SubmissionCycle.getSubmissionCycles(
          reportId,
          page,
          DateRangeFilter.startDate,
          DateRangeFilter.endDate
        ),
      { keepPreviousData: true }
    );

  const handleDateRangeFilter = useCallback(
    (startDate: string, endDate: string) => {
      setDateRangeFilter({
        startDate,
        endDate
      });
    },
    []
  );

  const updateCashedReport = async (updatedReport: Report) => {
    await queryClient.cancelQueries([QUERIES.LOAD_REPORT, reportId]);
    queryClient.setQueryData<Report>(
      [QUERIES.LOAD_REPORT, reportId],
      updatedReport
    );
  };

  if (loadingReport || !report) return <PlainLoader />;

  return (
    <Paper className={classes.root} elevation={0}>
      {/* ReportDetailsHeader: "Reports/reportName" and calendar */}
      <ReportDetailsHeader
        reportName={report.name}
        handleDateRangeFilter={handleDateRangeFilter}
      />

      {/* Report card that shows up on reports list page and at the top of the report details page */}
      <ReportCard
        report={report}
        viewDetails={false}
        updateCashedReport={updateCashedReport}
      />

      {/* List of members who haven't submitted their report */}
      {report.not_submitted_member_ids?.includes(currentUser?.id!) && (
        <SubmissionReminder report={report} status={status} />
      )}

      {report.type.title === REPORT_TYPES[4].title && (
        <SubmissionReminder report={report} status={status} />
      )}

      {report.type.title === REPORT_TYPES[4].title ? (
        <EmptyStateHandler
          loading={submissionLoading}
          data={!!report.responses.length}
          emptyComponent={
            <EmptySmallIcon mainPrompt="No Responses" page="reportDetails" />
          }
        >
          <GroupedResponses
            report={report}
            groupedResponses={groupResponsesByDay(report.responses)}
          />
        </EmptyStateHandler>
      ) : (
        <EmptyStateHandler
          loading={submissionLoading}
          data={!!submissionCycleResponse?.submission_cycles.length}
          emptyComponent={
            <EmptySmallIcon
              mainPrompt="No Submission cycles"
              page="reportDetails"
            />
          }
        >
          <SubmissionCycles
            report={report}
            submissionCycles={submissionCycleResponse?.submission_cycles || []}
          />
        </EmptyStateHandler>
      )}

      <CustomizedPagination
        data={submissionCycleResponse}
        page={page}
        setPage={setPage}
      />
    </Paper>
  );
};

export default withStyles(styles)(ReportDetails);
