import React, { useState, useEffect, useCallback } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import SideDrawerNav from './SideDrawerNav';
import NavBar from './NavBar';
import styles from './styles';

function PageHeader() {
  const [navBar, setNavBar] = useState(false);
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const classes = styles();
  useEffect(() => {
    const handleScroll = () => {
      window.scrollY >= 60 ? setNavBar(true) : setNavBar(false);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [navBar]);

  const handleSideDrawer = useCallback(() => {
    setShowSideDrawer(showSideDrawer => !showSideDrawer);
  }, [showSideDrawer]);

  return (
    <header>
      <div className={classes.sideDrawerNavWrapper}>
        <SideDrawerNav
          showSideDrawer={showSideDrawer}
          handleSideDrawer={handleSideDrawer}
        />
      </div>
      <AppBar
        position="fixed"
        className={clsx(classes.headerWrapper, {
          [classes.navbarBgWhite]: navBar
        })}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.navbarWrapper}>
            <IconButton
              onClick={handleSideDrawer}
              className={classes.drawerButton}
            >
              <MenuIcon color="primary" />
            </IconButton>
            <NavBar isSideDrawer={false} />
          </div>
        </Toolbar>
      </AppBar>
    </header>
  );
}

export default PageHeader;
