import { getReportURL, getSubmitReportURL } from './routes';
import { Notification } from '../models/Notification.model';
import SubmitNotifyImg from '../assets/images/SubmitNotifyImg.svg';
import ReportIcon from '../assets/images/reportIcon.svg'
import ReminderIcon from '../assets/images/reminderIcon.svg'
import PauseReportIcon from '../assets/images/pauseReportIcon.svg'
import ResumeReportIcon from '../assets/images/resumeReportIcon.svg'
import CommentIcon from '../assets/images/commentIcon.svg'
import MissedReportIcon from '../assets/images/missedReportIcon.svg'
import PATH_NAME from './pathNames';

export const NOTIFICATION_TYPES = {
  comment: 'comment',
  mention: 'mention',
  create_report: 'create report',
  pause_report: 'pause report',
  resume_report: 'resume report',
  delete_report: 'delete report',
  submission_reminder: 'report submission reminder',
  report_submission_status: 'report submission status',
  report_participant_last_leave: 'report participant last leave',
};

export const getNotificationURL = (notification: Notification) => {
  switch (notification.notify_type) {
    case NOTIFICATION_TYPES.comment: {
      return getReportURL(notification.second_target);
    }
    case NOTIFICATION_TYPES.mention: {
      return getReportURL(notification.second_target);
    }
    case NOTIFICATION_TYPES.report_submission_status: {
      return getReportURL(notification.target);
    }
    case NOTIFICATION_TYPES.create_report: {
      return getReportURL(notification.target);
    }
    case NOTIFICATION_TYPES.pause_report: {
      return getReportURL(notification.target);
    }
    case NOTIFICATION_TYPES.resume_report: {
      return getReportURL(notification.target);
    }
    case NOTIFICATION_TYPES.delete_report: {
      return PATH_NAME.REPORTS;
    }
    case NOTIFICATION_TYPES.submission_reminder: {
      return getSubmitReportURL(notification.target);
    }
    case NOTIFICATION_TYPES.report_participant_last_leave: {
      return getReportURL(notification.target);
    }
    default: {
      return '/reports';
    }
  }
};

export const getNotificationIcon = (type: string) => {
  switch (type) {
    case NOTIFICATION_TYPES.comment: {
      return CommentIcon;
    }
    case NOTIFICATION_TYPES.mention: {
      return ReportIcon;
    }
    case NOTIFICATION_TYPES.report_submission_status: {
      return SubmitNotifyImg;
    }
    case NOTIFICATION_TYPES.create_report: {
      return ReportIcon;
    }
    case NOTIFICATION_TYPES.pause_report: {
      return PauseReportIcon;
    }
    case NOTIFICATION_TYPES.resume_report: {
      return ResumeReportIcon;
    }
    case NOTIFICATION_TYPES.delete_report: {
      return MissedReportIcon;
    }
    case NOTIFICATION_TYPES.submission_reminder: {
      return ReminderIcon;
    }
    case NOTIFICATION_TYPES.report_participant_last_leave: {
      return ReportIcon;
    }
    default: {
      return;
    }
  }
};
