import { makeStyles, createStyles } from '@material-ui/core/styles';

export const DialogStyles = makeStyles(theme =>
  createStyles({
    paper: {
      background: '#fff',
      width: '586px',
      borderRadius: '5px'
    },
    root: {
      margin: 0
    },
    closeButton: { padding: 0 },
    dialogTitle: {
      padding: '24px 24px 60px 24px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& .MuiTypography-h6': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        '& .MuiTypography-body1': {
          margin: '0 auto',
          fontFamily: 'Lato',
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '1.13',
          letterSpacing: 'normal',
          textAlign: 'center',
          color: '#858d9b'
        }
      }
    },
    deleteModalContent: {
      padding: '0 70px 15px 70px',
      margin: 0,
      textAlign: 'center',
      whiteSpace: 'pre-line',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 1.69,
      letterSpacing: 'normal',
      color: '#354052'
    },
    workspaceName: {
      fontWeight: 'bold'
    },
    deleteBtn: {
      width: '118px',
      height: '40px',
      margin: '0 0 0 16px',
      padding: '12px 37px 12px 38px',
      opacity: 0.81,
      borderRadius: '6px',
      backgroundColor: '#d0021b',
      '&:hover': {
        backgroundColor: '#d0021b'
      }
    },
    deleteBtnText: {
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#ffffff'
    },
    cancelBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '118px',
      height: '40px',
      border: 'solid 1px #f5a623',
      '&:hover': {
        background: '#fff',
        opacity: 0.8
      }
    },
    cancelBtnText: {
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#f5a623'
    },
    createBtn: {
      width: '118px',
      height: '40px',
      margin: '0 0 0 16px',
      padding: '12px 37px 12px 38px',
      borderRadius: '6px',
      backgroundColor: '#f5a623',
      '&:hover': {
        background: '#f5a623',
        opacity: 0.8
      }
    },
    createBtnText: {
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#fff'
    },
    workspaceDialogContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 40px 40px 40px'
    },
    formItem: {
      padding: 0,
      margin: '0 0 16px 0'
    },
    fieldLabel: {
      height: '20px',
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '1.69',
      letterSpacing: 'normal',
      color: '#354052',
      margin: '0 0 8px 0'
    },
    formField: {
      height: '48px',
      margin: '0',
      '& .MuiInputBase-root': {
        height: '100%',
        padding: 0
      },
      '& .MuiInputBase-input': {
        height: '100%',
        padding: '0 25px',
        textOverflow: 'ellipsis',
        border: '1px solid #c3c3c3',
        borderRadius: '6px',
        transition: 'all 0.3s ease-in-out',
        '&.MuiOutlinedInput-inputMultiline': {
          padding: '13.7px 25px'
        },
        '&:hover,&:focus': {
          boxShadow: '2px 0 24px 0 rgba(103, 101, 101, 0.14)',
          border: '.5px solid #3a78f2'
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        height: '100%'
      }
    },
    formFieldTextArea: {
      minHeight: '48px',
      margin: '0',
      '& .MuiInputBase-root': {
        height: '100%',
        padding: 0
      },
      '& .MuiInputBase-input': {
        height: '100%',
        padding: '0 25px',
        textOverflow: 'ellipsis',
        border: '1px solid #c3c3c3',
        borderRadius: '6px',
        transition: 'all 0.3s ease-in-out',
        '&.MuiOutlinedInput-inputMultiline': {
          padding: '13.7px 25px',
          maxHeight: '100px',
          overflowY: 'auto !important',
          '&::-webkit-scrollbar': {
            height: '15px',
            width: '15px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            border: '4px solid rgba(0, 0, 0, 0)',
            backgroundClip: 'padding-box',
            borderRadius: '50px',
            backgroundColor: '#dee6ff',
            boxShadow:
              'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
          },
          '&::-moz-scrollbar': {
            height: '15px',
            width: '15px'
          },
          '&::-moz-scrollbar-track': {
            backgroundColor: 'transparent'
          },
          '&::-moz-scrollbar-thumb': {
            border: '4px solid rgba(0, 0, 0, 0)',
            backgroundClip: 'padding-box',
            borderRadius: '50px',
            backgroundColor: '#dee6ff',
            boxShadow:
              'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
          },
          '&::-ms-scrollbar': {
            height: '15px',
            width: '15px'
          },
          '&::-ms-scrollbar-track': {
            backgroundColor: 'transparent'
          },
          '&::-ms-scrollbar-thumb': {
            border: '4px solid rgba(0, 0, 0, 0)',
            backgroundClip: 'padding-box',
            borderRadius: '50px',
            backgroundColor: '#dee6ff',
            boxShadow:
              'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
          }
        },
        '&:hover,&:focus': {
          boxShadow: '2px 0 24px 0 rgba(103, 101, 101, 0.14)',
          border: '.5px solid #3a78f2'
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        height: '100%'
      }
    },
    formItemDropdown: {
      padding: 0,
      margin: '0 0 16px 0',
      '& .MuiAutocomplete-root': {
        height: '48px',
        '& .MuiFormControl-root': {
          height: '100%',
          '& .MuiInputBase-root': {
            height: '100%',
            padding: 0,
            border: '1px solid #c3c3c3',
            borderRadius: '6px',
            transition: 'all 0.3s ease-in-out',
            '&:hover,&.Mui-focused': {
              boxShadow: '2px 0 24px 0 rgba(103, 101, 101, 0.14)',
              border: '.5px solid #3a78f2'
            },
            '& .MuiInputBase-input': {
              height: '100%',
              textOverflow: 'ellipsis'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
              height: '100%'
            }
          },
          '& .MuiInputBase-input': {
            padding: '0 25px'
          }
        }
      }
    },
    optionContentText: {
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.69,
      letterSpacing: 'normal',
      color: '#76797f'
    },
    optionContentSpan: {
      fontStyle: 'italic',
      opacity: 0.8
    },
    formActions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '24px 0 0 0'
    },
    deleteModalActions: {
      margin: '0 0 32px 0'
    }
  })
);
