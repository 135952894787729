import { Report } from '../models/Report.model';
import { Workspace } from '../models/Workspace.model';
import { USER_ROLES } from './constants';

export const canEditReport = (user: any, report: Report) => {
  return (
    user?.role === USER_ROLES.admin.toLowerCase() ||
    !!report.admins?.find(member => member.id == user?.id)
  );
};

export const canEditWorkspace = (user: any, workspace: Workspace) => {
  return (
    user?.id === workspace.creator_id ||
    user?.role === USER_ROLES.admin.toLowerCase()
  );
};

export const isAdmin = (user: any) => {
  return user?.role === USER_ROLES.admin.toLowerCase();
};
