import React, { useEffect, useMemo } from 'react';
import { styles } from '../styles';
import { Typography, Select, MenuItem } from '@material-ui/core';
import { Workspace } from '../../../../../models/Workspace.model';
import InputField from '../InputField';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { get30MinIntervals } from '../../../../../utils/time';
import {
  REPORT_PERIODS,
  REPORT_TYPES,
  WRAP_REMIDER_OPTIONS,
  WRAP_TIME_VALUES
} from '../../../../../utils/constants';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { QUERIES } from '../../../../../utils/queris';
import { TimeZone } from '../../../../../models/TimeZone.model';
import { useFormContext, Controller } from 'react-hook-form';
import { FormSelect } from '../FormSelect';
import { WeekDays } from './WeekDays';
import AlarmIcon from '@material-ui/icons/Alarm';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FieldLabel } from '../shared/FieldLabel';
import CustomizedTooltip from '../../../../shared-components/CustomizedTooltip';
import { useWorkspaces } from '../../ReportsHooks';
import Switch from '@material-ui/core/Switch';

const TIME_24_TO_12_MAP = get30MinIntervals();

const TIME_INTERVALS = Array.from(TIME_24_TO_12_MAP.keys());

interface Props {
  reportTypeTitle: string;
}

export default function ReportSetup({ reportTypeTitle }: Props) {
  const classes = styles();
  const { setValue, watch } = useFormContext();
  const watchRecurrence = watch('recurrence');
  const watchReminder = watch('wrap_reminder', 15);
  const watchname = watch('name', '<report_name>');
  const watchScheduledSwitch = watch('scheduledSwitch', true);

  const { data: timezonesList } = useQuery<TimeZone[]>(
    QUERIES.LOAD_TIMEZONES,
    () => TimeZone.getAll()
  );

  const { data: workspacesResponse } = useWorkspaces();

  // When editing a report, set switch as true if report type is not Non-Scheduled
  // Only executed when edit form is first opened
  useEffect(() => {
    setValue('scheduledSwitch', reportTypeTitle !== REPORT_TYPES[4].title);
  }, []);

  useEffect(() => {
    setValue(
      'wrap_reminder_message',
      `Hey, just a friendly reminder to submit your response to ${watchname}. We are wrapping up in ${watchReminder} minutes!`
    );
    // eslint-disable-next-line
  }, [watchReminder, watchname]);

  // Set report type and clear unneeded fields when the switch value is changed
  useEffect(() => {
    if (watchScheduledSwitch) {
      setValue('type', REPORT_TYPES[2]);
    } else {
      setValue('type', REPORT_TYPES[4]);
      setValue('notification_time', '');
      setValue('recurrence', '');
      setValue('wrap_time', '');
      setValue('week_days', []);
      setValue('wrap_reminder_message', '');
    }
  }, [watchScheduledSwitch]);

  const recurrencesMap = useMemo(() => {
    let map = new Map();
    REPORT_PERIODS.forEach(period => {
      let target = period.replace('_', ' ');
      target = target.charAt(0).toUpperCase() + target.slice(1);
      map.set(period, target);
    });
    return map;
  }, []);

  return (
    <div className={classes.form1Fields}>
      <InputField
        label="Report Name"
        inputId="name"
        placeholder="Add report name"
        customClass={classes.reportName}
        type="text"
        required
      />

      <div className={classNames(classes.formControl, classes.workspace)}>
        <FieldLabel label="Select Workspace" required={true} />
        <FormSelect
          selectId="workspace"
          placeholder="Workspace"
          options={workspacesResponse?.workspaces || []}
          getOptionLabel={(option: Workspace) => option.name}
          customClasses={{
            inputRoot: classes.selectField
          }}
          getOptionSelected={(option, value) => option.id === value.id}
        />
      </div>

      <InputField
        label="Start Date"
        inputId="start_date"
        customClass={classes.startDate}
        type="date"
        required
      />

      <div className={classNames(classes.formControl, classes.timezone)}>
        <FieldLabel label="Time Zone" required={true} />
        <FormSelect
          selectId="timezone"
          placeholder="time zone"
          options={timezonesList || []}
          getOptionLabel={(option: TimeZone) =>
            option.name + ' (UTC ' + option.offset + ')'
          }
          customClasses={{
            inputRoot: classes.selectField
          }}
          getOptionSelected={(option, value) => option.name === value.name}
        />
      </div>

      {/* Show the switch for Custom and Non-Scheduled reports */}
      {(reportTypeTitle === REPORT_TYPES[2].title ||
        reportTypeTitle === REPORT_TYPES[4].title) && (
          <div>
          <Typography className={classes.iconRootLabel}>
            <span className={classes.promptStyle}>Scheduled</span>
          </Typography>
          <Controller
            name="scheduledSwitch"
            render={({ onChange, value }) => (
              <Switch
                checked={watchScheduledSwitch}
                onChange={e => onChange(e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            )}
          />
        </div>
      )}

      <div className={classes.reportTiming}>
        <div
          className={classNames(classes.formControl, classes.reportTimingDiv)}
        >
          <div className={classes.reportTimeLabel}>
            <FieldLabel label="Report timing" required={true} />
            <CustomizedTooltip
              title="Defines when BScrum should remind participants to submit the report"
              children={
                <InfoOutlinedIcon
                  className={classes.formIcon}
                  fontSize="small"
                />
              }
              placement="top"
            />
          </div>
          <FormSelect
            selectId="notification_time"
            placeholder="--:-- --"
            options={TIME_INTERVALS}
            disabled={!watchScheduledSwitch}
            getOptionLabel={(option: string) =>
              TIME_24_TO_12_MAP.get(option) || ''
            }
            customClasses={{
              inputRoot: classes.selectField
            }}
            popupIcon={<ScheduleIcon />}
          />
        </div>

        <div className={classes.formControl}>
          <div className={classes.reportTimeLabel}>
            <FieldLabel label="Wrap time" required={true} />
            <CustomizedTooltip
              title="Defines the end of the expected interval to accept responses and notify participants who didn't submit"
              children={
                <InfoOutlinedIcon
                  className={classes.formIcon}
                  fontSize="small"
                />
              }
              placement="top"
            />
          </div>
          <FormSelect
            selectId="wrap_time"
            placeholder="Select"
            getOptionLabel={(option: any) => {
              if (option > 0)
                return `${option} hour${option > 1 ? 's' : ''} after start`;
              else if (option < 0) return `${-option} hour before next start`;
              return '';
            }}
            options={
              watchRecurrence === REPORT_PERIODS[3]
                ? WRAP_TIME_VALUES.slice(0, -2)
                : WRAP_TIME_VALUES
            }
            groupBy={(option: any) => {
              if (option > 0) return 'After Start';
              else if (option < 0) return 'Before Next Start';
            }}
            disabled={!watchScheduledSwitch}
            customClasses={{
              inputRoot: classes.selectField
            }}
          />
        </div>
      </div>

      <div className={classNames(classes.formControl, classes.period)}>
        <FieldLabel label="Recurrence" required={true} />
        <FormSelect
          selectId="recurrence"
          placeholder="Select recurrence"
          options={REPORT_PERIODS}
          disabled={!watchScheduledSwitch}
          getOptionLabel={(option: string) => recurrencesMap.get(option)}
          customClasses={{
            inputRoot: classes.selectField
          }}
        />
      </div>

      {watchRecurrence !== REPORT_PERIODS[3] && (
        <WeekDays disabled={!watchScheduledSwitch} />
      )}

      <div className={classes.reminderSection}>
        <div className={classes.reminderWrapper}>
          <Typography className={classes.iconRootLabel}>
            <AlarmIcon className={classes.icon} />
            <span className={classes.iconLabel}>Reminder</span>
          </Typography>
          <Typography>
            <span className={classes.promptStyle}>Remind participants</span>
            <Controller
              name="wrap_reminder"
              render={({ onChange, value }) => (
                <Select
                  placeholder=""
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  disabled={!watchScheduledSwitch}
                  className={classes.inlineSelect}
                  variant="outlined"
                >
                  {WRAP_REMIDER_OPTIONS.map(el => (
                    <MenuItem value={el} key={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <span className={classes.promptStyle}>
              minutes before report wrap up time
            </span>
          </Typography>
        </div>

        <InputField
          label="Remind Participants"
          inputId="wrap_reminder_message"
          placeholder="Add reminder message"
          customClass={classes.messageField}
          customFieldText={classes.message}
          type="text"
          multiline={true}
          required={watchScheduledSwitch}
          disabled={!watchScheduledSwitch}
        />
      </div>
    </div>
  );
}
