import { makeStyles } from '@material-ui/core';

export const formErrorMessageStyles = makeStyles({
  errorMessage: {
    width: 'inherit',
    color: '#FF0000',
    textAlign: 'center'
  }
});

export const fieldLabelStyles = makeStyles({
  fieldLabel: {
    fontSize: 16,
    color: '#354052',
    marginBottom: '8px',
    letterSpacing: 'normal',
    fontFamily: 'Lato-SemiBold'
  }
});
