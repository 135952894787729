import React from 'react';
import { styles } from '../styles';
import { Popover, Typography } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { useRefreshChannels } from '../reportFormHooks';

export const ChannelHelper = () => {
  const classes = styles();
  const refreshChannels = useRefreshChannels();
  return (
    <PopupState variant="popover" popupId="channels-popup-popover">
      {popupState => (
        <div>
          <div className={classes.helperPrompt} {...bindTrigger(popupState)}>
            Don't see the channel in the list?
          </div>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            classes={{
              paper: classes.helpersCard
            }}
          >
            <div className={classes.tooltip}>
              <Typography>
                If you do not see the channel in the list, Most probably your
                channel is private. Please, invite Bscrum bot by typing:&nbsp;
                <b>/add</b> then click<b> Add apps to this channel</b> then search for
                <b> Bscrum</b> then click <b>Add</b>
              </Typography>
              <Typography>
                If it's not private,&nbsp;
                <span
                  onClick={() => {
                    refreshChannels.mutate();
                    popupState.close();
                  }}
                  className={classes.refreshLink}
                >
                  click here
                </span>
                &nbsp;to refresh channels fetching.
              </Typography>
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};
