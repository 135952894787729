import React from 'react';
import { ReactComponent as ReportsIcon } from '../assets/images/ic-reports.svg';
import { ReactComponent as StandupIcon } from '../assets/images/ic-standup.svg';
import { ReactComponent as WorkspacesIcon } from '../assets/images/ic-projects.svg';
import { ReactComponent as ResponsesIcon } from '../assets/images/ic-responses.svg';
import { Components } from '../models/Components';

export const capitalizeString = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const  getImageUrl = (reportName: string, className: string): any => {
  if (reportName === 'Created Reports') {
    return {
      imageUrl: <ReportsIcon className={className} />,
      backgroundColor: '#f0a4a4'
    };
  } else if (reportName === 'Daily Standup') {
    return {
      imageUrl: <StandupIcon className={className} />,
      backgroundColor: '#4eb9a2'
    };
  } else if (reportName === 'Created Workspaces') {
    return {
      imageUrl: <WorkspacesIcon className={className} />,
      backgroundColor: '#4a90e2'
    };
  } else {
    // reportName === 'Received Responses'
    return {
      imageUrl: <ResponsesIcon className={className} />,
      backgroundColor: '#ffba48'
    };
  }
};

export const componentIsVisible = (componentName: string, components: Components[]) => {
  const visibleComponents =
    components
    .filter((component: Components) => component.is_visible)
    .filter((component: Components) => component.name === componentName)
  return visibleComponents.length > 0 
}
