import { SelectOption } from '../pages/shared-components/SelectField';

export function mapToSelectOption(
  list: Array<any>,
  idKey: any,
  valueKey: any,
): SelectOption[] {
  if (!list)
    return [];

  let listOptions: SelectOption[] = [];
  listOptions = list.map(
      element =>
        ({
          id: element[idKey] as Number,
          value: element[valueKey] as String
        } as SelectOption)
    );
  return listOptions;
}
