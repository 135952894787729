import { createStyles, Theme } from '@material-ui/core';

export const usersStyles = (theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      border: '0',
      boxShadow: 'none',
      backgroundColor: '#fff',
      '@media (max-width:650px)': {
        width: '95%'
      }
    },
    cardContent: {
      width: '100%',
      height: '100%',
      padding: '0 !important',
      overflow: 'overlay',
      '@supports ( -moz-appearance:none )': {
        overflow: 'auto'
      },
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: '#fff'
    },
    searchIcon: {
      width: '20px',
      height: '20px',
      opacity: '0.4',
      backgroundColor: '#fff',
      color: '#858d9b'
    },
    clearIcon: {
      width: '18px',
      height: '18px',
      opacity: '0.5',
      backgroundColor: '#fff',
      color: '#858d9b',
      '&:hover': {
        background: 'rgb(251,251,251)',
        cursor: 'pointer'
      }
    },
    searchTextField: {
      flex: 1,
      '@media (max-width:800px)': {
        width: '100%'
      }
    },
    underlineTextField: {
      '&:before': {
        borderBottom: '1px solid #eaeaea !important'
      },
      '&:after': {
        borderBottom: '1px solid #3a78f2'
      }
    },
    textInput: {
      flex: 1,
      color: '#76797f !important',
      height: '27px',
      opacity: '0.8',
      fontFamily: 'Lato',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '2.25',
      letterSpacing: 'normal',
      textOverflow: 'ellipsis'
    },
    membersList: {
      width: '100%',
      height: 'calc(100% - 60px)',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      padding: '0 8px 0 24px',
      '&::-webkit-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      },
      '&::-moz-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-moz-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-moz-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      },
      '&::-ms-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-ms-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-ms-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      }
    },
    memberCard: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'space-between'
    },
    divider: {
      backgroundColor: '#e7e9ea'
    },
    userFilters: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '20px 24px',
      '@media (max-width:800px)': {
        flexDirection: 'column'
      }
    },
    updateButton: {
      width: '138px',
      height: '56px',
      borderRadius: '6px',
      fontFamily: 'Lato',
      textTransform: 'capitalize',
      fontSize: '22px',
      fontWeight: 600,
      lineHeight: '0.86',
      letterSpacing: 'normal',
      backgroundColor: '#f5a623',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#f5a623'
      }
    },
    userRoleFilter: {
      width: '143px',
      height: '37px',
      borderRadius: '4px',
      marginLeft: '20px',
      '& .MuiFormControl-root': {
        height: '100%',
        '& .MuiInputBase-root': {
          border: '1px solid #c3c3c3',
          transition: 'all .2s ease-in-out',
          padding: '0 0 0 16px',
          height: '100%',
          '&:hover,&.Mui-focused': {
            boxShadow: '2px 0 24px 0 rgba(103, 101, 101, 0.14)',
            border: '0.5px solid #3a78f2'
          },
          '& .MuiInputBase-input': {
            padding: '0 0 0 16px !important',
            fontSize: '14px',
            lineHeight: 1.14,
            color: '#354052',
            textAlign: 'left'
          },
          '& .MuiAutocomplete-endAdornment': {
            '& .MuiButtonBase-root': {
              transition: 'all .3s ease-in-out'
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        },
        '& label.Mui-focused': {
          background: '#fff'
        }
      },
      '@media (max-width:800px)': {
        width: '100%',
        margin: '10px 0 0 0'
      }
    },
    userRoleFilterInput: {
      height: '100%',
      '& label': {
        fontSize: '14px',
        lineHeight: 1.14,
        color: '#354052',
        transform: 'translate(14px, 12px) scale(1)'
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: 0
      }
    },
    paper: {
      borderRadius: '4px',
      boxShadow: ' 2px 0 24px 0 rgba(103, 101, 101, 0.14)',
      backgroundColor: '#fff',
      '& ul': {
        padding: '0 16px',
        '& li': {
          padding: '8px 0',
          borderBottom: '1px solid #e7e9ea',
          fontSize: '16px',
          letterSpacing: '0.13px',
          color: '#a3a9b4',
          '&:last-of-type': {
            borderBottom: 'none'
          },
          '&[aria-selected~="true"]': {
            background: '#fff',
            fontSize: '16px',
            lineHeight: 'normal',
            letterSpacing: '0.13px',
            color: '#3a78f2'
          },
          '&[data-focus~="true"]': { background: '#fff' }
        }
      }
    }
  });
