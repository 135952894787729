import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { GroupingProps } from '..';
import { Answer } from '../../../../../../models/Answer.model';
import { Question } from '../../../../../../models/Question.model';
import { Member } from '../../../../../../models/Member.model';
import QuestionResponseCard from './QuestionResponseCard';
import { styles } from './styles';

export interface QuestionResponse {
  member: Member;
  answer: Answer;
}

export const QuestionGrouping = ({ responses, questions }: GroupingProps) => {
  const classes = styles();
  const questionsResponses = useMemo(() => {
    let answers = new Map<number, QuestionResponse[]>();
    for (const response of responses) {
      for (const answer of response.answers) {
        answers
          .get(answer.question_id)
          ?.push({ member: response.member, answer }) ||
          answers.set(answer.question_id, [
            { member: response.member, answer }
          ]);
      }
    }
    return answers;
  }, [responses]);

  return responses.length ? (
    <Grid container item direction="column" className={classes.QuestionGrouping}>
      {questions.map((question: Question, index: number) => {
        const quesResponses = questionsResponses.get(question.id);
        return quesResponses ? (
          <QuestionResponseCard
            question={question}
            questionIndex={index + 1}
            quesResponses={quesResponses}
            key={index}
          />
        ) : null;
      })}
    </Grid>
  ) : null;
};
