import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  PaginatedReportResponses,
  ReportResponse
} from '../../../../models/ReportResponse.model';
import { submitReportRequest } from '../../../../models/ReportResponse.model';
import { submitPollRequest } from '../../../../models/Option.model';
import { Option } from '../../../../models/Option.model';
import { useDispatch } from 'react-redux';
import { addConfirmation } from '../../../../store/confirmations/Actions';
import { Confirmation } from '../../../../models/Confirmation.model';
import { QUERIES } from '../../../../utils/queris';
import { ApiError } from '../../../../models/ApiError.model';
import { addError } from '../../../../store/errors/Actions';
import { Report } from '../../../../models/Report.model';
import { Member } from '../../../../models/Member.model';
import PATH_NAME from '../../../../utils/pathNames';

export const useSubmitReport = (history: any) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    (request: submitReportRequest) => ReportResponse.submit(request),
    {
      onMutate: async request => {
        await queryClient.cancelQueries(QUERIES.LOAD_PENDING_REPORTS);
        const prevPendingReports = queryClient.getQueryData<Report[]>(
          QUERIES.LOAD_PENDING_REPORTS
        );
        const newPendingReports = prevPendingReports?.filter(
          report => report.id !== request.reportId
        );
        queryClient.setQueryData(
          QUERIES.LOAD_PENDING_REPORTS,
          newPendingReports
        );
        return { prevPendingReports };
      },
      onSuccess: async (data, request) => {
        queryClient.invalidateQueries('PaginatedSubmissionCycles');
        dispatch(
          addConfirmation(
            Confirmation.getSuccessConfirmation(`Submit report successfully`)
          )
        );
        queryClient.invalidateQueries([
          QUERIES.LOAD_SUBMISSION_CYCLES,
          request.reportId.toString()
        ]);
        queryClient.invalidateQueries(QUERIES.LOAD_PENDING_REPORTS);
        history.push(`${PATH_NAME.REPORTS}/${request.reportId}`);
      },
      onError: (error, request, context: any) => {
        queryClient.setQueryData(
          QUERIES.LOAD_PENDING_REPORTS,
          context.prevPendingReports
        );
        dispatch(
          addError(ApiError.getError('', 'Failed to submit the report'))
        );
      }
    }
  );
};

export const useSubmitPoll = (history: any) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation((request: submitPollRequest) => Option.submit(request), {
    onMutate: async request => {
      await queryClient.cancelQueries(QUERIES.LOAD_PENDING_REPORTS);
      const prevPendingReports = queryClient.getQueryData<Report[]>(
        QUERIES.LOAD_PENDING_REPORTS
      );
      const newPendingReports = prevPendingReports?.filter(
        report => report.id !== request.reportId
      );
      queryClient.setQueryData(QUERIES.LOAD_PENDING_REPORTS, newPendingReports);
      return { prevPendingReports };
    },
    onSuccess: async (data, request) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(`Submit poll successfully`)
        )
      );
      queryClient.invalidateQueries([
        QUERIES.LOAD_REPORT_RESPONSES,
        request.reportId
      ]);
      queryClient.invalidateQueries(QUERIES.LOAD_PENDING_REPORTS);
      history.push(`${PATH_NAME.REPORTS}/${request.reportId}`);
    },
    onError: (error, request, context: any) => {
      queryClient.setQueryData(
        QUERIES.LOAD_PENDING_REPORTS,
        context.prevPendingReports
      );
      dispatch(addError(ApiError.getError('', 'Failed to submit the poll')));
    }
  });
};

export const usePendingReports = (user: any) => {
  return useQuery<Report[]>(QUERIES.LOAD_PENDING_REPORTS, () =>
    Report.getPendingReports(user.id)
  );
};

export const useMembers = () => {
  return useQuery<Member[]>(QUERIES.LOAD_MEMBERS, () => Member.getAll());
};

export const useLastMemberResponse = (reportId: number, memberId: number) => {
  return useQuery<PaginatedReportResponses>(
    [QUERIES.LOAD_MEMBER_RESPONSES, reportId, memberId],
    () =>
      Member.getMemberResponses({
        report_ids: [reportId],
        member_id: memberId,
        page: 1,
        items: 1
      })
  );
};
