import React, { useCallback, useEffect, useState } from 'react';
import { Report } from '../../../../models/Report.model';
import {
  Button,
  CardActions,
  CardContent,
  CircularProgress
} from '@material-ui/core';
import { styles as newReportStyles } from './styles';
import { ReportFormHeader } from './ReportFormHeader';
import {
  QUESTIONS_TAB_SCHEMA,
  REPORTING_TAB_SCHEMA,
  REPORT_SETUP_SCHEMA,
  TEAMMATES_TAB_SCHEMA
} from './validator';
import { useHistory, useLocation } from 'react-router';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReportSetup from './ReportSetup';
import QuestionsTab from './QuestionsTab';
import TeammatesTab from './TeammatesTab';
import ReportingTab from './ReportingTab';
import { Channel } from '../../../../models/Channel.model';
import { Member } from '../../../../models/Member.model';
import {
  useChannels,
  useCreateReport,
  useMembers,
  useEditReport,
  useReportFormAnalytics
} from './reportFormHooks';
import { REPORT_TYPES } from '../../../../utils/constants';
import { WorkspaceMembersDialog } from './WorkspaceMembersDialog';
import { useWorkspaces } from '../ReportsHooks';
import { Workspace } from '../../../../models/Workspace.model';
import PATH_NAME from '../../../../utils/pathNames';
import dayjs from 'dayjs';
interface Props {
  report: Report;
  updateReportForm: Function;
}

export const ReportForm = ({ report, updateReportForm }: Props) => {
  // Backend API requests
  const [schema, setSchema] = useState<any>(REPORT_SETUP_SCHEMA);
  const [disableCreate, setDisableCreate] = useState(false);
  const { data: channelsList } = useChannels();
  const { data: membersList } = useMembers();
  const editReport = useEditReport();
  const createReport = useCreateReport();
  const sendAnalyticsEvent = useReportFormAnalytics();

  // React-Hook-Form
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: report,
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: false
  });

  const { getValues, control, trigger, reset } = methods;

  const classes = newReportStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [editForm, setEditForm] = useState(false);
  const history = useHistory();
  const location = useLocation();
  // handle select members outside report workspace
  const [openDialog, setOpenDialog] = useState(false);
  const [workspaceExternalMembers, setWorkspaceExternalMembers] = useState<
    Member[]
  >([] as Member[]);
  const [reportWorkspace, setReportWorkspace] = useState<Workspace>();

  useEffect(() => {
    reset(report);
    setActiveStep(0);
    setEditForm(false);
  }, [report.id]);

  useEffect(() => {
    if (activeStep === 0) {
      setSchema(REPORT_SETUP_SCHEMA);
      localStorage.removeItem('lastEventTime');
      localStorage.setItem('lastEventTime', dayjs().toString());
    } else if (activeStep === 1) {
      setSchema(
        QUESTIONS_TAB_SCHEMA(
          REPORT_TYPES.find(t => t.title === report.type.title)?.id ||
            report.type.id
        )
      );
    } else if (activeStep === 2) {
      setSchema(TEAMMATES_TAB_SCHEMA);
    } else if (activeStep === 3) {
      setSchema(REPORTING_TAB_SCHEMA);
    }
  }, [activeStep, report]);

  useEffect(() => {
    if (
      location.pathname.startsWith(
        PATH_NAME.EDIT_REPORT_FORM.replace(':id', '')
      )
    ) {
      setEditForm(true);
    }
  }, [location]);

  const handleBack = useCallback(() => {
    updateReportForm(getValues());
    setActiveStep(activeStep - 1);
  }, [activeStep, updateReportForm, getValues]);

  const handleCancel = useCallback(() => {
    history.push(PATH_NAME.REPORTS);
  }, [history]);

  const { data: workspacesResponse } = useWorkspaces();
  const handleSaveReport = () => {
    const selectedWorkspace = workspacesResponse?.workspaces.find(
      (w: Workspace) => w.id === getValues('workspace').id
    );
    setReportWorkspace(selectedWorkspace);
    const workspaceMemberIds = selectedWorkspace?.members.map(
      (member: Member) => member.id
    );
    const addedMembers = getValues('participants').filter(
      member => workspaceMemberIds && !workspaceMemberIds.includes(member.id)
    );
    const addedAdmins = getValues('admins').filter(
      member => workspaceMemberIds && !workspaceMemberIds.includes(member.id)
    );
    const addedMembersIds = addedMembers.map(m => m.id);
    const distinctMembers = addedMembers.concat(
      addedAdmins.filter(admin => !addedMembersIds.includes(admin.id))
    );
    if (addedMembers.length > 0 || addedAdmins.length > 0) {
      setOpenDialog(true);
      setWorkspaceExternalMembers(distinctMembers);
    } else {
      submitForm();
    }
  };

  const submitForm = useCallback(() => {
    setDisableCreate(true);
    if (report.id === -1) {
      createReport.mutate(report);
    } else editReport.mutate(report);
  }, [createReport, editReport, report]);

  const handleNextForm = async (e: React.MouseEvent) => {
    e.preventDefault();
    const isValid = await trigger();
    if (isValid) {
      if (report.id === -1) sendAnalyticsEvent.mutate(activeStep);
      updateReportForm(getValues());
      if (activeStep === 3) {
        handleSaveReport();
      } else {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleStepNavigation = useCallback(async (step: any) => {
    const isValid = await trigger();
    if (isValid) {
      updateReportForm(getValues());
      setActiveStep(step);
    }
  }, []);

  return (
    <>
      <Controller
        control={control}
        name="type"
        render={() => (
          <ReportFormHeader
            activeStep={activeStep}
            reportTitle={editForm ? report.name : report.type.title}
            editForm={editForm}
            handleStepNavigation={handleStepNavigation}
          />
        )}
      />
      <FormProvider {...methods}>
        <form className={classes.paper}>
          <CardContent className={classes.cardContent}>
            {getStepContent(
              activeStep,
              report.type.title,
              membersList || [],
              channelsList || []
            )}
            <CardActions>
              <Button
                onClick={activeStep === 0 ? handleCancel : handleBack}
                variant="contained"
                classes={{
                  root: classes.actionButton,
                  label: classes.buttonLabel,
                  contained: classes.backButton
                }}
              >
                {activeStep === 0 ? 'Cancel' : 'Back'}
              </Button>

              <Button
                variant="contained"
                onClick={e => {
                  handleNextForm(e);
                }}
                classes={{
                  root: classes.actionButton,
                  label: classes.buttonLabel,
                  contained: classes.nextButton
                }}
                disabled={disableCreate}
              >
                {activeStep === 3
                  ? report.id === -1
                    ? 'Create'
                    : 'update'
                  : 'Next'}
              </Button>
              {disableCreate && <CircularProgress />}
            </CardActions>
          </CardContent>
          {reportWorkspace && (
            <WorkspaceMembersDialog
              open={openDialog}
              setOpen={setOpenDialog}
              workspace={reportWorkspace as Workspace}
              addedMembers={workspaceExternalMembers}
              saveReport={submitForm}
            />
          )}
        </form>
      </FormProvider>
    </>
  );
};

function getStepContent(
  step: number,
  reportTypeTitle: string,
  membersList: Member[],
  channelsList: Channel[]
) {
  switch (step) {
    case 0:
      return <ReportSetup reportTypeTitle={reportTypeTitle} />;
    case 1:
      return <QuestionsTab />;
    case 2:
      return (
        <TeammatesTab membersList={membersList} channelsList={channelsList} />
      );
    case 3:
      return (
        <ReportingTab membersList={membersList} channelsList={channelsList} />
      );
  }
}
