import React, { useState } from 'react';
import WorkspaceCard from './WorkspaceCard';
import { styles } from './styles';
import { useAssignedReports, useUserWorkspaces } from '../UserHooks';
import CircularLoader from '../../../shared-components/CircularLoader';
import { CustomizedPagination } from '../../../shared-components/CustomizedPagination';

interface props {
  userID: string;
}

const UserWorkspaces = ({ userID }: props) => {
  const classes = styles();
  const [page, setPage] = useState(1);

  const { isLoading: isReportsLoading, data: reports } = useAssignedReports(
    userID
  );

  const { isLoading: isWorkspaceLoading, data } = useUserWorkspaces(
    Number(userID),
    page
  );

  return (
    <CircularLoader isLoading={isWorkspaceLoading || isReportsLoading}>
      <div className={classes.root}>
        {data?.workspaces.map((workspace: any) => (
          <WorkspaceCard
            workspace={workspace}
            reports={reports!}
            key={workspace.id}
            userID={userID}
          />
        ))}
        <CustomizedPagination data={data} page={page} setPage={setPage} />
      </div>
    </CircularLoader>
  );
};

export default UserWorkspaces;
