import {API} from "./API";
import axios from '../services/axios'
import {Plan} from './Plan.model'
import store from '../store';
export class Subscription extends API {
	public team_id: number = -1;
    public billing_period: string = '';
    public next_payment: string = '';
    public last_payment: string = '';
    public paid: boolean = true;
	public plan: Plan = new Plan();
	public downgraded: boolean = false;


	public static async getSubscription(): Promise<Subscription> {
		const headers = Subscription.getConfig();

		if (headers) {
			return new Promise<Subscription>((resolve, reject) => {
				axios.get('payments/team_subscription' , headers)
					.then(res => {
						let subscription: Subscription = res.data.subscription;
						resolve(subscription);
					})
					.catch(e => {
						Subscription.checkUnauthorizedRequest(e,store);
						reject(e);
					});
			})
		}

		return Promise.reject('Error while fetching this subscription');
	}

	public static async getPaymentPage(plan_id: number, billing_period: number, customer_ip: string): Promise<any> {
		const headers = Subscription.getConfig();

		if (headers) {
			return new Promise<any>((resolve, reject) => {
				axios.post('payments/accept/payment_url' , {plan_id, customer_ip, billing_period}, headers)
					.then(res => {
						let response = res.data;
						resolve(response);
					})
					.catch(e => {
						Subscription.checkUnauthorizedRequest(e,store);
						reject(e);
					});
			})
		}

		return Promise.reject('System Error');
	}

	// downgrade the current subscription after ending the current payment period
	public static async downgrade(): Promise<Subscription> {
		const headers = Subscription.getConfig();

		if (headers) {
			return new Promise<Subscription>((resolve, reject) => {
				axios.put('payments/subscriptions/downgrade' , {}, headers)
					.then(res => {
						let subscription: Subscription = res.data;
						resolve(subscription);
					})
					.catch(e => {
						Subscription.checkUnauthorizedRequest(e,store);
						reject(e);
					});
			})
		}

		return Promise.reject('System Error');
	}

	// continue the current subscription if it's downgraded before next payment date
	public static async continue(): Promise<Subscription> {
		const headers = Subscription.getConfig();

		if (headers) {
			return new Promise<Subscription>((resolve, reject) => {
				axios.put('payments/subscriptions/continue' , {}, headers)
					.then(res => {
						let subscription: Subscription = res.data;
						resolve(subscription);
					})
					.catch(e => {
						Subscription.checkUnauthorizedRequest(e,store);
						reject(e);
					});
			})
		}

		return Promise.reject('System Error');
	}

}
