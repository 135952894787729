import React, { useEffect, useState, memo } from 'react';
import { Checkbox, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { styles } from './styles';
import classNames from 'classnames';

export interface SelectOption {
  id: any;
  value: string;
}

export interface SelectFieldProps {
  selectItems: SelectOption[];
  selectId: string;
  onSelect: Function; // function that take
  placeholder?: string;
  label?: string;
  value?: any;
  multiple?: boolean;
  popupIcon?: any;
  customClasses?: any; // used to override or extend the styles applied to the component
  customClass?: any;
  error?: boolean;
  errorMessage?: string;
  addAllOption?: boolean;
}

export const SelectField = memo(
  ({
    selectItems,
    label,
    onSelect,
    selectId,
    placeholder = '',
    value,
    multiple = false,
    popupIcon,
    customClasses,
    customClass,
    error,
    errorMessage,
    addAllOption = false
  }: SelectFieldProps) => {
    const classes = styles();
    const [optionsIds, setOptionsIds] = useState([] as number[]);
    const [optionsMap, setOptionsMap] = useState(new Map());
    const [allSelected, setAllSelected] = useState(false);

    useEffect(() => {
      let selectMap = new Map();
      let itemsIds: any[] = [];
      if (addAllOption) {
        itemsIds.push(-1);
        selectMap.set(-1, 'Add all');
      }
      selectItems.forEach(item => {
        itemsIds.push(item.id);
        selectMap.set(item.id, item.value);
      });
      setOptionsIds(itemsIds);
      setOptionsMap(selectMap);
      // eslint-disable-next-line
    }, [selectItems]);

    return (
      <Autocomplete
        classes={
          customClasses
            ? customClasses
            : {
                popper: classes.popper,
                inputRoot: classes.filter
              }
        }
        className={customClass}
        multiple={multiple}
        id={selectId}
        options={optionsIds}
        value={value || undefined}
        disableCloseOnSelect={multiple}
        limitTags={2}
        getOptionLabel={option =>
          optionsMap.get(option) ||
          selectItems.find(item => item.id === option)?.value ||
          option
        }
        getOptionSelected={(option, value) => option === value}
        renderTags={value =>
          multiple ? (
            value.map(val => (
              <span className={classes.selectedNumber}>
                {optionsMap.get(val)}
              </span>
            ))
          ) : (
            <span className={classes.selectedNumber}>{value}</span>
          )
        }
        renderOption={(option, { selected }) => (
          <>
            {multiple && (
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                className={classes.checkButton}
                checked={allSelected || selected}
                color="primary"
              />
            )}
            <Typography
              noWrap
              className={classNames(
                classes.CheckboxTxt,
                selected ? classes.selectedCheckbox : classes.unSelectedCheckbox
              )}
            >
              {optionsMap.get(option)}
            </Typography>
          </>
        )}
        renderInput={params => (
          <TextField
            {...params}
            size="small"
            className={classes.filterText}
            variant="outlined"
            label={label}
            placeholder={placeholder}
            helperText={error ? errorMessage : undefined}
            error={error}
          />
        )}
        onChange={(event: object, selectedValue: any) => {
          if (addAllOption) {
            if (selectedValue.includes(-1)) {
              setAllSelected(true);
              selectedValue = optionsIds.filter(option => option !== -1);
            } else if (allSelected) {
              setAllSelected(false);
            }
          }
          onSelect(selectId, selectedValue);
        }}
        popupIcon={
          popupIcon || <KeyboardArrowDownIcon className={classes.dropIcon} />
        }
      />
    );
  }
);
