import { createStyles, makeStyles } from '@material-ui/core/styles';

export const workspaceCardStyles = makeStyles(() =>
  createStyles({
    card: {
      width: '100%',
      marginBottom: '16px',
      padding: '16px',
      borderRadius: '10px',
      boxShadow: '0 2px 2px 0 rgba(37, 37, 37, 0.03)',
      border: 'solid 1px #ededed',
      backgroundColor: '#ffffff',
      '@media (max-width:520px)': {
        width:'95%',
        margin:'8px auto',
      }
    },
    expandButton: {
      padding: 0
    },
    expandIcon: {
      opacity: 0.5,
      color: '#858d9b'
    },
    title: {
      fontSize: '18px',
      fontWeight: 'bold'
    },
    cardContent: {
      padding: 0
    },
    emptyWorkSpace: {
      fontSize: '16px',
      fontWeight: 600,
      color: '#354052',
      opacity: 0.4,
      textAlign: 'center',
      paddingTop: '40px'
    }
  })
);

