import React from 'react';
import { useReadNotification } from './notificationsHooks';
import { notificationStyles } from './styles';
import { Notification } from '../../../../models/Notification.model';
import { withStyles, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import {
  getNotificationIcon,
  getNotificationURL
} from '../../../../utils/notifications';
import classNames from 'classnames';

interface Props {
  notification: Notification;
  classes: any;
}

const NotificationElement = ({
  notification,
  classes
}: Props) => {
  const readNotification = useReadNotification();
  const history = useHistory();
  const handleViewNotification = () => {
    if (!notification.read_at) {
      readNotification.mutate(notification.id);
    }
    history.push(getNotificationURL(notification));
  };
  const handleMarkAsReadNotification = (e: any) => {
    e.stopPropagation();
    if (!notification.read_at) {
      readNotification.mutate(notification.id);
    }
  };

  return (
    <div
      onClick={handleViewNotification}
      className={
        notification.read_at
          ? classes.notificationElementWrapper
          : classNames(
              classes.notificationElementWrapper,
              classes.unreadnotificationElement
            )
      }
    >
      <div className={classes.notificationElement}>
        <div className={classes.notificationIconContent}>
          <img
            className={classes.imgWrapper}
            src={getNotificationIcon(notification.notify_type)}
          />
          <p
            className={
              notification.read_at
                ? classes.notificationText
                : classNames(
                    classes.notificationText,
                    classes.unreadNotification
                  )
            }
          >
            {notification.title}
          </p>
        </div>
        {!notification.read_at && (
          <Button
            className={classes.unreadBadge}
            onClick={handleMarkAsReadNotification}
          ></Button>
        )}
      </div>
    </div>
  );
};

export default withStyles(notificationStyles)(React.memo(NotificationElement));
