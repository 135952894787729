import React, { useContext } from 'react';
import { Typography, Link, Box } from '@material-ui/core';
import classNames from 'classnames';
import { User } from '../../../models/User.model';
import { AppContext } from '../../../App';
import BscrumLogo from '../../../assets/images/bscrum-logo.svg';
import { styles } from './styles';

export default function Privacy() {
  const classes = styles();
  const currentUser: User = useContext(AppContext);

  return (
    <div>
      <div className={classes.preIntro}>
        {currentUser ? (
          <img src={BscrumLogo} alt="bscrum logo" className={classes.logo} />
        ) : null}

        <Typography
          className={classNames(classes.bold, classes.h1, classes.centeredText)}
        >
          PRIVACY NOTICE
        </Typography>
        <Typography
          className={classNames(classes.subTitle, classes.centeredText)}
        >
          Please read this privacy policy carefully as it will help you make
          informed decisions about <br></br>sharing your personal information
          with us.
        </Typography>
        <Typography
          className={classNames(classes.centeredText, classes.updateDate)}
        >
          Last updated November 22, 2020
        </Typography>
      </div>
      <div className={classes.wrapper}>
        <div className="intro">
          <Typography className={classNames(classes.bold, classes.h2)}>
            Introduction
          </Typography>

          <Typography
            className={classNames(
              classes.answer,
              classes.lightGray,
              classes.myListItem
            )}
          >
            Thank you for choosing to be part of our community at Blink22
            ("company", "we", "us", "our"). We are committed to protecting your
            personal information and your right to privacy. If you have any
            questions or concerns about our policy, or our practices with
            regards to your personal information, please contact us at
            info@blink22.com.
          </Typography>
          <Typography
            className={classNames(
              classes.answer,
              classes.lightGray,
              classes.myListItem
            )}
          >
            When you visit our website
            <Link href="http://bscrum.io" className={classes.lightGray}>
              {' '}
              http://bscrum.io
            </Link>
            , and use our services, you trust us with your personal information.
            We take your privacy very seriously. In this privacy notice, we
            describe our privacy policy. We seek to explain to you in the
            clearest way possible what information we collect, how we use it and
            what rights you have in relation to it. We hope you take some time
            to read through it carefully, as it is important. If there are any
            terms in this privacy policy that you do not agree with, please
            discontinue use of our Sites and our services.
          </Typography>
          <Typography
            className={classNames(
              classes.answer,
              classes.lightGray,
              classes.myListItem
            )}
          >
            This privacy policy applies to all information collected through our
            website (such as
            <Link href="http://bscrum.io" className={classes.lightGray}>
              {' '}
              http://bscrum.io
            </Link>
            ), and/or any related services, sales, marketing or events (we refer
            to them collectively in this privacy policy as the "Sites").
          </Typography>

          <Typography className={classNames(classes.bold, classes.h2)}>
            TABLE OF CONTENTS
          </Typography>
          <div className={classes.linksWrapper}>
            <div className={classes.linksBlock}>
              <Link href="#one" className={classes.tableContentItem}>
                01. What information do we collect?{' '}
              </Link>
              <Link className={classes.tableContentItem} href="#two">
                02. How do we use your information?{' '}
              </Link>
              <Link className={classes.tableContentItem} href="#three">
                03. Will your information be shared with anyone?{' '}
              </Link>
              <Link className={classes.tableContentItem} href="#four">
                04. How long do we keep your information?{' '}
              </Link>{' '}
            </div>

            <div className={classes.linksBlock}>
              <Link className={classes.tableContentItem} href="#five">
                05. How do we keep your information safe?{' '}
              </Link>
              <Link className={classes.tableContentItem} href="#six">
                06. Do we collect information from minors?{' '}
              </Link>
              <Link className={classes.tableContentItem} href="#seven">
                07. What are your privacy rights?{' '}
              </Link>
              <Link className={classes.tableContentItem} href="#eight">
                08. Controls for do-not track features{' '}
              </Link>{' '}
            </div>

            <div className={classes.linksBlock}>
              <Link className={classes.tableContentItem} href="#nine">
                09. Do California residents have specific privacy rights?{' '}
              </Link>
              <Link className={classes.tableContentItem} href="#ten">
                10. Do we make updates for this notice?{' '}
              </Link>
              <Link className={classes.tableContentItem} href="#eleven">
                11. How can you contact us about this notice?{' '}
              </Link>{' '}
            </div>
          </div>
        </div>

        <div id="one">
          <Typography className={classNames(classes.bold, classes.h2)}>
            01. What information do we collect?{' '}
          </Typography>
          <Typography
            className={classNames(classes.bold, classes.h3, classes.myListItem)}
          >
            Personal information you disclose to us
          </Typography>
          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}>In Short: </Typography> We
              collect personal information that you provide to us such as name,
              address, contact information, passwords and security data, and
              payment information.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              We collect personal information that you voluntarily provide to us
              when registering at the Sites expressing an interest in obtaining
              information about us or our products and services, when
              participating in activities on the Sites or otherwise contacting
              us.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              The personal information that we collect depends on the context of
              your interactions with us and the Sites, the choices you make and
              the products and features you use. The personal information we
              collect may include the following:
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}>
                {' '}
                Name and Contact Data.
              </Typography>{' '}
              We collect your first and last name, email address, postal
              address, phone number, and other similar contact data.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}>Credentials. </Typography>We
              collect passwords, password hints, and similar security
              information used for authentication and account access.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}> Payment Data.</Typography>{' '}
              We collect data necessary to process your payment if you make
              purchases, such as your payment instrument number (such as a
              credit card number), and the security code associated with your
              payment instrument. All payment data is stored by our payment
              processor and you should review its privacy policies and contact
              the payment processor directly to respond to your questions.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              All personal information that you provide to us must be true,
              complete and accurate, and you must notify us of any changes to
              such personal information.
            </Typography>
          </Box>
          <Typography
            className={classNames(classes.bold, classes.h3, classes.myListItem)}
          >
            Information collected from other sources
          </Typography>
          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}>In Short: </Typography> We
              may collect limited data from public databases, marketing
              partners, and other outside sources.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              We may obtain information about you from other sources, such as
              public databases, joint marketing partners, as well as from other
              third parties. Examples of the information we receive from other
              sources include: social media profile information; marketing leads
              and search results and links, including paid listings (such as
              sponsored links).
            </Typography>
          </Box>
        </div>

        <div id="two">
          <Typography className={classNames(classes.bold, classes.h2)}>
            02. How do we use your information?{' '}
          </Typography>
          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}>In Short: </Typography>We
              process your information for purposes based on legitimate business
              interests, the fulfillment of our contract with you, compliance
              with our legal obligations, and/or your consent.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              We use personal information collected via our Sites for a variety
              of business purposes described below. We process your personal
              information for these purposes in reliance on our legitimate
              business interests ("Business Purposes"), in order to enter into
              or perform a contract with you ("Contractual"), with your consent
              ("Consent"), and/or for compliance with our legal obligations
              ("Legal Reasons"). We indicate the specific processing grounds we
              rely on next to each purpose listed below.
            </Typography>
          </Box>

          <Typography className={classNames(classes.bold, classes.h3)}>
            We use the information we collect or receive:
          </Typography>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}>
                To facilitate account creation and logon process.
              </Typography>
              If you choose to link your account with us to a third party
              account (such as your Google or Facebook account), we use the
              information you allowed us to collect from those third parties to
              facilitate account creation and logon process.
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}>
                To send you marketing and promotional communications.
              </Typography>
              We and/or our third party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. You can opt-out of
              our marketing emails at any time (see the
              <Link href="#seven" className={classes.answer}>
                "WHAT ARE YOUR PRIVACY RIGHTS"
              </Link>{' '}
              below).
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}>
                To send administrative information to you.{' '}
              </Typography>
              We may use your personal information to send you product, service
              and new feature information and/or information about changes to
              our terms, conditions, and policies.
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}>
                Fulfill and manage your orders.
              </Typography>
              We may use your information to fulfill and manage your orders,
              payments, returns, and exchanges made through the Sites.
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}>
                Request Feedback.
              </Typography>
              We may use your information to request feedback and to contact you
              about your use of our Sites.
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}>
                For other Business Purposes.
              </Typography>
              We may use your information for other Business Purposes, such as
              data analysis, identifying usage trends, determining the
              effectiveness of our promotional campaigns and to evaluate and
              improve our Sites, products, services, marketing and your
              experience.
            </Typography>
          </Box>
        </div>
        <div id="three">
          <Typography className={classNames(classes.bold, classes.h2)}>
            03. Will your information be shared with anyone?{' '}
          </Typography>

          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}>In Short: </Typography>We
              only share information with your consent, to comply with laws, to
              provide you with services, to protect your rights, or to fulfill
              business obligations.
            </Typography>
          </Box>
          <Typography className={classNames(classes.bold, classes.h3)}>
            We may process or share your data that we hold based on the
            following legal basis:
          </Typography>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}>Consent:</Typography>We may
              process your data if you have given us specific consent to use
              your personal information for a specific purpose.
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}>
                Legitimate Interests:
              </Typography>
              We may process your data when it is reasonably necessary to
              achieve our legitimate business interests.
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}>
                Performance of a Contract:
              </Typography>
              Where we have entered into a contract with you, we may process
              your personal information to fulfill the terms of our contract.
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}>
                Legal Obligations:
              </Typography>
              We may disclose your information where we are legally required to
              do so in order to comply with applicable law, governmental
              requests, a judicial proceeding, court order, or legal process,
              such as in response to a court order or a subpoena (including in
              response to public authorities to meet national security or law
              enforcement requirements).
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}>
                Vital Interests:{' '}
              </Typography>
              We may disclose your information where we believe it is necessary
              to investigate, prevent, or take action regarding potential
              violations of our policies, suspected fraud, situations involving
              potential threats to the safety of any person and illegal
              activities, or as evidence in litigation in which we are involved.
            </Typography>
          </Box>
          <Typography className={classNames(classes.bold, classes.h3)}>
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </Typography>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}>
                Business Transfers.{' '}
              </Typography>
              We may share or transfer your information in connection with, or
              during negotiations of, any merger, sale of company assets,
              financing, or acquisition of all or a portion of our business to
              another company.
            </Typography>
          </Box>
        </div>
        <div id="four">
          <Typography className={classNames(classes.bold, classes.h2)}>
            04. How long do we keep your information?{' '}
          </Typography>
          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}>In Short:</Typography>We keep
              your information for as long as necessary to fulfill the purposes
              outlined in this privacy policy unless otherwise required by law.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy policy, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              policy will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize it, or,
              if this is not possible (for example, because your personal
              information has been stored in backup archives), then we will
              securely store your personal information and isolate it from any
              further processing until deletion is possible.
            </Typography>
          </Box>
        </div>
        <div id="five">
          <Typography className={classNames(classes.bold, classes.h2)}>
            05. How do we keep your information safe?{' '}
          </Typography>
          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}>In Short:</Typography>We aim
              to protect your personal information through a system of
              organisational and technical security measures.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              We have implemented appropriate technical and organisational
              security measures designed to protect the security of any personal
              information we process. However, please also remember that we
              cannot guarantee that the internet itself is 100% secure. Although
              we will do our best to protect your personal information,
              transmission of personal information to and from our Sites is at
              your own risk. You should only access the services within a secure
              environment.
            </Typography>
          </Box>
        </div>
        <div id="six">
          <Typography className={classNames(classes.bold, classes.h2)}>
            06. Do we collect information from minors?{' '}
          </Typography>
          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}>In Short:</Typography>We do
              not knowingly collect data from or market to children under 18
              years of age.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Sites, you represent that you are at
              least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Sites. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at info@blink22.com.
            </Typography>
          </Box>
        </div>
        <div id="seven">
          <Typography className={classNames(classes.bold, classes.h2)}>
            07. What are your privacy rights?{' '}
          </Typography>

          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}>In short: </Typography>n some
              regions, such as the European Economic Area, you have rights that
              allow you greater access to and control over your personal
              information. You may review, change, or terminate your account at
              any time.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              In some regions (like the European Economic Area), you have
              certain rights under applicable data protection laws. These may
              include the right (i) to request access and obtain a copy of your
              personal information, (ii) to request rectification or erasure;
              (iii) to restrict the processing of your personal information; and
              (iv) if applicable, to data portability. In certain circumstances,
              you may also have the right to object to the processing of your
              personal information. To make such a request, please use the
              <Link href="#eleven"></Link>provided below. We will consider and
              act upon any request in accordance with applicable data protection
              laws.
            </Typography>
          </Box>

          <Box>
            <Typography className={classes.answer}>
              If we are relying on your consent to process your personal
              information, you have the right to withdraw your consent at any
              time. Please note however that this will not affect the lawfulness
              of the processing before its withdrawal.<br></br> If you are a
              resident in the European Economic Area and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:{' '}
              <Link
                href="https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080"
                className={classes.answer}
              >
                {' '}
                http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
              </Link>
            </Typography>
            <Typography className={classNames(classes.bold, classes.h3)}>
              Account Information
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </Typography>
          </Box>

          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              <Typography className={classes.bold}></Typography>Contact us using
              the contact information provided.
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, some information my be retained in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with legal requirements.
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classNames(classes.answer, classes.myListItem)}
            >
              Opting out of email marketing: You can unsubscribe from our
              marketing email list at any time by clicking on the unsubscribe
              link in the emails that we send or by contacting us using the
              details provided below. You will then be removed from the
              marketing email list — however, we will still need to send you
              service-related emails that are necessary for the administration
              and use of your account. To otherwise opt-out, you may Access your
              account settings and update preferences.
            </Typography>
          </Box>
        </div>
        <div id="eight">
          <Typography className={classNames(classes.bold, classes.h2)}>
            08. Controls for do-not track features{' '}
          </Typography>
          <Box>
            <Typography className={classes.answer}>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. No
              uniform technology standard for recognizing and implementing DNT
              signals has been finalized. As such, we do not currently respond
              to DNT browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online. If a standard
              for online tracking is adopted that we must follow in the future,
              we will inform you about that practice in a revised version of
              this Privacy Policy.
            </Typography>
          </Box>
        </div>
        <div id="nine">
          <Typography className={classNames(classes.bold, classes.h2)}>
            09. Do California residents have specific privacy rights?{' '}
          </Typography>
          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}>In short: </Typography>Yes,
              if you are a resident of California, you are granted specific
              rights regarding access to your personal information.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              If you are under 18 years of age, reside in California, and have a
              registered account with the Sites, you have the right to request
              removal of unwanted data that you publicly post on the Sites. To
              request removal of such data, please contact us using the contact
              information provided below, and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Sites, but please be aware that the data may not be
              completely or comprehensively removed from all our systems.
            </Typography>
          </Box>
        </div>
        <div id="ten">
          <Typography className={classNames(classes.bold, classes.h2)}>
            10. Do we make updates for this notice?{' '}
          </Typography>
          <Box>
            <Typography className={classes.answer}>
              <Typography className={classes.bold}>In Short: </Typography> Yes,
              we will update this policy as necessary to stay compliant with
              relevant laws.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              We may update this privacy policy from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy policy, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy policy frequently to be informed of how we are
              protecting your information.
            </Typography>
          </Box>
        </div>
        <div id="eleven">
          <Typography className={classNames(classes.bold, classes.h2)}>
            11. How can you contact us about this notice?{' '}
          </Typography>
          <Box>
            <Typography className={classes.answer}>
              If you have questions or comments about this policy, you may email
              us at info@blink22.com or by post to:
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.answer}>
              Blink22<br></br> 1 Amir AlBehar st., Roushdy Alexandria, Egypt
              Studio 104<br></br>
              Alexandria, __________ <br></br>Egypt
            </Typography>
          </Box>
        </div>
        <div id="">
          <Typography className={classNames(classes.bold, classes.h2)}>
            How can you review, update, or delete the data we collect from you?{' '}
          </Typography>
          <Box>
            <Typography className={classes.answer}>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please submit a request form by clicking{' '}
              <Link
                href="https://app.termly.io/notify/1daec413-cf73-4aa1-b622-5202cde5407d"
                className={classes.answer}
              >
                here
              </Link>
              . We will respond to your request within 30 days.
            </Typography>
          </Box>
        </div>
      </div>
    </div>
  );
}
