import React, { useState } from 'react';
import { styles } from './styles';
import {
  Typography,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Link,
  Box
} from '@material-ui/core';
import { Activity as ActivityModel } from '../../../../models/Activity.model';
import { ReactComponent as TimeIcon } from '../../../../assets/images/ic-time.svg';
import { getTimeFromDateSeconds } from '../../../../utils/time';
import { useHistory } from 'react-router';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ResponseContent from '../../Reports/SubmissionCycles/SubmissionCycleCard/Responses/ResponseCard/ResponseContent';
import { Link as RLink } from 'react-router-dom';
import { getUserProfileURL, getReportURL } from '../../../../utils/routes';

interface Props {
  activity: ActivityModel;
  defaultExpand: boolean;
}

export const Activity = React.memo(({ activity, defaultExpand }: Props) => {
  const classes = styles();
  const history = useHistory();
  const [expand, setExpand] = useState(defaultExpand);

  const handleExpandActivity = () => {
    setExpand(!expand);
  };

  return (
    <div className={classes.activityCardWrapper}>
      <div key={activity.response.id} className={classes.activityCard}>
        <ListItem className={classes.listItem} id={`${activity.response.id}`}>
          <div className={classes.avatarWrapper}>
            <RLink to={getUserProfileURL(activity.response.member.id)}>
              <ListItemAvatar>
                <Avatar
                  src={activity.response.member.avatar}
                  alt={activity.response.member.name}
                  className={classes.activityAvatar}
                />
              </ListItemAvatar>
            </RLink>

            <ListItemText
              className={classes.reportContent}
              secondary={
                <>
                  <RLink
                    to={getUserProfileURL(activity.response.member.id)}
                    className={classes.memberLink}
                  >
                    <span className={classes.activityTextPrimary}>
                      {activity.response.member.name}
                    </span>
                  </RLink>

                  <span className={classes.activityTextSecondary}>
                    {` added their status for the report `}
                  </span>

                  <Link
                    className={classes.reportName}
                    href="#"
                    onClick={(event: any) => {
                      event.preventDefault();
                      history.push(getReportURL(activity.report));
                    }}
                  >
                    {`${activity.report.name}`}
                  </Link>

                  {activity.report.workspace?.name && (
                    <>
                      <span className={classes.activityTextSecondary}>
                        {`  for workspace `}
                      </span>
                      <span className={classes.activityTextPrimary}>
                        {activity.report.workspace?.name}
                      </span>
                    </>
                  )}
                </>
              }
            />
          </div>

          <p className={classes.reportTypeText}>{activity.report.type.title}</p>

          <div className={classes.dateWrapper}>
            <TimeIcon className={classes.timeIcon} />
            <Typography className={classes.reportTimeText}>
              {getTimeFromDateSeconds(activity.response.time)}
            </Typography>
            <IconButton
              className={classes.iconButton}
              onClick={handleExpandActivity}
              aria-label="expand"
            >
              {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
        </ListItem>
      </div>

      {expand && (
        <ResponseContent
          questions={activity.report.questions}
          response={activity.response}
        />
      )}
    </div>
  );
});
