import { makeStyles, createStyles } from '@material-ui/core/styles';

export const subMenuStyles = makeStyles(theme =>
  createStyles({
    moreIcon: {
      opacity: 0.35,
      color: '#000',
      '&:hover': {
        cursor: 'pointer'
      },
      '& svg': {
        opacity: 0.3
      }
    },
    WorkspacesMoreIcon: {
      opacity: 0.35,
      color: '#000',
      marginLeft: '30px',
      '&:hover': {
        cursor: 'pointer'
      },
      '& svg': {
        opacity: 0.3
      }
    },
    dotsIcon: {
      width: '26px',
      color: '#000',
      opacity: 0.2
    },
    emptyDiv: {
      width: '26px',
      margin: '0 0 0 30px'
    },
    actionMenuItem: {
      opacity: '0.8',
      fontSize: '19px',
      color: '#474f58',
      fontFamily: 'Lato'
    },
    actionMenuItemImportant: {
      opacity: '0.8',
      fontSize: '19px',
      color: '#d0021b',
      fontFamily: 'Lato'
    },
    actionMenuItemImportantGreen: {
      color: '#7ed321'
    },
    subMenuPaper: {
      width: '135px',
      backgroundColor: '#fff',
      boxShadow: '2px 0 24px 0 rgba(103, 101, 101, 0.14)',
      left: 'unset !important',
      right: '64px'
    },
    altSubMenuPaper: {
      right: '455px'
    }
  })
);
