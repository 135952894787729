import { createStyles, makeStyles } from '@material-ui/core';

const styles = makeStyles(() =>
  createStyles({
    howItWorksWrapper: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      alignItems: 'center',
      minHeight: '560px',
      padding: '57px 0',
      maxWidth: '1440px',
      margin: '0 auto',
      '@media only screen and (max-width: 1000px)': {
        gridTemplateColumns: '100%'
      }
    },
    howItWorksLeftSide: {
      height: '100%',
      paddingRight: '50px',
      '@media only screen and (max-width: 1040px)': {
        paddingRight: '25px !important'
      },
      '@media only screen and (max-width: 1000px)': {
        paddingRight: '25px !important'
      }
    },
    carouseWrapper: {
      height: '100%',
      paddingLeft: '100px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .indicators': {
        '& .MuiSvgIcon-root': {
          fill: '#3a78f2',
          fontSize: '3.1rem'
        },
        '& .MuiIconButton-root': {
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      },
      '@media only screen and (max-width: 1040px)': {
        paddingLeft: '50px !important'
      },
      '@media only screen and (max-width: 1000px)': {
        paddingLeft: '25px !important'
      }
    },
    carouseWrapperTitle: {
      fontSize: '28px',
      fontWeight: 800,
      color: '#3a78f2',
      marginBottom: '46px'
    },
    carouselItemTitle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '27px'
    },
    carouselItemName: {
      fontSize: '40px',
      fontWeight: 'bold',
      color: '#55647d',
      margin: '0',
      '@media only screen and (max-width: 1000px)': {
        fontSize: '30px !important'
      },
      '@media only screen and (max-width: 2100px)': {
        fontSize: '35px !important'
      }
    },

    carouselItemTitleCount: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '50px',
      height: '50px',
      borderRadius: '8px',
      backgroundColor: 'rgba(58, 120, 242, 0.1)',
      overflow: 'hidden',
      marginRight: '24px',
      '& span': {
        height: '100%',
        fontSize: '40px',
        fontWeight: '900',
        lineHeight: '1.4',
        color: 'rgba(58, 120, 242, 0.5)'
      }
    },
    carouselItemContent: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '1.5',
      opacity: '0.8',
      color: '#55647d',
      margin: '0',
      minHeight: '108px'
    },
    indicators: {
      textAlign: 'unset'
    },
    howItWorksRightSide: {
      height: '100%',
      paddingLeft: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '@media only screen and (max-width: 1040px)': {
        paddingLeft: '25px !important'
      },
      '@media only screen and (max-width: 1000px)': {
        display: 'none !important'
      }
    },
    slideLeft: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '@media only screen and (max-width: 1040px)': {
        paddingRight: '50px !important'
      }
    },
    howItWorksImgWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    howItWorksImg: {
      width: '100%',
      height: '100%'
    }
  })
);
export default styles;
