import { Grid } from '@material-ui/core';
import React from 'react';
import { Member } from '../../../../../../models/Member.model';
import { CommentEditor } from './CommentEditor';
import { LikeButton } from './LikeButton';
import { attachmentsStyles } from './styles';

interface IProps {
    responseId: number;
    reportId: number;
    members: Member[];
}

export const ResponseReaction = ({ responseId, reportId, members }: IProps) => {
    const classes = attachmentsStyles();
    return (
        <Grid item container className={classes.reactionWrapper}>
            <Grid item lg={1} md={2}> <LikeButton responseId={responseId} /></Grid>
            <Grid item lg={11} md={10}> <CommentEditor responseId={responseId} reportId={reportId} members={members} /></Grid>
        </Grid>
    )
}