import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { styles } from './../styles';

interface Props {
  data: Array<any> | boolean;
  loading: boolean;
  emptyComponent: any;
  children: any;
}

// a HOC for empty state and loading
export const EmptyStateHandler = ({
  data,
  loading,
  emptyComponent,
  children
}: Props) => {
  const classes = styles();

  if (loading) return <CircularProgress className={classes.loader} />;

  if ((Array.isArray(data) && data.length === 0) || data === false)
    return emptyComponent;
  // if data resolves to true
  return children;
};
