import React, { useState, useEffect } from 'react';
import { Report } from '../../../../models/Report.model';
import { getReportTypeById } from '../../../../models/ReportType.model';
import { useParams } from 'react-router';
import { ReportForm } from './ReportForm';
import { QUERIES } from '../../../../utils/queris';
import { useQuery } from 'react-query';
import { PlainLoader } from '../../../shared-components/PlainLoader';

interface Params {
  id?: string;
  operation?: string;
}

const initReport = (reportTypeId?: number | string): Report | undefined => {
  const reportType = getReportTypeById(Number(reportTypeId));
  if (reportType) {
    const report = new Report(reportType);
    return report;
  }
  return undefined;
};

const NewReportForm = () => {
  const { id, operation }: Params = useParams();
  const [reportFormObject, setReportFormObject] = useState<Report | undefined>(
    undefined
  );

  useEffect(() => {
    if (operation === 'create') {
      setReportFormObject(initReport(id));
    }
  }, [operation, id]);

  const { isLoading } = useQuery<Report>(
    [QUERIES.LOAD_REPORT, id],
    () => Report.getReport(Number(id)),
    {
      enabled: Number.isInteger(Number(id)) && operation === 'edit',
      onSuccess: (report: Report) => {
        setReportFormObject(report);
      }
    }
  );

  const updateReportForm = (updated_values: any) => {
    setReportFormObject(Object.assign(reportFormObject, updated_values));
  };

  if (!isLoading && reportFormObject)
    return (
      <ReportForm
        report={reportFormObject}
        updateReportForm={updateReportForm}
      />
    );
  return <PlainLoader />;
};

export default NewReportForm;
