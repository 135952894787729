export default {
  whatsNew: {
    button: "What's New!"
  },
  releaseNotes: {
    boards: {
      title: {
        header: "What's New? 🚀",
        subHeader: "Stay up to date with the latest BSCRUM roadmap and release notes.",
      },

    },
    releaseNotes: {
      title: {
        header: "Release Notes 🎉",
        subHeader: "out all latest releases!",
      }
    }
  },
  boards: {
    emptyList: "this list is empty 😔"
  },
  workspaces: {
    edit: {
      confirmSubmit: {
        header :"Confirm action",
        message: "Removing members from a workspace will remove them from all the reports inside that workspace",
        confirm: "confirm"
      }
    }
  },
}
