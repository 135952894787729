import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  EditorState,
  RichUtils,
  KeyBindingUtil,
  getDefaultKeyBinding,
  DraftHandleValue
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import { Grid, Typography } from '@material-ui/core';
import createMentionPlugin, {
  defaultSuggestionsFilter
} from '@draft-js-plugins/mention';
import getMentionedMembers from './mentions';
import 'draft-js/dist/Draft.css';
import { CustomToolBar, styleMap } from './CustomToolBar';
import { customEditorStyles } from './customEditorStyles';
import { EDITOR_STYLES } from '../../../utils/constants';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';

interface customiedEditorProps {
  members: any[];
  editorState: EditorState;
  setEditorState: any;
  placeholder: string;
  editorStyleClass: any;
  rootStyleClass?: any;
  editMode: boolean;
  submitEdit: any;
  cancelEdit: any;
}

export function CustomizedEditor(props: customiedEditorProps): ReactElement {
  const classes = customEditorStyles();
  const {
    members,
    placeholder,
    setEditorState,
    editorStyleClass,
    rootStyleClass,
    editMode
  } = props;
  const ref = useRef<Editor>(null);
  const [open, setOpen] = useState(false);
  const mentions = getMentionedMembers(members);
  const [suggestions, setSuggestions] = useState(mentions);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent(mentionProps) {
        return (
          <span className={mentionProps.className}>
            {mentionProps.children}
          </span>
        );
      },
      mentionPrefix: '@',
      supportWhitespace: true
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const handleMentionSuggestionOpen = (_open: boolean) => {
    setOpen(_open);
    //remove bindings while the suggestions popup is open and apply it again when closed.
    if (_open) {
      removeBinding();
    } else {
      applyBinding();
    }
  };

  const onSearchChange = useCallback(({ value }: { value: string }) => {
    setSuggestions(defaultSuggestionsFilter(value, mentions));
  }, []);

  const onChange = (editorState: any) => {
    setEditorState(editorState);
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    let newEditorState: any = RichUtils.handleKeyCommand(
      props.editorState,
      command
    );
    if (command === EDITOR_STYLES.Strikethrough) {
      newEditorState = RichUtils.toggleInlineStyle(
        props.editorState,
        EDITOR_STYLES.Strikethrough
      );
    }
    if (command === EDITOR_STYLES.Code) {
      newEditorState = RichUtils.toggleInlineStyle(
        props.editorState,
        EDITOR_STYLES.Code
      );
    }
    if (command === EDITOR_STYLES.OrderList) {
      newEditorState = RichUtils.toggleBlockType(
        props.editorState,
        EDITOR_STYLES.OrderList
      );
    }
    if (command === EDITOR_STYLES.UnorderList) {
      newEditorState = RichUtils.toggleBlockType(
        props.editorState,
        EDITOR_STYLES.UnorderList
      );
    }
    if (newEditorState) {
      onChange(newEditorState);
      return 'handled';
    }
    return 'not-handled';
  };

  const keyBindingFn =
    () =>
    (event: any): string | null => {
      if (
        KeyBindingUtil.hasCommandModifier(event) &&
        event.shiftKey &&
        (event.key === 'X' || event.key === 'x')
      ) {
        return EDITOR_STYLES.Strikethrough;
      }

      if (
        KeyBindingUtil.hasCommandModifier(event) &&
        event.shiftKey &&
        (event.key === 'C' || event.key === 'c')
      ) {
        return EDITOR_STYLES.Code;
      }

      if (
        KeyBindingUtil.hasCommandModifier(event) &&
        event.shiftKey &&
        event.key === '&'
      ) {
        return EDITOR_STYLES.OrderList;
      }

      if (
        KeyBindingUtil.hasCommandModifier(event) &&
        event.shiftKey &&
        event.key === '*'
      ) {
        return EDITOR_STYLES.UnorderList;
      }

      return getDefaultKeyBinding(event);
    };
  const [keyBinding, setKeyBinding] = useState<any>(keyBindingFn);

  const toggleBlockType = (blocktype: any) => {
    onChange(RichUtils.toggleBlockType(props.editorState, blocktype));
  };

  const toggleInlineStyle = (inlineStyle: any) => {
    onChange(RichUtils.toggleInlineStyle(props.editorState, inlineStyle));
  };

  const checkPlaceholder = () => {
    const contentState = props.editorState.getCurrentContent();
    if (
      !contentState.hasText() &&
      contentState.getBlockMap().first().getType() !== 'unstyled'
    ) {
      return '';
    }
    return placeholder;
  };

  const removeBinding = () => {
    setKeyBinding(null);
  };

  const applyBinding = () => {
    setKeyBinding(keyBindingFn);
  };

  return (
    <div className={classNames(classes.root, rootStyleClass)}>
      <div
        className={editorStyleClass}
        onClick={() => {
          ref.current!.focus();
        }}
      >
        <Grid item container spacing={2} >
          <Grid item xs>
            <Editor
              customStyleMap={styleMap}
              handleKeyCommand={handleKeyCommand}
              keyBindingFn={keyBinding}
              editorKey={'editor'}
              editorState={props.editorState}
              onChange={onChange}
              plugins={plugins}
              ref={ref}
              placeholder={checkPlaceholder()}
            />
          </Grid>
            {editMode && (
              <Typography
                className={classes.submitBtn}
                onClick={props.submitEdit}
              >
                Update Comment
              </Typography>
            )}
            {editMode && (
             <CloseIcon
              className={classes.closeButton}
              onClick={props.cancelEdit}
              />
            )}
        </Grid>
        
        <MentionSuggestions
          open={open}
          onOpenChange={handleMentionSuggestionOpen}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
        />
      </div>
      <CustomToolBar
        editorState={props.editorState}
        onBlockToggle={toggleBlockType}
        onInlineToggle={toggleInlineStyle}
      />
    </div>
  );
}
