import React from 'react';
import { Link as RLink } from 'react-router-dom';
import PATH_NAME from '../../../../utils/pathNames';
import styles from './styles';

interface Props {
  buttonLabel: string;
}

function SingUpButton(props: Props) {
  const classes = styles();
  const { buttonLabel = 'Sign Up' } = props;

  return (
    <RLink to={PATH_NAME.HOME} className={classes.signUpLink}>
      <button className={classes.signUpButton}>{buttonLabel}</button>
    </RLink>
  );
}

export default SingUpButton;
