import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../../App';
import { User } from '../../models/User.model';
import { USER_ROLES } from '../../utils/constants';

interface Props {
  children: any;
  otherAuthority?: boolean;
}

export const AdminAuthority = React.memo(({ children , otherAuthority}: Props) => {
  const currentUser: User = useContext(AppContext);
  if ((currentUser && currentUser.role === USER_ROLES.admin.toLowerCase()|| otherAuthority)) return children;
  return null;
});
