import {
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Typography
} from '@material-ui/core';
import React from 'react';
import { styles } from './logoutConfirmationStyles';
import classNames from 'classnames';
import closeIcon from '../../../../assets/images/ic-close.svg';
import { useSignout } from '../homeHooks';

interface Props {
  open: boolean;
  handleClose: any;
}

export default function LogoutConfirmation({
  open,
  handleClose,
}: Props) {
  const classes = styles();
  const signOut = useSignout();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      classes={{
        paper: classes.logoutDialog
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
    >
      <div className={classes.logoutDialogHeader}>
        <Typography className={classes.logoutTitle}>
          Logout confirmation
        </Typography>
        <img
          src={closeIcon}
          alt="close"
          className={classes.closeIcon}
          onClick={handleClose}
        ></img>
      </div>
      <DialogContent className={classes.dialogText}>
        <Typography className={classes.logoutText}>
          Are you sure you would like to logout from Bscrum?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogBtns}>
        <Button
          onClick={() => signOut.mutate()}
          className={classes.logoutBtn}
          classes={{
            label: classNames(classes.logoutBtnsTxt, classes.logoutTxt)
          }}
        >
          Logout
        </Button>
        <Button
          className={classes.closeLogoutBtn}
          classes={{
            label: classNames(classes.logoutBtnsTxt, classes.closeLogoutTxt)
          }}
          onClick={handleClose}
        >
          No, Thanks
        </Button>
      </DialogActions>
    </Dialog>
  );
}
