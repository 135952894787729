import React from 'react';
import { Link as RLink } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { styles } from './styles';
import { getUserProfileURL } from '../../../utils/routes';

interface UserProps {
  name: string;
  avatar: string;
  id: number;
  wrapperClass?: string;
}
const UserComponent = ({ name, avatar, id, wrapperClass }: UserProps) => {
  const classes = styles();
  return (
    <span
      className={`${classes.userClass} ${wrapperClass}`}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <RLink to={getUserProfileURL(id)} className={classes.avatarWrapper}>
        <Avatar alt={name} src={avatar} />
      </RLink>
      <RLink to={getUserProfileURL(id)} className={classes.memberLink}>
        <span className={classes.memberName}>{name}</span>
      </RLink>
    </span>
  );
};

export default React.memo(UserComponent);
