import {
  useInfiniteQuery,
  useMutation,
  useQueryClient
} from 'react-query';
import { useDispatch } from 'react-redux';
import {
  NotificationsResponse,
  Notification
} from '../../../../models/Notification.model';
import { QUERIES } from '../../../../utils/queris';
import { addError } from '../../../../store/errors/Actions';
import shortid from 'shortid';
import { ApiError } from '../../../../models/ApiError.model';

export const useNotifications = () => {
  return useInfiniteQuery<NotificationsResponse>(
    QUERIES.LOAD_NOTIFICATIONS,
    ({ pageParam = 1 }) => Notification.getAll(pageParam),
    {
      getNextPageParam: (_lastPage, _allPages) => {
        if (_lastPage.page_count <= _allPages.length) return undefined;
        return _allPages.length + 1;
      }
    }
  );
};

export const useReadNotification = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    (notificatonId: number) => Notification.readNotification(notificatonId),
    {
      onMutate: async notificatonId => {
        await queryClient.cancelQueries(QUERIES.LOAD_NOTIFICATIONS);
        const prevNotificationResponse = queryClient.getQueryData<any>(
          QUERIES.LOAD_NOTIFICATIONS
        );

        let newNotificationResponse = prevNotificationResponse;
        newNotificationResponse.pages.map((page: NotificationsResponse) => {
          page?.notifications.map((notificaton: Notification) => {
            if (notificaton.id == notificatonId){
              notificaton.read_at = new Date().toString();
            }
            return notificaton;
          });
          page.unread_count -= 1;
          return page;
        });
        queryClient.setQueryData(
          QUERIES.LOAD_NOTIFICATIONS,
          newNotificationResponse
        );
        return { prevNotificationResponse };
      },
      onError: (error: any, context: any) => {
        queryClient.setQueryData(
          QUERIES.LOAD_NOTIFICATIONS,
          context.prevNotificationResponse
        );
        queryClient.invalidateQueries(QUERIES.LOAD_NOTIFICATIONS);
        dispatch(
          addError(
            new ApiError(
              shortid.generate(),
              error.message,
              'Error: Can not read this notification'
            )
          )
        );
      },
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERIES.LOAD_NOTIFICATIONS);
      }
    }
  );
};

export const useReadAllNotifications = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(() => Notification.readAllNotifications(), {
    onMutate: async (variables) => {
      await queryClient.cancelQueries(QUERIES.LOAD_NOTIFICATIONS);
      const prevNotificationResponse = queryClient.getQueryData<any>(
        QUERIES.LOAD_NOTIFICATIONS
      );

      let newNotificationResponse = prevNotificationResponse;
      newNotificationResponse.pages.map((page: NotificationsResponse) => {
        page?.notifications.map((notificaton: Notification) => {
          if (!notificaton.read_at){
            notificaton.read_at = new Date().toString();
          }
          return notificaton;
        });
        page.unread_count = 0;
        return page;
      });
      queryClient.setQueryData(
        QUERIES.LOAD_NOTIFICATIONS,
        newNotificationResponse
      );
      return { prevNotificationResponse };
    },
    onError: (error, context: any) => {
      queryClient.setQueryData(
        QUERIES.LOAD_NOTIFICATIONS,
        context.prevNotificationResponse
      );
      queryClient.invalidateQueries(QUERIES.LOAD_NOTIFICATIONS);
      dispatch(
        addError(
          new ApiError(
            shortid.generate(),
            "",
            'Error: Can not read this notification'
          )
        )
      );
    },
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries(QUERIES.LOAD_NOTIFICATIONS);
    }
  });
};
