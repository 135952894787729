import { Theme, createStyles } from '@material-ui/core';

import { DRAWER_WIDTH } from '../../../../utils/constants';

export const styles = (theme: Theme) =>
  createStyles({
    drawerPaper: {
      border: '0px',
      height: '100%',
      display: 'flex',
      position: 'fixed',
      overflow: 'overlay',
      '@supports ( -moz-appearance:none )': {
        overflow: 'auto',
        height: '130%'
      },
      width: `${DRAWER_WIDTH}px`,
      alignItems: 'center',
      whiteSpace: 'nowrap',
      flexDirection: 'column',
      backgroundColor: '#f5f6fa',
      boxShadow: '-8px 0 42px 0 rgba(42, 34, 64, 0.14)',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      },
      '&::-ms-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-ms-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-ms-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      },
      '&::-moz-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-moz-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-moz-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      }
    }
  });
