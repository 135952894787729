import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { addConfirmation } from '../../../../store/confirmations/Actions';
import { useDispatch } from 'react-redux';
import { Confirmation } from '../../../../models/Confirmation.model';
import { QUERIES } from '../../../../utils/queris';
import { Report } from '../../../../models/Report.model';
import { addError } from '../../../../store/errors/Actions';
import { ApiError } from '../../../../models/ApiError.model';
import { useHistory } from 'react-router';
import PATH_NAME from '../../../../utils/pathNames';

export const usePinReport = (report: Report, updateCashedReport: Function) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((reportId: number) => Report.pinReport(reportId), {
    onSuccess: async (data, variables) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(
            `${report.name} has been added to favorites.`
          )
        )
      );
    },
    onMutate: async reportId => {
      updateCashedReport(Object.assign(report, { pinned: true }));
    },
    onError: (err: any) => {
      updateCashedReport(Object.assign(report, { pinned: false }));
      dispatch(
        addError(
          ApiError.getError(
            err,
            `${err.message}, Pinning ${report.name} has failed.`
          )
        )
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERIES.LOAD_WORKSPACE_REPORTS]);
      queryClient.invalidateQueries(QUERIES.LOAD_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PENDING_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PINNED_REPORTS);
      queryClient.invalidateQueries( [QUERIES.LOAD_REPORT, report.id.toString()])
    }
  });
};

export const useUnpinReport = (
  report: Report,
  updateCashedReport: Function
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((reportId: number) => Report.unpinReport(reportId), {
    onSuccess: async (data, variables) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(
            `${report.name} has been removed from favorites.`
          )
        )
      );
    },
    onMutate: async reportId => {
      updateCashedReport(Object.assign(report, { pinned: false }));
    },
    onError: (err: any) => {
      updateCashedReport(Object.assign(report, { pinned: true }));
      dispatch(
        addError(
          ApiError.getError(
            err,
            `${err.message}, Unpinning ${report.name} has failed.`
          )
        )
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERIES.LOAD_WORKSPACE_REPORTS]);
      queryClient.invalidateQueries(QUERIES.LOAD_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PENDING_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PINNED_REPORTS);
      queryClient.invalidateQueries( [QUERIES.LOAD_REPORT, report.id.toString()])
    }
  });
};

export const usePauseReport = (
  report: Report,
  updateCashedReport: Function
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((reportId: number) => Report.pauseReport(reportId), {
    onSuccess: async () => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(
            `${report.name} has been deactivated.`
          )
        )
      );
    },
    onMutate: async reportId => {
      updateCashedReport(Object.assign(report, { active: false }));
    },
    onError: (err: any) => {
      updateCashedReport(Object.assign(report, { active: true }));
      dispatch(
        addError(
          ApiError.getError(
            err,
            `${err.message}, Deactivating ${report.name} has failed.`
          )
        )
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERIES.LOAD_WORKSPACE_REPORTS]);
      queryClient.invalidateQueries(QUERIES.LOAD_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PENDING_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PINNED_REPORTS);
      queryClient.invalidateQueries( [QUERIES.LOAD_REPORT, report.id.toString()])
    }
  });
};

export const useResumeReport = (
  report: Report,
  updateCashedReport: Function
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((reportId: number) => Report.resumeReport(reportId), {
    onSuccess: async (data, reportId) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(
            `${report.name} has been activated.`
          )
        )
      );
      queryClient.invalidateQueries(QUERIES.LOAD_REPORTS);
    },
    onMutate: async reportId => {
      updateCashedReport(Object.assign(report, { active: true }));
    },
    onError: (err: any) => {
      updateCashedReport(Object.assign(report, { active: false }));
      dispatch(
        addError(
          ApiError.getError(
            err,
            `${err.message}, Activating ${report.name} has failed.`
          )
        )
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERIES.LOAD_WORKSPACE_REPORTS]);
      queryClient.invalidateQueries(QUERIES.LOAD_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PENDING_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PINNED_REPORTS);
      queryClient.invalidateQueries( [QUERIES.LOAD_REPORT, report.id.toString()])
    }
  });
};

export const useDeleteReport = (report:Report ,redirect:boolean) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const history = useHistory();
  return useMutation((reportId: number) => Report.deleteReport(reportId), {
    onSuccess: async () => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(
            `${report.name} has been deleted successfully.`
          )
        )
      );
      queryClient.invalidateQueries([QUERIES.LOAD_WORKSPACE_REPORTS]);
      queryClient.invalidateQueries(QUERIES.LOAD_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PENDING_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PINNED_REPORTS);
      queryClient.invalidateQueries( [QUERIES.LOAD_REPORT, report.id.toString()])
      if(redirect) history.push(PATH_NAME.REPORTS);
    }
  });
};

export const useJoinReport = (report:Report) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    (reportId:number) => Report.joinReport(reportId),
    {
      onSuccess: async (data, request) => {
        dispatch(
          addConfirmation(
            Confirmation.getSuccessConfirmation(`joined ${report.name} report successfully`)
          )
        );
        queryClient.invalidateQueries([QUERIES.LOAD_WORKSPACE_REPORTS]);
        queryClient.invalidateQueries(QUERIES.LOAD_REPORTS);
        queryClient.invalidateQueries(QUERIES.LOAD_PENDING_REPORTS);
        queryClient.invalidateQueries(QUERIES.LOAD_PINNED_REPORTS);
        queryClient.invalidateQueries( [QUERIES.LOAD_REPORT, report.id.toString()])
      },
      onError: error => {
        dispatch(addError(ApiError.getError('', 'Failed to join the report')));
      }
    }
  );
};

export const useLeaveReport = (report: Report) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    (reportId:number) => Report.leaveReport(reportId),
    {
      onSuccess: async (data, request) => {
        dispatch(
          addConfirmation(
            Confirmation.getSuccessConfirmation(`left ${report.name} report successfully`)
          )
        );
        queryClient.invalidateQueries([QUERIES.LOAD_WORKSPACE_REPORTS]);
        queryClient.invalidateQueries(QUERIES.LOAD_REPORTS);
        queryClient.invalidateQueries(QUERIES.LOAD_PENDING_REPORTS);
        queryClient.invalidateQueries(QUERIES.LOAD_PINNED_REPORTS);
        queryClient.invalidateQueries( [QUERIES.LOAD_REPORT, report.id.toString()])
      },
      onError: error => {
        dispatch(addError(ApiError.getError('', 'Failed to leave the report')));
      }
    }
  );
};

export const updateCashedReportList = async (
  queryClient: QueryClient,
  queryKey: any[],
  updatedReport: Report
) => {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<any>(queryKey) || {};
  cachedData.reports.forEach((report: Report) => {
    if (report.id === updatedReport.id) report = updatedReport;
  });
  cachedData.reports = cachedData.reports.sort(
    (report1: Report, report2: Report) =>
      Number(report2.pinned) - Number(report1.pinned)
  );
  queryClient.setQueryData<any>(queryKey, cachedData);
};
