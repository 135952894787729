import React from 'react';
import { styles } from './styles';
import { Grid, Typography } from '@material-ui/core';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { QUERIES } from '../../../../utils/queris';
import { Report } from '../../../../models/Report.model';
import ReportCard from '../../Reports/ReportCard';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

export const PinnedReports = () => {
  const classes = styles();
  const queryClient = useQueryClient();
  const {
    hasNextPage,
    data: pinned_reports,
    isFetchingNextPage,
    fetchNextPage
  } = useInfiniteQuery<{ count: number; reports: Report[] }>(
    QUERIES.LOAD_PINNED_REPORTS,
    ({ pageParam }) => Report.getPinnedReports(pageParam),
    {
      getNextPageParam: (_lastPage, _allPages) => {
        const allCount = _allPages.reduce(
          (acc, _currentPage) => acc + _currentPage.reports.length,
          0
        );
        if (_lastPage.count <= allCount) return false;
        return _allPages.length + 1;
      }
    }
  );

  const updateCashedReport = async (updatedReport: Report) => {
    await queryClient.cancelQueries(QUERIES.LOAD_PINNED_REPORTS);
    let cashedData =
      queryClient.getQueryData<any>(QUERIES.LOAD_PINNED_REPORTS) || {};
    cashedData.pages?.forEach((page: any) => {
      page.reports.forEach((report: Report, index: number) => {
        if (report.id == updatedReport.id) {
          if (updatedReport.pinned) report = updatedReport;
          else page.reports.splice(index, 1);
        }
      });
    });
    queryClient.setQueryData(QUERIES.LOAD_PINNED_REPORTS, cashedData);
  };

  if (
    !pinned_reports ||
    !pinned_reports.pages ||
    pinned_reports.pages[0]?.count === 0
  )
    return null;

  return (
    <>
      <Typography className={classes.dailyUpdatesText}>
        Pinned Reports
      </Typography>
      <Grid container direction="column">
        {pinned_reports?.pages?.map((page, index) => (
          <React.Fragment key={index}>
            {page.reports.map((report: Report) => (
              <ReportCard
                key={report.id + '-' + report.type?.title}
                report={report}
                viewDetails={true}
                updateCashedReport={updateCashedReport}
              />
            ))}
          </React.Fragment>
        ))}
      </Grid>
      {hasNextPage && !isFetchingNextPage ? (
        <IconButton
          className={classes.expandMorePinned}
          onClick={() => fetchNextPage()}
          aria-label="expand"
        >
          <ExpandMoreIcon />
        </IconButton>
      ) : null}
    </>
  );
};
