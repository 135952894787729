import { Typography } from '@material-ui/core';
import React from 'react';
import { ReactComponent as Icon } from '../../../assets/images/empty-search-state-illustration.svg';
import { emptySmallIcon } from './styles';

interface Props {
  bordered?: boolean;
  mainPrompt?: string;
  subPrompt?: string;
  page?: string;
}

export const EmptySmallIcon = React.memo(
  ({ bordered, mainPrompt, subPrompt, page }: Props) => {
    const classes = emptySmallIcon();
    return (
      <div
        className={`
        ${classes.emptySmallIcon} 
        ${bordered ? classes.bordered : ''} 
        ${page === 'reportDetails' ? classes.emptySmallIconShrinked : ''}
        ${page === 'pollResponses' ? classes.emptySmallIconMini : ''}
        `}
      >
        <Icon className={classes.noResponsesIcon} />
        <Typography className={classes.noResponsesText}>
          {mainPrompt || 'No Responses Yet'}
        </Typography>
        <Typography className={classes.contentNeededText}>
          {subPrompt || 'Content is needed!'}
        </Typography>
      </div>
    );
  }
);
