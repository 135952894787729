import { makeStyles } from '@material-ui/core';

export const useTooltipSysles = makeStyles(theme => ({
  arrow: {
    color: '#ffffff'
  },
  tooltip: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
    margin: '5px 0 0',
    padding: '10px',
    borderRadius: '12px',
    boxShadow: '0 2px 4px 0 rgba(201, 201, 201, 0.5)',
    border: 'solid 1px #eeeeee',
    backgroundColor: '#ffffff',
    fontFamily: 'Lato',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#76797f',
    fontWeight: 'normal'
  }
}));
