import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { styles } from './styles';


interface Props {
  header: string;
  subHeader: string;
}

export const Title = ({header, subHeader}: Props) => {
  const classes = styles();

  return (
    <>
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        <Typography variant="h2" gutterBottom>
          { header }
        </Typography>
        <Typography variant="h6" color='textSecondary' gutterBottom>
          { subHeader }
        </Typography>
      </Grid>
    </>
  );
};
