import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Question } from '../../../../../../models/Question.model';
import { ReportResponse } from '../../../../../../models/ReportResponse.model';
import { RespondentGrouping } from './RespondentGrouping';
import { QuestionGrouping } from './QuestionGrouping';
import { ResponsesHeader } from './ResponsesHeader';
import { RESPONSE_GROUPING_OPTIONS } from '../../../../../../utils/constants';

interface ResponsesProps {
  responses: ReportResponse[];
  questions: Question[];
}

export const Responses = ({ responses, questions }: ResponsesProps) => {
  const [grouping, setGrouping] = useState<string>('1');
  const handleChangeGrouping = (event: any) => {
    setGrouping(event.target.value);
  };
  return (
    <>
      <Grid item>
        <ResponsesHeader
          grouping={grouping}
          handleChangeGrouping={handleChangeGrouping}
        />
      </Grid>
      {grouping === RESPONSE_GROUPING_OPTIONS[1].id ? (
        <QuestionGrouping responses={responses} questions={questions} />
      ) : (
        <RespondentGrouping responses={responses} questions={questions} />
      )}
    </>
  );
};
