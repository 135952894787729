import { makeStyles, createStyles } from '@material-ui/core/styles';

export const workspaceDetailsStyles = makeStyles(theme =>
  createStyles({
    relatedReportsWrapper: { height: '100%', display: 'flex' },
    relatedReportsLeftSide: {
      marginRight: '24px',
      height: '100%',
      flex: 1
    },
    scrollableLeftSide: {
      overflow: 'auto',
      height: 'calc(100% - 122px)',
      '&::-webkit-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      },
      '&::-moz-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-moz-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-moz-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      },
      '&::-ms-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-ms-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-ms-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      }
    },
    relatedReportsRightSide: {
      minWidth: '348px',
      margin: '45px 0 0 0'
    },
    scrollableRightSide: {
      overflow: 'auto',
      overflowX: 'hidden',
      height: 'calc(100% - 26.88px)',
      boxShadow: '0 2px 2px 0 rgba(37, 37, 37, 0.03)',
      borderRadius: '10px',
      '&::-webkit-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      },
      '&::-moz-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-moz-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-moz-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      },
      '&::-ms-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-ms-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-ms-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      }
    },
    head: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#f9f9f9',
      zIndex: 5
    },
    workspaceTitle: {
      margin: '0 0 24px 0',
      fontSize: '16px',
      lineHeight: 'normal',
      color: '#777f8c'
    },
    workspaceTitleText: {
      fontWeight: 600,
      color: '#354052'
    },
    listTitle: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 'normal',
      margin: 0,
      padding: '0 0 8px 0',
      color: '#354052'
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '500px'
    },
    reportsWrapper: {
      height: '300px'
    },
    membersWrapper: {
      height: '300px'
    },
    usersList: {
      borderRadius: '10px',
      backgroundColor: '#fff',
      boxShadow: '0 2px 2px 0 rgba(37, 37, 37, 0.03)'
    },
    listItem: {
      padding: '0 16px !important',
      height: '60px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '& .user-wrapper': {
        borderBottom: '1px solid #c3c3c3 !important',
        padding: '16px 0 !important',
        margin: 0,
        height: '100%',
        width: '100%'
      },
      '&:last-child': {
        '& .user-wrapper': {
          borderBottom: 'none !important'
        }
      }
    },
    cardWrapper: {
      cursor:'pointer',
      display: 'flex',
      alignItems: 'center',
      margin: '8px 0 16px',
      padding: '24px',
      borderRadius: '10px',
      boxShadow: '0 2px 2px 0 rgba(37, 37, 37, 0.03)',
      border: 'solid 1px #ededed',
      backgroundColor: '#fff',
      '&:first-of-type': {
        margin: '0 0 16px 0'
      }
    },
    cardLeftSide: { flex: 1 },
    cardRightSide: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginLeft: '20px'
    },
    cardLeftUpper: { display: 'flex', margin: '0 0 16px 0' },
    reportName: {
      fontSize: '28px',
      fontWeight: 600,
      fontFamily: '"lato", Lato-SemiBold',
      color: '#354052',
      margin: '0 12px 0 0',
      lineHeight: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    reportActivationLabel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '20px',
      padding: '3px 8px',
      borderRadius: '3px',
      backgroundColor: '#e3ecff',
      color: '#3a78f2',
      fontFamily: 'Nunito-Bold',
      fontWeight: 700,
      fontSize: '14px',
      margin: '5px 12px 0 0',
      opacity: 0.7
    },
    pinnedReportIcon: {
      color: '#3a78f2',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    unpinnedReportIcon: {
      opacity: 0.6,
      color: '#858a94',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    viewReportBtn: {
      background: '#fff',
      border: 'none',
      outline: 'none',
      fontSize: '20px',
      color: '#3a78f2',
      cursor: 'pointer',
      padding: 0,
      fontWeight: 700,
      fontFamily: '"lato", Lato-Bold'
    },
    relatedReportsEmptyState: { height: '100%' }
  })
);
