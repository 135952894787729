import React from 'react';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  List,
  ListItemText
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Workspace } from '../../../../models/Workspace.model';
import { Member } from '../../../../models/Member.model';
import { useEditWorkspace } from '../../Workspaces/workspaceHooks';
import { styles as newReportStyles } from './styles';

interface Props {
  open: boolean;
  workspace: Workspace;
  addedMembers: Member[];
  saveReport: Function;
  setOpen: Function;
}

export const WorkspaceMembersDialog = ({
  open,
  workspace,
  addedMembers,
  saveReport,
  setOpen
}: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const editWorkspace = useEditWorkspace();
  const classes = newReportStyles();

  const handleSavingReport = () => {
    let newWorkspace = { ...workspace };
    newWorkspace.members.push(...addedMembers);
    setOpen(false);
    editWorkspace.mutate(newWorkspace);
    saveReport();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {`Members outside ${workspace.name} workspace`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`You have selected team members outside ${workspace.name} workspace. `}
        </DialogContentText>
        <List>
          {addedMembers.map(member => (
            <ListItemText primary={member.name} />
          ))}
        </List>
        <DialogContentText>
          {`By saving this report, those members will be added to ${workspace.name} workspace`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          classes={{
            root: classes.actionButton,
            label: classes.buttonLabel,
            contained: classes.backButton
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSavingReport}
          variant="contained"
          classes={{
            root: classes.actionButton,
            label: classes.buttonLabel,
            contained: classes.nextButton
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
