import React, { useEffect, useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { QuickFindSearchStyles } from './styles';
import { Typography } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { useMembers } from '../../../Reports/ReportForm/reportFormHooks';
import { Member } from '../../../../../models/Member.model';
import UserComponent from '../../../../shared-components/UserComponent';
import { Report, ReportFilters } from '../../../../../models/Report.model';
import { useRepots } from '../../../Reports/ReportsHooks';
import { getReportURL } from '../../../../../utils/routes';
import { useHistory } from 'react-router';
import { EmptySearchResult } from '../../../../shared-components/EmptyStates/EmptySearchResult';

export const QuickFindSearch = () => {
  const classes = QuickFindSearchStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { isLoading: loadingMembers, data: membersList } = useMembers();
  const [userSearchResults, setUserSearchResults] = useState<Member[]>(
    membersList || []
  );
  const history = useHistory();
  const [reportFilters, setReportFilters] = useState({
    status: [] as number[],
    types: [] as number[],
    workspaces: [] as number[],
    title: ''
  } as ReportFilters);
  const searchTimeout = useRef<any>(0);

  const { data: reportSearchResult, isLoading: loadingReports } = useRepots(
    1,
    reportFilters
  );

  const setTitleFilter = (title: string | undefined) => {
    setReportFilters({
      ...reportFilters,
      title
    });
  };

  useEffect(() => {
    setUserSearchResults(
      membersList?.filter(({ name }) =>
        name.match(new RegExp(searchTerm, 'i'))
      ) || []
    );
  }, [searchTerm]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <PopupState variant="popover">
      {popupState => (
        <div className={classes.root}>
          <Paper
            onClick={handleClick}
            style={{ display: 'flex', width: '100%' }}
          >
            <InputBase
              className={classes.input}
              placeholder="Search for reports and users"
              inputProps={{ 'aria-label': 'Search BScrum' }}
              onChange={(e: any) => {
                setSearchTerm(e.target.value);
                if (searchTimeout.current) clearTimeout(searchTimeout.current);
                if (e.target.value) {
                  searchTimeout.current = setTimeout(() => {
                    setTitleFilter(e.target.value);
                    popupState.open();
                  }, 1000);
                }
              }}
              value={searchTerm}
            />
            <Divider className={classes.divider} orientation="vertical" />

            <IconButton
              {...bindTrigger(popupState)}
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <Popover
            {...bindPopover(popupState)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            classes={{ paper: classes.searchPopover }}
          >
            <Paper className={classes.divroot}>
              {reportSearchResult && reportSearchResult?.reports?.length > 0 && (
                <>
                  <Typography className={classes.sectionTitle}>
                    Reports
                  </Typography>
                  <List component="nav" aria-label="main mailbox folders">
                    {reportSearchResult?.reports?.map((report: Report) => (
                      <ListItem
                        button
                        onClick={(event: any) => {
                          event.preventDefault();
                          history.push(getReportURL(report));
                        }}
                      >
                        <ListItemText primary={report.name} />
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
              {userSearchResults.length > 0 && (
                <>
                  <Divider />
                  <Typography className={classes.sectionTitle}>
                    Members
                  </Typography>
                  <List component="nav" aria-label="secondary mailbox folders">
                    {userSearchResults.slice(0, 10).map(user => (
                      <ListItem button>
                        <UserComponent
                          name={user.name}
                          avatar={user.avatar}
                          id={user.id}
                        />
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
              {userSearchResults.length === 0 &&
                reportSearchResult &&
                reportSearchResult?.reports.length == 0 && (
                  <EmptySearchResult />
                )}
            </Paper>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};
