import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { formErrorMessageStyles } from './styles';

interface Props {
  fieldName: string;
}

export const FormErrorMessage = ({ fieldName }: Props) => {
  const { formState } = useFormContext();
  const classes = formErrorMessageStyles();
  return (
    <ErrorMessage
      errors={formState.errors}
      name={fieldName}
      render={({ message }) => (
        <p className={classes.errorMessage}>{message}</p>
      )}
    />
  );
};
