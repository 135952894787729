import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme => ({
  whatsNewButton: {
    height: '34px',
    display: 'flex',
    padding: '4px 16px',
    borderRadius: '4px',
    marginRight: '18px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#00d38c',
    boxShadow: '0 2px 4px 0 rgba(89, 89, 89, 0.11)'
  },
  buttonLabel: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textTransform: 'capitalize'
  },
  link: {
    textDecoration: 'none'
  }
}));
