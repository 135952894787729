import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    reportRect: {
      cursor: 'pointer',
      display: 'flex',
      marginBottom: '8px',
      borderRadius: '10px',
      backgroundColor: '#fff',
      border: 'solid 1px #ededed',
      padding: '16px 8px 16px 16px',
      justifyContent: 'space-between',
      boxShadow: '0 2px 2px 0 rgba(37, 37, 37, 0.03)'
    },
    reportNameTxt: {
      color: '#354052',
      fontSize: '24px',
      letterSpacing: 'normal',
      fontFamily: 'Lato-SemiBold',
      textTransform: 'capitalize'
    },
    reportStatus: {
      fontSize: 14,
      margin: '0 8px',
      borderRadius: 3,
      color: '#3a78f2',
      fontWeight: 'bold',
      padding: '0 8px 1px',
      verticalAlign: '15%',
      letterSpacing: 'normal',
      backgroundColor: '#e3ecff'
    },
    inactiveReport: {
      color: '#afb1b6',
      backgroundColor: '#e5e6e8'
    },
    pinnedReportIcon: {
      color: '#3a78f2',
      verticalAlign: '-12%',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    unpinnedReportIcon: {
      opacity: 0.3,
      color: '#858a94',
      verticalAlign: '-12%',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    viewDetails: {
      color: '#3a78f2',
      fontSize: '16px',
      fontFamily: 'Lato',
      fontWeight: 'bold',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      '&:hover': {
        color: '#0574ef',
        cursor: 'pointer'
      }
    },
    fixedGridWidth: {
      width: 245
    },
    oval: {
      width: '14px',
      height: '14px',
      borderRadius: '50%',
      display: 'inline-block'
    },
    inprogressOval: {
      backgroundColor: 'rgba(245, 166, 35, 0.29)'
    },
    completedOval: {
      opacity: 0.5,
      backgroundColor: 'rgba(126, 211, 33, 0.54)'
    },
    incompletedOval: {
      backgroundColor: '#ffe6e2'
    },
    pausedOval: {
      backgroundColor: '#d5dce3'
    },
    notstartedOval: {
      backgroundColor: '#e3ecff'
    },
    circle: {
      width: 14,
      height: 14,
      borderRadius: '50%',
      display: 'inline-block',
      border: 'solid 2.5px #bebebe'
    },
    icon: {
      width: 14,
      height: 14
    },
    reportInfoText: {
      fontSize: 16,
      fontWeight: 500,
      color: '#474f58',
      lineHeight: 1.25,
      marginLeft: '16px',
      fontFamily: 'Lato',
      letterSpacing: 0.13,
      display: 'inline-block',
      textTransform: 'capitalize'
    },
    submissionStatus: {
      cursor: 'pointer',
      margin: '0 0 0 4px',
      fontFamily: 'Lato-Bold'
    },
    nextCyclePrompt: {
      fontSize: '12px',
      marginLeft: '4px',
      cursor: 'pointer',
      fontFamily: 'Lato',
      textTransform: 'none',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    completedStatus: {
      color: '#7ed321'
    },
    incompletedStatus: {
      color: '#db787d'
    },
    inprogressStatus: {
      color: '#f5a623'
    },
    pausedStatus: {
      color: '#a3a7ab'
    },
    notstartedStatus: {
      color: '#8ab0ff'
    },
    listGrid: {
      marginRight: -100,
      '@media (max-width: 959px)': {
        marginTop: 8,
        justifyContent: 'flex-start'
      }
    },
    '@media (max-width: 420px)': {
      reportNameTxt: {
        fontSize: '20px'
      },
      reportStatus: {
        verticalAlign: '12%'
      },
      pinnedReportIcon: {
        verticalAlign: '-15%'
      },
      unpinnedReportIcon: {
        verticalAlign: '-15%'
      },
      reportInfoText: {
        fontSize: 14
      }
    },
    '@media (max-width: 320px)': {
      reportNameTxt: {
        fontSize: 18
      },
      reportStatus: {
        fontSize: 12
      },
      reportInfoText: {
        fontSize: 12
      },
      nextCyclePrompt: {
        fontSize: 10
      }
    }
  })
);
