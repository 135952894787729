import { createStyles, makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: '6px',
      boxShadow: '0 2px 20px 9px rgba(31, 31, 31, 0.04)',
      padding: '16px',
      '@media (max-width:520px)': {
        width:'95%',
        margin:'auto'
      }
    },
    headerWrapper: {
      paddingBottom: '16px',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid  #e7e9ea',
      flexWrap: 'wrap'
    },
    activityWrapper: {
      borderBottom: '1px solid #e7e9ea',
      paddingTop:'16px'
    },
    reportNameWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap:'wrap'
    },
    reportName: {
      display: 'flex',
      alignItems: 'center',
      color: '#3a78f2',
      fontSize: '14px',
      fontWeight: 'bold',
      opacity: 0.9
    },
    reportNameSpan: {
      fontSize: '12px',
      color: '#a9aeb7',
      marginLeft: '4px'
    },
    time: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Lato',
      fontSize: '12px',
      color: '#a9aeb7'
    },
    timeSpan: {
      fontWeight: 'bold',
      color: '#858a94',
      fontSize:'14px'
    },
    timeIcon: {
      width: '16px',
      height: '17px',
      marginRight: '4px'
    },
    activitiesHeader: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#000000'
    },
    activityList: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      padding: '0px',
      height: '100%',
      backgroundColor: '#ffffff',
      margin: '4px 0 16px 0',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '12px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#dceaff',
        borderRight: '8px white solid',
        borderRadius: '2px'
      }
    },
    button: {
      width: '81px',
      height: '41px',
      marginRight: '4.5px',
      marginTop: '6px',
      marginBottom: '6px',
      backgroundColor: 'rgba(236, 240, 255, 0.5)',
      border: '0px'
    },
    loadMoreButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '150px',
      height: '30px',
      padding: '0px',
      margin: 'auto'
    },
    loadMoreBtnLabel: {
      height: '27px',
      fontFamily: 'Lato',
      fontSize: '18px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: '#f5a623',
      textTransform: 'capitalize',
      textDecoration: 'underline'
    }
  })
);
