import { useQuery } from "react-query";
import { Report, ReportFilters } from "../../../models/Report.model";
import { Workspace } from "../../../models/Workspace.model";
import { QUERIES } from "../../../utils/queris";

export const useWorkspaces = () => {
    return useQuery<{workspaces:Workspace[] , pageCount:number}>(QUERIES.LOAD_WORKSPACES, () =>
      Workspace.getAll({})
    );
};

export const useRepots = (page: number, reportFilters: ReportFilters) => {
  return useQuery(
    [QUERIES.LOAD_REPORTS, page, reportFilters],
    () => Report.getAll(page, reportFilters)
  );
};