import { Answer } from './Answer.model';
import { Member } from './Member.model';
import { ReportType } from './ReportType.model';
import { Comment } from './Comment.model';
import { API } from './API';
import axios from '../services/axios';
import { USER_ROLES, REPORTTYPES } from '../utils/constants';
import store from '../store';

export type ReportResponseFilters = {
  startTime?: string;
  endTime?: string;
  members: number[];
  questions: number[];
};

export type PaginatedReportResponses = {
  responses: ReportResponse[];
  pageCount: number;
};

export type submitReportRequest = {
  reportId: number , 
  reportType: ReportType ,
  response: ReportResponse ,
  id?: string
};

export type reactionRequest = {
  memberId : number ;
  responseId: number;
}

export class ReportResponse extends API {
  public id: number = -1;
  public time: string = '';
  public answers: Answer[] = [];
  public comments: Comment[] = [];
  public editTime: string = '';
  public member: Member = new Member(1,'provided_id', 'Name', USER_ROLES.admin);
  public quick_response?: string = '';
  public report_id: number = -1 ;
  public poll_vote: any = {};

  public static getListByReportId(
    reportId: number,
    reportType: ReportType,
    page: number,
    filters?: ReportResponseFilters
  ): Promise<{ responses: ReportResponse[]; pageCount: number }> {
    const headers = ReportResponse.getConfig();

    if (headers) {
      let reportTypeUrl = REPORTTYPES[reportType.title].recordsUrl;
      return new Promise<PaginatedReportResponses>((resolve, reject) => {
        let url = `dashboard/${reportTypeUrl}/index?id=${reportId}&page=${page}`;

        if (filters) url += `${buildFiltersParams(filters).join('&')}`;

        axios
          .get(url, headers)
          .then(res => {
            let responses: ReportResponse[] = res.data.responses;
            let pageCount: number = res.data.pageCount;
            resolve({ responses, pageCount });
          })
          .catch(e => {
            ReportResponse.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }

    return Promise.reject('System Error');
  }

  public static submit(
    request: submitReportRequest
   ): Promise<void> {
      const headers = ReportResponse.getConfig(); 

      if(headers){
        let reportTypeUrl = REPORTTYPES[request.reportType.title].recordsUrl;
        reportTypeUrl = reportTypeUrl.substr(reportTypeUrl.length-1)=='/' ? reportTypeUrl : reportTypeUrl.concat('/');
        return new Promise<void>((resolve,reject)=>{
          let url = `dashboard/${reportTypeUrl}create?id=${request.reportId}` ;
          axios 
            .post(url , request.response ,headers )
            .then(()=> resolve())
            .catch( err => {
              ReportResponse.checkUnauthorizedRequest(err,store);
              reject(err);
            });
        })
      }
      return Promise.reject("Can't submit your response");
  }

  public static async update(request: submitReportRequest): Promise<ReportResponse> {
    const headers = ReportResponse.getConfig();
    if (headers) {
      let reportTypeUrl = REPORTTYPES[request.reportType.title].recordsUrl;
      return new Promise<ReportResponse>((resolve, reject) => {
        reportTypeUrl = reportTypeUrl.substr(reportTypeUrl.length-1)=='/' ? reportTypeUrl : reportTypeUrl.concat('/');
        let url = `dashboard/${reportTypeUrl}update?report_id=${request.reportId}&report_record_id=${request.id}` ;
        axios
          .put(url, request.response, headers)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            ReportResponse.checkUnauthorizedRequest(err,store);
            reject(err);
          });
      });
    }
    return Promise.reject('Errot while update response');
  }

  public static async delete(request: submitReportRequest): Promise<void> {
    const headers = ReportResponse.getConfig();
    if (headers) {
      let reportTypeUrl = REPORTTYPES[request.reportType.title].recordsUrl;
      reportTypeUrl = reportTypeUrl.substr(reportTypeUrl.length-1)=='/' ? reportTypeUrl : reportTypeUrl.concat('/');
      return new Promise<void>((resolve, reject) => {
        let url = `dashboard/${reportTypeUrl}destroy?id=${request.id}` 
        axios
          .delete(url, headers)
          .then(res => resolve())
          .catch(err => {
            ReportResponse.checkUnauthorizedRequest(err,store);
            reject(err);
          });
      });
    }
    return Promise.reject('Errot while delete response');
  }

  public static like(reaction:reactionRequest):Promise<unknown> {
    const headers = ReportResponse.getConfig();
  
    if(headers){
        return new Promise<unknown>((resolve,reject)=>{
            axios
            .put(`dashboard/report_records/${reaction.responseId}/like` , {},headers)
            .then(res=> resolve(res.data))
            .catch(e=> reject(e))
        })
    }
    return Promise.reject("Can't like")
    
  }
  
  public static unlike(reaction:reactionRequest):Promise<unknown> {
    const headers = ReportResponse.getConfig();
  
    if(headers){
        return new Promise<unknown>((resolve,reject)=>{
            axios
            .put(`dashboard/report_records/${reaction.responseId}/unlike` ,{}, headers)
            .then(res=> resolve(res.data))
            .catch(e=> reject(e))
        })
    }
    return Promise.reject("Can't unlike")
    
 
  }
  
  public static getLikedMembers(responseId:number):Promise<number[]>{
    const headers = ReportResponse.getConfig();
    
    if(headers){
        return new Promise<number[]>((resolve,reject)=>{
            axios
            .get(`dashboard/report_records/${responseId}/likes`,headers)
            .then(res => resolve(res.data.LikedMembersIds))
            .catch(e => reject(e))
        })
    }
    return Promise.reject('Error while retieving Likes')
  }

}



function buildFiltersParams(filters: ReportResponseFilters): string[] {
  if (!filters) return [];
  const params: string[] = [];
  if (filters.startTime && filters.startTime.length) {
    params.push(`&start_time=${JSON.stringify(filters.startTime)}`);
  }

  if (filters.endTime && filters.endTime.length) {
    params.push(`&end_time=${JSON.stringify(filters.endTime)}`);
  }

  if (filters.members && filters.members.length) {
    params.push(`&member_ids=${JSON.stringify(filters.members)}`);
  }

  if (filters.questions && filters.questions.length) {
    params.push(`&question_ids=${JSON.stringify(filters.questions)}`);
  }
  return params;
}
