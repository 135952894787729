import React from 'react';
import classNames from 'classnames';
import { customEditorStyles } from '../customEditorStyles';
import CustomizedTooltip from '../../CustomizedTooltip';

interface styleButtonProps {
  active: Boolean;
  label: string;
  onToggle: any;
  style: string;
  shortcut: string ;
}

export const StyleButton = (props: styleButtonProps) => {
  const classes = customEditorStyles();
  const { active, label, onToggle, style  ,shortcut } = props;

  const handleToggle = (e: any) => {
    e.preventDefault();
    onToggle(style);
  };

  const className = classNames({
    [classes.styleButton]: true,
    [classes.activeButton]: active
  });

  return (
    <CustomizedTooltip
      title={shortcut}
      children={
        <span className={className} onMouseDown={handleToggle}>
          {label}
        </span>
      }
      placement="top"
    /> 


    
  );
};
