import { createStyles,  makeStyles } from '@material-ui/core/styles';

export const profileCardStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: '16px 24px',
      borderRadius: '6px',
      boxShadow: '0 2px 20px 9px rgba(31, 31, 31, 0.04)',
      '@media (max-width:520px)': {
        padding:'16px 0',
        width:'95%',
        margin:'auto'
      }

    },
    media: {
      width: '55px',
      height: '56px',
      borderRadius: '28px',
      margin: '0 auto',
      marginBottom: '8px'
    },
    user: {
      padding: '0',
      borderBottom:'2px solid rgb(231, 233, 234,0.6)',
      '&:last-child': {
        paddingBottom: '16px'
      }
    },
    memberName: {
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 1.69,
      color: '#354052',
      textAlign: 'center',
      paddingBottom: 0
    },
    responseRate: {
      marginLeft: '3px',
      fontSize: '12px',
      fontWeight: 500,
      color: '#354052',
      opacity: 0.78
    },
    percentage: {
      color: '#4eb9a2',
      marginRight: '3px'
    },
    userBox: {
       marginBottom: '16px'
    },
    reportCountCardContainer: {
      padding:'24px',
      paddingBottom:0
    }
  })
);
