import React, { useCallback, useState } from 'react';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { CommentContent } from './CommentContent';
import { attachmentsStyles } from '../styles';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import { Member } from '../../../../../../../models/Member.model';
import { Comment } from '../../../../../../../models/Comment.model';

interface commentsProps {
  comments: Comment[];
  members: Member[];
}

export const Comments = ({ comments, members }: commentsProps) => {
  const classes = attachmentsStyles();
  const [collapse, setCollapse] = useState(true);

  const handleCollapse = useCallback(() => {
    setCollapse(collapse => !collapse);
  }, [setCollapse]);

  if (comments.length === 0) return null;

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item container alignItems="center">
        <Grid item>
          <TextsmsOutlinedIcon className={classes.commentsTitleIcon} />
        </Grid>
        <Grid item>
          <Typography className={classes.commentsTitleText}>
            Comments
          </Typography>
        </Grid>
      </Grid>

      {collapse ? (
        <>
          {comments
            ?.slice(0, Math.min(2, comments.length))
            .map((comment, index) => (
              <Grid item key={index}>
                <CommentContent
                  members={members!}
                  key={comment.id}
                  comment={comment}
                />
                {comments.length !== index + 1 ? <Divider /> : null}
              </Grid>
            ))}
        </>
      ) : (
        <>
          {comments?.map((comment, index) => (
            <Grid item key={index}>
              <CommentContent
                members={members!}
                key={comment.id}
                comment={comment}
              />
              <Divider />
            </Grid>
          ))}
        </>
      )}

      {comments.length > 2 && (
        <Grid item>
          <Button
            onClick={handleCollapse}
            classes={{
              root: classes.loadMoreButton,
              label: classes.loadMoreBtnLabel
            }}
          >
            {collapse
              ? `+ ${comments.length - 2} More Comments`
              : 'Collapse Comments'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
