import React from 'react';
import { EditorState } from 'draft-js';
import { CustomizedEditor } from '../../../shared-components/CustomizedEditor';
import { styles } from './styles';

interface SubmissionEditorProps {
  members: any[];
  question_id: number;
  setEditorState: (question_id: number, editorState: EditorState) => void;
  editorState: EditorState;
  editMode: boolean
}

export const SubmissionEditor = (props: SubmissionEditorProps) => {
  const { members, question_id, setEditorState, editorState } = props;
  const classes = styles();
  const handleStateChange = (editorState: EditorState) => {
    setEditorState(question_id, editorState);
  };

  return (
    <CustomizedEditor
      members={members}
      setEditorState={handleStateChange}
      editorState={editorState}
      placeholder="Write your answer"
      editorStyleClass={classes.editor}
      rootStyleClass={classes.rootEditor}
      editMode={false}
      submitEdit={undefined}
      cancelEdit={undefined}
    />
  );
};
