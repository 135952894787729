import { makeStyles, createStyles } from '@material-ui/core/styles';
export const styles = makeStyles(theme =>
  createStyles({
    browserImage: {
      width: '100%',
      height: 'auto',
      borderRadius: '5px',
      objectFit: 'contain'
    },
    title: {
      textShadow: '1px 1px 0 rgba(0, 0, 0, 0.64)',
      fontFamily: 'Lato',
      fontSize: '32px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.25',
      letterSpacing: ' normal',
      color: '#ffffff',
      marginLeft: ' 30px'
    },
    txt: {
      width: '511px',
      height: '54px',
      textShadow: '1px 1px 0 rgba(0, 0, 0, 0.64)',
      fontFamily: 'Lato',
      fontSize: '20px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.35',
      letterSpacing: 'normal',
      color: '#ffffff',
      marginLeft: ' 30px'
    },
    carousel: {
      width: '95%',
      margin: 'auto',
    },
   
  })
);
