import React, { useCallback } from 'react';
import { styles } from './styles';
import { Typography, Grid } from '@material-ui/core';
import { Report } from '../../../../models/Report.model';
import { useHistory } from 'react-router';

import ReportCardHeader from './ReportCardHeader';
import { SlicedParticipantsList } from '../SlicedParticipantsList';
import LoopIcon from '../../../../assets/images/refresh.svg';
import ProjectIcon from '../../../../assets/images/project.svg';
import classNames from 'classnames';
import {
  REPORT_PERIODS,
  REPORT_TYPES,
  SUBMISSION_CYCLE_STATUS
} from '../../../../utils/constants';
import CustomizedTooltip from '../../../shared-components/CustomizedTooltip';
import { ReportSubMenu } from '../../../shared-components/SettingsSubMenus/ReportSubmenu';
import { getReportURL } from '../../../../utils/routes';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
interface ReportCardProps {
  report: Report;
  viewDetails: boolean;
  updateCashedReport: (updatedReport: Report) => void;
}

export default function ReportCard({
  viewDetails,
  report,
  updateCashedReport,
  ...props
}: ReportCardProps) {
  const classes = styles();
  const history = useHistory();

  const handleViewHistoryClick = useCallback(() => {
    history.push(getReportURL(report));
  }, [history, report]);

  const getSubmissionStatusClass = () => {
    switch (report.submission_status) {
      case SUBMISSION_CYCLE_STATUS[0]:
        return { oval: classes.pausedOval, status: classes.pausedStatus };
      case SUBMISSION_CYCLE_STATUS[1]:
        return { oval: classes.completedOval, status: classes.completedStatus };
      case SUBMISSION_CYCLE_STATUS[2]:
        return {
          oval: classes.notstartedOval,
          status: classes.notstartedStatus
        };
      case SUBMISSION_CYCLE_STATUS[3]:
        return {
          oval: classes.inprogressOval,
          status: classes.inprogressStatus
        };
      case SUBMISSION_CYCLE_STATUS[4]:
        return {
          oval: classes.incompletedOval,
          status: classes.incompletedStatus
        };
    }
  };

  return (
    <Grid
      container
      onClick={handleViewHistoryClick}
      className={classes.reportRect}
      id={`report-${report.id}`}
    >
      <Grid
        container
        item
        spacing={1}
        xs={12}
        sm={12}
        md={7}
        direction="column"
      >
        {/* Report name, active, pinned */}
        <Grid item>
          <ReportCardHeader
            report={report}
            updateCashedReport={updateCashedReport}
          />
        </Grid>

        {/* List of participant icons */}
        <Grid item>
          <SlicedParticipantsList
            members={report.participants}
            slicedCount={3}
            not_submitted_member_ids={report.not_submitted_member_ids}
          />
        </Grid>

        {viewDetails ? (
          <Grid item>
            <span
              className={classes.viewDetails}
              onClick={handleViewHistoryClick}
            >
              View Details
            </span>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        container
        item
        spacing={2}
        xs={11}
        sm={11}
        md={4}
        alignItems="center"
        justify="flex-end"
        className={classes.listGrid}
      >
        <Grid item>
          <Grid
            container
            spacing={2}
            justify="center"
            direction="column"
            className={classes.fixedGridWidth}
          >
            {viewDetails ? (
              <Grid item>
                <span
                  className={classNames(
                    classes.oval,
                    getSubmissionStatusClass()?.oval
                  )}
                />

                {/* Submission: closed/open */}
                {report.type.title !== REPORT_TYPES[4].title ? (
                  <Typography className={classes.reportInfoText}>
                    Submission
                    <CustomizedTooltip
                      title={report.submission_status_description}
                      children={
                        <span
                          className={classNames(
                            classes.submissionStatus,
                            getSubmissionStatusClass()?.status
                          )}
                        >
                          {report.submission_status}
                        </span>
                      }
                      placement="top"
                    />
                  </Typography>
                ) : (
                  <Typography className={classes.reportInfoText}>
                    Submissions:
                    <CustomizedTooltip
                      title={report.num_responses.toString()}
                      children={
                        <span
                          className={classNames(
                            classes.submissionStatus,
                            getSubmissionStatusClass()?.status
                          )}
                        >
                          {report.num_responses}
                        </span>
                      }
                      placement="top"
                    />
                  </Typography>
                )}
              </Grid>
            ) : null}

            {/* Report recurrence */}
            <Grid item>
              {report.recurrence === REPORT_PERIODS[3] ? (
                <span className={classes.circle} />
              ) : (
                <img src={LoopIcon} alt="refresh" className={classes.icon} />
              )}

              {report.type.title === REPORT_TYPES[4].title ? (
                <Typography className={classes.reportInfoText}>
                  Non-Scheduled
                </Typography>
              ) : (
                <Typography className={classes.reportInfoText}>
                  {report.recurrence.replace('_', ' ')}
                  {report.next_submission_cycle_date && (
                    <CustomizedTooltip
                      title={`Next submission cycle will be on 
                    ${report.next_submission_cycle_date}`}
                      placement="bottom"
                      children={
                        <span className={classes.nextCyclePrompt}>
                          Next submission cycle
                        </span>
                      }
                    />
                  )}
                </Typography>
              )}
            </Grid>

            {/* Name of workspace that the report belongs to */}
            {report.workspace ? (
              <Grid item>
                <img src={ProjectIcon} alt="refresh" className={classes.icon} />
                <Typography className={classes.reportInfoText}>
                  {report.workspace?.name}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={1}
        sm={1}
        md={1}
        justify="flex-end"
        alignItems="center"
      >
        <ReportSubMenu
          workspace={report.workspace}
          report={report}
          updateCashedReport={updateCashedReport}
          redirectAfterDelete={true}
        />
      </Grid>
    </Grid>
  );
}
