import React, { useState } from 'react';
import { CreateWorkspaceDialog } from './Dialogs/CreateWorkspaceDialog';
import { ConfirmSubmitDialog } from './Dialogs/ConfirmSubmitDialog';
import { CustomizedPagination } from '../../shared-components/CustomizedPagination';
import { EmptyCreateWorkspace } from '../../shared-components/EmptyStates/EmptyCreateWorkspace';
import { NewWorkspaceButton } from './NewWorkspaceButton';
import { EmptyStateHandler } from '../../shared-components/EmptyStates/EmptyStateHandler';
import { workspaceStyles } from './workspaceStyles';
import { useWorkspaces } from './workspaceHooks';
import { Workspace } from '../../../models/Workspace.model';
import { Grid } from '@material-ui/core';
import WorkspaceCard from './WorkspaceCard';

const Workspaces = () => {
  const classes = workspaceStyles();
  const [page, setPage] = useState(1);
  const [openWorkspaceForm, setOpenWorkspaceForm] = useState(false);
  const [confirmSubmitForm, setConfirmSubmitForm] = useState(false);
  const [workspaceForm, setWorkspaceForm] = useState(new Workspace());
  const [confirmWorkspaceForm, setConfirmWorkspaceForm] = useState(new Workspace());
  const [editedWorkspaceData, setEditedWorkspaceData] = useState({});
  const { data, isLoading } = useWorkspaces(page);

  const handleClose = () => {
    setOpenWorkspaceForm(false);
    setWorkspaceForm(new Workspace());
  };

  const openCreateForm = () => {
    setOpenWorkspaceForm(true);
    setWorkspaceForm(new Workspace());
  };

  const openEditForm = (workspace: Workspace) => {
    setOpenWorkspaceForm(true);
    setWorkspaceForm(workspace);
  };

  const openConfirmForm = (workspace: Workspace) => {
    setConfirmSubmitForm(true);
    setConfirmWorkspaceForm(workspace);
  };

  const handleCloseConfirmSubmit = () => {
    setConfirmSubmitForm(false);
    setConfirmWorkspaceForm(new Workspace());
  };

  return (
    <>
      <CreateWorkspaceDialog
        open={openWorkspaceForm}
        handleClose={handleClose}
        isCreateWorkspace={workspaceForm.id === -1 ? true : false}
        workspace={workspaceForm}
        openConfirmForm={openConfirmForm}
        setEditedWorkspaceData={setEditedWorkspaceData}
      />

      <ConfirmSubmitDialog
        open={confirmSubmitForm}
        handleClose={handleCloseConfirmSubmit}
        workspace={confirmWorkspaceForm}
        editedWorkspaceData={editedWorkspaceData}
      />
      <EmptyStateHandler
        loading={isLoading}
        data={data?.workspaces!}
        emptyComponent={<EmptyCreateWorkspace />}
      >
        <Grid container className={classes.root} direction="column" spacing={1}>
          <Grid item className={classes.newWorkspaceBtnWrapper}>
            <h2 className={classes.workspacesTitle}>My Workspaces</h2>
            <NewWorkspaceButton openCreateForm={openCreateForm} />
          </Grid>

          <Grid
            className={classes.workspaceCardsWrapper}
            item
            container
            direction="column"
            spacing={2}
          >
            {data?.workspaces.map((workspace: Workspace) => {
              return (
                <Grid className={classes.workspaceCard} item key={workspace.id}>
                  <WorkspaceCard
                    key={workspace.id}
                    workspace={workspace}
                    openEditForm={openEditForm}
                  />
                </Grid>
              );
            })}
          </Grid>

          <Grid item>
            <CustomizedPagination data={data} page={page} setPage={setPage} />
          </Grid>
        </Grid>
      </EmptyStateHandler>
    </>
  );
};

export default Workspaces;
