import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    filter: {
      height: 32,
      width: '100%',
      minWidth: 135,
      maxWidth: 283,
      borderRadius: 6,
      backgroundColor: '#fff',
      border: 'solid 1px rgba(224, 224, 224, 0.49)',
      boxShadow: '0 2px 5px 0 rgba(219, 219, 219, 0.5)'
    },
    filterText: {
      // Label
      '& .MuiInputLabel-root': {
        fontFamily: 'Lato',
        transform: 'translate(15px, 7px)'
      },
      // Label shrinked
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(12px, -6px) scale(0.75) !important'
      },
      // Input text
      '& .MuiInputBase-input': {},
      // All input text (including x selected)
      '& .MuiInputBase-root': {
        color: '#76797f',
        fontSize: '14px'
      }
    },
    CheckboxTxt: {
      fontSize: '14px',
      fontFamily: 'Lato'
    },
    unSelectedCheckbox: {
      opacity: 0.5,
      color: '#474f58'
    },
    selectedCheckbox: {
      color: '#3a78f2'
    },
    dropIcon: {
      opacity: 0.5,
      color: '#858d9b'
    },
    popper: {
      minWidth: 180
    },
    selectedNumber: {
      height: '20px',
      display: 'flex',
      marginRight: 3,
      marginBottom: 4,
      padding: '0 5px',
      borderRadius: '10px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f3f3f3'
    },
    checkButton: {
      marginRight: 8
    }
  })
);
