export const USER_UPDATED = 'USER_UPDATED';
export type USER_UPDATED = typeof USER_UPDATED;

export const FETCH_O_AUTH_URLS_SUCCEEDED = 'FETCH_O_AUTH_URLS_SUCCEEDED';
export type FETCH_O_AUTH_URLS_SUCCEEDED = typeof FETCH_O_AUTH_URLS_SUCCEEDED;

export const SIGNIN_FAILED = 'SIGNIN_FAILED';
export type SIGNIN_FAILED = typeof SIGNIN_FAILED;

export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export type SIGNUP_FAILED = typeof SIGNUP_FAILED;

export const SIGNOUT_FAILED = 'SIGNOUT_FAILED';
export type SIGNOUT_FAILED = typeof SIGNOUT_FAILED;

export const GET_CURRENT_USER_FAILED = 'GET_CURRENT_USER_FAILED';
export type GET_CURRENT_USER_FAILED = typeof GET_CURRENT_USER_FAILED;

export const FETCH_O_AUTH_URLS_FAILED = 'FETCH_O_AUTH_URLS_FAILED';
export type FETCH_O_AUTH_URLS_FAILED = typeof FETCH_O_AUTH_URLS_FAILED;

export const AUTHORIZE_SLACK_FAILED = 'AUTHORIZE_SLACK_FAILED';
export type AUTHORIZE_SLACK_FAILED = typeof AUTHORIZE_SLACK_FAILED;

export const AUTHORIZE_SLACK_SUCCEEDED = 'AUTHORIZE_SLACK_SUCCEEDED';
export type AUTHORIZE_SLACK_SUCCEEDED = typeof AUTHORIZE_SLACK_SUCCEEDED;



// export const SET_AFTER_LOGIN_REDIRECTION_URL = 'SET_AFTER_LOGIN_REDIRECTION_URL';
// export type SET_AFTER_LOGIN_REDIRECTION_URL = typeof SET_AFTER_LOGIN_REDIRECTION_URL;