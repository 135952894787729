import { Report } from '../models/Report.model';
import { ReportType } from '../models/ReportType.model';
import PATH_NAME from './pathNames';

export const getReportURL = (report: Report) =>
  `${PATH_NAME.REPORTS}/${report.id}`;

export const getSubmitReportURL = (report: Report) =>
  `${PATH_NAME.REPORTS}/${report.id}/submit`;

export const getCreateReportURL = (reportType: ReportType) =>
  `${PATH_NAME.CREATE_REPORT.replace(':id', `${reportType.id}`)}`;

export const getEditReportURL = (id: Number) =>
  `${PATH_NAME.EDIT_REPORT_FORM.replace(':id', `${id}`)}`;

export const getUserProfileURL = (id: Number) =>
  `${PATH_NAME.USER_PROFILE.replace(':id', `${id}`)}`;

export const getWorkspaceURL = (id: Number) =>
  `${PATH_NAME.WORKSPACE_DETAILS.replace(':id', `${id}`)}`;
