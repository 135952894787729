import axios from '../services/axios';
import { USER_ROLES } from '../utils/constants';
import { API } from './API';
import store from '../store';
export class User extends API {
  public id: number = -1;
  public name: string = '';
  public email: string = '';
  public avatar: string = '';
  public role: string = USER_ROLES.member;
  public team_id: number = -1;
  public position: string = '';

  public static authorizeSlack(code: string, state: string): Promise<any> {
    const url = state === 'add_to_slack' ? 'add_workspace' : 'sign_in';

    return new Promise<any>((resolve, reject) => {
      axios
        .get('/slack/auth/' + url + '?code=' + code)
        .then(res => {
          localStorage.setItem('token', JSON.stringify(res.data.token));
          resolve(res.data);
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  public static signIn(email: string, password: string): Promise<User> {
    let user = new User();
    user.id = 1;
    user.name = 'mahmoud';
    user.email = email;
    // user.imgUrl = '';

    localStorage.setItem('token', 'token');

    return Promise.resolve(user);
  }

  public static signUp(email: string, password: string): Promise<User> {
    let user = new User();
    user.id = 1;
    user.name = 'mahmoud';
    user.email = email;
    // user.imgUrl = '';

    localStorage.setItem('token', 'token');

    return Promise.resolve(user);
  }

  public static signOut(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get('/dashboard/users/sign_out', this.getConfig())
        .then(() => {
          localStorage.removeItem('token');
          resolve();
        })
        .catch(e => {
          this.logout();
          reject();
        });
    });
  }

  public static async currentSession(): Promise<User | null> {
    const token = User.getToken();
    if (token) {
      return new Promise<User | null>((resolve, reject) => {
        axios
          .get('/dashboard/users/current', User.getConfig())
          .then(res => {
            resolve(res.data?.member);
          })
          .catch(e => {
            User.signOut();
            reject(e);
          });
      });
    }
    return Promise.resolve(null);
  }

  public static async allow_create_report(): Promise<boolean> {
    const headers = this.getConfig();

    if (headers) {
      return new Promise<boolean>((resolve, reject) => {
        axios
          .get('dashboard/reports/allow_report_creation', headers)
          .then(res => {
            if (res.status === 200) {
              resolve(true);
            }
          })
          .catch(e => {
            User.checkUnauthorizedRequest(e,store);
            if (e.response.status === 402) {
              // Payment Required
              resolve(false);
            }
          });
      });
    }
    return Promise.reject('System Error');
  }
}
