const PATH_NAME = {
  ROOT: '/',
  LANDING_PAGE: '/welcome',
  HOME: '/app',
  DASHBOARD: '/app/dashboard',
  REPORTS: '/app/reports',
  WORKSPACES: '/app/workspaces',
  WORKSPACE_DETAILS: '/app/workspaces/:id',
  REPORT_DETAILS: '/app/reports/:id/:status?',
  NEW_REPORT_FORM: '/app/report/:operation/:id',
  EDIT_REPORT_FORM: '/app/report/edit/:id',
  SUBMISSION: '/app/submit/report/:id',
  CREATE_REPORT: '/app/report/create/:id',
  USER_PROFILE: '/app/members/:id',
  RELEASE_NOTES: '/app/release-notes',
  SETTINGS: '/app/settings',
  PRIVACY_POLICY: '/app/privacy-policy',
  LOG_OUT: '/app/logout',
  AUTH_CALLBACK: '/app/auth/slack/callback'
};
export default PATH_NAME;
