import { Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import React, { useCallback, useState, useContext } from 'react';
import { ReportType } from '../../../models/ReportType.model';
import { subMenuStyles } from './subMenuStyles';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import DeleteDialog from '../../components/Dialogs/DeleteDialog';
import { useDeleteResponse } from '../../components/Reports/SubmissionCycles/SubmissionCycleCard/Responses/ResponseCard/ResponseCardHooks';
import { ReportSubmission } from '../../components/Reports/Submission/ReportSubmission';
import {
  useMembers,
  useReports
} from '../../components/Reports/ReportForm/reportFormHooks';
import CircularLoader from '../CircularLoader';
import {
  ReportResponse,
  submitReportRequest
} from '../../../models/ReportResponse.model';
import { User } from '../../../models/User.model';
import { AppContext } from '../../../App';

interface ResponseSubMenuProps {
  response: ReportResponse;
}

export const ResponseSubMenu = ({ response }: ResponseSubMenuProps) => {
  const classes = subMenuStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { data: membersList, isLoading: isMembersLoading } = useMembers();
  const { data: report, isLoading: isReportLoading } = useReports(
    response.report_id
  );
  const user: User = useContext(AppContext);
  const deleteResponse = useDeleteResponse();
  const isLoading: boolean = isReportLoading || isMembersLoading;
  const [openSubmissionModal, setOpenSubmissionModal] = useState(false);

  const request: submitReportRequest = {
    reportId: response.report_id,
    reportType: new ReportType(),
    response: response,
    id: response.id as any as string
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteResponse = useCallback(
    e => {
      e.stopPropagation();
      toggleDeleteDialog(e);
      request.reportType = report!.type;
      deleteResponse.mutate(request);
      handleClose(e);
    },
    [request]
  );

  const toggleDeleteDialog = (e: any) => {
    e.stopPropagation();
    setOpenDeleteDialog(openDeleteDialog => !openDeleteDialog);
    handleClose(e);
  };

  const handleEditResponse = (e: any) => {
    e.stopPropagation();
    setOpenSubmissionModal(true);
  };

  return (
    <>
      {openSubmissionModal && (
        <CircularLoader isLoading={isLoading}>
          <Typography>
            <ReportSubmission
              response={response}
              report={report!}
              members={membersList!}
              open={openSubmissionModal}
              setOpen={setOpenSubmissionModal}
              setTargetReportId={response.report_id}
            />
          </Typography>
        </CircularLoader>
      )}

      {!openSubmissionModal && user && user.id === Number(response.member.id) && (
        <>
          <CircularLoader isLoading={isLoading}>
            <Typography className={classes.moreIcon} onClick={handleClick}>
              <Tooltip arrow title="Open Settings Menu">
                <MoreVertOutlinedIcon fontSize="large" />
              </Tooltip>
            </Typography>
            <Menu
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              keepMounted
              onClose={handleClose}
              classes={{ paper: classes.subMenuPaper }}
            >
              <MenuItem
                className={classes.actionMenuItem}
                onClick={handleEditResponse}
              >
                Edit
              </MenuItem>
              <MenuItem
                className={classes.actionMenuItemImportant}
                onClick={toggleDeleteDialog}
              >
                Delete
              </MenuItem>
              <DeleteDialog
                contentText={
                  <span>
                    Are you sure that you want to delete this response
                  </span>
                }
                openDialog={openDeleteDialog}
                handleDialogClose={toggleDeleteDialog}
                handleDialogDelete={handleDeleteResponse}
              />
            </Menu>
          </CircularLoader>
        </>
      )}
    </>
  );
};
