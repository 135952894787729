import { Button, Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { Member } from '../../../../../models/Member.model';
import { styles } from '../styles';
import UserComponent from '../../../../shared-components/UserComponent';
import { useRemindParticipants } from '../ReportsHooks';
import { USER_ROLES } from '../../../../../utils/constants';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { AppContext } from '../../../../../App';
import { User } from '../../../../../models/User.model';

interface Props {
  reportId: number;
  reportName: string;
  notSubmittedMembers: Member[];
  closed: boolean;
}

export const NotSubmittedMembers = ({
  notSubmittedMembers,
  closed,
  reportId,
  reportName
}: Props) => {
  const classes = styles();
  const remindParticipants = useRemindParticipants(reportName);
  const currentUser: User = useContext(AppContext);

  return (
    <Grid
      container
      item
      direction="column"
      className={classes.notSubmittedMembers}
    >
      <Grid item>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          className={classes.alertRoot}
        >
          <Grid item container direction="row" alignItems="center" sm>
            <Grid item>
              <NotificationsActiveIcon className={classes.alertIcon} />
            </Grid>
            <Grid item>
              <span className={classes.alertMessage}>
                {closed ? "Didn't submit responses" : 'Yet to submit responses'}
              </span>
            </Grid>
          </Grid>
          <Grid item>
            {!closed && currentUser?.role !== USER_ROLES.member && (
              <Button
                className={classes.alertAction}
                onClick={() => remindParticipants.mutate(reportId)}
              >
                Send Reminder
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        direction="row"
        className={classes.notSubmittedMembersContainer}
      >
        {notSubmittedMembers?.map((member: Member) => (
          <Grid item key={member.id}>
            <UserComponent
              name={member.name}
              avatar={member.avatar}
              id={member.id}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
