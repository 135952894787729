import React from 'react';
import styles from './styles';

interface Props {
  cardIcon: string;
  cardTitle: string;
  cardDescription: string;
}

function FeatureCard(props: Props) {
  const classes = styles();
  const { cardIcon, cardTitle, cardDescription } = props;
  return (
    <div className={classes.featuresCard}>
      <img
        className={classes.featuresCardIcon}
        src={cardIcon}
        alt="card icon"
      />
      <h2 className={classes.featuresCardTitle}>{cardTitle}</h2>
      <p className={classes.featuresCardDescription}>{cardDescription}</p>
    </div>
  );
}

export default FeatureCard;
