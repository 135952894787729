import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    overflow: 'overlay',
    '@supports ( -moz-appearance:none )': {
      overflow: 'auto',
      height: '130%'
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderBottomRightRadius: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      background: 'rgba(133, 141, 155, .3)'
    },
    '&::-ms-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '&::-ms-scrollbar-track': {
      backgroundColor: 'transparent',
      borderBottomRightRadius: '6px'
    },
    '&::-ms-scrollbar-thumb': {
      borderRadius: '6px',
      background: 'rgba(133, 141, 155, .3)'
    },
    '&::-moz-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '&::-moz-scrollbar-track': {
      backgroundColor: 'transparent',
      borderBottomRightRadius: '6px'
    },
    '&::-moz-scrollbar-thumb': {
      borderRadius: '6px',
      background: 'rgba(133, 141, 155, .3)'
    }
  },
  container: {
    flexGrow: 1,
    width: '100%',
    height: 'calc(100% - 48px)',
    margin: '48px 0 0 0',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentShift: {
    marginLeft: '256px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  }
}));
