import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme =>
  createStyles({
    reportTypeDialogTitle: {
      display: 'flex',
      padding: '8px 0',
      flexDirection: 'row',
      alignItems: 'center'
    },
    reportTypeDialogTitleText: {
      fontSize: '24px',
      color: '#858d9b',
      margin: '0 auto',
      fontFamily: 'Lato-Bold',
      '@media (max-width:420px)': {
        fontSize: '20px'
      },
      '@media (max-width:330px)': {
        fontSize: '16px'
      }
    },
    closeIcon: {
      width: 22,
      height: 22,
      opacity: 0.7,
      marginTop: 4,
      marginRight: 24,
      '&:hover': {
        opacity: 1,
        cursor: 'pointer'
      },
      '@media (max-width:420px)': {
        width: 18,
        height: 18
      },
      '@media (max-width:330px)': {
        width: 14,
        height: 14
      }
    },
    reportDialogContent: {
      overflow: 'overlay',
      '@supports ( -moz-appearance:none )': {
        overflow: 'auto'
      },
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      },
      '&::-ms-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-ms-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-ms-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      },
      '&::-moz-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-moz-scrollbar-track': {
        backgroundColor: 'transparent',
        borderBottomRightRadius: '4px'
      },
      '&::-moz-scrollbar-thumb': {
        borderRadius: '4px',
        background: 'rgba(133, 141, 155, .3)'
      }
    },
    reportTypeDialogContent: {
      maxWidth: '667px'
    },
    reportTypeCard: {
      width: '300px',
      height: '240px',
      marginBottom: 8,
      margin: '0 auto',
      boxShadow: 'none',
      textAlign: 'center',
      backgroundColor: '#fff',
      '&:hover': {
        cursor: 'pointer',
        background: 'rgba(228, 228, 228, 0.4)'
      },
      '&:active': {
        cursor: 'pointer',
        background: 'rgba(228, 228, 228, 0.4)'
      },
      '@media (max-width:711px)': {
        width: 250
      },
      '@media (max-width:611px)': {
        width: 200,
        height: 280
      },
      '@media (max-width:511px)': {
        width: 300
      }
    },
    reportTypeImg: {
      width: '100px',
      height: '100px'
    },
    reportTypeTitle: {
      color: '#4f5560',
      fontSize: '22px',
      lineHeight: 'normal',
      letterSpacing: '0.18px',
      fontFamily: 'Lato-Bold'
    },
    reportTypeDescription: {
      opacity: '0.9',
      color: '#354052',
      fontSize: '16px',
      marginTop: '16px',
      lineHeight: '1.25',
      fontFamily: 'Lato',
      letterSpacing: 'normal'
    }
  })
);
