import React from 'react';
import Button from '@material-ui/core/Button';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Typography
} from '@material-ui/core';
import { Workspace } from '../../../../models/Workspace.model';
import { Member } from '../../../../models/Member.model';
import { useForm, NestedValue } from 'react-hook-form';
import {
  useEditWorkspace,
} from '../workspaceHooks';
import { DialogStyles } from './DialogStyles';
import CloseIcon from '@material-ui/icons/Close';
import t from '../../../../translations/english'


interface workspaceDialogProps {
  workspace: Workspace;
  open: boolean;
  handleClose: () => void;
  editedWorkspaceData: any;
}

interface IFormInput {
  name: string;
  description: string;
  assigned_members: NestedValue<Member[]>;
}

export const ConfirmSubmitDialog = (props: workspaceDialogProps) => {
  const classes = DialogStyles();
  const { open, handleClose, workspace, editedWorkspaceData } = props;


  const editWorkspace = useEditWorkspace();

  const onSubmit = () => {
    let newWorkspace: Workspace = Object.assign({}, workspace);
    newWorkspace.name = editedWorkspaceData.name;
    newWorkspace.description = editedWorkspaceData.description;
    newWorkspace.members = editedWorkspaceData.assigned_members;
    editWorkspace.mutate(newWorkspace);
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent className={classes.workspaceDialogContent}>
        <Typography>
          {t.workspaces.edit.confirmSubmit.message}
        </Typography>
        <FormControl>
          <Box className={classes.formActions}>
            <Button className={classes.cancelBtn} onClick={handleClose}>
              <Typography className={classes.cancelBtnText}>Cancel</Typography>
            </Button>

            <Button
              className={classes.createBtn}
              onClick={onSubmit}
              id='confirm_remove_member_from_workspace'
            >
              <Typography className={classes.createBtnText}>
                {t.workspaces.edit.confirmSubmit.confirm}
              </Typography>
            </Button>
          </Box>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};
