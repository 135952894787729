import { makeStyles } from '@material-ui/core/styles';

import background from '../../../assets/images/bg.png';
export const styles = makeStyles(() => ({
  root: {
    height: '100%',
    '@supports ( -moz-appearance:none )': {
      height: '130%'
    }
  },
  image: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#3571E6',
    backgroundSize: 'contain'
  },
  headerText: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '340px',
    height: '40px',
    fontFamily: 'Lato',
    fontSize: '32px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#2a5cbf'
  },
  card: {
    marginTop: '34px',
    boxShadow: '0 2px 20px 9px rgba(31, 31, 31, 0.04)',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  signInCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center',
    padding: '48px 56px'
  },
  slackUrl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: '20px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
    width: '357px',
    padding: '16px 0',
    borderRadius: '6px',
    marginBottom: '24px',
    boxShadow: '0 1px 1px 0 rgba(193, 193, 193, 0.23)',
    border: 'solid 1px #000000'
  },
  slackIcon: {
    width: '24px',
    height: '24px',
    marginRight: '16px'
  },
  bold: {
    fontWeight: 'bold'
  },
  signTxt: {
    width: '314px',
    height: '27px',
    opacity: '0.56',
    fontFamily: 'Lato',
    fontSize: '20px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.35',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#354052',
    marginBottom: '24px'
  },
  termsAndConditions: {
    marginTop: '8px',
    fontWeight: 500,
    color: '#354052',
    fontSize: '12px',
    lineHeight: '27px',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));
