import { makeStyles } from '@material-ui/core/styles';

export const QuickFindSearchStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    flexDirection: 'column'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  typography: {
    padding: theme.spacing(2)
  },
  divroot: {
    width: '400px',
    backgroundColor: theme.palette.background.paper
  },
  sectionTitle: {
    padding: '16px 0',
    margin: '0 16px',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    fontSize: '16px',
    lineHeight: 'normal',
    color: '#000'
  },
  searchPopover: {
    width: '400px',
    maxHeight: 'calc(100% - 55px)',
    height: 'unset',
    minHeight: 'unset',
    boxShadow:
      '0 2px 5px 0 rgba(219, 219, 219, 0.5), 5px 5px 20px 0 rgba(123, 123, 123, 0.17)',
    border: 'solid 1px rgba(224, 224, 224, 0.49)',
    backgroundColor: theme.palette.background.paper,
    marginTop: '5px',
    borderRadius: '8px',
    top: '40px !important'
  }
}));
