import React, { useState, useCallback } from 'react';
import { styles } from './styles';
import ReportsFilter from '../../../shared-components/ReportsFilter';
import ActivityList from './ActivityList';

const ActivityOption = {
  allOption: 1,
  favoriteOption: 2
};

const ActivitySection = () => {
  const classes = styles();
  const [selectFavorite, setSelectFavorite] = useState(
    Number(localStorage.getItem('activityOption')) ==
      ActivityOption.favoriteOption || false
  );

  const handleReportFilterChange = useCallback(
    (isFavoriteSelected: boolean) => {
      setSelectFavorite(isFavoriteSelected);
    },
    [selectFavorite]
  );

  return (
    <>
      <div className={classes.dashboardHead}>
        <h2 className={classes.activitiesHeader}>Recent Activity List</h2>
        <ReportsFilter onChange={handleReportFilterChange} />
      </div>

      <ActivityList selectFavorite={selectFavorite} />
    </>
  );
};

export default React.memo(ActivitySection);
