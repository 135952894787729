import React from 'react';
import OAuth from './OAuth';
import CircularLoader from '../../shared-components/CircularLoader';
import { User } from '../../../models/User.model';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAuthorizeSlack } from './AuthHooks';
interface Props {
  setCurrentUser: Function;
}

export const SlackAuthCallback = ({ setCurrentUser }: Props) => {
  const params = new URLSearchParams(window.location.search);
  // const error = params.get('error') || '';
  const code = params.get('code') || '';
  const state = params.get('state') || '';
  const history = useHistory();
  const { data, isLoading } = useAuthorizeSlack(code, state);

  useEffect(() => {
    if ((data as any)?.user) {
      setCurrentUser((data as any)?.user as User);
      history.push('/app');
    }
  }, [data]);

  return (
    <CircularLoader isLoading={isLoading}>
      <OAuth />
    </CircularLoader>
  );
};

export default SlackAuthCallback;
