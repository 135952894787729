import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Report } from '../../../../models/Report.model';
import { useDispatch } from 'react-redux';
import { addConfirmation } from '../../../../store/confirmations/Actions';
import { Confirmation } from '../../../../models/Confirmation.model';
import { QUERIES } from '../../../../utils/queris';
import { ApiError } from '../../../../models/ApiError.model';
import { addError } from '../../../../store/errors/Actions';
import { Channel } from '../../../../models/Channel.model';
import { Member } from '../../../../models/Member.model';
import history from '../../../../services/history';
import { getReportURL } from '../../../../utils/routes';
import { Analytics, analyticsEvent } from '../../../../models/Analytics.model';
import { getDifferenceInSeconds } from '../../../../utils/time';
import dayjs from 'dayjs';
import { CREATION_REPORT_EVENTS } from '../../../../utils/constants';

export const useDeleteReport = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((reportId: number) => Report.deleteReport(reportId), {
    onSuccess: async (data, variables) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(`Report deleted successfully`)
        )
      );
      queryClient.invalidateQueries(QUERIES.LOAD_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PENDING_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_WORKSPACE_REPORTS);
    },
    onError: error => {
      dispatch(addError(ApiError.getError('', 'Failed to delete the report')));
    }
  });
};

export const useEditReport = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((report: Report) => Report.edit(report), {
    onSuccess: async (data, variables) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(`Report edited successfully`)
        )
      );
      history.push(getReportURL(data));
      queryClient.invalidateQueries(QUERIES.LOAD_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PENDING_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_WORKSPACE_REPORTS);
    },
    onError: error => {
      dispatch(addError(ApiError.getError('', 'Failed to edit the report')));
    }
  });
};

export const useCreateReport = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((report: Report) => Report.create(report), {
    onSuccess: async (data, variables) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(`Report created successfully`)
        )
      );
      history.push(getReportURL(data));
      queryClient.invalidateQueries(QUERIES.LOAD_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_PENDING_REPORTS);
      queryClient.invalidateQueries(QUERIES.LOAD_WORKSPACE_REPORTS);
    },
    onError: error => {
      dispatch(addError(ApiError.getError('', 'Failed to create the report')));
    }
  });
};

export const useChannels = () => {
  return useQuery<Channel[]>(QUERIES.LOAD_CHANNELS, () => Channel.getAll());
};

export const useMembers = () => {
  return useQuery<Member[]>(QUERIES.LOAD_MEMBERS, () => Member.getAll());
};

export const useReports = (id:number) => {
  return useQuery<Report>([QUERIES.LOAD_REPORT , id] ,()=> Report.getReport(id));
}


export const useRefreshChannels = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(() => Channel.refreshChannels(), {
    onSuccess: async (refetchedChannels) => {
      dispatch(
        addConfirmation(
          Confirmation.getSuccessConfirmation(`Channels are refreshed successfully`)
        )
      );
      queryClient.setQueryData(QUERIES.LOAD_CHANNELS, refetchedChannels);
    },
    onError: error => {
      dispatch(addError(ApiError.getError('', 'Error occured while refreshing, try again')));
    }
  });
}

export const useReportFormAnalytics = ()=> {  
  return useMutation((stepNumber: number)=>{
    const eventTimeTaken = getDifferenceInSeconds(dayjs().toString(),localStorage.getItem('lastEventTime')!);
    if(stepNumber === 3){
      localStorage.removeItem('lastEventTime');
    }else{
      localStorage.setItem('lastEventTime' , dayjs().toString());
    }
    return Analytics.addAnalyticsEvent({eventName: CREATION_REPORT_EVENTS[stepNumber] , eventTimeTaken:eventTimeTaken.toString()});
  }
  );
}

export const useEventAnalytics = ()=> {
  return useMutation((event:analyticsEvent)=>{
    return Analytics.addAnalyticsEvent(event);
  })
}