import { makeStyles, createStyles } from '@material-ui/core/styles';

export const releaseNotesStyles = makeStyles(theme =>
  createStyles({
    bottomText: {
      marginTop: '30px',
      marginBottom: '30px',
    },
    headImage: {
      borderRadius: 10
    },
    horizontalDivider: {
      marginBottom: '30px'
    }
  })
);
