import React from 'react';
import { emptySearchResult } from './styles';
import emptySearchImg from '../../../assets/images/no_search.svg';
import { Typography } from '@material-ui/core';

export const EmptySearchResult = () => {
  const classes = emptySearchResult();

  return (
    <div className={classes.noSearchDiv}>
      <img
        src={emptySearchImg}
        className={classes.noSearchImg}
        alt="UserImage"
      />

      <Typography className={classes.noSearchTxt}>No search results</Typography>
      <Typography className={classes.noSearchHint}>
        Try to use another key words
      </Typography>
    </div>
  );
};
