import { makeStyles, createStyles } from '@material-ui/core/styles';

export const customEditorStyles = makeStyles(theme =>
  createStyles({
    root: {
      fontSize: 14,
      marginLeft: 24,
      marginBottom: 16,
      background: '#fff',
      padding: '8px 16px',
      border: '1px solid #ddd',
      '& .DraftEditor-root': {
        paddingRight: 108,
        wordBreak: 'break-word'
      },
      '@media (max-width: 500px)': {
        marginLeft: 0,
        padding: '4px 8px'
      }
    },
    editorControls: {
      fontSize: 14,
      marginBottom: 4,
      userSelect: 'none',
      fontFamily: 'Lato'
    },
    styleButton: {
      color: '#999',
      cursor: 'pointer',
      marginRight: 16,
      padding: '2px 0',
      display: 'inline-block'
    },
    submitBtn: {
      opacity: 0.8,
      fontSize: 16,
      color: '#3a78f2',
      lineHeight: '20px',
      cursor: 'pointer',
      fontFamily: 'Lato',
      textAlign: 'right',
      letterSpacing: '0.13px',
      paddingTop:6
    },
    closeButton: {
      width: 17,
      height: 20,
      marginRight: 8,
      marginTop: 4,
      paddingTop:4,
      marginLeft:4,
      
      '&:hover': {
        opacity: 0.6,
        cursor: 'pointer'
      },
      '@media (max-width:330px)': {
        width: 17,
        height: 17,
        marginRight: 8,
        marginTop: 2
      }
    },
    activeButton: {
      color: '#5890ff'
    }
  })
);
