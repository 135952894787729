import { createStyles, makeStyles } from '@material-ui/core';
import bottomBackground from '../../../../assets/images/group-28-copy.svg';
import topBackground from '../../../../assets/images/group-28.svg';
const styles = makeStyles(() =>
  createStyles({
    pricingWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '891px',
      backgroundColor: '#172358',
      position: 'relative',
      overflow: 'hidden',
      padding: '57px 0',
      '&::after': {
        content: `url(${bottomBackground})`,
        position: 'absolute',
        left: '-220px',
        bottom: '-100px'
      },
      '&::before': {
        content: `url(${topBackground})`,
        position: 'absolute',
        right: '-220px',
        top: '-100px'
      }
    },
    pricingTitle: {
      fontSize: '48px',
      fontWeight: 'bold',
      color: '#fff',
      marginBottom: '16px',
      textAlign: 'center',
      zIndex: 10
    },
    pricingDescription: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '1.33',
      color: '#fff',
      textAlign: 'center',
      whiteSpace: 'pre-line',
      marginBottom: '40px',
      zIndex: 10,
      padding: '0 20px'
    },
    pricingPlansCardsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '@media only screen and (max-width: 800px)': {
        flexDirection: 'column'
      }
    },
    pricingCardLeftWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      zIndex: 10,
      '& $pricingPlansCard': {
        width: '330px',
        height: '571px',
        boxShadow: '5px 26px 26px 0 rgba(125, 125, 125, 0.39)',
        borderBottom: '8px solid #a4c3ff'
      },
      '& $altPricingPlansCard': {
        width: '297px',
        height: '514px'
      },
      '& $plansCardPrice': {
        backgroundColor: '#ebf1fd'
      },
      '& $plansCardBtn': {
        boxShadow: '0 1px 5px 0 rgba(255, 207, 128, 0.6)',
        border: 'solid 1px #f5a623',
        backgroundColor: '#fff',
        color: '#f5a623'
      },
      '@media only screen and (max-width: 800px)': {
        marginBottom: '20px',
        justifyContent: 'center',
        paddingRight: '25px !important',
        '& $pricingPlansCard': {
          marginLeft: '25px !important'
        }
      }
    },
    pricingCardRightWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      zIndex: 10,
      '& $pricingPlansCard': {
        width: '352px',
        height: '532px',
        boxShadow: '6px 0 26px 0 rgba(125, 125, 125, 0.39)',
        borderBottom: '8px solid rgba(245, 166, 35, 0.1)'
      },
      '& $altPricingPlansCard': {
        width: '297px',
        height: '480px'
      },
      '& $plansCardPrice': {
        backgroundColor: 'rgba(245, 166, 35, 0.1)'
      },
      '& $plansCardBtn': {
        boxShadow: '0 2px 5px 0 #ffcf80',
        backgroundColor: '#f5a623',
        color: '#fff'
      },
      '@media only screen and (max-width: 800px)': {
        marginBottom: '20px',
        justifyContent: 'center',
        paddingLeft: '25px !important',
        '& $pricingPlansCard': {
          width: '330px',
          marginRight: '25px !important'
        }
      }
    },
    pricingPlansCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '6px',
      border: '0.9px solid rgba(151, 151, 151, 0.159064)',
      boxSizing: 'border-box',
      backgroundColor: '#fff',
      animationName: 'scale3dIn',
      animationDuration: '0.5s',
      animationFillMode: 'both',
      animationTimingFunction: 'ease-in-out',
      overflow: 'hidden',
      '&:hover': {
        animationName: '$scale3dOut',
        animationDuration: '0.5s',
        animationFillMode: 'both',
        animationTimingFunction: 'ease-in-out'
      }
    },
    activeFreeSubscription:{
      borderBottom: '8px solid #3A78F2 !important',
      border: '0.9px solid #3A78F2',
      
    },
    activePaidSubscription:{
      border: '0.9px solid  #F5A623',
      borderBottom: '8px solid #F5A623 !important'
     
    },
    currentPlanButton:{
       background:'white !important',
       color:'#F5A623 !important'
    },
    getStartedButton:{
      background:'#F5A623 !important',
      color:'white !important'
    },
    plansCardTitle: {
      fontSize: '18px',
      fontWeight: 900,
      color: '#3a78f2',
      textTransform: 'uppercase',
      marginBottom: '16px'
    },
    plansCardPrice: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '104px',
      marginBottom: '18px',
      '& p': {
        margin: '0',
        '&:nth-child(1)': {
          opacity: '0.8',
          fontSize: '25px',
          fontWeight: 'bold',
          color: '#55647d',
          marginBottom: '22px'
        },
        '&:nth-child(2)': {
          fontSize: '40px',
          fontWeight: 'bold',
          color: '#55647d',
          marginBottom: '11px'
        },
        '&:nth-child(3)': {
          fontSize: '32px',
          color: '#55647d',
          marginTop: '11px'
        }
      }
    },
    plansCardInfo: {
      width: '100%',
      '& p': {
        display: 'flex',
        alignItems: 'center',
        opacity: '0.8',
        fontSize: '18px',
        fontWeight: '500',
        color: '#55647d',
        marginBottom: '16px',
        padding: '0 24px'
      }
    },
    checkIcon: {
      color: '#f5a623',
      width: '14px',
      height: '11px',
      marginRight: '8px'
    },
    plansCardBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'nowrap',
      width: '167px',
      height: '40px',
      borderRadius: '8px',
      boxShadow: '0 1px 5px 0 rgba(255, 207, 128, 0.6)',
      border: 'solid 1px #f5a623',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#f5a623',
      padding: '0',
      marginTop: '8px',
      cursor: 'pointer'
    },
    '@keyframes scale3dOut': {
      '0%': {
        transform: 'scale3d(1, 1, 1)'
      },
      '100%': {
        transform: 'scale3d(1.05, 1.05, 1.05)'
      }
    },
    altPricingWrapper: {
      backgroundColor: 'unset',
      padding: '0 8px 300px 8px'
    },
    activePlanLeft: {
      border: '0.9px solid #3A78F2'
    },
    activePlanRight: {
      border: '0.9px solid #F5A623'
    },
    altPricingPlansCard: { paddingBlock: '60px' }
  })
);

export default styles;
