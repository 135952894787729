import { Avatar, Badge, Menu, MenuItem } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Member } from '../../../models/Member.model';
import { SlicedParticipantsListStyles } from './slicedParticipantsListStyles';
import {
  usePopupState,
  bindTrigger,
  bindMenu
} from 'material-ui-popup-state/hooks';
import UserComponent from '../../shared-components/UserComponent';
import CheckIcon from '@material-ui/icons/Check';
import classNames from 'classnames';
import { Link as RLink, useHistory } from 'react-router-dom';
import CustomizedTooltip from '../../shared-components/CustomizedTooltip';
import { getUserProfileURL } from '../../../utils/routes';

interface Props {
  members: Member[];
  slicedCount: number;
  not_submitted_member_ids?: number[];
}

const getMemberList = (members: Member[], slicedCount: number): Member[] => {
  if (members.length > slicedCount) return members.slice(0, slicedCount);
  return members;
};

export const SlicedParticipantsList = ({
  members,
  slicedCount,
  not_submitted_member_ids
}: Props) => {
  const classes = SlicedParticipantsListStyles();
  const slicedMembers = getMemberList(members, slicedCount);
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
  const history = useHistory();
  const url = history.location.pathname;
  const memberSubmissionStatus = useMemo(() => {
    let submissionStatusMap = new Map();
    members.forEach((member: Member) => {
      submissionStatusMap.set(
        member.id,
        !!not_submitted_member_ids &&
          !not_submitted_member_ids?.find(
            (member_id: number) => member.id === member_id
          )
      );
    });
    return submissionStatusMap;
  }, [members, not_submitted_member_ids]);

  return (
    <div className={classes.avatars}>
      {slicedMembers.map(member => {
        return (
          <RLink
            to={getUserProfileURL(member.id)}
            key={member.id}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <CustomizedTooltip
              title={member.name}
              placement="bottom"
              children={
                <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  badgeContent={
                    memberSubmissionStatus.get(member.id) ? (
                      <CheckIcon className={classes.checkmarkIcon} />
                    ) : null
                  }
                  classes={{
                    anchorOriginTopLeftCircle: classes.anchorOriginTopLeftCircle
                  }}
                  key={member.id}
                >
                  <Avatar
                    alt={member.name}
                    src={member.avatar}
                    className={classes.oval}
                  />
                </Badge>
              }
            />
          </RLink>
        );
      })}
      {members.length > slicedCount && (
        <div
          className={classNames(classes.oval, classes.fixedOval)}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <span className={classes.ovalText} {...bindTrigger(popupState)}>
            +{members.length - slicedCount}
          </span>
          <Menu
            {...bindMenu(popupState)}
            
          >
            {members.map(member => (
              <MenuItem
                onClick={e => {
                  e.stopPropagation();
                  popupState.close();
                }}
                key={member.id}
              >
                <UserComponent
                  name={member.name}
                  avatar={member.avatar}
                  id={member.id}
                />
                {memberSubmissionStatus.get(member.id) && (
                  <CheckIcon className={classes.checkmarkIcon} />
                )}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </div>
  );
};
