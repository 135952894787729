import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Question } from '../../../../../models/Question.model';
import { Report } from '../../../../../models/Report.model';
import { ReportResponse } from '../../../../../models/ReportResponse.model';
import { SubmissionCycle } from '../../../../../models/SubmissionCycle.model';
import { styles } from '../styles';
import { NotSubmittedMembers } from './NotSubmittedMembers';
import SubmissionCycleHeader from './SubmissionCycleHeader';
import { Responses } from './Responses';
import { PollResponses } from './PollResponses';
import { REPORT_TYPES } from '../../../../../utils/constants';

interface Props {
  submissionCycle: SubmissionCycle;
  report: Report;
  openId: number;
  setOpenId: Function;
}

export interface GroupingProps {
  responses: ReportResponse[];
  questions: Question[];
}

export const SubmissionCycleCard = ({
  submissionCycle,
  report,
  openId,
  setOpenId
}: Props) => {
  const classes = styles();

  return (
    <Grid container direction="column" className={classes.submissionCycleCard}>
      {/* Submission cycle header */}
      <SubmissionCycleHeader
        createdDate={submissionCycle.created_at}
        submittedResponsesCount={submissionCycle.responses?.length as number}
        totalResponsesCount={
          (submissionCycle.responses?.length as number) +
          (submissionCycle.not_submitted_members?.length as number)
        }
        closed={submissionCycle.closed}
        collapse={!(submissionCycle.id === openId)}
        setOpenId={setOpenId}
        submissionCycleId={submissionCycle.id}
      />
      {submissionCycle.id === openId && (
        <>
          {/* List of members who didn't submit responses*/}
          <Grid item>
            {submissionCycle.not_submitted_members?.length ? (
              <NotSubmittedMembers
                reportId={report.id}
                reportName={report.name}
                notSubmittedMembers={submissionCycle.not_submitted_members}
                closed={submissionCycle.closed}
              />
            ) : (
              <Grid item className={classes.gridSeparator}></Grid>
            )}
          </Grid>
          {submissionCycle.responses?.length ? (
            report.type.title === REPORT_TYPES[3].title ? (
              <PollResponses
                report={report}
                reportSubmissionCycle={submissionCycle}
              />
            ) : (
              <Responses
                responses={submissionCycle.responses}
                questions={report.questions}
              />
            )
          ) : (
            <Typography align="center" className={classes.emptyResponses}>
              No submissions!
            </Typography>
          )}
        </>
      )}
    </Grid>
  );
};
