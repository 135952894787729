import { API } from './API';
import axios from '../services/axios';
import { ReportResponse } from './ReportResponse.model';
import { Member } from './Member.model';
import store from '../store';

export const SUBMISSION_CYCLES_PAGE_LIMIT = 5;

export type PaginatedSubmissionCycles = {
  submission_cycles: SubmissionCycle[];
  pageCount: number;
};

export class SubmissionCycle extends API {
  public id: number = -1;
  public closed: boolean = false;
  public start_time: string = '';
  public end_time: string = '';
  public created_at: string = '';
  public responses?: ReportResponse[] = [];
  public not_submitted_members?: Member[] = [];

  public static async getSubmissionCycles(
    reportId: number,
    page: number,
    startDate?: string,
    endDate?: string
  ): Promise<PaginatedSubmissionCycles> {
    const queryParams = `page=${page}&page_limit=${SUBMISSION_CYCLES_PAGE_LIMIT}&start_date=${startDate}&end_date=${endDate}`;
    return new Promise<PaginatedSubmissionCycles>((resolve, reject) => {
      axios
        .get(
          `dashboard/reports/${reportId}/submission_cycles?${queryParams}`,
          SubmissionCycle.getConfig()
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          this.checkUnauthorizedRequest(e,store);
          reject(e);
        });
    });
  }
}
