import { Theme, createStyles } from '@material-ui/core';
import { DRAWER_WIDTH } from '../../../../utils/constants';

export const headerStyles = (theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: 100,
      height: '58px',
      backgroundColor: '#fff',
      boxShadow: '0 2px 16px 3px rgba(31, 31, 31, 0.04)',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    toolbar: {
      display: 'flex',
      minHeight: '48px',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'rgba(31, 31, 31, 0.04)',
      boxShadow: '0 2px 16px 3px rgba(31, 31, 31, 0.04)'
    },
    sidebarButton: {
      visibility: 'hidden'
    },
    newReportButton: {
      width: '123px',
      height: '34px',
      display: 'flex',
      borderRadius: '4px',
      padding: '4px 16px',
      whiteSpace: 'nowrap',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: '#f5a623',
      boxShadow: '0 2px 4px 0 rgba(89, 89, 89, 0.11)'
    },
    plusIcon: {
      width: '8px',
      height: '8px',
      margin: '5px 8px 5px 0'
    },
    plusIconEmptyState: {
      width: '12px',
      height: '12px',
      margin: '0 8px 2px 0'
    },
    buttonLabel: {
      color: '#fff',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '16px',
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      textTransform: 'capitalize'
    },
    appBarShift: {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    notificationSection: {
      display: 'flex',
      alignItems: 'center'
    },
    '@media screen and (max-width:700px)': {
      sidebarButton: {
        visibility: 'visible'
      }
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      paddingTop: '6px'
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'center'
    },
    dialogTitleText: {
      textAlign: 'center',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '27px',
      color: '#858D9B'
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    contentHeader: {
      fontWeight: 800,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#4F5560'
    },
    contentBody: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#354052',
      width: '268px',
      height: '36px',
      marginTop: '8px'
    },
    upsellingIllustration: {
      width: '150px',
      height: '186px',
      marginInline: '120px',
      marginBottom: '16px'
    },
    dialogActions: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    upgradeButton: {
      backgroundColor: '#F5A623',
      fontWeight: 700,
      color: 'white',
      fontSize: '14px',
      lineHeight: '16px',
      width: '132px',
      height: '40px',
      marginBottom: '37px',
      textTransform: 'unset'
    }
  });

export const notificationStyles = (theme: Theme) =>
  createStyles({
    notificationIcon: {
      padding: 0,
      marginLeft: 18,
      color: '#858d9b'
    },
    notificationBadge: {
      height: 11,
      minWidth: 11,
      color: '#fff',
      padding: '1px',
      fontSize: '11px',
      fontWeight: 'bold',
      margin: '0 1px 0 2px',
      backgroundColor: '#f5a623',
      fontFamily: 'Nunito-Regular',
      boxShadow: '0 2px 4px 0 rgba(128, 128, 128, 0.37)'
    },
    notificationTitleWrapper: {
      display: 'flex',
      background: '#fff',
      position: 'sticky',
      top: 0,
      zIndex: 2,
      borderRadius: '8px',
      '&::before': {
        top: -8,
        right: 9,
        width: 10,
        height: 10,
        content: '""',
        position: 'absolute',
        backgroundColor: '#fff',
        transform: 'translate(-50%, 50%) rotate(-45deg)'
      }
    },
    notificationTitle: {
      display: 'flex',
      padding: '16px 0',
      margin: '0 16px',
      fontWeight: 'bold',
      letterSpacing: 'normal',
      fontSize: '16px',
      lineHeight: 'normal',
      color: '#000',
      borderBottom: '1px solid rgba(216, 216, 216, 0.3)'
    },
    notificationText: {
      margin: 0
    },
    unreadBadge: {
      minWidth: 18,
      minHeight: 18,
      opacity: 0.6,
      borderRadius: '50%',
      backgroundColor: '#f5a623 !important',
      margin: '7px 0 0 5px'
    },
    arrowUp: {
      width: '324px',
      maxHeight: 'calc(100% - 55px)',
      height: 'unset',
      minHeight: 'unset',
      overflow: 'visible',
      boxShadow:
        '0 2px 5px 0 rgba(219, 219, 219, 0.5), 5px 5px 20px 0 rgba(123, 123, 123, 0.17)',
      border: 'solid 1px rgba(224, 224, 224, 0.49)',
      backgroundColor: '#fff',
      marginTop: '8px',
      borderRadius: '8px',
      top: '40px !important',
      left: 'unset !important',
      right: '16px',
      '@media screen and (max-width:700px)': {
        right: '8px'
      }
    },
    scrollableNotifications: {
      maxHeight: '83vh',
      overflow: 'overlay',
      '@supports ( -moz-appearance:none )': {
        overflow: 'auto'
      },
      '&::-webkit-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      },
      '&::-moz-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-moz-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-moz-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      },
      '&::-ms-scrollbar': {
        height: '15px',
        width: '15px'
      },
      '&::-ms-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '&::-ms-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '50px',
        backgroundColor: '#dee6ff',
        boxShadow:
          'inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)'
      }
    },
    notificationElementWrapper: {
      cursor: 'pointer',
      padding: '0 16px'
    },
    notificationElement: {
      borderBottom: '1px solid rgb(216, 216, 216, 0.3)',
      padding: '8px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      fontFamily: 'Nunito-Regular',
      fontSize: '14px',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#85909c'
    },
    notificationIconContent: {
      display: 'flex'
    },
    unreadnotificationElement: {
      background: 'rgba(216, 216, 216, 0.2)',
      fontFamily: 'Nunito-SemiBold',
      fontWeight: 600,
      '& p': {
        color: '#505861'
      }
    },
    noNotificationsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
      height: '254px'
    },
    noNotificationsHead: {
      margin: '0 0 8px 0',
      fontFamily: 'Nunito-Regular',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#505861'
    },
    noNotificationsContent: {
      margin: 0,
      fontFamily: 'Nunito-Regular',
      fontSize: '14px',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#85909c',
      whiteSpace: 'pre-line'
    },
    imgWrapper: {
      width: '24px',
      height: '24px',
      margin: '0 8px 0 0'
    },
    seeMoreWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'sticky',
      bottom: 0,
      backgroundColor: '#fff'
    },
    markAllAsRead: {
      display: 'flex',
      fontSize: '5px',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'sticky',
      bottom: 0,
      backgroundColor: '#fff',
      padding: '16px 0',
      margin: '0 16px',
      letterSpacing: 'normal',
      lineHeight: 'normal'
    }
  });
