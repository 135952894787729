import { createStyles, makeStyles } from '@material-ui/core';
const styles = makeStyles(() =>
  createStyles({
    signUpLink: {
      textDecoration: 'none'
    },
    signUpButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '167px',
      height: '40px',
      borderRadius: '8px',
      boxShadow: '0 2px 5px 0 #ffcf80',
      backgroundColor: '#f5a623',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '1.14',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        opacity: '0.8'
      }
    }
  })
);
export default styles;
