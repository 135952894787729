import { styles } from '../styles';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import React from 'react';
import { Channel } from '../../../../../models/Channel.model';
import { FormSelect } from '../FormSelect';
import { Member } from '../../../../../models/Member.model';
import { ParticipantsList } from '../TeammatesTab/ParticipantsList';
import { Controller, useFormContext } from 'react-hook-form';
import { Emails } from './Emails';
import { ChannelHelper } from './ChannelHelper';
import { FieldLabel } from '../shared/FieldLabel';

interface Props {
  membersList: Member[];
  channelsList: Channel[];
}

export default function ReportingTab({ membersList, channelsList }: Props) {
  const classes = styles();
  const { watch } = useFormContext();
  const watchSubscribers = watch('notification_subscribers');

  return (
    <>
      <div className={classes.channels}>
        <div className={classes.channelSelect}>
          <FieldLabel label="Deliver via Channel" />
          <FormSelect
            selectId="channels"
            placeholder="Select channels"
            multiple={true}
            getOptionLabel={(channel: Channel) => channel.name}
            options={(channelsList as Channel[]) || []}
            customClasses={{
              inputRoot: classes.selectField
            }}
            getOptionSelected={(option: any, value: any) =>
              option.id === value.id
            }
          />
        </div>
        <ChannelHelper />
      </div>

      <div>
        <FieldLabel label="Deliver via Direct Messages to" />
        <FormSelect
          selectId="notification_subscribers"
          placeholder="Select participants"
          multiple={true}
          options={(membersList as Member[]) || []}
          getOptionLabel={(member: Member) => member.name}
          customClasses={{
            inputRoot: classes.selectField
          }}
          getOptionSelected={(option: any, value: any) =>
            option.id === value.id
          }
        />
      </div>

      <ParticipantsList
        participantList={watchSubscribers}
        inputName="notification_subscribers"
      />

      <Emails />

      <div className={classes.controllersWrapper}>
        <Controller
          name="group_responses"
          render={({ onChange, value }) => (
            <FormControl>
              <RadioGroup
                defaultValue={value}
                value={String(value)}
                onChange={(event: any) => onChange(event.target.value)}
              >
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Post when a member submits his response"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Post when the report is complete"
                />
              </RadioGroup>
            </FormControl>
          )}
        />

        <Controller
          name="threads_enabled"
          render={({ onChange, value, ref }) => (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={e => onChange(e.target.checked)}
                  checked={value}
                  inputRef={ref}
                  color="primary"
                />
              }
              label="Post responses as a thread"
            />
          )}
        />
      </div>
    </>
  );
}
