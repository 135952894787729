import React, { useCallback } from 'react';
import { SlicedParticipantsList } from '../../Reports/SlicedParticipantsList';
import { workspaceDetailsStyles } from './workspaceDetailsStyles';
import { ReportSubMenu } from '../../../shared-components/SettingsSubMenus/ReportSubmenu';
import { useHistory } from 'react-router';
import { Workspace } from '../../../../models/Workspace.model';
import { Report } from '../../../../models/Report.model';
import {
  useUnpinReport,
  usePinReport
} from '../../Reports/ReportCard/ReportCardHooks';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import { getReportURL } from '../../../../utils/routes';
interface IProps {
  report: Report;
  workspace: Workspace;
  updateCashedReport: Function;
}

export const ReportDetailsCard = ({
  report,
  workspace,
  updateCashedReport
}: IProps) => {
  const classes = workspaceDetailsStyles();
  const history = useHistory();
  const pinReport = usePinReport(report, updateCashedReport);
  const unpinReport = useUnpinReport(report, updateCashedReport);

  const handleViewReportClick = useCallback(() => {
    history.push(getReportURL(report));
  }, [history, report]);

  return (
    <section className={classes.cardWrapper} onClick={handleViewReportClick}>
      <div className={classes.cardLeftSide}>
        <div className={classes.cardLeftUpper}>
          <h2 className={classes.reportName}>{report.name}</h2>
          <span className={classes.reportActivationLabel}>
            {report.active ? 'Active' : 'Inactive'}
          </span>
          <div>
            {report.pinned ? (
              <StarOutlinedIcon
                onClick={(e) => {
                  e.stopPropagation();
                  unpinReport.mutate(report.id)}}
                className={classes.pinnedReportIcon}
              />
            ) : (
              <StarBorderOutlinedIcon
                className={classes.unpinnedReportIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  pinReport.mutate(report.id)}}
              />
            )}
          </div>
        </div>
        <button
          className={classes.viewReportBtn}
          onClick={handleViewReportClick}
        >
          View Report
        </button>
      </div>
      <div className={classes.cardRightSide}>
        <SlicedParticipantsList members={report.participants} slicedCount={3} />
        <ReportSubMenu
          report={report}
          workspace={workspace}
          updateCashedReport={updateCashedReport}
          redirectAfterDelete={false}
        />
      </div>
    </section>
  );
};
