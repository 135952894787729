
import React from 'react';
import { Comments } from './Comments';
import CircularLoader from '../../../../../shared-components/CircularLoader';
import { ReportResponse } from '../../../../../../models/ReportResponse.model';
import { useMembers } from './ResponseAttachmentsHooks';
import { CommentEditor } from './CommentEditor';
import { ResponseReaction } from './ResponseReaction';

interface IProps {
  response: ReportResponse;
}

export const ResponseAttachments = ({ response }: IProps) => {

  const { data: members, isLoading } = useMembers();


  return (
    <CircularLoader isLoading={isLoading}>
      <Comments comments={response.comments} members={members!} />
      <ResponseReaction responseId={response.id} reportId={response.report_id} members={members!} />
    </CircularLoader>
  );
};
