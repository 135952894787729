import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import GroupedResponsesCard from './GroupedResponsesCard';
import { Report } from '../../../../models/Report.model';
import { styles } from './styles';

interface GroupedResponsesProps {
  report: Report;
  groupedResponses: [];
}

export default function GroupedResponses({
  report,
  groupedResponses
}: GroupedResponsesProps) {
  const classes = styles();
  const [openId, setOpenId] = useState(0);

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      className={classes.groupedResponsesContainer}
    >
      {groupedResponses.map((element: any, index: number) => {
        return (
          <Grid item key={index}>
            <GroupedResponsesCard
              report={report}
              responses={element.responses}
              date={element.date}
              openId={openId}
              setOpenId={setOpenId}
              responseGroupId={index}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
