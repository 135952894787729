import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiError } from '../../../../models/ApiError.model';
import { RootState } from '../../../../store';
import { dismissError } from '../../../../store/errors/Actions';
import { styles } from '../styles';
import { PopupErrorMessage } from './PopupErrorMessage';



const ErrorsList = ()=>{
  const classes = styles();
  const errorsList = useSelector((states: RootState) => states.errors.errorsList);
  const dispatch = useDispatch();

  const handleClose = (err: ApiError) => {
     dispatch(dismissError(err));
  };

  return(
    <div>
    <ul className={classes.messagesList}>
      {errorsList.map(err => {
        return (
          <li key={err.id}>
           <PopupErrorMessage handleClose={handleClose} err={err} open={true}/>
          </li>
        );
      })}
    </ul>
  </div>
  )
}

export default ErrorsList ;

