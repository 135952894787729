import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectAutoComplete } from '../../../shared-components/SelectAutocomplete';

export interface SelectOption {
  id: any;
  value: string;
}

export interface SelectFieldProps {
  disabled?: boolean;
  options: any[];
  selectId: string;
  placeholder?: string;
  label?: string;
  multiple?: boolean;
  popupIcon?: any;
  customClasses?: any; // used to override or extend the styles applied to the component
  customClass?: any;
  error?: boolean;
  errorMessage?: string;
  getOptionLabel: Function;
  getOptionSelected?: (option: any, value: any) => boolean;
  groupBy?: Function
}

export const FormSelect = memo(
  ({
    disabled = false,
    options,
    selectId,
    placeholder = '',
    multiple = false,
    customClasses,
    customClass,
    getOptionLabel,
    getOptionSelected,
    groupBy
  }: SelectFieldProps) => {
    const {
      control,
      formState: { errors }
    } = useFormContext();

    return (
      <Controller
        render={({ onChange, value }) => (
          <SelectAutoComplete
            value={value}
            onChange={onChange}
            options={options}
            getOptionLabel={getOptionLabel}
            selectId={selectId}
            multiple={multiple}
            disabled={disabled}
            getOptionSelected={getOptionSelected}
            placeholder={placeholder}
            error={errors[selectId]}
            customClass={customClass}
            customClasses={customClasses}
            errorMessage={
              errors[selectId] ? errors[selectId].message : undefined
            }
            groupBy={groupBy}
          />
        )}
        // rules={{ required: true }}
        name={selectId}
        control={control}
      />
    );
  }
);
