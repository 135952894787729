import React, { useCallback, useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogContent
} from '@material-ui/core';
import { Report } from '../../../../models/Report.model';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router';
import { styles } from './styles';
import { submitPollRequest } from '../../../../models/Option.model';
import { useSubmitPoll } from './SubmissionHooks';
import CloseIcon from '@material-ui/icons/Close';

interface PollSubmissionProps {
  report: Report;
  open: boolean;
  setOpen: any;
  setTargetReportId: any;
}

export const PollSubmission = (props: PollSubmissionProps) => {
  const classes = styles();
  const { report, open, setOpen, setTargetReportId } = props;
  const [currentOption, setOption] = useState(report.options[0].id.toString());
  const { handleSubmit, control } = useForm();
  const history = useHistory();

  const handleChange = useCallback((event: any) => {
    setOption(event.target.value);
  }, []);

  const submitReportMutation = useSubmitPoll(history);

  const onSubmit = (data: any) => {
    const request: submitPollRequest = {
      reportId: report!.id,
      reportType: report!.type,
      optionId: data['option']
    } as submitPollRequest;
    submitReportMutation.mutate(request);
  };
  const handleClose = () => {
    setOpen(false);
    setTargetReportId(-1);
    const currentLocation = window.location.href;
    if (/#*\/submit/.test(currentLocation)) {
      history.replace({
        pathname: `/app/reports/${report?.id}`
      });
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <div className={classes.dialogTitleWrapper}>
        <Typography className={classes.dialogTitle}>
          Submit your Choice
        </Typography>
        <CloseIcon className={classes.closeButton} onClick={handleClose} />
      </div>
      <DialogContent className={classes.reportDialogContent}>
        <Grid item container justify="center">
          <form
            key={report!.id + report!.name}
            onSubmit={handleSubmit(onSubmit)}
            className={classes.pollForm}
          >
            <div>
              <h3 className={classes.question}>{report.question}</h3>
              <FormControl component="fieldset">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="option"
                  defaultValue={currentOption}
                  as={
                    <RadioGroup
                      aria-label="promoting"
                      value={currentOption}
                      onChange={handleChange}
                    >
                      {report.options?.map(option => (
                        <FormControlLabel
                          value={option.id.toString()}
                          control={<Radio className={classes.radioOptions} />}
                          label={option.content}
                          className={classes.optionContent}
                          classes={{ label: classes.optionContentLabel }}
                          key={option.id}
                        />
                      ))}
                    </RadioGroup>
                  }
                />
              </FormControl>
            </div>
            <Grid container justify="center">
              <Button
                className={classes.submitBtn}
                variant="contained"
                color="secondary"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
