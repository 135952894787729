import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ReportCountCard from './ReportCountCard';

import { Member } from '../../../../models/Member.model';
import { Statistic } from '../../../../models/Statistic.model';



import { profileCardStyles } from './styles';
interface props {
  member: Member;
  statistics: Statistic[];
}
export default function ProfileCard({ member, statistics }: props) {
  const classes = profileCardStyles();
  
  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={member?.avatar} />
      <CardContent className={classes.user}>
        <Box display="flex" justifyContent="center" alignItems="baseLine" className={classes.userBox}>
          <Typography className={classes.memberName}>{member?.name}</Typography>
          {/* Todo: Add percentage response rate */}
          {/* <Typography className={classes.responseRate}>
            ( <span className={classes.percentage}>80%</span>response rate )
          </Typography> */}
        </Box>
      </CardContent>
      <Grid className={classes.reportCountCardContainer} container  spacing={3} justify="center">
        {/*Todo: Replace with user's specific statistics */}
        {statistics.map((report: Statistic, index) => {
          return (
            <Grid key={index}    item>
              <ReportCountCard
                    key={report.id}
                    count={report.count}
                    reportName={report.name}
                  />
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
}
