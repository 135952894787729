import { makeStyles } from '@material-ui/core';

export const pollResponsesStyles = makeStyles({
  pollQuestion: {
    color: '#474f58',
    fontSize: '14px',
    marginLeft: '16px',
    fontFamily: 'Lato-Bold'
  }
});

export const pollOptionListStyles = makeStyles({
  root: {
    borderRadius: 5,
    padding: '16px 0',
    margin: '8px 16px',
    backgroundColor: 'rgb(216, 216, 216, 0.15)'
  },
  rectMax: {
    border: 'solid 1px #3a78f2',
    boxShadow: '2px 2px 20px 0 rgba(183, 183, 183, 0.34)'
  },
  rectMaxText: {
    color: '#3a78f2 !important'
  },
  votePercentageContainer: {
    '@media (max-width: 320px)': {
      marginLeft: '15%'
    }
  },
  avatars: {
    display: 'flex',
    flexDirection: 'row',
    margin: '16px 0px 0px 24px'
  },
  oval: {
    width: '36px',
    height: '36px',
    cursor: 'pointer',
    marginLeft: '-9px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    border: 'solid 0.8px #f0f0f0'
  },
  fixedOval: {
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 0.8px #f0f0f0'
  },
  ovalText: {
    color: '#3a78f2',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: 'normal'
  },
  optionContent: {
    color: '#4f5560',
    fontSize: '16px',
    margin: '0 16px',
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontStretch: 'normal'
  },
  votePercentage: {
    color: '#85909c',
    margin: '0 16px',
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '8px'
  },
  voteCount: {
    color: '#85909c',
    margin: '0 16px',
    fontSize: '16px',
    textAlign: 'center',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontFamily: 'Lato-SemiBold'
  }
});
