import React, { useContext, useState } from 'react';
import {
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Dialog,
  Button,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AdminAuthority } from '../../shared-components/AdminAuthority';
import { headerStyles } from '../Home/Header/styles';
import { User } from '../../../models/User.model';
import ReportTypeDialog from './ReportTypeDialog';
import upsellingIllustration from '../../../assets/images/buy me food.png';
import yellowPlusIcon from '../../../assets/images/yellow_plus.svg';
import whitePlusIcon from '../../../assets/images/white_plus.svg';
import history from '../../../services/history';
import { USER_ROLES } from '../../../utils/constants';
import PATH_NAME from '../../../utils/pathNames';
import { AppContext } from '../../../App';

interface StateProps {
  classes: any;
  isEmptyState?: boolean;
}

const NewReportButton = ({ classes, isEmptyState = false }: StateProps) => {
  const [warningDialog, setWarningDialog] = useState(false);
  const [reportTypeDialog, setReportTypeDialog] = useState(false);
  const currentUser: User = useContext(AppContext);

  //TODO: use callbacks and check performance with and without
  function createNewReportClicked() {
    User.allow_create_report().then(status => {
      status ? setReportTypeDialog(true) : setWarningDialog(true);
    });
  }

  function closeWarningDialog() {
    setWarningDialog(false);
  }

  function closeReportTypeDialog() {
    setReportTypeDialog(false);
  }

  function redirectToplanPage() {
    setWarningDialog(false);
    history.push({
      pathname: PATH_NAME.SETTINGS,
      state: { tab: 1 } // current plan tab in settings page
    });
  }

  return (
    <>
      {/* the button  */}
      {currentUser && currentUser.role !== USER_ROLES.viewer && (
        <Button
          variant="contained"
          onClick={createNewReportClicked}
          classes={{
            root: classes.newReportButton,
            label: classes.buttonLabel
          }}
        >
          {isEmptyState ? (
            <img
              src={yellowPlusIcon}
              className={classes.plusIconEmptyState}
              alt=""
            />
          ) : (
            <img src={whitePlusIcon} className={classes.plusIcon} alt="" />
          )}{' '}
          New Report
        </Button>
      )}
      {/* // report type dialog */}
      <ReportTypeDialog
        isOpen={reportTypeDialog}
        closeReportTypeDialog={closeReportTypeDialog}
      />
      {/* // plan dialog */}
      <Dialog
        open={warningDialog}
        onClose={closeWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          <Typography className={classes.dialogTitleText}>
            Upgrade your plan
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeWarningDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <img
            src={upsellingIllustration}
            className={classes.upsellingIllustration}
            alt="Upgrade"
          />
          <Typography className={classes.contentHeader}>
            You’ve reached the limit of your Free plan
          </Typography>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.contentBody}
          >
            Please, upgrade your plan in order to be able to create more reports
            with Bscrum
          </DialogContentText>
        </DialogContent>

        <AdminAuthority>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={redirectToplanPage}
              variant="contained"
              className={classes.upgradeButton}
            >
              Upgrade Now
            </Button>
          </DialogActions>
        </AdminAuthority>
      </Dialog>
    </>
  );
};

export default withStyles(headerStyles)(NewReportButton);
