import React, { useContext, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import { Submission } from '../../Reports/Submission';
import { useHistory } from 'react-router';
import { RootState } from '../../../../store';
import { useQuery } from 'react-query';
import { QUERIES } from '../../../../utils/queris';
import { Report } from '../../../../models/Report.model';
import { styles } from './styles';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DraftsIcon from '@material-ui/icons/Drafts';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import { User } from '../../../../models/User.model';
import { AppContext } from '../../../../App';

const PendingReports = () => {
  const classes = styles();
  const [expand, setExpand] = useState(false);
  const [openSubmissionModal, setOpenSubmissionModal] = useState(false);
  const [targetReportId, setTargetReportId] = useState(-1);
  const currentUser: User = useContext(AppContext);
  const handleClickExpand = () => {
    setExpand(expand => !expand);
  };

  const handleClickReport = (reportId: number) => {
    setTargetReportId(reportId);
    setOpenSubmissionModal(true);
  };

  const { data: pendingReports, isLoading } = useQuery<Report[]>(
    [QUERIES.LOAD_PENDING_REPORTS],
    () => Report.getPendingReports(currentUser!.id)
  );

  if (!pendingReports || pendingReports?.length == 0) return null;

  return (
    <>
      <Typography className={classes.dailyUpdatesText}>
        Submission Reminders
      </Typography>
      <Grid
        container
        alignItems="stretch"
        justify="flex-start"
        style={{ marginBottom: 15 }}
      >
        <List className={classes.pendingReminder}>
          <ListItem id={'submissionReminderButton'} button onClick={handleClickExpand}>
            <ListItemIcon>
              <AccessAlarmIcon />
            </ListItemIcon>
            <ListItemText primary=" You need to submit some reports" />
            {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={expand} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {expand &&
                pendingReports?.map((report: Report) => (
                  <ListItem
                    button
                    id={`report-${report.id}`}
                    key={report.id}
                    className={classes.pendingListItem}
                    onClick={() => handleClickReport(report.id)}
                  >
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary={report.name} />
                  </ListItem>
                ))}
            </List>
          </Collapse>
        </List>
      </Grid>
      {targetReportId !== -1 && (
        <Submission
          reportId={targetReportId}
          open={openSubmissionModal}
          setOpen={setOpenSubmissionModal}
          setTargetReportId={setTargetReportId}
        />
      )}
    </>
  );
};

export default PendingReports;
