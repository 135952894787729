import React from 'react';

interface Props {
  label: string;
  required?: boolean;
  className: any;
}
export const FieldLabel = React.memo(
  ({ label, required = false, className }: Props) => {
    return <p className={className}>{`${label} ${required ? '*' : ''}`}</p>;
  }
);
