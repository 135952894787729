import { createStyles, makeStyles } from '@material-ui/core';
const styles = makeStyles(() =>
  createStyles({
    headerWrapper: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      width: '100%',
      padding: '0 100px',
      backgroundColor: 'transparent',
      transition: 'all 0.2s ease-in-out',
      '& .MuiAppBar-colorPrimary': {
        backgroundColor: 'transparent'
      },
      '& .MuiPaper-elevation4': {
        boxShadow: 'none'
      },
      '@media only screen and (max-width: 1040px)': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      },
      '@media only screen and (max-width: 1000px)': {
        padding: '0 25px'
      }
    },
    navbarBgWhite: {
      backgroundColor: '#fff !important',
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.15)',
      '& .MuiAppBar-colorPrimary': {
        backgroundColor: '#fff'
      },
      '& .MuiPaper-elevation4': {
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.15)'
      }
    },
    toolbar: {
      width: '100%'
    },
    drawerButton: {
      zIndex: 1400,
      display: 'none',
      '@media only screen and (max-width: 1040px)': {
        display: 'block'
      }
    },
    drawerIcon: {},
    navbarWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '@media only screen and (max-width: 1040px)': {
        '& $navLink': {
          display: 'none !important'
        }
      }
    },
    nav: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      paddingTop: '0 !important',
      '& .MuiList-padding': {
        paddingTop: 0,
        paddingBottom: 0
      },
      '@media only screen and (max-width: 1040px)': {
        justifyContent: 'space-between'
      }
    },

    navRightSide: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& button': {
        width: '113px',
        marginLeft: '20px'
      },
      '@media only screen and (max-width: 1040px)': {
        flexDirection: 'column'
      }
    },
    navLink: {
      '& hover': {
        textDecoration: 'none'
      }
    },
    navItem: {
      paddingTop: '26px !important',
      marginBottom: '12px',
      fontSize: '16px',
      color: '#3c4758',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    selectedNavItem: {
      paddingTop: '23px !important',
      paddingBottom: '10px !important',
      borderTop: '3px solid #2a5cbf',
      color: '#2a5cbf',
      fontWeight: 'bold',
      marginBottom: '10px',
      backgroundColor: 'transparent !important',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    bscrumLogoWrapper: {
      padding: '0 !important',
      fontSize: 'unset',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '41px',
      marginTop: '5px',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    bscrumLogoImg: {
      height: '100%',
      margin: '5px'
    },
    bscrumLogoText: {
      marginLeft: '2px',
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#2a5cbf'
    },
    signUpButton: {
      width: '113px',
      marginLeft: '20px'
    },
    sideDrawerNavWrapper: {
      display: 'none',
      '@media only screen and (max-width: 1040px)': {
        display: 'block !important'
      }
    },
    sideDrawer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      cursor: 'pointer'
    },

    sideNav: {
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'flex-start',
      width: '256px',
      '& $navRightSide': {
        alignItems: 'flex-start',
        width: '100%',
        '& $navLink': {
          marginBottom: '0',
          '& $navItem': {
            marginBottom: '0',
            padding: '15px !important'
          },
          '& $selectedNavItem': {
            padding: '15px !important',
            margin: '0',
            borderTop: '0',
            borderLeft: '3px solid #2a5cbf',
            color: '#2a5cbf',
            fontWeight: 'bold'
          }
        },
        '& button': {
          margin: '16px !important',
          width: '86%'
        }
      }
    }
  })
);
export default styles;
