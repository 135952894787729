import { API } from './API';
import axios from '../services/axios';
import store from '../store';
export class Question extends API {
  public id: number = -1;
  public content: string = '';
  public optional: boolean = false;
  public order_appearance: number = 1;

  constructor(
    id: number = -1,
    content: string = '',
    optional: boolean = false,
    order_appearance = 1
  ) {
    super();
    this.id = id;
    this.content = content;
    this.optional = optional;
    this.order_appearance = order_appearance;
  }

  public static getListByReportId(reportId: number): Promise<Question[]> {
    const headers = Question.getConfig();

    if (headers) {
      return new Promise<Question[]>((resolve, reject) => {
        axios
          .get('dashboard/reports/get_questions?id=' + reportId, headers)
          .then(res => {
            const questions: Question[] = res.data.questions as Question[];
            resolve(questions);
          })
          .catch(e => {
            Question.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }

    return Promise.reject('System Error');
  }
}
