import { useQuery, useInfiniteQuery } from 'react-query';
import { ActivityFilters } from '../../../models/Activity.model';
import { Member } from '../../../models/Member.model';
import { Report } from '../../../models/Report.model';
import { Workspace, WorkspaceURLParams } from '../../../models/Workspace.model';
import { Statistic } from '../../../models/Statistic.model';
import { QUERIES } from '../../../utils/queris';
import {
  Activity as ActivityModel,
  ActivityScroll
} from '../../../models/Activity.model';

export const useMember = (memberId: string) => {
  return useQuery<Member>([QUERIES.LOAD_MEMBER, memberId], () =>
    Member.getMember(memberId)
  );
};

export const useAssignedReports = (memberId: string) => {
  return useQuery<Report[]>([QUERIES.LOAD_Assigned_REPORTS, memberId], () =>
    Report.getAssignedReports(parseInt(memberId))
  );
};

export const useUserWorkspaces = (memberId: number, page: number) => {
  const params: WorkspaceURLParams = {userId: memberId, minimal: true, page: page, count: 10};
  return useQuery<{workspaces: Workspace[] ; pageCount: number }>([QUERIES.LOAD_MINIMAL_WORKSPACES , page],
    () => Workspace.getAll(params),
    { keepPreviousData : true }
  );
};

export const useMemberActivity = (activityFilters: ActivityFilters) => {
  const { selectFavorite, memberId } = activityFilters;
  return useInfiniteQuery<ActivityScroll>(
    [QUERIES.LOAD_ACTIVITIES, selectFavorite, memberId],
    ({ pageParam }) =>
      ActivityModel.getRecent(pageParam, activityFilters),
    {
      getNextPageParam: (_lastPage, _allPages) => {
        const allCount = _allPages.reduce(
          (acc, _currentPage) => acc + _currentPage.activities.length,
          0
        );
        if (_lastPage.count <= allCount) return false;
        return _allPages.length + 1;
      }
    }
  );
};

export const useStatistics = (memberId: string) => {
  return useQuery<Statistic[]>(
    [QUERIES.LOAD_STATISTICS, memberId],
    () => Statistic.getMemberStatistics(memberId)
  );
};
