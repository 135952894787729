import { Button, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Report } from '../../../models/Report.model';
import { SubmissionReminderStyles } from './styles';
import { Submission } from './Submission';
import WarningIcon from '@material-ui/icons/Warning';
import { useHistory } from 'react-router';
import { REPORT_TYPES } from '../../../utils/constants';

interface IProps {
  report: Report;
  status?: string;
}

export const SubmissionReminder = ({ report, status }: IProps) => {
  const classes = SubmissionReminderStyles();
  const [reportTargetId, setTargetReportId] = useState(-1);
  const [openSubmissionModal, setOpenSubmissionModal] = useState(false);
  const history = useHistory();
  const handleClick = () => {
    setTargetReportId(report.id);
    history.push({
      pathname: `${report.id}/submit`
    });
  };
  useEffect(() => {
    if (status) {
      setTargetReportId(report.id);
      setOpenSubmissionModal(true);
    }
  }, []);
  return (
    <>
      {report.active && (
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          className={classes.alertRoot}
        >
          <Grid item container direction="row" alignItems="center" sm>
            <Grid item>
              <WarningIcon className={classes.alertIcon} />
            </Grid>
            <Grid item>
              <span className={classes.alertMessage}>
                {report.type.title === REPORT_TYPES[4].title ? "Want to submit your report?" : "Your status is still missing from today's report"}
              </span>
            </Grid>
          </Grid>
          <Grid item>
            <Button className={classes.alertAction} onClick={handleClick}>
              Submit your status
            </Button>
          </Grid>
        </Grid>
      )}
      {reportTargetId !== -1 && (
        <Submission
          reportId={reportTargetId}
          open={openSubmissionModal}
          setOpen={setOpenSubmissionModal}
          setTargetReportId={setTargetReportId}
        />
      )}
    </>
  );
};
