import React, { useContext, useState } from 'react';
import {
  MenuItem,
  Menu,
  Divider,
  Tooltip,
  Typography
} from '@material-ui/core';
import DeleteDialog from '../../../../../Dialogs/DeleteDialog';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Comment } from '../../../../../../../models/Comment.model';
import { useDeleteComment } from '../ResponseAttachmentsHooks';
import { attachmentsStyles } from '../styles';
import { AppContext } from '../../../../../../../App';
import { User } from '../../../../../../../models/User.model';

interface CommentSubMenuProps {
  comment: Comment;
  setEditMode: any;
}

export const CommentSubMenu = ({
  comment,
  setEditMode
}: CommentSubMenuProps) => {
  const classes = attachmentsStyles();
  const currentUser: User = useContext(AppContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const deleteCommentMutation = useDeleteComment();

  const handleDeleteReport = () => {
    toggleDeleteDialog();
    deleteCommentMutation.mutate(comment);
  };

  const toggleDeleteDialog = () => {
    setOpenDeleteDialog(openDeleteDialog => !openDeleteDialog);
  };

  const handleEditReport = () => {
    setEditMode(true);
  };

  return (
    <>
      {currentUser && currentUser.id === Number(comment.member.id) && (
        <>
          <PopupState variant="popover">
            {popupState => (
              <>
                <Tooltip arrow title="Open Settings Menu">
                  <Typography
                    className={classes.moreIcon}
                    {...bindTrigger(popupState)}
                  >
                    <MoreVertOutlinedIcon fontSize="large" />
                  </Typography>
                </Tooltip>
                <Menu
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  {...bindMenu(popupState)}
                  classes={{ paper: classes.subMenuPaper }}
                >
                  <MenuItem
                    className={classes.actionMenuItem}
                    onClick={handleEditReport}
                  >
                    Edit
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    className={classes.actionMenuDeleteItem}
                    onClick={toggleDeleteDialog}
                  >
                    Delete
                  </MenuItem>
                  <DeleteDialog
                    contentText={
                      <span>Are you sure that you want to delete</span>
                    }
                    openDialog={openDeleteDialog}
                    handleDialogClose={toggleDeleteDialog}
                    handleDialogDelete={handleDeleteReport}
                  />
                </Menu>
              </>
            )}
          </PopupState>
        </>
      )}
    </>
  );
};
