import {
  Grid,
  Typography,
  Select,
  FormControl,
  InputLabel
} from '@material-ui/core';
import React from 'react';
import { RESPONSE_GROUPING_OPTIONS } from '../../../../../../utils/constants';
import { styles } from '../../styles';
import chat from '../../../../../../assets/images/chat.svg';

interface Props {
  grouping: string;
  handleChangeGrouping: (event: any) => void;
}

const Header = ({ grouping, handleChangeGrouping }: Props) => {
  const classes = styles();
  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      className={classes.responsesHeaderContainer}
    >
      <Grid
        item
        container
        alignItems="center"
        xs={6}
        className={classes.responsesTitleContainer}
      >
        <Grid item>
          <img src={chat} alt="Responses" className={classes.responsesIcon} />
        </Grid>
        <Grid item>
          <Typography className={classes.responsesTitle}>Responses</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <FormControl className={classes.formControl}>
          <InputLabel shrink classes={{ root: classes.label }}>
            Group by
          </InputLabel>
          <Select
            native
            variant="outlined"
            value={grouping}
            onChange={handleChangeGrouping}
            className={classes.groupingSelect}
          >
            {RESPONSE_GROUPING_OPTIONS.map((option: any) => (
              <option key={option.id} value={option.id}>
                {option.value}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const ResponsesHeader = React.memo(Header);
