import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as types from './ActionTypes';
import { Confirmation } from '../../models/Confirmation.model';
import { UpdateMembersSucceeded } from '../settings/Actions';

// Action Definition
export interface DismissConfirmation {
  type: types.DISMISS_CONFIRMATION;
  confirmation: Confirmation;
}

export interface AddConfirmation {
  type: types.ADD_CONFIRMATION;
  confirmation: Confirmation;
}

// Union Action Types
export type Action =
  | AddConfirmation
  | DismissConfirmation
  | UpdateMembersSucceeded;

// Action Creators
export const dismissConfirmation = (
  confirmation: Confirmation
): ThunkAction<DismissConfirmation, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): DismissConfirmation => {
    return dispatch({ type: types.DISMISS_CONFIRMATION, confirmation });
  };
};

export const addConfirmation = (
  confirmation: Confirmation
): ThunkAction<AddConfirmation, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): AddConfirmation => {
    return dispatch({ type: types.ADD_CONFIRMATION, confirmation });
  };
};
