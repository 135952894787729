import React, { useCallback, useState } from 'react';
import { Divider, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { DeleteWorkspaceDialog } from '../../components/Workspaces/Dialogs/DeleteWorkspaceDialog';
import { useDeleteWorkspace } from '../../components/Workspaces/workspaceHooks';
import { subMenuStyles } from './subMenuStyles';
import { Workspace } from '../../../models/Workspace.model';
import MoreVertIcon from '@material-ui/icons/MoreVert';

interface WorkspaceSubMenuProps {
  workspace: Workspace;
  openEditForm: (workspace: Workspace) => void;
}

export const WorkspaceSubMenu = ({
  workspace,
  openEditForm
}: WorkspaceSubMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = subMenuStyles();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const deleteWorkspace = useDeleteWorkspace();

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteWorkspace = useCallback(
    e => {
      e.stopPropagation();
      toggleDeleteDialog(e);
      deleteWorkspace.mutate(workspace.id);
    },
    [workspace]
  );

  const toggleDeleteDialog = (e: any) => {
    e.stopPropagation();
    setOpenDeleteDialog(openDeleteDialog => !openDeleteDialog);
    handleClose(e);
  };

  const handleEditWorkspace = (e: any) => {
    e.stopPropagation();
    openEditForm(workspace);
    handleClose(e);
  };

  return (
    <>
      <div className={classes.WorkspacesMoreIcon} onClick={handleClick} id={`workspace-${workspace.id}-operations`}>
        <Tooltip arrow title="Open Settings Menu" className={classes.dotsIcon}>
          <MoreVertIcon />
        </Tooltip>
      </div>
      <Menu
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        classes={{ paper: classes.subMenuPaper }}
      >
        <MenuItem
          className={`${classes.actionMenuItem} edit_workspace_button`}
          onClick={handleEditWorkspace}
        >
          Edit
        </MenuItem>
        {!workspace.is_default && <Divider />}
        {!workspace.is_default && (
          <MenuItem
            className={`${classes.actionMenuItemImportant} delete_workspace_button`}
            onClick={toggleDeleteDialog}
          >
            Delete
          </MenuItem>
        )}
        <DeleteWorkspaceDialog
          workspace={workspace}
          open={openDeleteDialog}
          handleClose={toggleDeleteDialog}
          handleDeleteWorkspace={handleDeleteWorkspace}
        />
      </Menu>
    </>
  );
};
