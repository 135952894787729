// fetch members action types
export const FETCH_MEMBERS_SUCCEEDED = 'FETCH_MEMBERS_SUCCEEDED';
export type FETCH_MEMBERS_SUCCEEDED = typeof FETCH_MEMBERS_SUCCEEDED;
export const FETCH_MEMBERS_FAILED = 'FETCH_MEMBERS_FAILED';
export type FETCH_MEMBERS_FAILED = typeof FETCH_MEMBERS_FAILED;

// fetch workspaces actions types
export const FETCH_workspaces_SUCCEEDED = 'FETCH_workspaces_SUCCEEDED';
export type FETCH_workspaces_SUCCEEDED = typeof FETCH_workspaces_SUCCEEDED;
export const FETCH_workspaces_FAILED = 'FETCH_workspaces_FAILED';
export type FETCH_workspaces_FAILED = typeof FETCH_workspaces_FAILED;

// fetch channels actions types
export const FETCH_CHANNELS_SUCCEEDED = 'FETCH_CHANNELS_SUCCEEDED';
export type FETCH_CHANNELS_SUCCEEDED = typeof FETCH_CHANNELS_SUCCEEDED;
export const FETCH_CHANNELS_FAILED = 'FETCH_CHANNELS_FAILED';
export type FETCH_CHANNELS_FAILED = typeof FETCH_CHANNELS_FAILED;

// Update roles
export const UPDATE_MEMBERS_SUCCEEDED = 'UPDATE_MEMBERS_SUCCEEDED';
export type UPDATE_MEMBERS_SUCCEEDED = typeof UPDATE_MEMBERS_SUCCEEDED;
export const UPDATE_MEMBERS_FAILED = 'UPDATE_MEMBERS_FAILED';
export type UPDATE_MEMBERS_FAILED = typeof UPDATE_MEMBERS_FAILED;

// fetch timezones actions types TIMEZONES
export const FETCH_TIMEZONES_SUCCEEDED = 'FETCH_TIMEZONES_SUCCEEDED';
export type FETCH_TIMEZONES_SUCCEEDED = typeof FETCH_TIMEZONES_SUCCEEDED;
export const FETCH_TIMEZONES_FAILED = 'FETCH_TIMEZONES_FAILED';
export type FETCH_TIMEZONES_FAILED = typeof FETCH_TIMEZONES_FAILED;

// fetch subscription actions types
export const FETCH_SUBSCRIPTION_SUCCEEDED = 'FETCH_SUBSCRIPTION_SUCCEEDED';
export type FETCH_SUBSCRIPTION_SUCCEEDED = typeof FETCH_SUBSCRIPTION_SUCCEEDED;
export const FETCH_SUBSCRIPTION_FAILED = 'FETCH_SUBSCRIPTION_FAILED';
export type FETCH_SUBSCRIPTION_FAILED = typeof FETCH_SUBSCRIPTION_FAILED;

// downgrade subscription actions types
export const CHANGE_SUBSCRIPTION__STATUS_SUCCEEDED = 'CHANGE_SUBSCRIPTION__STATUS_SUCCEEDED';
export type CHANGE_SUBSCRIPTION__STATUS_SUCCEEDED = typeof CHANGE_SUBSCRIPTION__STATUS_SUCCEEDED;
export const CHANGE_SUBSCRIPTION__STATUS_FAILD = 'CHANGE_SUBSCRIPTION__STATUS_FAILD';
export type CHANGE_SUBSCRIPTION__STATUS_FAILD = typeof CHANGE_SUBSCRIPTION__STATUS_FAILD;