import { makeStyles } from '@material-ui/core';

export const SlicedParticipantsListStyles = makeStyles({
  avatars: {
    marginLeft: 7,
    display: 'flex',
    flexDirection: 'row'
  },
  oval: {
    width: '36px',
    height: '36px',
    cursor: 'pointer',
    marginLeft: '-9px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    border: 'solid 0.8px #f0f0f0'
  },
  checkmarkIcon: {
    width: 12,
    height: 12,
    color: '#fff',
    borderRadius: '50%',
    backgroundColor: '#42ba96',
    border: 'solid 0.8px #f0f0f0'
  },
  fixedOval: {
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 0.8px #f0f0f0'
  },
  ovalText: {
    color: '#3a78f2',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: 'normal'
  },
  anchorOriginTopLeftCircle: {
    top: '7%',
    left: '7%'
  },

});
