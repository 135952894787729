import * as React from 'react';
import { styles } from '../styles';
import { Grid, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ResponseContent from './ResponseContent';
import { ReportResponse } from '../../../../../../../models/ReportResponse.model';
import { Question } from '../../../../../../../models/Question.model';
import UserComponent from '../../../../../../shared-components/UserComponent';
import { ResponseSubMenu } from '../../../../../../shared-components/SettingsSubMenus/ResponseSubmenu';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import classNames from 'classnames';

dayjs.extend(relativeTime);
interface ResponseCardProps {
  questions: Question[];
  response: ReportResponse;
}

export default function ResponseCard({
  response,
  questions
}: ResponseCardProps) {
  const classes = styles();

  return (
    <Grid
      item
      container
      direction="column"
      justify="space-between"
      className={classes.root}
    >
      <Grid container item direction="row" alignItems="center">
        <Grid item container alignItems="center" sm>
          <Grid item>
            <UserComponent
              name={response.member.name}
              avatar={response.member.avatar}
              id={response.member.id}
            />
          </Grid>
          {response.editTime && (
            <Grid item>
              <Typography className={classes.time}>
                (updated &nbsp;
                <span className={classes.timeSpan}>
                  {dayjs(response.editTime).fromNow(true)}
                </span>
                &nbsp;ago)
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid>
          <Grid
            container
            item
            xs={1}
            sm={1}
            md={1}
            justify="flex-end"
            alignItems="center"
          >
            <Typography>
              <ResponseSubMenu response={response} />
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classNames(classes.responseTime, classes.time)}>
          <AccessTimeIcon />
          <span className={classes.timeSpan}>
            {dayjs(response.time).fromNow(true)}
          </span>
          &nbsp;ago
        </Grid>
      </Grid>

      <ResponseContent questions={questions} response={response} />
    </Grid>
  );
}
