import React, { useState } from 'react';
import { styles } from './styles';
import Select from '@material-ui/core/Select';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

interface props {
  onChange(isFavoriteSelected: boolean): void;
}

const ActivityOption = {
  allOption: 1,
  favoriteOption: 2
};

const ReportsFilter = ({ onChange }: props) => {
  const classes = styles();

  const [selectFavorite, setSelectFavorite] = useState(
    Number(localStorage.getItem('activityOption')) ==
      ActivityOption.favoriteOption || false
  );

  const handleReportFilterChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedOption: any = event.target.value;
    setSelectFavorite(Number(selectedOption) == ActivityOption.favoriteOption);
    onChange(Number(selectedOption) == ActivityOption.favoriteOption);
    localStorage.setItem('activityOption', selectedOption);
  };

  return (
    <Select
      id={'reportsFilter'}
      className={classes.selectField}
      MenuProps={{
        classes: { paper: classes.dropdownStyle }
      }}
      value={
        selectFavorite
          ? ActivityOption.favoriteOption
          : ActivityOption.allOption
      }
      variant="outlined"
      onChange={handleReportFilterChange}
      IconComponent={KeyboardArrowUpIcon}
    >
      <option value={ActivityOption.allOption}>All Reports</option>
      <option value={ActivityOption.favoriteOption}>Favorite Reports</option>
    </Select>
  );
};
export default ReportsFilter;
