import React from 'react';
import { PollSubmission } from './PollSubmission';
import { ReportSubmission } from './ReportSubmission';
import '@draft-js-plugins/mention/lib/plugin.css';
import { useMembers, useReports } from '../ReportForm/reportFormHooks';
import CircularLoader from '../../../shared-components/CircularLoader';
import { REPORT_TYPES } from '../../../../utils/constants';

interface IProps {
  reportId: number;
  open: boolean;
  setOpen: any;
  setTargetReportId: any;
}
export const Submission = ({
  reportId,
  open,
  setOpen,
  setTargetReportId
}: IProps) => {
  const { data: report, isLoading: isReportLoading } = useReports(reportId);
  const { data: members, isLoading: isMembersLoading } = useMembers();

  const isLoading = isReportLoading || isMembersLoading;

  return (
    <CircularLoader isLoading={isLoading}>
      {report?.type.title === REPORT_TYPES[3].title ? (
        <PollSubmission
          report={report!}
          open={open}
          setOpen={setOpen}
          setTargetReportId={setTargetReportId}
        />
      ) : (
        <ReportSubmission
          members={members!}
          report={report!}
          open={open}
          setOpen={setOpen}
          setTargetReportId={setTargetReportId}
        />
      )}
    </CircularLoader>
  );
};
