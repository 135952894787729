import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useFetchOAuthUrls } from '../Auth/AuthHooks';
import { useSignout } from './homeHooks';
import { styles } from './Sidebar/logoutConfirmationStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

export default function BlockingDialog() {
  const { data: authURLS } = useFetchOAuthUrls();
  const signOut = useSignout();
  const classes = styles();

  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.logoutDialog
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop
          }
        }}
      >
        <div className={classes.logoutDialogHeader}>
          <Typography className={classes.logoutTitle}>
            Add BScrum to Slack ?
          </Typography>
        </div>
        <DialogContent>
          <DialogContentText
            className={classes.dialogText}
            id="alert-dialog-description"
          >
            BScrum has been removed from your Slack workspace. So, you can't
            perform any action in the application.
          </DialogContentText>
          <DialogContentText
            className={classes.dialogText}
            id="alert-dialog-description"
          >
            If you want to continue using BScrum, please add it to Slack
            workspace again.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogBtns}>
          <Button
            className={classes.logoutBtn}
            classes={{
              label: classNames(classes.logoutBtnsTxt, classes.logoutTxt)
            }}
            onClick={() => signOut.mutate()}
            color="secondary"
          >
            Discard and Logout
          </Button>
          <Button
            className={classes.closeLogoutBtn}
            classes={{
              label: classNames(classes.logoutBtnsTxt, classes.closeLogoutTxt)
            }}
            color="primary"
            href={authURLS?.add_to_slack}
            onClick={() => localStorage.clear()}
          >
            Add to Slack Again
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
