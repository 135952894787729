import axios from '../services/axios';
import { API } from './API';
import store from '../store';

export class Team extends API {
  public id: number = -1;
  public name: string = '';
  public integrated_with_slack: boolean = true;

  public static async getTeam(teamId:number):Promise<Team>{
    const headers = Team.getConfig();
    if (headers) {
      return new Promise<Team>((resolve, reject) => {
        axios
          .get(`/dashboard/teams/${teamId}`, headers)
          .then(res => {
            resolve(res.data.team as Team);
          })
          .catch(e => {
            Team.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject("can't fetch team data");
  }
}
