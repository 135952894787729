import React from 'react';
import {
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

interface DeleteDialogProps {
  contentText: JSX.Element;
  openDialog: boolean;
  handleDialogClose: (e: any) => void;
  handleDialogDelete: (e: any) => void;
}

export default function DeleteDialog(props: DeleteDialogProps) {
  let { contentText, openDialog, handleDialogClose, handleDialogDelete } =
    props;
  return (
    <Dialog open={openDialog}>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} autoFocus>
          Cancel
        </Button>
        <Button onClick={handleDialogDelete} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
