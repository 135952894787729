import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTooltipSysles } from './TooltipStyles';

interface TooltipProps {
  title: string;
  children: any;
  placement: any;
}

const CustomizedTooltip = ({ title, children, placement }: TooltipProps) => {
  const classes = useTooltipSysles();
  return (
    <Tooltip placement={placement} title={title} classes={classes} arrow>
      {children}
    </Tooltip>
  );
};

export default React.memo(CustomizedTooltip);
