import { API } from './API';
import axios from '../services/axios';
import store from '../store';
export class Plan extends API {
  public price: number = 0;
  public name: string = '';
  public max_number_reports: number = 2;

  public static async getAll(): Promise<Plan[]> {
    let headers = Plan.getConfig();

    if (headers) {
      return new Promise<Plan[]>((resolve, reject) => {
        axios
          .get('payments/plans', headers)
          .then(res => {
            let plans: Plan[] = res.data;
            resolve(plans);
          })
          .catch(e => {
            Plan.checkUnauthorizedRequest(e,store);
            reject(e);
          });
      });
    }
    return Promise.reject('System Error');
  }
}
