import React from 'react';
import { workspaceDetailsStyles } from './workspaceDetailsStyles';
import { Grid, Typography } from '@material-ui/core';
import { Member } from '../../../../models/Member.model';
import UserComponent from '../../../shared-components/UserComponent';

interface IProps {
  members: Member[];
}
export const WorkspaceUsersList = ({ members }: IProps) => {
  const classes = workspaceDetailsStyles();

  return (
    <>
      <Grid item>
        <Typography className={classes.listTitle}>
          {`Workspace users (${members?.length})`}
        </Typography>
      </Grid>
      <div className={classes.scrollableRightSide}>
        <Grid
          item
          container
          direction="column"
          spacing={1}
          className={classes.usersList}
        >
          {members?.map(member => (
            <Grid item className={classes.listItem} key={member.id}>
              <UserComponent
                wrapperClass="user-wrapper"
                name={member.name}
                id={member.id}
                avatar={member.avatar}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};
