import React from 'react';
import ResponsesGrid from './ResponsesGrid';

const Dashboard = () => {
  return (
      <ResponsesGrid />
  );
};

export default Dashboard;
